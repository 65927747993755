import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import separator from "assets/img/Separator.png";
import { BannerBox } from "utils/DynamicComponents/LandingComponents";
import { Dynamic_Style } from "./Dynamic_Style";
import { BannerTitle } from "utils/DynamicComponents/LandingComponents";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function NewPolicies(props) {
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const isMobile = useMediaQuery('(max-width: 1152px)');

    return (
        <div>
            <Grid container spacing={0} style={{marginTop: "2em",marginLeft: "1em",marginRight: "1em"}}>
                <BannerTitle
                    Header={"POLICIES"}
                />
                <Grid container
                    lg={4}
                    xs={11}
                    style={{
                        ...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        gap: '20px',
                        flexWrap:"nowrap", overflow:"auto"
                    }}
                >
                    <BannerBox Dotted={true} ColumnSize={2} Heading={"TOTAL"} Data={props.Total}/>
                    <BannerBox ColumnSize={2} Heading={"Online"} Data={props.Online}/>
                    <BannerBox ColumnSize={2} Heading={"CRC"} Data={props.Crc} />
                    {props.HasAgency && <BannerBox ColumnSize={2} Heading={"Agency"} Data={props.Agency} />}
                    <BannerBox ColumnSize={2} Heading={"Combo"} Data={props.Combo} Popover={"Policies quoted online initially then bound by internal CURE reps only"}/>
                </Grid>
                <Grid container
                    lg={4}
                    xs={11}
                    style={{
                        ...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        gap: '12px', flexWrap:"nowrap", overflow:"auto"
                    }}
                >
                     <img src={separator} width="100%" style={isMobile ? Dynamic_Style.SeparatorWithOutSubHeader_Mobile : Dynamic_Style.SeparatorWithOutSubHeader_Desktop} alt="..." />
                     <span style={isMobile ? Dynamic_Style.YTDLabelWithOutSubHeader_Mobile : Dynamic_Style.YTDLabelWithOutSubHeader_Desktop}>
                         YTD
                     </span>
                    <BannerBox
                        Dotted={true}
                        ColumnSize={2}
                        Heading={"Total"}
                        Data={props.ytddata.policytotalytd}
                        style={{ marginLeft: '1em' }}
                    />
                    <BannerBox ColumnSize={2} Heading={"Online"} Data={props.ytddata.policyonlineytd}/>
                    <BannerBox ColumnSize={2} Heading={"CRC"} Data={props.ytddata.policycrcytd} />
                    {props.HasAgency && <BannerBox ColumnSize={2} Heading={"Agency"} Data={props.ytddata.policyagencyytd} />}
                    <BannerBox ColumnSize={2} Heading={"Combo"} Data={props.ytddata.policycomboytd} Popover={"Policies quoted online initially then bound by internal CURE reps only"}/>
                </Grid>                        
            </Grid>
        </div>
    );
}
