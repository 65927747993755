import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, Router } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from 'react-loading';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box } from '@mui/material/';
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "utils/Grid/GridContainer";
import GridItem from "utils/Grid/GridItem";
import { Grid } from "@material-ui/core";
// react component plugin for creating a beautiful datetime dropdown picker
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import lightBlue from "@material-ui/core/colors/lightBlue";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import '../../assets/css/datepicker.css'
import { Datepicker, setOptions } from '@mobiscroll/react';
import {
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

// core components
import Button from "utils/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import { Styles } from "./MainNavbar_Styles";
import avatar from "assets/img/Logo.png";
import { ViewSize } from "./MainNavbar.Utils";
import { ChangeView, Header, DateSelection, LinkBar, SearchProjectReleases, Wrapper } from "./MainNavbar.Components";
import ReleaseDrawer from 'components/ReleaseDrawer';

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
    //const history = useHistory();
    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });    

    const [open, setOpen] = React.useState(false);
    

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const [groupDateBy, setGroupDateBy] = React.useState("Day");//React.useState(props.groupDateBy);

    const handleStartDateChange = (date) => {
        props.onChangeStartDate(date);
    };

    const handleEndDateChange = (date) => {
        props.onChangeEndDate(date);
    };

    const handleGroupDateByChange = (selected) => {
        setGroupDateBy(selected.target.value);
        props.onChangeGroupDateBy(selected.target.value);
    };

    const handleCall = () => {
        props.onChangeCall();
    };

    // verifies if routeName is the one active (in browser input)
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    const classes = useStyles();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });

    const [allReleases, setAllReleases] = React.useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);

    // Add this function to handle filter changes
    const handleFilterChange = (releases, types) => {
        setAllReleases(releases);
        setSelectedTypes(types);
    };

    return (
        <>
            <AppBar position="static" className={classes.appBar + appBarClasses} style={Styles.AppBar}>
                <Toolbar className={classes.container} style={Styles.ToolBar}>
                    <Grid container>                       
                        <Grid item xs={12} style={{ display: "inherit" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <Grid item xs={3} sm={4} md={3} lg={3} >
                                        <Button href="/" style={Styles.Logo_Button} className={classes.title} color="transparent">
                                            <img src={avatar} height="auto" width="90%" style={Styles["Logo_"+ViewSize()]} alt="..." />
                                        </Button>
                                    </Grid>
                                    <Grid item container lg={5} sm={8} xs={9} style={Styles['ChangeViewHeader_'+ViewSize()+'_Box']}>
                                        <Header state={props.state} city={props.city} ViewSize={ViewSize}/>
                                        {!(activeRoute("cancelandrenewal") || activeRoute("onlinebinds"))&&<ChangeView  state={props.state}  ViewSize={ViewSize}/>}
                                    </Grid>
                                
                                    <DateSelection 
                                            handleCall={handleCall} 
                                            handleStartDateChange={handleStartDateChange} 
                                            handleEndDateChange={handleEndDateChange}
                                            ViewSize={ViewSize}
                                            startDate={props.startDate}
                                            endDate={props.endDate}
                                            call={props.call}
                                            onFilterChange={handleFilterChange}
                                    />

                                    <LinkBar state={props.state} city={props.city} ViewSize={ViewSize}/>
                                </Grid>
                                <Grid container style={activeRoute("options") || activeRoute("dma") ? {} : { display: "none" }}>
                                    <Grid item xs={3} sm={3}>
                                        <div style={{ marginTop: "15px" }}>
                                            <label style={{ "color": "white", fontSize: "18px" }}>SUM BY OPTIONS</label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8} sm={8}>
                                        <Select
                                            value={groupDateBy}
                                            onChange={handleGroupDateByChange}
                                            SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '0.45em 3em', 'marginBottom': '8px' } }}
                                        >
                                            <MenuItem value=""> </MenuItem>
                                            <MenuItem value="Day">Day</MenuItem>
                                            <MenuItem value="Month">Month</MenuItem>
                                            <MenuItem value="Year">Year</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <ReleaseDrawer
                allReleases={allReleases}
                selectedTypes={selectedTypes}
                startDate={props.startDate}
                endDate={props.endDate}
                state={props.state}
            />
        </>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string
};