import React from 'react';
import { Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop } from '@material-ui/core';

const columnMapping = {
    ReleaseDate: "Release Date",
    AppArea: "App Area",
    ReleaseType: "Release Type",
    Project: "Project"
};

const transformData = (data) => {
    return data.map(row => {
        const newRow = {};
        Object.keys(row).forEach(key => {
            newRow[columnMapping[key] || key] = row[key];
        });
        return newRow;
    });
};

const SearchResultsModal = ({ open, handleClose, data }) => {
    const transformedData = transformData(data);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper style={{ margin: 'auto', marginTop: '5%', padding: '20px', maxWidth: '80%', maxHeight: '80%', display: 'flex', flexDirection: 'column' }}>
                <TableContainer style={{ maxHeight: 'calc(100% - 40px)', overflowY: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {transformedData.length > 0 && Object.keys(transformedData[0]).map((key) => (
                                    <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transformedData.map((row, index) => (
                                <TableRow key={index}>
                                    {Object.values(row).map((value, idx) => (
                                        <TableCell key={idx}>{value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Modal>
    );
};

export default SearchResultsModal;