/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Landing from "layouts/Landing.js";

import AuthLayout from "layouts/MI/Home.js";
import PolicyHolders from "layouts/MI/PolicyHolder";
import StolenVehicles from "layouts/MI/StolenVehicles";
import MIOptions from "layouts/MI/AdvanceOptions.js";
import NJOptions from "layouts/NJ/AdvanceOptions.js";
import PAOptions from "layouts/PA/AdvanceOptions.js";
import AllOptions from "layouts/all/AdvanceOptions.js";
import DMA from "layouts/MI/DMA.js";
import Comparison from "layouts/MI/Comparison.js";
import CancelAndRenewalMI from "layouts/MI/CancelsRenewals.js";
import CancelAndRenewalNJ from "layouts/NJ/CancelsRenewals.js";
import Projections from "layouts/MI/Projections.js";

import AuthLayout_NJ from "layouts/NJ/Home.js"
import AuthLayout_PA from "layouts/PA/Home.js"
import Comparison_NJ from "layouts/NJ/Comparison.js"
import Comparison_PA from "layouts/PA/Comparison.js"
import Comparison_all from "layouts/all/Comparison.js"
import Comparison_Detroit from "layouts/MI/Detroit/Comparison.js"
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { CookiesProvider } from "react-cookie";
import QuotesMap from "layouts/MI/QuotesMap";
import ActivePolicyZip from "layouts/MI/ActivePolicyZip";
import AutoAppraisal from "layouts/all/AutoAppraisal";

import AuthLayout_Detroit from "layouts/MI/Detroit/Home.js";
import AuthLayout_All from "layouts/all/Home.js"
import SharedDriveData from "./components/SharedDriveData";

const hist = createBrowserHistory();

ReactDOM.render(
  <CookiesProvider>
    <Router history={hist}>
        <Switch>
        <Route path="/all/pass" component={AuthLayout_All} />
        <Route path="/autoappraisal" component={AutoAppraisal} />
        <Route path="/MI/Detroit/pass" component={AuthLayout_Detroit} />
        <Route path="/pass" component={AuthLayout} />
        <Route path="/policyholders" component={PolicyHolders} />
        <Route path="/NJ/policyholders" component={PolicyHolders} />
        <Route path="/activepolicyzip" component={ActivePolicyZip} />
        <Route path="/quotesmap" component={QuotesMap} />
        <Route path="/stolenvehicles" component={StolenVehicles} />
        <Route path="/NJ/pass" component={AuthLayout_NJ} />
        <Route path="/PA/pass" component={AuthLayout_PA} />
        <Route path="/NJ/comparison" component={Comparison_NJ} />
        <Route path="/all/comparison" component={Comparison_all} />
        <Route path="/MI/options" component={MIOptions} />
        <Route path="/NJ/options" component={NJOptions} />
        <Route path="/PA/options" component={PAOptions} />
        <Route path="/all/options" component={AllOptions} />
        <Route path="/comparison" component={Comparison} />
        <Route path="/MI/cancelandrenewal" component={CancelAndRenewalMI} />
        <Route path="/NJ/cancelandrenewal" component={CancelAndRenewalNJ} />
        <Route path="/projections" component={Projections} />
        <Route path="/shareddrive" component={ SharedDriveData} />
        <Route path="/dma" component={DMA} />
        <Route path="/PA/Comparison" component={Comparison_PA} />
        <Route path="/Detroit/Comparison" component={Comparison_Detroit} />
        <Route path="/" component={Landing} />
      </Switch>
    </Router>
  </CookiesProvider>
  ,
  document.getElementById("root")
);
