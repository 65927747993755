import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import Button from "utils/CustomButtons/Button";
// @material-ui/core components
import TablePagination from '@mui/material/TablePagination';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Card from "utils/Card/Card";
import CardHeader from "utils/Card/CardHeader";
import CardBody from "utils/Card/CardBody";
import ChartistGraph from "react-chartist";
import CardFooter from "utils/Card/CardFooter";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { Pie } from 'react-chartjs-2';
import { styled } from '@material-ui/styles';
import marker from "assets/img/marker.png";
import { Rotate90DegreesCcw } from "@material-ui/icons";
import { GetPremiumData } from "services/Options";
import { formatDate } from "services/Options";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function GWPPP_Detailed(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [renewaldata, setRenewalData] = React.useState([]);
    const [newbusinessdata, setNewBusinessData] = React.useState([]);
    const [totaldata, setTotalData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isPP, setIsPP] = React.useState(false);


    const GetData = async (toggle) => {
        const data = await GetPremiumData(props.startDate, props.endDate,props.states,props.groupDateBy,toggle)
        if (data.aggPPRenewalResult !== undefined) {
            setRenewalData(data.aggPPRenewalResult);
        }

        if (data.aggPPNewBusinessResult !== undefined) {
            setNewBusinessData(data.aggPPNewBusinessResult);
        }

        if (data.aggPPTotalResult !== undefined) {
            setTotalData(data.aggPPTotalResult);
        }
        setLoading(false);
    };

    const handleIsPP = () => {
        var toggle = !isPP;
        setIsPP(toggle);
        setLoading(true);
        GetData(toggle);
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData(isPP);
            setLoading(true);
        }
      
    }, [props.call]);


    return (
        <Grid container> {/* Quotes Table */}
            <Grid item lg={12} xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        margin: "0em",
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        paddingTop: "0.25em",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        paddingTop: "0.25em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                {isPP ?
                                    "POSTED PREMIUM"
                                    :
                                    "GROSS WRITTEN PREMIUM"
                                }
                                </span>
                        </span>
                        <Button onClick={handleIsPP} style={{ color: 'white', margin: '20px', backgroundColor: '#ff8000' }} color="primary" size="md"> {isPP ? "TOGGLE GWP" : "TOGGLE PP"}</Button>
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                Renewal Premium
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <DataTableView
                                data={renewaldata}
                                groupDateBy={props.groupDateBy}
                                states={props.states}
                            />
                        </LoadingOverlay>

                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                New Business Premium
                            </span>
                        </span>


                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <DataTableView
                                data={newbusinessdata}
                                groupDateBy={props.groupDateBy}
                                states={props.states}
                            />
                        </LoadingOverlay>
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                {isPP ?
                                    "Total Posted Premium"
                                    :
                                    "Total Gross Written Premium"
                                }
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                        <DataTableView
                            data={totaldata}
                            groupDateBy={props.groupDateBy}
                            states={props.states}
                        />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_Gross_Written_Premium"
                        />

                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    );
}
const DataTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isPA = qprops.states.length === 1 && qprops.states[0] === 'PA';

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px"}}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", minWidth: "120px" }}>{qprops.groupDateBy}</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>TOTAL PREM</TableCell>
                            {!isPA && <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>PIP</TableCell>}
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>COMP</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>COLL</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>BI</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>UMBI</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>UIMBI</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>PD</TableCell>
                            {!isPA && <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>PPI</TableCell>}
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>RENT</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px" }}>ROAD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                            <TableRow
                                key={row.date}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                    <TableCell component="th" scope="row" style={row.date == "Total" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                    {row.date !== "Total" ? formatDate(row.date) : row.date }
                                </TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.total.toFixed(2))}</TableCell>
                                {!isPA && <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.pipPrem.toFixed(2))}</TableCell>}
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.compPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.collPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.biPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.umbiPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.uimbiPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.pdPrem.toFixed(2))}</TableCell>
                                {!isPA && <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.ppiPrem.toFixed(2))}</TableCell>}
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.rentPrem.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.roadPrem.toFixed(2))}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper>
        );
}
