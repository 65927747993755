import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import separator from "assets/img/Separator.png";
import { CallAPI, all } from "services/all/API";
import { BannerBox } from "utils/DynamicComponents/LandingComponents";
import { Dynamic_Style } from "./Dynamic_Style";
import { BannerTitle } from "utils/DynamicComponents/LandingComponents";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function NewApps(props) {
    const didMount = React.useRef(false);
    const [appData, setAppData] = React.useState({});

    const RefreshApp = async () => {
        const data = await CallAPI(all.GetAppData, {
            startDate: props.startDate,
            endDate: props.endDate,
            states: props.states,
            city: props.city
        });
        if (data) {
            setAppData(data.result[0])
        }
    }
    
    useEffect(() => {
        async function fetchData() {
            let obj;
            if (props.call === "true" || props.call == null) {
                setAppData({})
                await Promise.all([RefreshApp()])
                    .then(() => {
                        props.fetchCompleted();
                    })
                    .catch(error => {
                        console.error("Error fetching data: ", error);
                        // Handle any errors here
                    });
            }
        }

        if (didMount.current) {
            fetchData();
        } else {
            didMount.current = true;
        }
    }, [props.call]);

    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const isMobile = useMediaQuery('(max-width: 1152px)');
    return (
        <div>
            <Grid container spacing={0} style={{marginTop: "2em",marginLeft: "1em",marginRight: "1em"}}>
                <BannerTitle
                    Header={"APPLICATIONS"}
                />
                <Grid container
                    lg={4}
                    xs={11}
                    style={{...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        flexWrap:"nowrap", overflow:"auto"
                    }}
                >
                    <BannerBox Dotted={true} ColumnSize={3} Heading={"TOTAL"} Data={appData.total}/>
                    <BannerBox ColumnSize={3} Heading={"Online"} Data={appData.online}/>
                    <BannerBox ColumnSize={3} Heading={"CRC"} Data={appData.crc}/>
                    {props.HasAgency && <BannerBox ColumnSize={3} Heading={"Agency"} Data={appData.agency}/>}
                </Grid>
                <Grid container
                    lg={4}
                    xs={11}
                    style={{...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        flexWrap:"nowrap", overflow:"auto"
                    }}
                >
                     <img src={separator} width="100%" style={isMobile ? Dynamic_Style.SeparatorWithOutSubHeader_Mobile : Dynamic_Style.SeparatorWithOutSubHeader_Desktop} alt="..." />
                     <span style={isMobile ? Dynamic_Style.YTDLabelWithOutSubHeader_Mobile : Dynamic_Style.YTDLabelWithOutSubHeader_Desktop}>
                         YTD
                     </span>
                    <BannerBox Dotted={true} ColumnSize={3} Heading={"Total"} Data={props.ytddata.apptotalytd}/>
                    <BannerBox ColumnSize={3} Heading={"Online"} Data={props.ytddata.apponlineytd}/>
                    <BannerBox ColumnSize={3} Heading={"CRC"} Data={props.ytddata.appcrcytd}/>
                    {props.HasAgency && <BannerBox ColumnSize={3} Heading={"Agency"} Data={props.ytddata.appagencyytd}/>}
                </Grid>                        
            </Grid>
        </div>
    );
}
