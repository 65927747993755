import { Grid, IconButton, Popover, Typography } from "@material-ui/core";
import React, { useEffect, useReducer } from "react";
import { Hidden } from "@material-ui/core";
import GridContainer from "utils/Grid/GridContainer";
import GridItem from "utils/Grid/GridItem";
import separator from "assets/img/Separator.png";
import { Help } from "@material-ui/icons";
import CustomDropdown from "utils/CustomDropdown/CustomDropdown";
import ReactLoading from 'react-loading';
import Button from "utils/CustomButtons/Button";
import TooltipButton from "utils/TooltipButton/TooltipButton";

//Show download button in staging environment
const production = 0;

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

// Reducer initial state
const initialState = {
    loading: false,
    error: null,
    data: {}
};

// Action types
const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

// Reducer function
const dataReducer = (state, action) => {
    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            };
        default:
            return state;
    }
};

export const Savings = (props) => {
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [text, setText] = React.useState(props.Percentage);

    const [state, dispatch] = useReducer(dataReducer, initialState);


    const RefreshSavings = async () => {
        dispatch({ type: FETCH_DATA_REQUEST });

        var percentageFormatted = text.toString().replace('%', '').replace('any', '-1').replace('ANY', '-1');
        try {
            await fetch('/GetSavingsData?' + new URLSearchParams(Object.entries({
                startDate: props.startDate,
                endDate: props.endDate,
                states: props.state && [props.state] || props.states,
                city: props.city,
                percentage: percentageFormatted
            }).filter(([k, v]) => v != null).flatMap(([k, v]) => Array.isArray(v) ? v.map((x) => [k, x]) : [[k, v]])))
                .then((response) => {
                    if (!response.ok) {
                        dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
                    }
                    return response.json();
                })
                .then((data) => {
                    dispatch({ type: FETCH_DATA_SUCCESS, payload: data });
                });
        } catch (error) {
            dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
        }
    };

    const buttonTextSetTo = (text) => {
        setText(text);
    };

    const { loading, error, data } = state;

    const downloadCSV = () => {
        var filename = "exportSavingsData_" + props.startDate + "_to_" + props.endDate+".csv";
        if (data?.export != null) {
            function convertToCSVRow(obj) {
                const values = Object.values(obj);
                return values.map(value => `"${value}"`).join(",");
            }

            let csvContent = "Quote Number, Policy Number, Previous Premium, Current Premium, Percentage Ceiling\n";
            data.export.forEach(result => {
                const csvRow = convertToCSVRow(result);
                csvContent += csvRow + "\n";
            });
            console.log(csvContent);
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const newTab = window.open();
                newTab.document.write(csvContent);
                newTab.document.close();
            }
        }
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            RefreshSavings();
        }
    }, [props.call]);

    useEffect(() => {
        RefreshSavings();
    }, [text]);

    return (
        <div
            style={{
                marginTop: "2em",
                backgroundColor: "#f1f1f1"
            }}
        >
            <Grid container

                spacing={0}

            >
                <Grid item
                    lg={3}
                    sm={5}
                    xs={9}
                    style={{ textAlign: "left" }}
                >
                    <span
                        style={
                            isMobile ?
                                {
                                    fontSize: "1.6em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: ".4em",
                                    display: "inline-block"
                                }
                                :
                                {
                                    fontSize: "2em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: "1em",
                                    display: "inline-block"
                                }
                        }
                    >
                        TODAY'S <b>SAVINGS</b>
                        <TooltipButton tooltipText="Now includes online and CRC data." />
                    </span>
                </Grid>
                <Grid item lg={3} sm={7} xs={3}
                    style={{
                        paddingTop: "1.2em",
                        paddingBottom: "1em",
                        textAlign: "left",
                        display: "inline-block"
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <CustomDropdown
                            className="112122"
                            Background={"#ff8000"}
                            fontSize={"0.9em"}
                            buttonText={text}
                            Size={isMobile ? "1.1em" : "1.4em"}
                            Padding={isMobile ? ".7em" : ""}
                            dropdownList={[
                                "10%",
                                "20%",
                                "30%",
                                "40%",
                                "50%",
                                "60%",
                                "70%",
                                "80%",
                                "90%",
                                "100%",
                                "ANY"
                            ]}
                            setButtonText={buttonTextSetTo}
                        />
                        <ReactLoading type={'spin'} style={loading == true ? { fill: "black", height: "30px", width: "30px", marginLeft: "1em" } : { display: "none" }} height={isMobile ? 30 : 30} width={isMobile ? 30 : 30} />
                        <Button onClick={downloadCSV} style={loading == false && data?.export != null && production == 0 ? { color: 'white', marginLeft: "1em", backgroundColor: '#ff8000' } : { display: "none" }}  color="primary" size="sm"><i class="fa fa-download"></i></Button>

                    </div>
                </Grid>
                <Grid item lg xs={12} sm={6}
                    style={
                        isMobile ?
                            {
                                textAlign: "left",
                                paddingBottom: "2em",
                                fontSize: "1em"
                            }
                            :
                            {
                                paddingTop: "2em",
                                textAlign: "center",
                                paddingBottom: "2em"
                            }
                    }
                >
                    <span
                        style={{
                            fontSize: "2em"
                        }}
                    >
                        QUOTES
                    </span>
                    <span
                        style={{
                            color: "#ff8000",
                            paddingLeft: ".5em",
                            fontSize: "2em",
                        }}>
                        {data.saving?.quotetotalnumber ? data.saving?.quoteactivetotalnumber.toLocaleString() + '/' + data.saving?.quotetotalnumber.toLocaleString() : '--/--'}
                        <TooltipButton tooltipText="Includes only applicants that provided previous premium values between $200-$5,000 for a 6-month term.  #Quotes with Savings/Total Quotes meeting prev carrier premium criteria" />
                    </span>
                </Grid>
                <Grid item lg xs={12} sm={6}
                    style={
                        isMobile ?
                            {
                                textAlign: "left",
                                paddingBottom: "2em",
                                fontSize: "1em"
                            }
                            :
                            {
                                paddingTop: "2em",
                                textAlign: "center",
                                paddingBottom: "2em"
                            }
                    }
                >
                    <span
                        style={{
                            fontSize: "2em"
                        }}
                    >
                        BOUND
                    </span>
                    <span
                        style={{
                            color: "#ff8000",
                            fontSize: "2em",
                            paddingLeft: ".5em",
                        }}>
                        {data.saving?.policytotalnumber ? data.saving?.policyactivetotalnumber.toLocaleString() + '/' + data.saving?.policytotalnumber.toLocaleString() : '--/--'}
                        <TooltipButton tooltipText="Includes only applicants that provided previous premium values between $200-$5,000 for a 6-month term.  #Binds with Savings/Total Binds meeting prev carrier premium criteria" />
                    </span>
                </Grid>
            </Grid>
            <Grid container>
                <SavingsTile
                    key={1}
                    Text="Avg. Savings Per Quote"
                    Val={data.saving?.quoteaveragesavings ? '$' + data.saving?.quoteaveragesavings.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '$--'}
                    Popover="Includes only quotes in which money was saved"
                />
                <SavingsTile
                    key={2}
                    Text="Highest Savings Any Single Quote"
                    Val={data.saving?.quotemaxsavings ? '$' + data.saving?.quotemaxsavings.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '$--'}
                />
                <SavingsTile
                    key={3}
                    Text="Quotes Applicant Saved Money"
                    Val={data.saving?.quotesapplicantsavedmoney ? data.saving?.quotesapplicantsavedmoney.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%' : '--%'}
                />
            </Grid>
            <Grid container>
                <SavingsTile
                    key={4}
                    Text="Avg Savings Per Bound Policy"
                    Val={data.saving?.policyaveragesavings ? '$' + data.saving?.policyaveragesavings.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '$--'}
                    Popover="Includes only quotes in which money was saved"
                />
                <SavingsTile
                    key={5}
                    Text="Highest Savings on Any Single Bound Policy"
                    Val={data.saving?.policymaxsavings ? '$' + data.saving?.policymaxsavings.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '$--'}
                />
                <SavingsTile
                    key={6}
                    Text="Bound Policies Applicant Saved Money"
                    Val={data.saving?.policyapplicantsavedmoney ? data.saving?.policyapplicantsavedmoney.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%' : '--%'}

                />
            </Grid>
        </div>
    );
}

const SavingsTile = (props) => {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    return (
        <Grid container
            style={isMobile ? {
                backgroundColor: "#e4e4e4",
                height: "10em",
                marginBottom: "2em",
                fontSize: "1.1em"
            }
                :
                {
                    backgroundColor: "#e4e4e4",
                    height: "8em",
                    marginLeft: "1em",
                    marginBottom: "1em",
                    maxWidth: "31%",
                    fontSize: "1.5em"
                }
            }
        >

            <Grid item xs={7}
                style={isMobile ?
                    {
                        fontSize: "1.4em",
                        padding: "1em",
                        lineHeight: "1.3em"
                    }
                    :
                    {
                        fontSize: "1.2em",
                        padding: "1em",
                        lineHeight: "1.3em"
                    }
                }
            >
                {props.Text}
                {!!props.Popover && <TooltipButton tooltipText={ props.Popover} />}
            </Grid>
            <Grid item xs={5}
                style={
                    isMobile ?
                        {
                            fontSize: "1.9em",
                            color: "#ff8000",
                            paddingTop: "1.9em",
                            fontWeight: "",
                            textAlign: "center"
                        }
                        :
                        {
                            fontSize: "1.8em",
                            color: "#ff8000",
                            paddingTop: "1.5em",
                            fontWeight: "",
                            textAlign: "center"
                        }
                }
            >
                {props.Val}
            </Grid>
        </Grid>
    );
}