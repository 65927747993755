import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from 'react-loading';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "utils/Grid/GridContainer";
import GridItem from "utils/Grid/GridItem";
import { FormControl, Grid } from "@material-ui/core";
// react component plugin for creating a beautiful datetime dropdown picker
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
import lightBlue from "@material-ui/core/colors/lightBlue";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';

// core components
import Button from "utils/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

import avatar from "assets/img/Logo.png";
import stateMI from "assets/img/MI_State.png";
import stateNJ from "assets/img/NJ_State.png";
import { Label } from "@material-ui/icons";
const useStyles = makeStyles(styles);




export default function AuthNavbar(props) {

    const useMediaQuery = (query) => {
        const mediaMatch = window.matchMedia(query);
        const [matches, setMatches] = React.useState(mediaMatch.matches);

        React.useEffect(() => {
            const handler = e => setMatches(e.matches);
            mediaMatch.addEventListener("change", handler);
            return () => mediaMatch.removeEventListener("change", handler);
        });
        return matches;
    };

    const [open, setOpen] = React.useState(false);
    const isSmallerScreen = useMediaQuery('(max-width: 1900px)');
    const isMobile = useMediaQuery('(max-width: 1152px)');

    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: "#1d4c80",
                },
            },
            MuiInputLabel: {
                root: {
                    color: "white",
                    fontSize: "1.7em"
                }
            },
            MuiInputBase: {
                input: {
                    color: "white",
                    backgroundColor: "#ff8000",
                    padding:"9px 0px",
                    marginTop: "10px",
                    textAlign: "center",
                    borderRadius: "3px",
                }
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    // backgroundColor: lightBlue.A200,
                    // color: "white",
                },
            },
            MuiPickersDay: {
                day: {
                    color: "#ff8000",
                },
                daySelected: {
                    backgroundColor: "#1d4c80",
                },
                dayDisabled: {
                    color: lightBlue["100"],
                },
                current: {
                    color: lightBlue["900"],
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: lightBlue["400"],
                },
            },
        },
    });

    // verifies if routeName is the one active (in browser input)
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    const classes = useStyles();
    let DateStart = Date.now();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });

    return (
        <AppBar position="static" className={classes.appBar + appBarClasses} style={{ backgroundColor: "#1d4c80", padding: 0, flexWrap: "wrap" }}>
            <Toolbar className={classes.container} style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
                <GridContainer>
                    <Hidden mdDown>
                        <GridItem xs={3}>

                            <Button href="/"
                                style={{ padding: "0px", margin: "0px" }}
                                className={classes.title} color="transparent">
                                <img src={avatar} height="auto" width="90%"
                                    style={{
                                        minWidth: "150px",
                                        maxHeight: "100px",
                                        marginTop:".8em"
                                    }}
                                    alt="..." />
                            </Button>

                        </GridItem>
                    </Hidden>
                    <Hidden mdDown>
                        <GridItem lg={9} style={{ display: "inherit" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <Grid item lg={5} style={{display: "flex"}}>
                                        <img src={stateMI}
                                            style={{
                                                marginTop:"1.2em",
                                                height: "auto",
                                                maxHeight: "75px",
                                                marginRight: ".8em"
                                            }} alt="..." />

                                        {useMediaQuery('(max-width: 1576px)') ? <h3 style={{ fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", whiteSpace: "nowrap", margin: "35px 0 10px" }}>
                                            MICHIGAN AND
                                        </h3> : <h2 style={{ fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", whiteSpace: "nowrap" }}>
                                            MICHIGAN AND
                                        </h2>}

                                        <img src={stateNJ}
                                            style={{
                                                marginTop: "1.2em",
                                                height: "auto",
                                                maxHeight: "75px",
                                                marginRight: ".8em"
                                            }} alt="..." />
                                        {useMediaQuery('(max-width: 1576px)') ? <h3 style={{ fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", whiteSpace: "nowrap", margin: "35px 0 10px" }}>
                                            NEW JERSEY DASHBOARD
                                        </h3> : <h2 style={{ fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", whiteSpace: "nowrap"}}>
                                            NEW JERSEY DASHBOARD
                                        </h2>}
                                    </Grid>
                                    <Grid item lg={2}>
                                    </Grid>
                                    <Grid item lg={5}>
                                    </Grid>
                                    <Grid item lg={12} style={activeRoute("pass") ? { marginBottom: "15px", marginTop: "20px", textAlign: "right", flexBasis: "95.5%" } : { display: "none" }}>
                                        <a href="/options" color="primary" style={{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }} >ADVANCED FILTER OPTIONS</a>
                                        <a href="/dma" color="primary" style={{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }} >DMA STATS</a>
                                        <a href="/comparison" color="primary" style={{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>COMPARISON SEARCH</a>
                                        <a href="/cancelandrenewal" color="primary" style={{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>CANCELS & RENEWALS</a>
                                        <a href="/projections" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>PROJECTIONS</a>
                                    </Grid>
                                    <Grid item lg={1}> </Grid>

                                </Grid>
                            </MuiPickersUtilsProvider>
                        </GridItem>
                    </Hidden>
                    <Hidden lgUp>
                        <GridItem xs={3} sm={4} style={{paddingLeft:"0px"}}>
                            <Button
                                href="/"
                                className={classes.title}
                                color="transparent"
                                style={{
                                    marginTop: ".6em",
                                    padding: "0px"
                                }}
                            >
                                <img src={avatar} height="auto" width="100%"
                                    style={{
                                        minWidth: "120px",
                                        maxHeight: "100px"
                                    }}
                                    alt="..." />
                            </Button>

                        </GridItem>
                    </Hidden>
                    <Hidden lgUp xsDown>
                        <GridItem xs={5} sm={6} style={{ display: "inherit", marginLeft: "2.2em", padding: "0!important" }}>
                            <h2
                                style={{
                                    fontFamily: 'GlacialIndifferenceRegular',
                                    color: "#ffff",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    fontSize: "3vw",
                                    marginTop: "auto",
                                    whiteSpace: "nowrap"
                                }}>
                                MICHIGAN AND NEW JERSEY DASHBOARD
                            </h2>
                        </GridItem>
                        
                        <GridItem lg={5}>
                            <Grid container>
                                <GridItem xs={12} sm={12}>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <Grid item lg={12} style={activeRoute("pass") ? { marginBottom: "14px", marginTop: "20px", textAlign: "center" } : { display: "none" }}>
                                        <a href="/options" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }} >ADVANCED FILTER OPTIONS</a>
                                        <a href="/dma" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }} >DMA STATS</a>
                                        <a href="/comparison" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>COMPARISON SEARCH</a>
                                        <a href="/cancelandrenewal" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>CANCELS & RENEWALS</a>
                                        <a href="/projections" color="primary" style={{ fontSize: "13px", height: '37px', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>PROJECTIONS</a>
                                    </Grid>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Hidden>
                    <Hidden smUp>
                        <GridItem xs={8} sm={6} style={{ display: "inherit", marginLeft: "2.2em", padding: "0!important" }}>
                            <h2
                                style={{
                                    fontFamily: 'GlacialIndifferenceRegular',
                                    color: "#ffff",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    fontSize: "18px",
                                    marginLeft:"25px",
                                    letterSpacing:"-1px!important",
                                    marginTop: "auto"
                                }}>
                                MICHIGAN AND NEW JERSEY DASHBOARD
              </h2>
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                          <Grid container>
                                <GridItem xs={12} sm={12}>
                                    <Grid container style={activeRoute("pass") ? {} : { display: "none" }}>
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'marginTop': '10px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/options" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }} >ADVANCED FILTER OPTIONS</a>
                                        </Grid>
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/dma" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }} >DMA STATS</a>
                                        </Grid>
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/comparison" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>COMPARISON SEARCH</a>
                                        </Grid>
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/cancelandrenewal" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>CANCELS & RENEWALS</a>
                                        </Grid>
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'marginBottom': '10px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/projections" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>PROJECTIONS</a>
                                        </Grid>
                                    </Grid>
                                    
                                </GridItem>
                          </Grid>
                        </GridItem>
                    </Hidden>
                </GridContainer>
            </Toolbar>
        </AppBar >
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string
};
