import React, { useEffect } from "react";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });

    return matches;
};

export default function Device() {
    const types = {
        mobile: useMediaQuery('(max-width: 1152px)'),
        tablet: false,
        desktop: true
    };

    const sizes = {
        xxl: useMediaQuery('(min-width: 1400px)'),
        xl: useMediaQuery('(min-width: 1200px)'),
        lg: useMediaQuery('(min-width: 992px)'),
        md: useMediaQuery('(min-width: 768px)'),
        sm: useMediaQuery('(min-width: 576px)'),
        xs: useMediaQuery('(min-width: 300px)'),
        xxs: true
    };

    var device = {
        type: "",
        size: "",
        get isMobile() { return this.type === "Mobile"; }
    };

    if (types.mobile)
        device.type = "Mobile";
    else if (types.tablet)
        device.type = "Tablet";
    else
        device.type = "Desktop";

    if (sizes.xxl)
        device.size = "xxl";
    else if (sizes.xl)
        device.size = "xl";
    else if (sizes.lg)
        device.size = "lg";
    else if (sizes.md)
        device.size = "md";
    else if (sizes.sm)
        device.size = "sm";
    else if (sizes.xs)
        device.size = "xs";
    else
        device.size = "xxs";

    return device;
};