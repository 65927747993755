import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function Cancellations_Premiums(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [renewaldata, setRenewalData] = React.useState([]);
    const [newbusinessdata, setNewBusinessData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            //GetData();
            setLoading(true);
        } else {
            setLoading(false);
        }
      
    }, [props.call]);


    return (
        <Grid container> {/* Quotes Table */}
            <Grid item lg={12} xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        margin: "0em",
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                IN FORCE CANCELLATIONS - BREAKDOWN
                                </span>
                        </span>
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                1st Term In Force <IconPop message={"  Active policies in 1st 6-month term (rolling 6 months)"} />
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <PoliciesTableView
                                data={props}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="1st_Term_In_Force"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                Term 2+ In Force <IconPop message={"  Active policies that have renewed into 2nd 6-month term or greater"} />
                            </span>
                        </span>
                        
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <CancellationsTableView
                                data={props}
                        />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Term_2+_In_Force"
                        />
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    );
}


const PoliciesTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];


    return (
            <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px"}}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px" , maxWidth: "70px" }}></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>In Force</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Canceled</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Cancel %</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Reinstated</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Net Cancel %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={"policyCancellations2"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>Policies</TableCell>
                            <TableCell align="left" >{formatWhole(num.InForceFTPolicies)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.CancelFTPolicies)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.CancelFTPoliciesPer)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.CancelFTReinstate)}</TableCell> 
                            <TableCell align="left" >{formatPercentage(num.CancelFTPoliciesNetPer)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={"policyCancellations1"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>GWP</TableCell>
                            <TableCell align="left" >{formatDecimal(num.InForceFTPoliciesGWP)}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.CancelFTPoliciesGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.CancelFTPoliciesGWPPer))}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.CancelFTReinstateGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.CancelFTPoliciesGWPNetPer))}</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        );
}

const CancellationsTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px" , maxWidth: "70px"  }}></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>In Force</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Canceled</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Cancel %</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Reinstated</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Net Cancel %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={"policyCancellations"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>Policies</TableCell>
                            <TableCell align="left" >{formatWhole(num.InForceRenewals)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.CancelRenewals)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.CancelRenewalsPoliciesPer)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.CancelRenewalsReinstate)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.CancelRenewalsPoliciesNetPer)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={"policyCancellations3"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>GWP</TableCell>
                            <TableCell align="left" >{formatDecimal(num.InForceRenewalsGWP)}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.CancelRenewalsGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.CancelRenewalsGWPPer))}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.CancelRenewalsReinstateGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.CancelRenewalsGWPNetPer))}</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
export const formatPercentage = (num) => {
    if (Number.isNaN(num) || num === undefined) { return "" }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(num)) + "%"
}
export const formatDecimal = (num) => {
    if (Number.isNaN(num) || num === undefined) { return "" }
    return "$" + (new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(num)))
}
export const formatWhole = (num) => {
    if (Number.isNaN(num) || num === undefined) { return "" }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(num))
}
const IconPop = (qprops) => {
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <span>
            <IconButton
                variant="contained"
                size="small"
                style={{ color: "#25b7d3", maxHeight: "25px", position: "absolute" }}
                onClick={handleClick} component="span">
                <Help style={{ width: ".75em" }} />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: "0px 10px", fontSize: "14px" }}>{qprops.message}</Typography>
            </Popover>
        </span>
    );
}