import React from "react";
import { IconButton, Typography, Popover } from "@material-ui/core";
import { Help } from "@material-ui/icons";

export default function TooltipButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
    <>
        <IconButton
            aria-describedby={id}
            variant="contained"
            size="small"
            style={{ color: "#25b7d3", maxHeight: "10px", ...props.style }}
            onClick={handleClick} component="span">
            {props.icon || <Help />}
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography style={{padding: "5px"}}>{props.tooltipText}</Typography>
        </Popover>
    </>
    );
}
