export const all = {
    EnvironmentService: {
      method: "GET",
      url: `/GetQuoteData/GetEnvironmentService`,
    },
    Login: {
      method: "POST",
      url: `/Login`,
    },
    GetQuoteData: {
      method: "POST",
      url: `/GetQuoteData`,
    },
    GetPremiumsQuotedData: {
        method: "POST",
        url: `/GetPremiumsQuotedData`,
    },
    GetAppData: {
      method: "POST",
      url: `/GetAppData`,
    },
    GetPolicyData: {
      method: "POST",
      url: `/GetPolicyData`,
    },
    GetYTDData: {
      method: "POST",
      url: `/GetYTDData`,
    },
    GetBookOfBusinessData: {
        method: "POST",
        url: `/GetBookOfBusinessData`,
    },
    GetGWPData: {
      method: "POST",
      url: `/GetGWPData`,
    },
    QuotesByCoverageType: {
        method: "POST",
        url: `/GetCompCollData/QuotesByCoverageType`,
    },
    PoliciesByCoverageType: {
        method: "POST",
        url: `/GetCompCollData/PoliciesByCoverageType`,
    },
    VehiclesByCoverageTypeQuotes: {
        method: "POST",
        url: `/GetCompCollData/VehiclesByCoverageTypeQuotes`,
    },
    VehiclesByCoverageTypePolicies: {
        method: "POST",
        url: `/GetCompCollData/VehiclesByCoverageTypePolicies`,
    },
    GetComments: {
        method: "POST",
        url: `/GetComments`,
    },
    GetLandingMessage: {
        method: "GET",
        url: `/GetLandingMessage`,
    }
  };

  export const NJ = {
    GetBIQuotesData: {
      method: "POST",
      url: `/GetBIQuotesData`,
    },
    GetBIPoliciesData: {
      method: "POST",
      url: `/GetBIPoliciesData`,
    }
  };

  export const Options = {
    GetPIPData: {
      method: "POST",
      url: `/options/GetPIPData`,
    },
    GetQuoteData: {
      method: "POST",
      url: `/options/GetQuoteData`,
    },
    GetPremiumData: {
      method: "POST",
      url: `/options/GetPremiumData`,
    }
  };