import React from "react";
import TableWithExportButton from "utils/Table/TableWithExportButton";

export default function CreditScore(props) {
    const typePlural = props.type == "Quote" ? "Quotes" : "Policies";
    const number = new Intl.NumberFormat('en-US');

    // Ensure data is always an array
    const data = Array.isArray(props.data) ? props.data : [];

    // Create table
    const title = typePlural.toUpperCase() + " - CREDIT SCORE";

    const headers = [[
        props.type + " Type",
        typePlural,
        "599 And Under",
        "600-699",
        "700 And Over"
    ]];
    
    const rows = data.map((row) => [
        (row.policyType || row.quoteType) === "Total" ? "TOTAL" : row.policyType || row.quoteType,
        number.format(row.policies || row.quotes || 0),
        number.format(row.creditScore_599AndUnder || 0),
        number.format(row.creditScore_600To699 || 0),
        number.format(row.creditScore_700AndOver || 0)
    ]);

    if (props.comments?.length > 0) {
        headers.push('');
        rows.map(x => {
            let comment = props.comments.find(y => y.row === "Total" ? "TOTAL" : y.row == x[0]);
            x.push(comment ? comment.message : '');
        });
    }

    return (
        <div>
            <TableWithExportButton
                title={title}
                fixedColumns={1}
                style={{ Table: { Body: { whiteSpace: 'pre-line' } } }}
                headers={headers}
                rows={rows.filter(x => x[0] !== 'TOTAL')}
                footers={rows.filter(x => x[0] === 'TOTAL')}
            />
        </div>
    );
};