import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { CallAPI, all } from "services/all/API";

export const LandingMessage = (props) => {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const GetLandingMessage = async () => {
            const data = await CallAPI(all.GetLandingMessage);
            if (data)
                setMessage(data);
        };

        GetLandingMessage();
    }, []);

    return message && (
        <>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <p style={{ color: "red", margin: "3em 1em 0", fontSize: "1.2em", whiteSpace: 'pre-line' }}>{message}</p>
                </Grid>
            </Grid>
        </>
    );
};
