export const statesData = {
    "Alabama": [
        [
            [
                35.00118,
                -87.359296
            ],
            [
                34.984749,
                -85.606675
            ],
            [
                34.124869,
                -85.431413
            ],
            [
                32.859696,
                -85.184951
            ],
            [
                32.580372,
                -85.069935
            ],
            [
                32.421541,
                -84.960397
            ],
            [
                32.322956,
                -85.004212
            ],
            [
                32.262709,
                -84.889196
            ],
            [
                32.13674,
                -85.058981
            ],
            [
                32.01077,
                -85.053504
            ],
            [
                31.840985,
                -85.141136
            ],
            [
                31.539753,
                -85.042551
            ],
            [
                31.27686,
                -85.113751
            ],
            [
                31.003013,
                -85.004212
            ],
            [
                30.997536,
                -85.497137
            ],
            [
                30.997536,
                -87.600282
            ],
            [
                30.86609,
                -87.633143
            ],
            [
                30.674397,
                -87.408589
            ],
            [
                30.510088,
                -87.446927
            ],
            [
                30.427934,
                -87.37025
            ],
            [
                30.280057,
                -87.518128
            ],
            [
                30.247195,
                -87.655051
            ],
            [
                30.411504,
                -87.90699
            ],
            [
                30.657966,
                -87.934375
            ],
            [
                30.685351,
                -88.011052
            ],
            [
                30.499135,
                -88.10416
            ],
            [
                30.318396,
                -88.137022
            ],
            [
                30.367688,
                -88.394438
            ],
            [
                31.895754,
                -88.471115
            ],
            [
                33.796253,
                -88.241084
            ],
            [
                34.891641,
                -88.098683
            ],
            [
                34.995703,
                -88.202745
            ],
            [
                35.00118,
                -87.359296
            ]
        ]
    ],
    "Alaska": [
        [
            [
                [
                    55.117982,
                    -131.602021
                ],
                [
                    55.28229,
                    -131.569159
                ],
                [
                    55.183705,
                    -131.355558
                ],
                [
                    55.01392,
                    -131.38842
                ],
                [
                    55.035827,
                    -131.645836
                ],
                [
                    55.117982,
                    -131.602021
                ]
            ]
        ],
        [
            [
                [
                    55.42469,
                    -131.832052
                ],
                [
                    55.304197,
                    -131.645836
                ],
                [
                    55.128935,
                    -131.749898
                ],
                [
                    55.189182,
                    -131.832052
                ],
                [
                    55.42469,
                    -131.832052
                ]
            ]
        ],
        [
            [
                [
                    56.437924,
                    -132.976733
                ],
                [
                    56.459832,
                    -132.735747
                ],
                [
                    56.421493,
                    -132.631685
                ],
                [
                    56.273616,
                    -132.664547
                ],
                [
                    56.240754,
                    -132.878148
                ],
                [
                    56.333862,
                    -133.069841
                ],
                [
                    56.437924,
                    -132.976733
                ]
            ]
        ],
        [
            [
                [
                    56.350293,
                    -133.595627
                ],
                [
                    56.317431,
                    -133.162949
                ],
                [
                    56.125739,
                    -133.05341
                ],
                [
                    55.912138,
                    -132.620732
                ],
                [
                    55.780691,
                    -132.472854
                ],
                [
                    55.671152,
                    -132.4619
                ],
                [
                    55.649245,
                    -132.357838
                ],
                [
                    55.506844,
                    -132.341408
                ],
                [
                    55.364444,
                    -132.166146
                ],
                [
                    55.238474,
                    -132.144238
                ],
                [
                    55.276813,
                    -132.029222
                ],
                [
                    55.178228,
                    -131.97993
                ],
                [
                    54.789365,
                    -131.958022
                ],
                [
                    54.701734,
                    -132.029222
                ],
                [
                    54.718165,
                    -132.308546
                ],
                [
                    54.915335,
                    -132.385223
                ],
                [
                    54.898904,
                    -132.483808
                ],
                [
                    55.046781,
                    -132.686455
                ],
                [
                    54.997489,
                    -132.746701
                ],
                [
                    55.046781,
                    -132.916486
                ],
                [
                    54.898904,
                    -132.889102
                ],
                [
                    54.937242,
                    -132.73027
                ],
                [
                    54.882473,
                    -132.626209
                ],
                [
                    54.679826,
                    -132.675501
                ],
                [
                    54.701734,
                    -132.867194
                ],
                [
                    54.95915,
                    -133.157472
                ],
                [
                    55.090597,
                    -133.239626
                ],
                [
                    55.22752,
                    -133.223195
                ],
                [
                    55.216566,
                    -133.453227
                ],
                [
                    55.320628,
                    -133.453227
                ],
                [
                    55.331582,
                    -133.277964
                ],
                [
                    55.42469,
                    -133.102702
                ],
                [
                    55.588998,
                    -133.17938
                ],
                [
                    55.62186,
                    -133.387503
                ],
                [
                    55.884753,
                    -133.420365
                ],
                [
                    56.0162,
                    -133.497042
                ],
                [
                    55.923092,
                    -133.639442
                ],
                [
                    56.070969,
                    -133.694212
                ],
                [
                    56.142169,
                    -133.546335
                ],
                [
                    56.311955,
                    -133.666827
                ],
                [
                    56.350293,
                    -133.595627
                ]
            ]
        ],
        [
            [
                [
                    55.556137,
                    -133.738027
                ],
                [
                    55.490413,
                    -133.546335
                ],
                [
                    55.572568,
                    -133.414888
                ],
                [
                    55.534229,
                    -133.283441
                ],
                [
                    55.386352,
                    -133.420365
                ],
                [
                    55.430167,
                    -133.633966
                ],
                [
                    55.556137,
                    -133.738027
                ]
            ]
        ],
        [
            [
                [
                    56.930849,
                    -133.907813
                ],
                [
                    57.029434,
                    -134.050213
                ],
                [
                    57.095157,
                    -133.885905
                ],
                [
                    57.002049,
                    -133.343688
                ],
                [
                    57.007526,
                    -133.102702
                ],
                [
                    56.82131,
                    -132.932917
                ],
                [
                    56.667956,
                    -132.620732
                ],
                [
                    56.55294,
                    -132.653593
                ],
                [
                    56.492694,
                    -132.817901
                ],
                [
                    56.520078,
                    -133.042456
                ],
                [
                    56.448878,
                    -133.201287
                ],
                [
                    56.492694,
                    -133.420365
                ],
                [
                    56.448878,
                    -133.66135
                ],
                [
                    56.684386,
                    -133.710643
                ],
                [
                    56.837741,
                    -133.688735
                ],
                [
                    56.843218,
                    -133.869474
                ],
                [
                    56.930849,
                    -133.907813
                ]
            ]
        ],
        [
            [
                [
                    56.48174,
                    -134.115936
                ],
                [
                    56.558417,
                    -134.25286
                ],
                [
                    56.722725,
                    -134.400737
                ],
                [
                    56.848695,
                    -134.417168
                ],
                [
                    56.908941,
                    -134.296675
                ],
                [
                    56.848695,
                    -134.170706
                ],
                [
                    56.952757,
                    -134.143321
                ],
                [
                    56.772017,
                    -133.748981
                ],
                [
                    56.596755,
                    -133.710643
                ],
                [
                    56.574848,
                    -133.847566
                ],
                [
                    56.377678,
                    -133.935197
                ],
                [
                    56.322908,
                    -133.836612
                ],
                [
                    56.092877,
                    -133.957105
                ],
                [
                    56.142169,
                    -134.110459
                ],
                [
                    55.999769,
                    -134.132367
                ],
                [
                    56.070969,
                    -134.230952
                ],
                [
                    56.350293,
                    -134.291198
                ],
                [
                    56.48174,
                    -134.115936
                ]
            ]
        ],
        [
            [
                [
                    56.28457,
                    -134.636246
                ],
                [
                    56.169554,
                    -134.669107
                ],
                [
                    56.235277,
                    -134.806031
                ],
                [
                    56.67891,
                    -135.178463
                ],
                [
                    56.810356,
                    -135.413971
                ],
                [
                    56.914418,
                    -135.331817
                ],
                [
                    57.166357,
                    -135.424925
                ],
                [
                    57.369004,
                    -135.687818
                ],
                [
                    57.566174,
                    -135.419448
                ],
                [
                    57.48402,
                    -135.298955
                ],
                [
                    57.418296,
                    -135.063447
                ],
                [
                    57.407343,
                    -134.849846
                ],
                [
                    57.248511,
                    -134.844369
                ],
                [
                    56.728202,
                    -134.636246
                ],
                [
                    56.28457,
                    -134.636246
                ]
            ]
        ],
        [
            [
                [
                    58.223407,
                    -134.712923
                ],
                [
                    58.14673,
                    -134.373353
                ],
                [
                    58.157683,
                    -134.176183
                ],
                [
                    58.081006,
                    -134.187137
                ],
                [
                    57.807159,
                    -133.902336
                ],
                [
                    57.850975,
                    -134.099505
                ],
                [
                    57.757867,
                    -134.148798
                ],
                [
                    57.615466,
                    -133.935197
                ],
                [
                    57.363527,
                    -133.869474
                ],
                [
                    57.297804,
                    -134.083075
                ],
                [
                    57.210173,
                    -134.154275
                ],
                [
                    57.029434,
                    -134.499322
                ],
                [
                    57.034911,
                    -134.603384
                ],
                [
                    57.226604,
                    -134.6472
                ],
                [
                    57.341619,
                    -134.575999
                ],
                [
                    57.511404,
                    -134.608861
                ],
                [
                    57.719528,
                    -134.729354
                ],
                [
                    57.829067,
                    -134.707446
                ],
                [
                    58.097437,
                    -134.784123
                ],
                [
                    58.212453,
                    -134.91557
                ],
                [
                    58.409623,
                    -134.953908
                ],
                [
                    58.223407,
                    -134.712923
                ]
            ]
        ],
        [
            [
                [
                    57.330665,
                    -135.857603
                ],
                [
                    57.330665,
                    -135.715203
                ],
                [
                    57.149926,
                    -135.567326
                ],
                [
                    57.023957,
                    -135.633049
                ],
                [
                    56.996572,
                    -135.857603
                ],
                [
                    57.193742,
                    -135.824742
                ],
                [
                    57.330665,
                    -135.857603
                ]
            ]
        ],
        [
            [
                [
                    58.206976,
                    -136.279328
                ],
                [
                    58.201499,
                    -135.978096
                ],
                [
                    58.28913,
                    -135.780926
                ],
                [
                    58.168637,
                    -135.496125
                ],
                [
                    58.037191,
                    -135.64948
                ],
                [
                    57.987898,
                    -135.59471
                ],
                [
                    58.135776,
                    -135.45231
                ],
                [
                    58.086483,
                    -135.107263
                ],
                [
                    57.976944,
                    -134.91557
                ],
                [
                    57.779775,
                    -135.025108
                ],
                [
                    57.763344,
                    -134.937477
                ],
                [
                    57.500451,
                    -134.822462
                ],
                [
                    57.462112,
                    -135.085355
                ],
                [
                    57.675713,
                    -135.572802
                ],
                [
                    57.456635,
                    -135.556372
                ],
                [
                    57.369004,
                    -135.709726
                ],
                [
                    57.407343,
                    -135.890465
                ],
                [
                    57.544266,
                    -136.000004
                ],
                [
                    57.637374,
                    -136.208128
                ],
                [
                    57.829067,
                    -136.366959
                ],
                [
                    57.916698,
                    -136.569606
                ],
                [
                    58.075529,
                    -136.558652
                ],
                [
                    58.130299,
                    -136.421728
                ],
                [
                    58.267222,
                    -136.377913
                ],
                [
                    58.206976,
                    -136.279328
                ]
            ]
        ],
        [
            [
                [
                    60.200582,
                    -147.079854
                ],
                [
                    59.948643,
                    -147.501579
                ],
                [
                    59.850058,
                    -147.53444
                ],
                [
                    59.784335,
                    -147.874011
                ],
                [
                    59.937689,
                    -147.80281
                ],
                [
                    60.09652,
                    -147.435855
                ],
                [
                    60.271782,
                    -147.205824
                ],
                [
                    60.200582,
                    -147.079854
                ]
            ]
        ],
        [
            [
                [
                    60.578491,
                    -147.561825
                ],
                [
                    60.370367,
                    -147.616594
                ],
                [
                    60.156767,
                    -147.758995
                ],
                [
                    60.227967,
                    -147.956165
                ],
                [
                    60.474429,
                    -147.791856
                ],
                [
                    60.578491,
                    -147.561825
                ]
            ]
        ],
        [
            [
                [
                    70.245291,
                    -147.786379
                ],
                [
                    70.201475,
                    -147.682318
                ],
                [
                    70.15766,
                    -147.162008
                ],
                [
                    70.185044,
                    -146.888161
                ],
                [
                    70.185044,
                    -146.510252
                ],
                [
                    70.146706,
                    -146.099482
                ],
                [
                    70.168614,
                    -145.858496
                ],
                [
                    70.08646,
                    -145.622988
                ],
                [
                    69.993352,
                    -145.195787
                ],
                [
                    69.971444,
                    -144.620708
                ],
                [
                    70.026213,
                    -144.461877
                ],
                [
                    70.059075,
                    -144.078491
                ],
                [
                    70.130275,
                    -143.914183
                ],
                [
                    70.141229,
                    -143.497935
                ],
                [
                    70.091936,
                    -143.503412
                ],
                [
                    70.119321,
                    -143.25695
                ],
                [
                    70.042644,
                    -142.747594
                ],
                [
                    69.916674,
                    -142.402547
                ],
                [
                    69.856428,
                    -142.079408
                ],
                [
                    69.801659,
                    -142.008207
                ],
                [
                    69.790705,
                    -141.712453
                ],
                [
                    69.697597,
                    -141.433129
                ],
                [
                    69.63735,
                    -141.378359
                ],
                [
                    69.686643,
                    -141.208574
                ],
                [
                    69.648304,
                    -141.00045
                ],
                [
                    60.304644,
                    -141.00045
                ],
                [
                    60.22249,
                    -140.53491
                ],
                [
                    60.310121,
                    -140.474664
                ],
                [
                    60.184151,
                    -139.987216
                ],
                [
                    60.342983,
                    -139.696939
                ],
                [
                    60.359413,
                    -139.088998
                ],
                [
                    60.091043,
                    -139.198537
                ],
                [
                    59.997935,
                    -139.045183
                ],
                [
                    59.910304,
                    -138.700135
                ],
                [
                    59.767904,
                    -138.623458
                ],
                [
                    59.242118,
                    -137.604747
                ],
                [
                    58.908024,
                    -137.445916
                ],
                [
                    59.001132,
                    -137.265177
                ],
                [
                    59.159963,
                    -136.827022
                ],
                [
                    59.16544,
                    -136.580559
                ],
                [
                    59.285933,
                    -136.465544
                ],
                [
                    59.466672,
                    -136.476498
                ],
                [
                    59.466672,
                    -136.301236
                ],
                [
                    59.625503,
                    -136.25742
                ],
                [
                    59.663842,
                    -135.945234
                ],
                [
                    59.800766,
                    -135.479694
                ],
                [
                    59.565257,
                    -135.025108
                ],
                [
                    59.422857,
                    -135.068924
                ],
                [
                    59.280456,
                    -134.959385
                ],
                [
                    59.247595,
                    -134.701969
                ],
                [
                    59.033994,
                    -134.378829
                ],
                [
                    58.973748,
                    -134.400737
                ],
                [
                    58.858732,
                    -134.25286
                ],
                [
                    58.727285,
                    -133.842089
                ],
                [
                    58.152206,
                    -133.173903
                ],
                [
                    57.998852,
                    -133.075318
                ],
                [
                    57.845498,
                    -132.867194
                ],
                [
                    57.505928,
                    -132.560485
                ],
                [
                    57.21565,
                    -132.253777
                ],
                [
                    57.095157,
                    -132.368792
                ],
                [
                    57.051341,
                    -132.05113
                ],
                [
                    56.876079,
                    -132.127807
                ],
                [
                    56.804879,
                    -131.870391
                ],
                [
                    56.602232,
                    -131.837529
                ],
                [
                    56.613186,
                    -131.580113
                ],
                [
                    56.405062,
                    -131.087188
                ],
                [
                    56.366724,
                    -130.78048
                ],
                [
                    56.268139,
                    -130.621648
                ],
                [
                    56.240754,
                    -130.468294
                ],
                [
                    56.142169,
                    -130.424478
                ],
                [
                    56.114785,
                    -130.101339
                ],
                [
                    55.994292,
                    -130.002754
                ],
                [
                    55.769737,
                    -130.150631
                ],
                [
                    55.583521,
                    -130.128724
                ],
                [
                    55.276813,
                    -129.986323
                ],
                [
                    55.200136,
                    -130.095862
                ],
                [
                    54.920812,
                    -130.336847
                ],
                [
                    54.718165,
                    -130.687372
                ],
                [
                    54.822227,
                    -130.785957
                ],
                [
                    54.789365,
                    -130.917403
                ],
                [
                    54.997489,
                    -131.010511
                ],
                [
                    55.08512,
                    -130.983126
                ],
                [
                    55.189182,
                    -131.092665
                ],
                [
                    55.298721,
                    -130.862634
                ],
                [
                    55.337059,
                    -130.928357
                ],
                [
                    55.200136,
                    -131.158389
                ],
                [
                    55.287767,
                    -131.284358
                ],
                [
                    55.238474,
                    -131.426759
                ],
                [
                    55.457552,
                    -131.843006
                ],
                [
                    55.698537,
                    -131.700606
                ],
                [
                    55.616383,
                    -131.963499
                ],
                [
                    55.49589,
                    -131.974453
                ],
                [
                    55.588998,
                    -132.182576
                ],
                [
                    55.704014,
                    -132.226392
                ],
                [
                    55.829984,
                    -132.083991
                ],
                [
                    55.955953,
                    -132.127807
                ],
                [
                    55.851892,
                    -132.324977
                ],
                [
                    56.076446,
                    -132.522147
                ],
                [
                    56.032631,
                    -132.642639
                ],
                [
                    56.218847,
                    -132.719317
                ],
                [
                    56.339339,
                    -132.527624
                ],
                [
                    56.339339,
                    -132.341408
                ],
                [
                    56.487217,
                    -132.396177
                ],
                [
                    56.67891,
                    -132.297592
                ],
                [
                    56.673433,
                    -132.450946
                ],
                [
                    56.837741,
                    -132.768609
                ],
                [
                    57.034911,
                    -132.993164
                ],
                [
                    57.177311,
                    -133.51895
                ],
                [
                    57.577128,
                    -133.507996
                ],
                [
                    57.62642,
                    -133.677781
                ],
                [
                    57.790728,
                    -133.639442
                ],
                [
                    57.834544,
                    -133.814705
                ],
                [
                    58.053622,
                    -134.072121
                ],
                [
                    58.168637,
                    -134.143321
                ],
                [
                    58.206976,
                    -134.586953
                ],
                [
                    58.502731,
                    -135.074401
                ],
                [
                    59.192825,
                    -135.282525
                ],
                [
                    59.033994,
                    -135.38111
                ],
                [
                    58.891593,
                    -135.337294
                ],
                [
                    58.617746,
                    -135.140124
                ],
                [
                    58.573931,
                    -135.189417
                ],
                [
                    58.349376,
                    -135.05797
                ],
                [
                    58.201499,
                    -135.085355
                ],
                [
                    58.234361,
                    -135.277048
                ],
                [
                    58.398669,
                    -135.430402
                ],
                [
                    58.426053,
                    -135.633049
                ],
                [
                    58.382238,
                    -135.91785
                ],
                [
                    58.617746,
                    -135.912373
                ],
                [
                    58.814916,
                    -136.087635
                ],
                [
                    58.75467,
                    -136.246466
                ],
                [
                    58.962794,
                    -136.876314
                ],
                [
                    58.902547,
                    -136.931084
                ],
                [
                    58.836824,
                    -136.586036
                ],
                [
                    58.672516,
                    -136.317666
                ],
                [
                    58.667039,
                    -136.213604
                ],
                [
                    58.535592,
                    -136.180743
                ],
                [
                    58.382238,
                    -136.043819
                ],
                [
                    58.294607,
                    -136.388867
                ],
                [
                    58.349376,
                    -136.591513
                ],
                [
                    58.212453,
                    -136.59699
                ],
                [
                    58.316515,
                    -136.859883
                ],
                [
                    58.393192,
                    -136.947514
                ],
                [
                    58.393192,
                    -137.111823
                ],
                [
                    58.590362,
                    -137.566409
                ],
                [
                    58.765624,
                    -137.900502
                ],
                [
                    58.869686,
                    -137.933364
                ],
                [
                    59.02304,
                    -138.11958
                ],
                [
                    59.132579,
                    -138.634412
                ],
                [
                    59.247595,
                    -138.919213
                ],
                [
                    59.379041,
                    -139.417615
                ],
                [
                    59.505011,
                    -139.746231
                ],
                [
                    59.641934,
                    -139.718846
                ],
                [
                    59.598119,
                    -139.625738
                ],
                [
                    59.68575,
                    -139.5162
                ],
                [
                    59.88292,
                    -139.625738
                ],
                [
                    59.992458,
                    -139.488815
                ],
                [
                    60.041751,
                    -139.554538
                ],
                [
                    59.833627,
                    -139.801
                ],
                [
                    59.696704,
                    -140.315833
                ],
                [
                    59.745996,
                    -140.92925
                ],
                [
                    59.871966,
                    -141.444083
                ],
                [
                    59.970551,
                    -141.46599
                ],
                [
                    59.948643,
                    -141.706976
                ],
                [
                    60.019843,
                    -141.964392
                ],
                [
                    60.085566,
                    -142.539471
                ],
                [
                    60.091043,
                    -142.873564
                ],
                [
                    60.036274,
                    -143.623905
                ],
                [
                    59.997935,
                    -143.892275
                ],
                [
                    60.140336,
                    -144.231845
                ],
                [
                    60.206059,
                    -144.65357
                ],
                [
                    60.29369,
                    -144.785016
                ],
                [
                    60.441568,
                    -144.834309
                ],
                [
                    60.430614,
                    -145.124586
                ],
                [
                    60.299167,
                    -145.223171
                ],
                [
                    60.474429,
                    -145.738004
                ],
                [
                    60.551106,
                    -145.820158
                ],
                [
                    60.408706,
                    -146.351421
                ],
                [
                    60.238921,
                    -146.608837
                ],
                [
                    60.397752,
                    -146.718376
                ],
                [
                    60.485383,
                    -146.608837
                ],
                [
                    60.463475,
                    -146.455483
                ],
                [
                    60.578491,
                    -145.951604
                ],
                [
                    60.666122,
                    -146.017328
                ],
                [
                    60.622307,
                    -146.252836
                ],
                [
                    60.737322,
                    -146.345944
                ],
                [
                    60.753753,
                    -146.565022
                ],
                [
                    61.044031,
                    -146.784099
                ],
                [
                    60.972831,
                    -146.866253
                ],
                [
                    60.934492,
                    -147.172962
                ],
                [
                    60.972831,
                    -147.271547
                ],
                [
                    60.879723,
                    -147.375609
                ],
                [
                    60.912584,
                    -147.758995
                ],
                [
                    60.808523,
                    -147.775426
                ],
                [
                    60.781138,
                    -148.032842
                ],
                [
                    60.819476,
                    -148.153334
                ],
                [
                    61.005692,
                    -148.065703
                ],
                [
                    61.000215,
                    -148.175242
                ],
                [
                    60.803046,
                    -148.350504
                ],
                [
                    60.737322,
                    -148.109519
                ],
                [
                    60.594922,
                    -148.087611
                ],
                [
                    60.441568,
                    -147.939734
                ],
                [
                    60.277259,
                    -148.027365
                ],
                [
                    60.332029,
                    -148.219058
                ],
                [
                    60.249875,
                    -148.273827
                ],
                [
                    60.217013,
                    -148.087611
                ],
                [
                    59.997935,
                    -147.983549
                ],
                [
                    59.95412,
                    -148.251919
                ],
                [
                    59.997935,
                    -148.399797
                ],
                [
                    59.937689,
                    -148.635305
                ],
                [
                    59.986981,
                    -148.755798
                ],
                [
                    59.981505,
                    -149.067984
                ],
                [
                    60.063659,
                    -149.05703
                ],
                [
                    60.008889,
                    -149.204907
                ],
                [
                    59.904827,
                    -149.287061
                ],
                [
                    59.997935,
                    -149.418508
                ],
                [
                    59.866489,
                    -149.582816
                ],
                [
                    59.806242,
                    -149.511616
                ],
                [
                    59.729565,
                    -149.741647
                ],
                [
                    59.718611,
                    -149.949771
                ],
                [
                    59.61455,
                    -150.031925
                ],
                [
                    59.521442,
                    -150.25648
                ],
                [
                    59.554303,
                    -150.409834
                ],
                [
                    59.444764,
                    -150.579619
                ],
                [
                    59.450241,
                    -150.716543
                ],
                [
                    59.225687,
                    -151.001343
                ],
                [
                    59.209256,
                    -151.308052
                ],
                [
                    59.280456,
                    -151.406637
                ],
                [
                    59.159963,
                    -151.592853
                ],
                [
                    59.253071,
                    -151.976239
                ],
                [
                    59.422857,
                    -151.888608
                ],
                [
                    59.483103,
                    -151.636669
                ],
                [
                    59.472149,
                    -151.47236
                ],
                [
                    59.537872,
                    -151.423068
                ],
                [
                    59.669319,
                    -151.127313
                ],
                [
                    59.778858,
                    -151.116359
                ],
                [
                    59.63098,
                    -151.505222
                ],
                [
                    59.718611,
                    -151.828361
                ],
                [
                    59.778858,
                    -151.8667
                ],
                [
                    60.030797,
                    -151.702392
                ],
                [
                    60.211536,
                    -151.423068
                ],
                [
                    60.359413,
                    -151.379252
                ],
                [
                    60.386798,
                    -151.297098
                ],
                [
                    60.545629,
                    -151.264237
                ],
                [
                    60.720892,
                    -151.406637
                ],
                [
                    60.786615,
                    -151.06159
                ],
                [
                    61.038554,
                    -150.404357
                ],
                [
                    60.939969,
                    -150.245526
                ],
                [
                    60.912584,
                    -150.042879
                ],
                [
                    61.016646,
                    -149.741647
                ],
                [
                    61.15357,
                    -150.075741
                ],
                [
                    61.257632,
                    -150.207187
                ],
                [
                    61.246678,
                    -150.47008
                ],
                [
                    61.29597,
                    -150.656296
                ],
                [
                    61.252155,
                    -150.711066
                ],
                [
                    61.180954,
                    -151.023251
                ],
                [
                    61.044031,
                    -151.165652
                ],
                [
                    61.011169,
                    -151.477837
                ],
                [
                    60.852338,
                    -151.800977
                ],
                [
                    60.748276,
                    -151.833838
                ],
                [
                    60.693507,
                    -152.080301
                ],
                [
                    60.578491,
                    -152.13507
                ],
                [
                    60.507291,
                    -152.310332
                ],
                [
                    60.304644,
                    -152.392486
                ],
                [
                    60.173197,
                    -152.732057
                ],
                [
                    60.069136,
                    -152.567748
                ],
                [
                    59.915781,
                    -152.704672
                ],
                [
                    59.888397,
                    -153.022334
                ],
                [
                    59.691227,
                    -153.049719
                ],
                [
                    59.620026,
                    -153.345474
                ],
                [
                    59.702181,
                    -153.438582
                ],
                [
                    59.548826,
                    -153.586459
                ],
                [
                    59.543349,
                    -153.761721
                ],
                [
                    59.433811,
                    -153.72886
                ],
                [
                    59.368087,
                    -154.117723
                ],
                [
                    59.066856,
                    -154.1944
                ],
                [
                    59.050425,
                    -153.750768
                ],
                [
                    58.968271,
                    -153.400243
                ],
                [
                    58.869686,
                    -153.301658
                ],
                [
                    58.710854,
                    -153.444059
                ],
                [
                    58.612269,
                    -153.679567
                ],
                [
                    58.606793,
                    -153.898645
                ],
                [
                    58.519161,
                    -153.920553
                ],
                [
                    58.4863,
                    -154.062953
                ],
                [
                    58.376761,
                    -153.99723
                ],
                [
                    58.212453,
                    -154.145107
                ],
                [
                    58.059098,
                    -154.46277
                ],
                [
                    58.059098,
                    -154.643509
                ],
                [
                    58.004329,
                    -154.818771
                ],
                [
                    58.015283,
                    -154.988556
                ],
                [
                    57.955037,
                    -155.120003
                ],
                [
                    57.872883,
                    -155.081664
                ],
                [
                    57.829067,
                    -155.328126
                ],
                [
                    57.708574,
                    -155.377419
                ],
                [
                    57.785251,
                    -155.547204
                ],
                [
                    57.549743,
                    -155.73342
                ],
                [
                    57.566174,
                    -156.045606
                ],
                [
                    57.440204,
                    -156.023698
                ],
                [
                    57.473066,
                    -156.209914
                ],
                [
                    57.418296,
                    -156.34136
                ],
                [
                    57.248511,
                    -156.34136
                ],
                [
                    56.985618,
                    -156.549484
                ],
                [
                    56.952757,
                    -156.883577
                ],
                [
                    56.832264,
                    -157.157424
                ],
                [
                    56.766541,
                    -157.20124
                ],
                [
                    56.859649,
                    -157.376502
                ],
                [
                    56.607709,
                    -157.672257
                ],
                [
                    56.67891,
                    -157.754411
                ],
                [
                    56.657002,
                    -157.918719
                ],
                [
                    56.514601,
                    -157.957058
                ],
                [
                    56.459832,
                    -158.126843
                ],
                [
                    56.48174,
                    -158.32949
                ],
                [
                    56.339339,
                    -158.488321
                ],
                [
                    56.295524,
                    -158.208997
                ],
                [
                    55.977861,
                    -158.510229
                ],
                [
                    55.873799,
                    -159.375585
                ],
                [
                    55.594475,
                    -159.616571
                ],
                [
                    55.654722,
                    -159.676817
                ],
                [
                    55.829984,
                    -159.643955
                ],
                [
                    55.857368,
                    -159.813741
                ],
                [
                    55.791645,
                    -160.027341
                ],
                [
                    55.720445,
                    -160.060203
                ],
                [
                    55.605429,
                    -160.394296
                ],
                [
                    55.473983,
                    -160.536697
                ],
                [
                    55.567091,
                    -160.580512
                ],
                [
                    55.457552,
                    -160.668143
                ],
                [
                    55.528752,
                    -160.865313
                ],
                [
                    55.358967,
                    -161.232268
                ],
                [
                    55.364444,
                    -161.506115
                ],
                [
                    55.49589,
                    -161.467776
                ],
                [
                    55.62186,
                    -161.588269
                ],
                [
                    55.517798,
                    -161.697808
                ],
                [
                    55.408259,
                    -161.686854
                ],
                [
                    55.074166,
                    -162.053809
                ],
                [
                    55.15632,
                    -162.179779
                ],
                [
                    55.03035,
                    -162.218117
                ],
                [
                    55.052258,
                    -162.470057
                ],
                [
                    55.249428,
                    -162.508395
                ],
                [
                    55.293244,
                    -162.661749
                ],
                [
                    55.222043,
                    -162.716519
                ],
                [
                    55.134412,
                    -162.579595
                ],
                [
                    54.997489,
                    -162.645319
                ],
                [
                    54.926289,
                    -162.847965
                ],
                [
                    55.079643,
                    -163.00132
                ],
                [
                    55.090597,
                    -163.187536
                ],
                [
                    55.03035,
                    -163.220397
                ],
                [
                    54.942719,
                    -163.034181
                ],
                [
                    54.800319,
                    -163.373752
                ],
                [
                    54.76198,
                    -163.14372
                ],
                [
                    54.696257,
                    -163.138243
                ],
                [
                    54.74555,
                    -163.329936
                ],
                [
                    54.614103,
                    -163.587352
                ],
                [
                    54.61958,
                    -164.085754
                ],
                [
                    54.531949,
                    -164.332216
                ],
                [
                    54.466226,
                    -164.354124
                ],
                [
                    54.389548,
                    -164.638925
                ],
                [
                    54.416933,
                    -164.847049
                ],
                [
                    54.603149,
                    -164.918249
                ],
                [
                    54.663395,
                    -164.710125
                ],
                [
                    54.88795,
                    -164.551294
                ],
                [
                    54.893427,
                    -164.34317
                ],
                [
                    55.041304,
                    -163.894061
                ],
                [
                    55.046781,
                    -163.532583
                ],
                [
                    54.904381,
                    -163.39566
                ],
                [
                    55.008443,
                    -163.291598
                ],
                [
                    55.128935,
                    -163.313505
                ],
                [
                    55.183705,
                    -163.105382
                ],
                [
                    55.183705,
                    -162.880827
                ],
                [
                    55.446598,
                    -162.579595
                ],
                [
                    55.682106,
                    -162.245502
                ],
                [
                    55.89023,
                    -161.807347
                ],
                [
                    55.983338,
                    -161.292514
                ],
                [
                    55.939523,
                    -161.078914
                ],
                [
                    55.999769,
                    -160.87079
                ],
                [
                    55.912138,
                    -160.816021
                ],
                [
                    55.813553,
                    -160.931036
                ],
                [
                    55.736876,
                    -160.805067
                ],
                [
                    55.857368,
                    -160.766728
                ],
                [
                    55.868322,
                    -160.509312
                ],
                [
                    55.791645,
                    -160.438112
                ],
                [
                    55.76426,
                    -160.27928
                ],
                [
                    55.857368,
                    -160.273803
                ],
                [
                    55.939523,
                    -160.536697
                ],
                [
                    55.994292,
                    -160.558604
                ],
                [
                    56.251708,
                    -160.383342
                ],
                [
                    56.399586,
                    -160.147834
                ],
                [
                    56.541986,
                    -159.830171
                ],
                [
                    56.667956,
                    -159.326293
                ],
                [
                    56.848695,
                    -158.959338
                ],
                [
                    56.782971,
                    -158.784076
                ],
                [
                    56.810356,
                    -158.641675
                ],
                [
                    56.925372,
                    -158.701922
                ],
                [
                    57.034911,
                    -158.658106
                ],
                [
                    57.264942,
                    -158.378782
                ],
                [
                    57.41282,
                    -157.995396
                ],
                [
                    57.609989,
                    -157.688688
                ],
                [
                    57.719528,
                    -157.705118
                ],
                [
                    58.497254,
                    -157.458656
                ],
                [
                    58.705377,
                    -157.07527
                ],
                [
                    58.869686,
                    -157.119086
                ],
                [
                    58.634177,
                    -158.039212
                ],
                [
                    58.661562,
                    -158.32949
                ],
                [
                    58.760147,
                    -158.40069
                ],
                [
                    58.803962,
                    -158.564998
                ],
                [
                    58.913501,
                    -158.619768
                ],
                [
                    58.864209,
                    -158.767645
                ],
                [
                    58.694424,
                    -158.860753
                ],
                [
                    58.480823,
                    -158.701922
                ],
                [
                    58.387715,
                    -158.893615
                ],
                [
                    58.420577,
                    -159.0634
                ],
                [
                    58.760147,
                    -159.392016
                ],
                [
                    58.929932,
                    -159.616571
                ],
                [
                    58.929932,
                    -159.731586
                ],
                [
                    58.803962,
                    -159.808264
                ],
                [
                    58.782055,
                    -159.906848
                ],
                [
                    58.886116,
                    -160.054726
                ],
                [
                    58.902547,
                    -160.235465
                ],
                [
                    59.072332,
                    -160.317619
                ],
                [
                    58.88064,
                    -160.854359
                ],
                [
                    58.743716,
                    -161.33633
                ],
                [
                    58.667039,
                    -161.374669
                ],
                [
                    58.552023,
                    -161.752577
                ],
                [
                    58.656085,
                    -161.938793
                ],
                [
                    58.776578,
                    -161.769008
                ],
                [
                    59.061379,
                    -161.829255
                ],
                [
                    59.36261,
                    -161.955224
                ],
                [
                    59.48858,
                    -161.703285
                ],
                [
                    59.740519,
                    -161.911409
                ],
                [
                    59.88292,
                    -162.092148
                ],
                [
                    60.091043,
                    -162.234548
                ],
                [
                    60.178674,
                    -162.448149
                ],
                [
                    59.997935,
                    -162.502918
                ],
                [
                    59.959597,
                    -162.760334
                ],
                [
                    59.844581,
                    -163.171105
                ],
                [
                    59.795289,
                    -163.66403
                ],
                [
                    59.806242,
                    -163.9324
                ],
                [
                    59.866489,
                    -164.162431
                ],
                [
                    60.02532,
                    -164.189816
                ],
                [
                    60.074613,
                    -164.386986
                ],
                [
                    60.29369,
                    -164.699171
                ],
                [
                    60.337506,
                    -164.962064
                ],
                [
                    60.578491,
                    -165.268773
                ],
                [
                    60.68803,
                    -165.060649
                ],
                [
                    60.890677,
                    -165.016834
                ],
                [
                    60.846861,
                    -165.175665
                ],
                [
                    60.972831,
                    -165.197573
                ],
                [
                    61.076893,
                    -165.120896
                ],
                [
                    61.170001,
                    -165.323543
                ],
                [
                    61.071416,
                    -165.34545
                ],
                [
                    61.109754,
                    -165.591913
                ],
                [
                    61.279539,
                    -165.624774
                ],
                [
                    61.301447,
                    -165.816467
                ],
                [
                    61.416463,
                    -165.920529
                ],
                [
                    61.558863,
                    -165.915052
                ],
                [
                    61.49314,
                    -166.106745
                ],
                [
                    61.630064,
                    -166.139607
                ],
                [
                    61.662925,
                    -165.904098
                ],
                [
                    61.81628,
                    -166.095791
                ],
                [
                    61.827233,
                    -165.756221
                ],
                [
                    62.013449,
                    -165.756221
                ],
                [
                    62.139419,
                    -165.674067
                ],
                [
                    62.539236,
                    -165.044219
                ],
                [
                    62.659728,
                    -164.912772
                ],
                [
                    62.637821,
                    -164.819664
                ],
                [
                    62.807606,
                    -164.874433
                ],
                [
                    63.097884,
                    -164.633448
                ],
                [
                    63.212899,
                    -164.425324
                ],
                [
                    63.262192,
                    -164.036462
                ],
                [
                    63.212899,
                    -163.73523
                ],
                [
                    63.037637,
                    -163.313505
                ],
                [
                    63.059545,
                    -163.039658
                ],
                [
                    63.22933,
                    -162.661749
                ],
                [
                    63.486746,
                    -162.272887
                ],
                [
                    63.514131,
                    -162.075717
                ],
                [
                    63.448408,
                    -162.026424
                ],
                [
                    63.448408,
                    -161.555408
                ],
                [
                    63.503177,
                    -161.13916
                ],
                [
                    63.771547,
                    -160.766728
                ],
                [
                    63.837271,
                    -160.766728
                ],
                [
                    64.08921,
                    -160.952944
                ],
                [
                    64.237087,
                    -160.974852
                ],
                [
                    64.395918,
                    -161.26513
                ],
                [
                    64.532842,
                    -161.374669
                ],
                [
                    64.494503,
                    -161.078914
                ],
                [
                    64.609519,
                    -160.79959
                ],
                [
                    64.719058,
                    -160.783159
                ],
                [
                    64.921705,
                    -161.144637
                ],
                [
                    64.762873,
                    -161.413007
                ],
                [
                    64.790258,
                    -161.664946
                ],
                [
                    64.702627,
                    -161.900455
                ],
                [
                    64.680719,
                    -162.168825
                ],
                [
                    64.620473,
                    -162.234548
                ],
                [
                    64.532842,
                    -162.541257
                ],
                [
                    64.384965,
                    -162.634365
                ],
                [
                    64.324718,
                    -162.787719
                ],
                [
                    64.49998,
                    -162.858919
                ],
                [
                    64.538319,
                    -163.045135
                ],
                [
                    64.401395,
                    -163.176582
                ],
                [
                    64.467119,
                    -163.253259
                ],
                [
                    64.565704,
                    -163.598306
                ],
                [
                    64.560227,
                    -164.304832
                ],
                [
                    64.450688,
                    -164.80871
                ],
                [
                    64.434257,
                    -165.000403
                ],
                [
                    64.49998,
                    -165.411174
                ],
                [
                    64.576658,
                    -166.188899
                ],
                [
                    64.636904,
                    -166.391546
                ],
                [
                    64.735489,
                    -166.484654
                ],
                [
                    64.872412,
                    -166.413454
                ],
                [
                    64.987428,
                    -166.692778
                ],
                [
                    65.113398,
                    -166.638008
                ],
                [
                    65.179121,
                    -166.462746
                ],
                [
                    65.337952,
                    -166.517516
                ],
                [
                    65.337952,
                    -166.796839
                ],
                [
                    65.381768,
                    -167.026871
                ],
                [
                    65.414629,
                    -167.47598
                ],
                [
                    65.496784,
                    -167.711489
                ],
                [
                    65.578938,
                    -168.072967
                ],
                [
                    65.682999,
                    -168.105828
                ],
                [
                    65.819923,
                    -167.541703
                ],
                [
                    66.049954,
                    -166.829701
                ],
                [
                    66.186878,
                    -166.3313
                ],
                [
                    66.110201,
                    -166.046499
                ],
                [
                    66.09377,
                    -165.756221
                ],
                [
                    66.203309,
                    -165.690498
                ],
                [
                    66.21974,
                    -165.86576
                ],
                [
                    66.312848,
                    -165.88219
                ],
                [
                    66.466202,
                    -165.186619
                ],
                [
                    66.581218,
                    -164.403417
                ],
                [
                    66.592172,
                    -163.981692
                ],
                [
                    66.553833,
                    -163.751661
                ],
                [
                    66.389525,
                    -163.872153
                ],
                [
                    66.274509,
                    -163.828338
                ],
                [
                    66.192355,
                    -163.915969
                ],
                [
                    66.060908,
                    -163.768091
                ],
                [
                    66.082816,
                    -163.494244
                ],
                [
                    66.060908,
                    -163.149197
                ],
                [
                    66.088293,
                    -162.749381
                ],
                [
                    66.039001,
                    -162.634365
                ],
                [
                    66.028047,
                    -162.371472
                ],
                [
                    66.077339,
                    -162.14144
                ],
                [
                    66.02257,
                    -161.840208
                ],
                [
                    66.241647,
                    -161.549931
                ],
                [
                    66.252601,
                    -161.341807
                ],
                [
                    66.208786,
                    -161.199406
                ],
                [
                    66.334755,
                    -161.128206
                ],
                [
                    66.395002,
                    -161.528023
                ],
                [
                    66.345709,
                    -161.911409
                ],
                [
                    66.510017,
                    -161.87307
                ],
                [
                    66.68528,
                    -162.174302
                ],
                [
                    66.740049,
                    -162.502918
                ],
                [
                    66.89888,
                    -162.601503
                ],
                [
                    66.937219,
                    -162.344087
                ],
                [
                    66.778388,
                    -162.015471
                ],
                [
                    66.652418,
                    -162.075717
                ],
                [
                    66.553833,
                    -161.916886
                ],
                [
                    66.438817,
                    -161.571838
                ],
                [
                    66.55931,
                    -161.489684
                ],
                [
                    66.718141,
                    -161.884024
                ],
                [
                    67.002942,
                    -161.714239
                ],
                [
                    67.052235,
                    -161.851162
                ],
                [
                    66.991988,
                    -162.240025
                ],
                [
                    67.008419,
                    -162.639842
                ],
                [
                    67.057712,
                    -162.700088
                ],
                [
                    67.008419,
                    -162.902735
                ],
                [
                    67.128912,
                    -163.740707
                ],
                [
                    67.254881,
                    -163.757138
                ],
                [
                    67.534205,
                    -164.009077
                ],
                [
                    67.638267,
                    -164.211724
                ],
                [
                    67.725898,
                    -164.534863
                ],
                [
                    67.966884,
                    -165.192096
                ],
                [
                    68.059992,
                    -165.493328
                ],
                [
                    68.081899,
                    -165.794559
                ],
                [
                    68.246208,
                    -166.243668
                ],
                [
                    68.339316,
                    -166.681824
                ],
                [
                    68.372177,
                    -166.703731
                ],
                [
                    68.42147,
                    -166.375115
                ],
                [
                    68.574824,
                    -166.227238
                ],
                [
                    68.881533,
                    -166.216284
                ],
                [
                    68.859625,
                    -165.329019
                ],
                [
                    68.930825,
                    -164.255539
                ],
                [
                    68.985595,
                    -163.976215
                ],
                [
                    69.138949,
                    -163.532583
                ],
                [
                    69.374457,
                    -163.110859
                ],
                [
                    69.609966,
                    -163.023228
                ],
                [
                    69.812613,
                    -162.842489
                ],
                [
                    69.982398,
                    -162.470057
                ],
                [
                    70.108367,
                    -162.311225
                ],
                [
                    70.311014,
                    -161.851162
                ],
                [
                    70.256245,
                    -161.779962
                ],
                [
                    70.239814,
                    -161.396576
                ],
                [
                    70.343876,
                    -160.837928
                ],
                [
                    70.453415,
                    -160.487404
                ],
                [
                    70.792985,
                    -159.649432
                ],
                [
                    70.809416,
                    -159.33177
                ],
                [
                    70.760123,
                    -159.298908
                ],
                [
                    70.798462,
                    -158.975769
                ],
                [
                    70.787508,
                    -158.658106
                ],
                [
                    70.831323,
                    -158.033735
                ],
                [
                    70.979201,
                    -157.420318
                ],
                [
                    71.285909,
                    -156.812377
                ],
                [
                    71.351633,
                    -156.565915
                ],
                [
                    71.296863,
                    -156.522099
                ],
                [
                    71.170894,
                    -155.585543
                ],
                [
                    71.083263,
                    -155.508865
                ],
                [
                    70.968247,
                    -155.832005
                ],
                [
                    70.96277,
                    -155.979882
                ],
                [
                    70.809416,
                    -155.974405
                ],
                [
                    70.858708,
                    -155.503388
                ],
                [
                    70.940862,
                    -155.476004
                ],
                [
                    71.017539,
                    -155.262403
                ],
                [
                    70.973724,
                    -155.191203
                ],
                [
                    71.148986,
                    -155.032372
                ],
                [
                    70.990155,
                    -154.566832
                ],
                [
                    70.869662,
                    -154.643509
                ],
                [
                    70.8368,
                    -154.353231
                ],
                [
                    70.7656,
                    -154.183446
                ],
                [
                    70.880616,
                    -153.931507
                ],
                [
                    70.886093,
                    -153.487874
                ],
                [
                    70.924431,
                    -153.235935
                ],
                [
                    70.886093,
                    -152.589656
                ],
                [
                    70.842277,
                    -152.26104
                ],
                [
                    70.606769,
                    -152.419871
                ],
                [
                    70.546523,
                    -151.817408
                ],
                [
                    70.486276,
                    -151.773592
                ],
                [
                    70.382214,
                    -151.187559
                ],
                [
                    70.431507,
                    -151.182082
                ],
                [
                    70.49723,
                    -150.760358
                ],
                [
                    70.491753,
                    -150.355064
                ],
                [
                    70.436984,
                    -150.349588
                ],
                [
                    70.431507,
                    -150.114079
                ],
                [
                    70.508184,
                    -149.867617
                ],
                [
                    70.519138,
                    -149.462323
                ],
                [
                    70.486276,
                    -149.177522
                ],
                [
                    70.404122,
                    -148.78866
                ],
                [
                    70.420553,
                    -148.607921
                ],
                [
                    70.305537,
                    -148.350504
                ],
                [
                    70.349353,
                    -148.202627
                ],
                [
                    70.316491,
                    -147.961642
                ],
                [
                    70.245291,
                    -147.786379
                ]
            ]
        ],
        [
            [
                [
                    58.026237,
                    -152.94018
                ],
                [
                    57.982421,
                    -152.945657
                ],
                [
                    58.048145,
                    -153.290705
                ],
                [
                    58.305561,
                    -153.044242
                ],
                [
                    58.327469,
                    -152.819688
                ],
                [
                    58.562977,
                    -152.666333
                ],
                [
                    58.354853,
                    -152.496548
                ],
                [
                    58.426053,
                    -152.354148
                ],
                [
                    58.311038,
                    -152.080301
                ],
                [
                    58.152206,
                    -152.080301
                ],
                [
                    58.130299,
                    -152.480117
                ],
                [
                    58.059098,
                    -152.655379
                ],
                [
                    58.026237,
                    -152.94018
                ]
            ]
        ],
        [
            [
                [
                    57.538789,
                    -153.958891
                ],
                [
                    57.670236,
                    -153.67409
                ],
                [
                    57.69762,
                    -153.931507
                ],
                [
                    57.812636,
                    -153.936983
                ],
                [
                    57.889313,
                    -153.723383
                ],
                [
                    57.834544,
                    -153.570028
                ],
                [
                    57.719528,
                    -153.548121
                ],
                [
                    57.796205,
                    -153.46049
                ],
                [
                    57.96599,
                    -153.455013
                ],
                [
                    57.889313,
                    -153.268797
                ],
                [
                    57.998852,
                    -153.235935
                ],
                [
                    57.933129,
                    -153.071627
                ],
                [
                    57.933129,
                    -152.874457
                ],
                [
                    57.993375,
                    -152.721103
                ],
                [
                    57.889313,
                    -152.469163
                ],
                [
                    57.599035,
                    -152.469163
                ],
                [
                    57.620943,
                    -152.151501
                ],
                [
                    57.42925,
                    -152.359625
                ],
                [
                    57.505928,
                    -152.74301
                ],
                [
                    57.379958,
                    -152.60061
                ],
                [
                    57.275896,
                    -152.710149
                ],
                [
                    57.325188,
                    -152.907319
                ],
                [
                    57.128019,
                    -152.912796
                ],
                [
                    57.073249,
                    -153.214027
                ],
                [
                    56.991095,
                    -153.312612
                ],
                [
                    57.067772,
                    -153.498828
                ],
                [
                    56.859649,
                    -153.695998
                ],
                [
                    56.837741,
                    -153.849352
                ],
                [
                    56.744633,
                    -154.013661
                ],
                [
                    56.969187,
                    -154.073907
                ],
                [
                    56.848695,
                    -154.303938
                ],
                [
                    56.919895,
                    -154.314892
                ],
                [
                    56.991095,
                    -154.523016
                ],
                [
                    57.193742,
                    -154.539447
                ],
                [
                    57.275896,
                    -154.742094
                ],
                [
                    57.511404,
                    -154.627078
                ],
                [
                    57.659282,
                    -154.227261
                ],
                [
                    57.648328,
                    -153.980799
                ],
                [
                    57.538789,
                    -153.958891
                ]
            ]
        ],
        [
            [
                [
                    56.602232,
                    -154.53397
                ],
                [
                    56.399586,
                    -154.742094
                ],
                [
                    56.432447,
                    -154.807817
                ],
                [
                    56.602232,
                    -154.53397
                ]
            ]
        ],
        [
            [
                [
                    55.923092,
                    -155.634835
                ],
                [
                    55.912138,
                    -155.476004
                ],
                [
                    55.704014,
                    -155.530773
                ],
                [
                    55.731399,
                    -155.793666
                ],
                [
                    55.802599,
                    -155.837482
                ],
                [
                    55.923092,
                    -155.634835
                ]
            ]
        ],
        [
            [
                [
                    55.28229,
                    -159.890418
                ],
                [
                    55.068689,
                    -159.950664
                ],
                [
                    54.893427,
                    -160.257373
                ],
                [
                    55.161797,
                    -160.109495
                ],
                [
                    55.134412,
                    -160.005433
                ],
                [
                    55.28229,
                    -159.890418
                ]
            ]
        ],
        [
            [
                [
                    55.358967,
                    -160.520266
                ],
                [
                    55.358967,
                    -160.33405
                ],
                [
                    55.249428,
                    -160.339527
                ],
                [
                    55.128935,
                    -160.525743
                ],
                [
                    55.211089,
                    -160.690051
                ],
                [
                    55.134412,
                    -160.794113
                ],
                [
                    55.320628,
                    -160.854359
                ],
                [
                    55.380875,
                    -160.79959
                ],
                [
                    55.358967,
                    -160.520266
                ]
            ]
        ],
        [
            [
                [
                    54.981058,
                    -162.256456
                ],
                [
                    54.893427,
                    -162.234548
                ],
                [
                    54.838658,
                    -162.349564
                ],
                [
                    54.931766,
                    -162.437195
                ],
                [
                    54.981058,
                    -162.256456
                ]
            ]
        ],
        [
            [
                [
                    63.634624,
                    -162.415287
                ],
                [
                    63.536039,
                    -162.563165
                ],
                [
                    63.62367,
                    -162.612457
                ],
                [
                    63.634624,
                    -162.415287
                ]
            ]
        ],
        [
            [
                [
                    54.488133,
                    -162.80415
                ],
                [
                    54.449795,
                    -162.590549
                ],
                [
                    54.367641,
                    -162.612457
                ],
                [
                    54.373118,
                    -162.782242
                ],
                [
                    54.488133,
                    -162.80415
                ]
            ]
        ],
        [
            [
                [
                    54.29644,
                    -165.548097
                ],
                [
                    54.181425,
                    -165.476897
                ],
                [
                    54.132132,
                    -165.630251
                ],
                [
                    54.252625,
                    -165.685021
                ],
                [
                    54.29644,
                    -165.548097
                ]
            ]
        ],
        [
            [
                [
                    54.15404,
                    -165.73979
                ],
                [
                    54.044501,
                    -166.046499
                ],
                [
                    54.121178,
                    -166.112222
                ],
                [
                    54.219763,
                    -165.980775
                ],
                [
                    54.15404,
                    -165.73979
                ]
            ]
        ],
        [
            [
                [
                    60.359413,
                    -166.364161
                ],
                [
                    60.397752,
                    -166.13413
                ],
                [
                    60.326552,
                    -166.084837
                ],
                [
                    60.342983,
                    -165.88219
                ],
                [
                    60.277259,
                    -165.685021
                ],
                [
                    59.992458,
                    -165.646682
                ],
                [
                    59.89935,
                    -165.750744
                ],
                [
                    59.844581,
                    -166.00816
                ],
                [
                    59.745996,
                    -166.062929
                ],
                [
                    59.855535,
                    -166.440838
                ],
                [
                    59.850058,
                    -166.6161
                ],
                [
                    59.992458,
                    -166.994009
                ],
                [
                    59.992458,
                    -167.125456
                ],
                [
                    60.074613,
                    -167.344534
                ],
                [
                    60.206059,
                    -167.421211
                ],
                [
                    60.238921,
                    -167.311672
                ],
                [
                    60.206059,
                    -166.93924
                ],
                [
                    60.310121,
                    -166.763978
                ],
                [
                    60.321075,
                    -166.577762
                ],
                [
                    60.392275,
                    -166.495608
                ],
                [
                    60.359413,
                    -166.364161
                ]
            ]
        ],
        [
            [
                [
                    54.01164,
                    -166.375115
                ],
                [
                    53.934962,
                    -166.210807
                ],
                [
                    53.748746,
                    -166.5449
                ],
                [
                    53.715885,
                    -166.539423
                ],
                [
                    53.852808,
                    -166.117699
                ],
                [
                    53.776131,
                    -166.112222
                ],
                [
                    53.683023,
                    -166.282007
                ],
                [
                    53.622777,
                    -166.555854
                ],
                [
                    53.529669,
                    -166.583239
                ],
                [
                    53.431084,
                    -166.878994
                ],
                [
                    53.425607,
                    -167.13641
                ],
                [
                    53.332499,
                    -167.306195
                ],
                [
                    53.250345,
                    -167.623857
                ],
                [
                    53.337976,
                    -167.793643
                ],
                [
                    53.442038,
                    -167.459549
                ],
                [
                    53.425607,
                    -167.355487
                ],
                [
                    53.513238,
                    -167.103548
                ],
                [
                    53.611823,
                    -167.163794
                ],
                [
                    53.715885,
                    -167.021394
                ],
                [
                    53.666592,
                    -166.807793
                ],
                [
                    53.732316,
                    -166.785886
                ],
                [
                    53.754223,
                    -167.015917
                ],
                [
                    53.825424,
                    -167.141887
                ],
                [
                    53.945916,
                    -167.032348
                ],
                [
                    54.017116,
                    -166.643485
                ],
                [
                    53.880193,
                    -166.561331
                ],
                [
                    54.01164,
                    -166.375115
                ]
            ]
        ],
        [
            [
                [
                    53.157237,
                    -168.790446
                ],
                [
                    53.34893,
                    -168.40706
                ],
                [
                    53.431084,
                    -168.385152
                ],
                [
                    53.524192,
                    -168.237275
                ],
                [
                    53.568007,
                    -168.007243
                ],
                [
                    53.518715,
                    -167.886751
                ],
                [
                    53.387268,
                    -167.842935
                ],
                [
                    53.244868,
                    -168.270136
                ],
                [
                    53.036744,
                    -168.500168
                ],
                [
                    52.965544,
                    -168.686384
                ],
                [
                    53.157237,
                    -168.790446
                ]
            ]
        ],
        [
            [
                [
                    52.894344,
                    -169.74891
                ],
                [
                    52.795759,
                    -169.705095
                ],
                [
                    52.790282,
                    -169.962511
                ],
                [
                    52.856005,
                    -169.989896
                ],
                [
                    52.894344,
                    -169.74891
                ]
            ]
        ],
        [
            [
                [
                    57.221127,
                    -170.148727
                ],
                [
                    57.128019,
                    -170.28565
                ],
                [
                    57.221127,
                    -170.313035
                ],
                [
                    57.221127,
                    -170.148727
                ]
            ]
        ],
        [
            [
                [
                    52.697174,
                    -170.669036
                ],
                [
                    52.604066,
                    -170.603313
                ],
                [
                    52.538343,
                    -170.789529
                ],
                [
                    52.636928,
                    -170.816914
                ],
                [
                    52.697174,
                    -170.669036
                ]
            ]
        ],
        [
            [
                [
                    63.716778,
                    -171.742517
                ],
                [
                    63.5689,
                    -170.94836
                ],
                [
                    63.69487,
                    -170.488297
                ],
                [
                    63.683916,
                    -170.280174
                ],
                [
                    63.612716,
                    -170.093958
                ],
                [
                    63.492223,
                    -170.044665
                ],
                [
                    63.4265,
                    -169.644848
                ],
                [
                    63.366254,
                    -169.518879
                ],
                [
                    63.338869,
                    -168.99857
                ],
                [
                    63.295053,
                    -168.686384
                ],
                [
                    63.147176,
                    -168.856169
                ],
                [
                    63.180038,
                    -169.108108
                ],
                [
                    63.152653,
                    -169.376478
                ],
                [
                    63.08693,
                    -169.513402
                ],
                [
                    62.939052,
                    -169.639372
                ],
                [
                    63.075976,
                    -169.831064
                ],
                [
                    63.169084,
                    -170.055619
                ],
                [
                    63.180038,
                    -170.263743
                ],
                [
                    63.2841,
                    -170.362328
                ],
                [
                    63.415546,
                    -170.866206
                ],
                [
                    63.421023,
                    -171.101715
                ],
                [
                    63.306007,
                    -171.463193
                ],
                [
                    63.366254,
                    -171.73704
                ],
                [
                    63.486746,
                    -171.852055
                ],
                [
                    63.716778,
                    -171.742517
                ]
            ]
        ],
        [
            [
                [
                    52.390465,
                    -172.432611
                ],
                [
                    52.275449,
                    -172.41618
                ],
                [
                    52.253542,
                    -172.607873
                ],
                [
                    52.352127,
                    -172.569535
                ],
                [
                    52.390465,
                    -172.432611
                ]
            ]
        ],
        [
            [
                [
                    52.14948,
                    -173.626584
                ],
                [
                    52.105664,
                    -173.495138
                ],
                [
                    52.111141,
                    -173.122706
                ],
                [
                    52.07828,
                    -173.106275
                ],
                [
                    52.028987,
                    -173.549907
                ],
                [
                    52.14948,
                    -173.626584
                ]
            ]
        ],
        [
            [
                [
                    52.280926,
                    -174.322156
                ],
                [
                    52.379511,
                    -174.327632
                ],
                [
                    52.41785,
                    -174.185232
                ],
                [
                    52.319265,
                    -173.982585
                ],
                [
                    52.226157,
                    -174.059262
                ],
                [
                    52.231634,
                    -174.179755
                ],
                [
                    52.127572,
                    -174.141417
                ],
                [
                    52.116618,
                    -174.333109
                ],
                [
                    52.007079,
                    -174.738403
                ],
                [
                    52.039941,
                    -174.968435
                ],
                [
                    52.116618,
                    -174.902711
                ],
                [
                    52.105664,
                    -174.656249
                ],
                [
                    52.280926,
                    -174.322156
                ]
            ]
        ],
        [
            [
                [
                    51.853725,
                    -176.469116
                ],
                [
                    51.870156,
                    -176.288377
                ],
                [
                    51.744186,
                    -176.288377
                ],
                [
                    51.760617,
                    -176.518409
                ],
                [
                    51.61274,
                    -176.80321
                ],
                [
                    51.80991,
                    -176.912748
                ],
                [
                    51.815386,
                    -176.792256
                ],
                [
                    51.963264,
                    -176.775825
                ],
                [
                    51.968741,
                    -176.627947
                ],
                [
                    51.859202,
                    -176.627947
                ],
                [
                    51.853725,
                    -176.469116
                ]
            ]
        ],
        [
            [
                [
                    51.946833,
                    -177.153734
                ],
                [
                    51.897541,
                    -177.044195
                ],
                [
                    51.727755,
                    -177.120872
                ],
                [
                    51.678463,
                    -177.274226
                ],
                [
                    51.782525,
                    -177.279703
                ],
                [
                    51.946833,
                    -177.153734
                ]
            ]
        ],
        [
            [
                [
                    51.919448,
                    -178.123152
                ],
                [
                    51.913971,
                    -177.953367
                ],
                [
                    51.793479,
                    -177.800013
                ],
                [
                    51.651078,
                    -177.964321
                ],
                [
                    51.919448,
                    -178.123152
                ]
            ]
        ],
        [
            [
                [
                    52.992929,
                    -187.107557
                ],
                [
                    52.927205,
                    -187.293773
                ],
                [
                    52.823143,
                    -187.304726
                ],
                [
                    52.762897,
                    -188.90491
                ],
                [
                    52.927205,
                    -188.642017
                ],
                [
                    53.003883,
                    -188.642017
                ],
                [
                    52.992929,
                    -187.107557
                ]
            ]
        ]
    ],
    "Arizona": [
        [
            [
                37.000263,
                -109.042503
            ],
            [
                31.331629,
                -109.04798
            ],
            [
                31.331629,
                -111.074448
            ],
            [
                31.704061,
                -112.246513
            ],
            [
                32.492741,
                -114.815198
            ],
            [
                32.717295,
                -114.72209
            ],
            [
                32.755634,
                -114.524921
            ],
            [
                32.843265,
                -114.470151
            ],
            [
                33.029481,
                -114.524921
            ],
            [
                33.034958,
                -114.661844
            ],
            [
                33.40739,
                -114.727567
            ],
            [
                33.54979,
                -114.524921
            ],
            [
                33.697668,
                -114.497536
            ],
            [
                33.933176,
                -114.535874
            ],
            [
                34.108438,
                -114.415382
            ],
            [
                34.174162,
                -114.256551
            ],
            [
                34.305608,
                -114.136058
            ],
            [
                34.448009,
                -114.333228
            ],
            [
                34.710902,
                -114.470151
            ],
            [
                34.87521,
                -114.634459
            ],
            [
                35.00118,
                -114.634459
            ],
            [
                35.138103,
                -114.574213
            ],
            [
                35.324319,
                -114.596121
            ],
            [
                35.516012,
                -114.678275
            ],
            [
                36.102045,
                -114.738521
            ],
            [
                36.140383,
                -114.371566
            ],
            [
                36.01989,
                -114.251074
            ],
            [
                36.025367,
                -114.152489
            ],
            [
                36.195153,
                -114.048427
            ],
            [
                37.000263,
                -114.048427
            ],
            [
                37.00574,
                -110.499369
            ],
            [
                37.000263,
                -109.042503
            ]
        ]
    ],
    "Arkansas": [
        [
            [
                36.501861,
                -94.473842
            ],
            [
                36.496384,
                -90.152536
            ],
            [
                36.304691,
                -90.064905
            ],
            [
                36.184199,
                -90.218259
            ],
            [
                35.997983,
                -90.377091
            ],
            [
                35.997983,
                -89.730812
            ],
            [
                35.811767,
                -89.763673
            ],
            [
                35.756997,
                -89.911551
            ],
            [
                35.603643,
                -89.944412
            ],
            [
                35.439335,
                -90.130628
            ],
            [
                35.198349,
                -90.114197
            ],
            [
                35.023087,
                -90.212782
            ],
            [
                34.995703,
                -90.311367
            ],
            [
                34.908072,
                -90.251121
            ],
            [
                34.831394,
                -90.409952
            ],
            [
                34.661609,
                -90.481152
            ],
            [
                34.617794,
                -90.585214
            ],
            [
                34.420624,
                -90.568783
            ],
            [
                34.365854,
                -90.749522
            ],
            [
                34.300131,
                -90.744046
            ],
            [
                34.135823,
                -90.952169
            ],
            [
                34.026284,
                -90.891923
            ],
            [
                33.867453,
                -91.072662
            ],
            [
                33.560744,
                -91.231493
            ],
            [
                33.429298,
                -91.056231
            ],
            [
                33.347144,
                -91.143862
            ],
            [
                33.13902,
                -91.089093
            ],
            [
                33.002096,
                -91.16577
            ],
            [
                33.018527,
                -93.608485
            ],
            [
                33.018527,
                -94.041164
            ],
            [
                33.54979,
                -94.041164
            ],
            [
                33.593606,
                -94.183564
            ],
            [
                33.544313,
                -94.380734
            ],
            [
                33.637421,
                -94.484796
            ],
            [
                35.395519,
                -94.430026
            ],
            [
                36.501861,
                -94.616242
            ],
            [
                36.501861,
                -94.473842
            ]
        ]
    ],
    "California": [
        [
            [
                42.006186,
                -123.233256
            ],
            [
                42.011663,
                -122.378853
            ],
            [
                41.995232,
                -121.037003
            ],
            [
                41.995232,
                -120.001861
            ],
            [
                40.264519,
                -119.996384
            ],
            [
                38.999346,
                -120.001861
            ],
            [
                38.101128,
                -118.71478
            ],
            [
                37.21934,
                -117.498899
            ],
            [
                36.501861,
                -116.540435
            ],
            [
                35.970598,
                -115.85034
            ],
            [
                35.00118,
                -114.634459
            ],
            [
                34.87521,
                -114.634459
            ],
            [
                34.710902,
                -114.470151
            ],
            [
                34.448009,
                -114.333228
            ],
            [
                34.305608,
                -114.136058
            ],
            [
                34.174162,
                -114.256551
            ],
            [
                34.108438,
                -114.415382
            ],
            [
                33.933176,
                -114.535874
            ],
            [
                33.697668,
                -114.497536
            ],
            [
                33.54979,
                -114.524921
            ],
            [
                33.40739,
                -114.727567
            ],
            [
                33.034958,
                -114.661844
            ],
            [
                33.029481,
                -114.524921
            ],
            [
                32.843265,
                -114.470151
            ],
            [
                32.755634,
                -114.524921
            ],
            [
                32.717295,
                -114.72209
            ],
            [
                32.624187,
                -116.04751
            ],
            [
                32.536556,
                -117.126467
            ],
            [
                32.668003,
                -117.24696
            ],
            [
                32.876127,
                -117.252437
            ],
            [
                33.122589,
                -117.329114
            ],
            [
                33.297851,
                -117.471515
            ],
            [
                33.538836,
                -117.7837
            ],
            [
                33.763391,
                -118.183517
            ],
            [
                33.703145,
                -118.260194
            ],
            [
                33.741483,
                -118.413548
            ],
            [
                33.840068,
                -118.391641
            ],
            [
                34.042715,
                -118.566903
            ],
            [
                33.998899,
                -118.802411
            ],
            [
                34.146777,
                -119.218659
            ],
            [
                34.26727,
                -119.278905
            ],
            [
                34.415147,
                -119.558229
            ],
            [
                34.40967,
                -119.875891
            ],
            [
                34.475393,
                -120.138784
            ],
            [
                34.448009,
                -120.472878
            ],
            [
                34.579455,
                -120.64814
            ],
            [
                34.858779,
                -120.609801
            ],
            [
                34.902595,
                -120.670048
            ],
            [
                35.099764,
                -120.631709
            ],
            [
                35.247642,
                -120.894602
            ],
            [
                35.450289,
                -120.905556
            ],
            [
                35.461243,
                -121.004141
            ],
            [
                35.636505,
                -121.168449
            ],
            [
                35.674843,
                -121.283465
            ],
            [
                35.784382,
                -121.332757
            ],
            [
                36.195153,
                -121.716143
            ],
            [
                36.315645,
                -121.896882
            ],
            [
                36.638785,
                -121.935221
            ],
            [
                36.6114,
                -121.858544
            ],
            [
                36.803093,
                -121.787344
            ],
            [
                36.978355,
                -121.929744
            ],
            [
                36.956447,
                -122.105006
            ],
            [
                37.115279,
                -122.335038
            ],
            [
                37.241248,
                -122.417192
            ],
            [
                37.361741,
                -122.400761
            ],
            [
                37.520572,
                -122.515777
            ],
            [
                37.783465,
                -122.515777
            ],
            [
                37.783465,
                -122.329561
            ],
            [
                38.15042,
                -122.406238
            ],
            [
                38.112082,
                -122.488392
            ],
            [
                37.931343,
                -122.504823
            ],
            [
                37.893004,
                -122.701993
            ],
            [
                38.029928,
                -122.937501
            ],
            [
                38.265436,
                -122.97584
            ],
            [
                38.451652,
                -123.129194
            ],
            [
                38.566668,
                -123.331841
            ],
            [
                38.698114,
                -123.44138
            ],
            [
                38.95553,
                -123.737134
            ],
            [
                39.032208,
                -123.687842
            ],
            [
                39.366301,
                -123.824765
            ],
            [
                39.552517,
                -123.764519
            ],
            [
                39.831841,
                -123.85215
            ],
            [
                40.105688,
                -124.109566
            ],
            [
                40.259042,
                -124.361506
            ],
            [
                40.439781,
                -124.410798
            ],
            [
                40.877937,
                -124.158859
            ],
            [
                41.025814,
                -124.109566
            ],
            [
                41.14083,
                -124.158859
            ],
            [
                41.442061,
                -124.065751
            ],
            [
                41.715908,
                -124.147905
            ],
            [
                41.781632,
                -124.257444
            ],
            [
                42.000709,
                -124.213628
            ],
            [
                42.006186,
                -123.233256
            ]
        ]
    ],
    "Colorado": [
        [
            [
                41.003906,
                -107.919731
            ],
            [
                40.998429,
                -105.728954
            ],
            [
                41.003906,
                -104.053011
            ],
            [
                41.003906,
                -102.053927
            ],
            [
                40.001626,
                -102.053927
            ],
            [
                36.994786,
                -102.042974
            ],
            [
                37.000263,
                -103.001438
            ],
            [
                36.994786,
                -104.337812
            ],
            [
                36.994786,
                -106.868158
            ],
            [
                37.000263,
                -107.421329
            ],
            [
                37.000263,
                -109.042503
            ],
            [
                38.166851,
                -109.042503
            ],
            [
                38.27639,
                -109.058934
            ],
            [
                39.125316,
                -109.053457
            ],
            [
                40.998429,
                -109.04798
            ],
            [
                41.003906,
                -107.919731
            ]
        ]
    ],
    "Connecticut": [
        [
            [
                42.039048,
                -73.053528
            ],
            [
                42.022617,
                -71.799309
            ],
            [
                42.006186,
                -71.799309
            ],
            [
                41.414677,
                -71.799309
            ],
            [
                41.321569,
                -71.859555
            ],
            [
                41.338,
                -71.947186
            ],
            [
                41.261322,
                -72.385341
            ],
            [
                41.28323,
                -72.905651
            ],
            [
                41.146307,
                -73.130205
            ],
            [
                41.102491,
                -73.371191
            ],
            [
                40.987475,
                -73.655992
            ],
            [
                41.102491,
                -73.727192
            ],
            [
                41.21203,
                -73.48073
            ],
            [
                41.294184,
                -73.55193
            ],
            [
                42.050002,
                -73.486206
            ],
            [
                42.039048,
                -73.053528
            ]
        ]
    ],
    "Delaware": [
        [
            [
                39.804456,
                -75.414089
            ],
            [
                39.683964,
                -75.507197
            ],
            [
                39.61824,
                -75.611259
            ],
            [
                39.459409,
                -75.589352
            ],
            [
                39.311532,
                -75.441474
            ],
            [
                39.065069,
                -75.403136
            ],
            [
                38.807653,
                -75.189535
            ],
            [
                38.796699,
                -75.09095
            ],
            [
                38.451652,
                -75.047134
            ],
            [
                38.462606,
                -75.693413
            ],
            [
                39.722302,
                -75.786521
            ],
            [
                39.831841,
                -75.616736
            ],
            [
                39.804456,
                -75.414089
            ]
        ]
    ],
    "District of Columbia": [
        [
            [
                38.993869,
                -77.035264
            ],
            [
                38.895284,
                -76.909294
            ],
            [
                38.791222,
                -77.040741
            ],
            [
                38.933623,
                -77.117418
            ],
            [
                38.993869,
                -77.035264
            ]
        ]
    ],
    "Florida": [
        [
            [
                30.997536,
                -85.497137
            ],
            [
                31.003013,
                -85.004212
            ],
            [
                30.712735,
                -84.867289
            ],
            [
                30.647012,
                -83.498053
            ],
            [
                30.570335,
                -82.216449
            ],
            [
                30.356734,
                -82.167157
            ],
            [
                30.362211,
                -82.046664
            ],
            [
                30.564858,
                -82.002849
            ],
            [
                30.751074,
                -82.041187
            ],
            [
                30.827751,
                -81.948079
            ],
            [
                30.745597,
                -81.718048
            ],
            [
                30.707258,
                -81.444201
            ],
            [
                30.27458,
                -81.383954
            ],
            [
                29.787132,
                -81.257985
            ],
            [
                29.14633,
                -80.967707
            ],
            [
                28.461713,
                -80.524075
            ],
            [
                28.41242,
                -80.589798
            ],
            [
                28.094758,
                -80.56789
            ],
            [
                27.738757,
                -80.381674
            ],
            [
                27.021277,
                -80.091397
            ],
            [
                26.796723,
                -80.03115
            ],
            [
                26.566691,
                -80.036627
            ],
            [
                25.739673,
                -80.146166
            ],
            [
                25.723243,
                -80.239274
            ],
            [
                25.465826,
                -80.337859
            ],
            [
                25.383672,
                -80.304997
            ],
            [
                25.197456,
                -80.49669
            ],
            [
                25.241272,
                -80.573367
            ],
            [
                25.164595,
                -80.759583
            ],
            [
                25.120779,
                -81.077246
            ],
            [
                25.224841,
                -81.170354
            ],
            [
                25.378195,
                -81.126538
            ],
            [
                25.821827,
                -81.351093
            ],
            [
                25.903982,
                -81.526355
            ],
            [
                25.843735,
                -81.679709
            ],
            [
                26.090198,
                -81.800202
            ],
            [
                26.292844,
                -81.833064
            ],
            [
                26.517399,
                -82.041187
            ],
            [
                26.665276,
                -82.09048
            ],
            [
                26.878877,
                -82.057618
            ],
            [
                26.917216,
                -82.172634
            ],
            [
                26.791246,
                -82.145249
            ],
            [
                26.758384,
                -82.249311
            ],
            [
                27.300601,
                -82.566974
            ],
            [
                27.437525,
                -82.692943
            ],
            [
                27.837342,
                -82.391711
            ],
            [
                27.815434,
                -82.588881
            ],
            [
                27.689464,
                -82.720328
            ],
            [
                27.886634,
                -82.851774
            ],
            [
                28.434328,
                -82.676512
            ],
            [
                28.888914,
                -82.643651
            ],
            [
                28.998453,
                -82.764143
            ],
            [
                29.14633,
                -82.802482
            ],
            [
                29.179192,
                -82.994175
            ],
            [
                29.420177,
                -83.218729
            ],
            [
                29.518762,
                -83.399469
            ],
            [
                29.66664,
                -83.410422
            ],
            [
                29.721409,
                -83.536392
            ],
            [
                29.885717,
                -83.640454
            ],
            [
                30.104795,
                -84.02384
            ],
            [
                30.055502,
                -84.357933
            ],
            [
                29.902148,
                -84.341502
            ],
            [
                29.929533,
                -84.451041
            ],
            [
                29.743317,
                -84.867289
            ],
            [
                29.699501,
                -85.310921
            ],
            [
                29.80904,
                -85.299967
            ],
            [
                29.940487,
                -85.404029
            ],
            [
                30.236241,
                -85.924338
            ],
            [
                30.362211,
                -86.29677
            ],
            [
                30.395073,
                -86.630863
            ],
            [
                30.373165,
                -86.910187
            ],
            [
                30.280057,
                -87.518128
            ],
            [
                30.427934,
                -87.37025
            ],
            [
                30.510088,
                -87.446927
            ],
            [
                30.674397,
                -87.408589
            ],
            [
                30.86609,
                -87.633143
            ],
            [
                30.997536,
                -87.600282
            ],
            [
                30.997536,
                -85.497137
            ]
        ]
    ],
    "Georgia": [
        [
            [
                35.00118,
                -83.109191
            ],
            [
                34.787579,
                -83.322791
            ],
            [
                34.683517,
                -83.339222
            ],
            [
                34.469916,
                -83.005129
            ],
            [
                34.486347,
                -82.901067
            ],
            [
                34.26727,
                -82.747713
            ],
            [
                34.152254,
                -82.714851
            ],
            [
                33.94413,
                -82.55602
            ],
            [
                33.81816,
                -82.325988
            ],
            [
                33.631944,
                -82.194542
            ],
            [
                33.462159,
                -81.926172
            ],
            [
                33.347144,
                -81.937125
            ],
            [
                33.160928,
                -81.761863
            ],
            [
                33.007573,
                -81.493493
            ],
            [
                32.843265,
                -81.42777
            ],
            [
                32.629664,
                -81.416816
            ],
            [
                32.558464,
                -81.279893
            ],
            [
                32.290094,
                -81.121061
            ],
            [
                32.120309,
                -81.115584
            ],
            [
                32.032678,
                -80.885553
            ],
            [
                31.693108,
                -81.132015
            ],
            [
                31.517845,
                -81.175831
            ],
            [
                31.364491,
                -81.279893
            ],
            [
                31.20566,
                -81.290846
            ],
            [
                31.13446,
                -81.400385
            ],
            [
                30.707258,
                -81.444201
            ],
            [
                30.745597,
                -81.718048
            ],
            [
                30.827751,
                -81.948079
            ],
            [
                30.751074,
                -82.041187
            ],
            [
                30.564858,
                -82.002849
            ],
            [
                30.362211,
                -82.046664
            ],
            [
                30.356734,
                -82.167157
            ],
            [
                30.570335,
                -82.216449
            ],
            [
                30.647012,
                -83.498053
            ],
            [
                30.712735,
                -84.867289
            ],
            [
                31.003013,
                -85.004212
            ],
            [
                31.27686,
                -85.113751
            ],
            [
                31.539753,
                -85.042551
            ],
            [
                31.840985,
                -85.141136
            ],
            [
                32.01077,
                -85.053504
            ],
            [
                32.13674,
                -85.058981
            ],
            [
                32.262709,
                -84.889196
            ],
            [
                32.322956,
                -85.004212
            ],
            [
                32.421541,
                -84.960397
            ],
            [
                32.580372,
                -85.069935
            ],
            [
                32.859696,
                -85.184951
            ],
            [
                34.124869,
                -85.431413
            ],
            [
                34.984749,
                -85.606675
            ],
            [
                34.990226,
                -84.319594
            ],
            [
                34.984749,
                -83.618546
            ],
            [
                35.00118,
                -83.109191
            ]
        ]
    ],
    "Hawaii": [
        [
            [
                [
                    18.948267,
                    -155.634835
                ],
                [
                    19.035898,
                    -155.881297
                ],
                [
                    19.123529,
                    -155.919636
                ],
                [
                    19.348084,
                    -155.886774
                ],
                [
                    19.73147,
                    -156.062036
                ],
                [
                    19.857439,
                    -155.925113
                ],
                [
                    20.032702,
                    -155.826528
                ],
                [
                    20.147717,
                    -155.897728
                ],
                [
                    20.26821,
                    -155.87582
                ],
                [
                    20.12581,
                    -155.596496
                ],
                [
                    20.021748,
                    -155.284311
                ],
                [
                    19.868393,
                    -155.092618
                ],
                [
                    19.736947,
                    -155.092618
                ],
                [
                    19.523346,
                    -154.807817
                ],
                [
                    19.348084,
                    -154.983079
                ],
                [
                    19.26593,
                    -155.295265
                ],
                [
                    19.134483,
                    -155.514342
                ],
                [
                    18.948267,
                    -155.634835
                ]
            ]
        ],
        [
            [
                [
                    21.029505,
                    -156.587823
                ],
                [
                    20.892581,
                    -156.472807
                ],
                [
                    20.952827,
                    -156.324929
                ],
                [
                    20.793996,
                    -156.00179
                ],
                [
                    20.651596,
                    -156.051082
                ],
                [
                    20.580396,
                    -156.379699
                ],
                [
                    20.60778,
                    -156.445422
                ],
                [
                    20.783042,
                    -156.461853
                ],
                [
                    20.821381,
                    -156.631638
                ],
                [
                    20.919966,
                    -156.697361
                ],
                [
                    21.029505,
                    -156.587823
                ]
            ]
        ],
        [
            [
                [
                    21.210244,
                    -156.982162
                ],
                [
                    21.106182,
                    -157.080747
                ],
                [
                    21.106182,
                    -157.310779
                ],
                [
                    21.221198,
                    -157.239579
                ],
                [
                    21.210244,
                    -156.982162
                ]
            ]
        ],
        [
            [
                [
                    21.697691,
                    -157.951581
                ],
                [
                    21.462183,
                    -157.842042
                ],
                [
                    21.325259,
                    -157.896811
                ],
                [
                    21.303352,
                    -158.110412
                ],
                [
                    21.582676,
                    -158.252813
                ],
                [
                    21.588153,
                    -158.126843
                ],
                [
                    21.697691,
                    -157.951581
                ]
            ]
        ],
        [
            [
                [
                    22.228955,
                    -159.468693
                ],
                [
                    22.218001,
                    -159.353678
                ],
                [
                    22.113939,
                    -159.298908
                ],
                [
                    21.966061,
                    -159.33177
                ],
                [
                    21.872953,
                    -159.446786
                ],
                [
                    21.987969,
                    -159.764448
                ],
                [
                    22.152277,
                    -159.726109
                ],
                [
                    22.228955,
                    -159.468693
                ]
            ]
        ]
    ],
    "Idaho": [
        [
            [
                49.000239,
                -116.04751
            ],
            [
                47.976051,
                -116.04751
            ],
            [
                47.696727,
                -115.724371
            ],
            [
                47.42288,
                -115.718894
            ],
            [
                47.302388,
                -115.527201
            ],
            [
                47.258572,
                -115.324554
            ],
            [
                47.187372,
                -115.302646
            ],
            [
                46.919002,
                -114.930214
            ],
            [
                46.809463,
                -114.886399
            ],
            [
                46.705401,
                -114.623506
            ],
            [
                46.639678,
                -114.612552
            ],
            [
                46.645155,
                -114.322274
            ],
            [
                46.272723,
                -114.464674
            ],
            [
                46.037214,
                -114.492059
            ],
            [
                45.88386,
                -114.387997
            ],
            [
                45.774321,
                -114.568736
            ],
            [
                45.670259,
                -114.497536
            ],
            [
                45.560721,
                -114.546828
            ],
            [
                45.456659,
                -114.333228
            ],
            [
                45.593582,
                -114.086765
            ],
            [
                45.703121,
                -113.98818
            ],
            [
                45.604536,
                -113.807441
            ],
            [
                45.522382,
                -113.834826
            ],
            [
                45.330689,
                -113.736241
            ],
            [
                45.128042,
                -113.571933
            ],
            [
                45.056842,
                -113.45144
            ],
            [
                44.865149,
                -113.456917
            ],
            [
                44.782995,
                -113.341901
            ],
            [
                44.772041,
                -113.133778
            ],
            [
                44.448902,
                -113.002331
            ],
            [
                44.394132,
                -112.887315
            ],
            [
                44.48724,
                -112.783254
            ],
            [
                44.481763,
                -112.471068
            ],
            [
                44.569394,
                -112.241036
            ],
            [
                44.520102,
                -112.104113
            ],
            [
                44.563917,
                -111.868605
            ],
            [
                44.509148,
                -111.819312
            ],
            [
                44.547487,
                -111.616665
            ],
            [
                44.75561,
                -111.386634
            ],
            [
                44.580348,
                -111.227803
            ],
            [
                44.476286,
                -111.047063
            ],
            [
                42.000709,
                -111.047063
            ],
            [
                41.995232,
                -112.164359
            ],
            [
                41.995232,
                -114.04295
            ],
            [
                42.000709,
                -117.027882
            ],
            [
                43.830007,
                -117.027882
            ],
            [
                44.158624,
                -116.896436
            ],
            [
                44.240778,
                -116.97859
            ],
            [
                44.257209,
                -117.170283
            ],
            [
                44.394132,
                -117.241483
            ],
            [
                44.750133,
                -117.038836
            ],
            [
                44.782995,
                -116.934774
            ],
            [
                44.930872,
                -116.830713
            ],
            [
                45.02398,
                -116.847143
            ],
            [
                45.144473,
                -116.732128
            ],
            [
                45.319735,
                -116.671881
            ],
            [
                45.61549,
                -116.463758
            ],
            [
                45.752413,
                -116.545912
            ],
            [
                45.823614,
                -116.78142
            ],
            [
                45.993399,
                -116.918344
            ],
            [
                46.168661,
                -116.92382
            ],
            [
                46.343923,
                -117.055267
            ],
            [
                46.426077,
                -117.038836
            ],
            [
                47.762451,
                -117.044313
            ],
            [
                49.000239,
                -117.033359
            ],
            [
                49.000239,
                -116.04751
            ]
        ]
    ],
    "Illinois": [
        [
            [
                42.510065,
                -90.639984
            ],
            [
                42.493634,
                -88.788778
            ],
            [
                42.493634,
                -87.802929
            ],
            [
                42.301941,
                -87.83579
            ],
            [
                42.077386,
                -87.682436
            ],
            [
                41.710431,
                -87.523605
            ],
            [
                39.34987,
                -87.529082
            ],
            [
                39.169131,
                -87.63862
            ],
            [
                38.95553,
                -87.512651
            ],
            [
                38.780268,
                -87.49622
            ],
            [
                38.637868,
                -87.62219
            ],
            [
                38.506421,
                -87.655051
            ],
            [
                38.292821,
                -87.83579
            ],
            [
                38.27639,
                -87.950806
            ],
            [
                38.15042,
                -87.923421
            ],
            [
                38.101128,
                -88.000098
            ],
            [
                37.865619,
                -88.060345
            ],
            [
                37.799896,
                -88.027483
            ],
            [
                37.657496,
                -88.15893
            ],
            [
                37.482234,
                -88.065822
            ],
            [
                37.389126,
                -88.476592
            ],
            [
                37.285064,
                -88.514931
            ],
            [
                37.153617,
                -88.421823
            ],
            [
                37.071463,
                -88.547792
            ],
            [
                37.224817,
                -88.914747
            ],
            [
                37.213863,
                -89.029763
            ],
            [
                37.038601,
                -89.183118
            ],
            [
                36.983832,
                -89.133825
            ],
            [
                36.994786,
                -89.292656
            ],
            [
                37.279587,
                -89.517211
            ],
            [
                37.34531,
                -89.435057
            ],
            [
                37.537003,
                -89.517211
            ],
            [
                37.690357,
                -89.517211
            ],
            [
                37.903958,
                -89.84035
            ],
            [
                37.88205,
                -89.949889
            ],
            [
                38.013497,
                -90.059428
            ],
            [
                38.216144,
                -90.355183
            ],
            [
                38.374975,
                -90.349706
            ],
            [
                38.632391,
                -90.179921
            ],
            [
                38.725499,
                -90.207305
            ],
            [
                38.845992,
                -90.10872
            ],
            [
                38.917192,
                -90.251121
            ],
            [
                38.961007,
                -90.470199
            ],
            [
                38.867899,
                -90.585214
            ],
            [
                38.928146,
                -90.661891
            ],
            [
                39.256762,
                -90.727615
            ],
            [
                39.470363,
                -91.061708
            ],
            [
                39.727779,
                -91.368417
            ],
            [
                40.034488,
                -91.494386
            ],
            [
                40.237135,
                -91.50534
            ],
            [
                40.379535,
                -91.417709
            ],
            [
                40.560274,
                -91.401278
            ],
            [
                40.669813,
                -91.121954
            ],
            [
                40.823167,
                -91.09457
            ],
            [
                40.921752,
                -90.963123
            ],
            [
                41.097014,
                -90.946692
            ],
            [
                41.239415,
                -91.111001
            ],
            [
                41.414677,
                -91.045277
            ],
            [
                41.463969,
                -90.656414
            ],
            [
                41.589939,
                -90.344229
            ],
            [
                41.743293,
                -90.311367
            ],
            [
                41.809016,
                -90.179921
            ],
            [
                42.000709,
                -90.141582
            ],
            [
                42.126679,
                -90.168967
            ],
            [
                42.225264,
                -90.393521
            ],
            [
                42.329326,
                -90.420906
            ],
            [
                42.510065,
                -90.639984
            ]
        ]
    ],
    "Indiana": [
        [
            [
                41.759724,
                -85.990061
            ],
            [
                41.759724,
                -84.807042
            ],
            [
                41.694001,
                -84.807042
            ],
            [
                40.500028,
                -84.801565
            ],
            [
                39.103408,
                -84.817996
            ],
            [
                39.059592,
                -84.894673
            ],
            [
                38.785745,
                -84.812519
            ],
            [
                38.780268,
                -84.987781
            ],
            [
                38.68716,
                -85.173997
            ],
            [
                38.730976,
                -85.431413
            ],
            [
                38.533806,
                -85.42046
            ],
            [
                38.451652,
                -85.590245
            ],
            [
                38.325682,
                -85.655968
            ],
            [
                38.27639,
                -85.83123
            ],
            [
                38.024451,
                -85.924338
            ],
            [
                37.958727,
                -86.039354
            ],
            [
                38.051835,
                -86.263908
            ],
            [
                38.166851,
                -86.302247
            ],
            [
                38.040881,
                -86.521325
            ],
            [
                37.931343,
                -86.504894
            ],
            [
                37.893004,
                -86.729448
            ],
            [
                37.991589,
                -86.795172
            ],
            [
                37.893004,
                -87.047111
            ],
            [
                37.788942,
                -87.129265
            ],
            [
                37.93682,
                -87.381204
            ],
            [
                37.903958,
                -87.512651
            ],
            [
                37.975158,
                -87.600282
            ],
            [
                37.903958,
                -87.682436
            ],
            [
                37.893004,
                -87.934375
            ],
            [
                37.799896,
                -88.027483
            ],
            [
                37.865619,
                -88.060345
            ],
            [
                38.101128,
                -88.000098
            ],
            [
                38.15042,
                -87.923421
            ],
            [
                38.27639,
                -87.950806
            ],
            [
                38.292821,
                -87.83579
            ],
            [
                38.506421,
                -87.655051
            ],
            [
                38.637868,
                -87.62219
            ],
            [
                38.780268,
                -87.49622
            ],
            [
                38.95553,
                -87.512651
            ],
            [
                39.169131,
                -87.63862
            ],
            [
                39.34987,
                -87.529082
            ],
            [
                41.710431,
                -87.523605
            ],
            [
                41.644708,
                -87.42502
            ],
            [
                41.644708,
                -87.118311
            ],
            [
                41.759724,
                -86.822556
            ],
            [
                41.759724,
                -85.990061
            ]
        ]
    ],
    "Iowa": [
        [
            [
                43.501391,
                -91.368417
            ],
            [
                43.501391,
                -91.215062
            ],
            [
                43.353514,
                -91.204109
            ],
            [
                43.254929,
                -91.056231
            ],
            [
                43.134436,
                -91.176724
            ],
            [
                42.909881,
                -91.143862
            ],
            [
                42.75105,
                -91.067185
            ],
            [
                42.636034,
                -90.711184
            ],
            [
                42.510065,
                -90.639984
            ],
            [
                42.329326,
                -90.420906
            ],
            [
                42.225264,
                -90.393521
            ],
            [
                42.126679,
                -90.168967
            ],
            [
                42.000709,
                -90.141582
            ],
            [
                41.809016,
                -90.179921
            ],
            [
                41.743293,
                -90.311367
            ],
            [
                41.589939,
                -90.344229
            ],
            [
                41.463969,
                -90.656414
            ],
            [
                41.414677,
                -91.045277
            ],
            [
                41.239415,
                -91.111001
            ],
            [
                41.097014,
                -90.946692
            ],
            [
                40.921752,
                -90.963123
            ],
            [
                40.823167,
                -91.09457
            ],
            [
                40.669813,
                -91.121954
            ],
            [
                40.560274,
                -91.401278
            ],
            [
                40.379535,
                -91.417709
            ],
            [
                40.412397,
                -91.527248
            ],
            [
                40.615043,
                -91.729895
            ],
            [
                40.609566,
                -91.833957
            ],
            [
                40.582182,
                -93.257961
            ],
            [
                40.571228,
                -94.632673
            ],
            [
                40.587659,
                -95.7664
            ],
            [
                40.719105,
                -95.881416
            ],
            [
                40.976521,
                -95.826646
            ],
            [
                41.201076,
                -95.925231
            ],
            [
                41.453015,
                -95.919754
            ],
            [
                41.540646,
                -96.095016
            ],
            [
                41.67757,
                -96.122401
            ],
            [
                41.798063,
                -96.062155
            ],
            [
                41.973325,
                -96.127878
            ],
            [
                42.039048,
                -96.264801
            ],
            [
                42.488157,
                -96.44554
            ],
            [
                42.707235,
                -96.631756
            ],
            [
                42.855112,
                -96.544125
            ],
            [
                43.052282,
                -96.511264
            ],
            [
                43.123482,
                -96.434587
            ],
            [
                43.222067,
                -96.560556
            ],
            [
                43.397329,
                -96.527695
            ],
            [
                43.479483,
                -96.582464
            ],
            [
                43.501391,
                -96.451017
            ],
            [
                43.501391,
                -91.368417
            ]
        ]
    ],
    "Kansas": [
        [
            [
                40.001626,
                -101.90605
            ],
            [
                40.001626,
                -95.306337
            ],
            [
                39.908518,
                -95.207752
            ],
            [
                39.831841,
                -94.884612
            ],
            [
                39.541563,
                -95.109167
            ],
            [
                39.442978,
                -94.983197
            ],
            [
                39.20747,
                -94.824366
            ],
            [
                39.158177,
                -94.610765
            ],
            [
                37.000263,
                -94.616242
            ],
            [
                37.000263,
                -100.087706
            ],
            [
                36.994786,
                -102.042974
            ],
            [
                40.001626,
                -102.053927
            ],
            [
                40.001626,
                -101.90605
            ]
        ]
    ],
    "Kentucky": [
        [
            [
                38.769315,
                -83.903347
            ],
            [
                38.632391,
                -83.678792
            ],
            [
                38.703591,
                -83.519961
            ],
            [
                38.626914,
                -83.142052
            ],
            [
                38.725499,
                -83.032514
            ],
            [
                38.758361,
                -82.890113
            ],
            [
                38.588575,
                -82.846298
            ],
            [
                38.561191,
                -82.731282
            ],
            [
                38.424267,
                -82.594358
            ],
            [
                38.123036,
                -82.621743
            ],
            [
                37.931343,
                -82.50125
            ],
            [
                37.783465,
                -82.342419
            ],
            [
                37.668449,
                -82.293127
            ],
            [
                37.553434,
                -82.101434
            ],
            [
                37.537003,
                -81.969987
            ],
            [
                37.268633,
                -82.353373
            ],
            [
                37.120755,
                -82.720328
            ],
            [
                37.044078,
                -82.720328
            ],
            [
                36.978355,
                -82.868205
            ],
            [
                36.890724,
                -82.879159
            ],
            [
                36.852385,
                -83.070852
            ],
            [
                36.742847,
                -83.136575
            ],
            [
                36.600446,
                -83.673316
            ],
            [
                36.584015,
                -83.689746
            ],
            [
                36.594969,
                -84.544149
            ],
            [
                36.627831,
                -85.289013
            ],
            [
                36.616877,
                -85.486183
            ],
            [
                36.655216,
                -86.592525
            ],
            [
                36.633308,
                -87.852221
            ],
            [
                36.677123,
                -88.071299
            ],
            [
                36.496384,
                -88.054868
            ],
            [
                36.507338,
                -89.298133
            ],
            [
                36.496384,
                -89.418626
            ],
            [
                36.622354,
                -89.363857
            ],
            [
                36.578538,
                -89.215979
            ],
            [
                36.983832,
                -89.133825
            ],
            [
                37.038601,
                -89.183118
            ],
            [
                37.213863,
                -89.029763
            ],
            [
                37.224817,
                -88.914747
            ],
            [
                37.071463,
                -88.547792
            ],
            [
                37.153617,
                -88.421823
            ],
            [
                37.285064,
                -88.514931
            ],
            [
                37.389126,
                -88.476592
            ],
            [
                37.482234,
                -88.065822
            ],
            [
                37.657496,
                -88.15893
            ],
            [
                37.799896,
                -88.027483
            ],
            [
                37.893004,
                -87.934375
            ],
            [
                37.903958,
                -87.682436
            ],
            [
                37.975158,
                -87.600282
            ],
            [
                37.903958,
                -87.512651
            ],
            [
                37.93682,
                -87.381204
            ],
            [
                37.788942,
                -87.129265
            ],
            [
                37.893004,
                -87.047111
            ],
            [
                37.991589,
                -86.795172
            ],
            [
                37.893004,
                -86.729448
            ],
            [
                37.931343,
                -86.504894
            ],
            [
                38.040881,
                -86.521325
            ],
            [
                38.166851,
                -86.302247
            ],
            [
                38.051835,
                -86.263908
            ],
            [
                37.958727,
                -86.039354
            ],
            [
                38.024451,
                -85.924338
            ],
            [
                38.27639,
                -85.83123
            ],
            [
                38.325682,
                -85.655968
            ],
            [
                38.451652,
                -85.590245
            ],
            [
                38.533806,
                -85.42046
            ],
            [
                38.730976,
                -85.431413
            ],
            [
                38.68716,
                -85.173997
            ],
            [
                38.780268,
                -84.987781
            ],
            [
                38.785745,
                -84.812519
            ],
            [
                39.059592,
                -84.894673
            ],
            [
                39.103408,
                -84.817996
            ],
            [
                39.103408,
                -84.43461
            ],
            [
                38.895284,
                -84.231963
            ],
            [
                38.807653,
                -84.215533
            ],
            [
                38.769315,
                -83.903347
            ]
        ]
    ],
    "Louisiana": [
        [
            [
                33.018527,
                -93.608485
            ],
            [
                33.002096,
                -91.16577
            ],
            [
                32.887081,
                -91.072662
            ],
            [
                32.843265,
                -91.143862
            ],
            [
                32.640618,
                -91.154816
            ],
            [
                32.514649,
                -91.006939
            ],
            [
                32.218894,
                -90.985031
            ],
            [
                31.988862,
                -91.105524
            ],
            [
                31.846462,
                -91.341032
            ],
            [
                31.621907,
                -91.401278
            ],
            [
                31.643815,
                -91.499863
            ],
            [
                31.27686,
                -91.516294
            ],
            [
                31.265906,
                -91.636787
            ],
            [
                31.068736,
                -91.565587
            ],
            [
                30.997536,
                -91.636787
            ],
            [
                30.997536,
                -89.747242
            ],
            [
                30.66892,
                -89.845827
            ],
            [
                30.449842,
                -89.681519
            ],
            [
                30.285534,
                -89.643181
            ],
            [
                30.181472,
                -89.522688
            ],
            [
                30.044549,
                -89.818443
            ],
            [
                29.945964,
                -89.84035
            ],
            [
                29.88024,
                -89.599365
            ],
            [
                30.039072,
                -89.495303
            ],
            [
                29.88024,
                -89.287179
            ],
            [
                29.754271,
                -89.30361
            ],
            [
                29.699501,
                -89.424103
            ],
            [
                29.748794,
                -89.648657
            ],
            [
                29.655686,
                -89.621273
            ],
            [
                29.513285,
                -89.69795
            ],
            [
                29.387316,
                -89.506257
            ],
            [
                29.348977,
                -89.199548
            ],
            [
                29.2011,
                -89.09001
            ],
            [
                29.179192,
                -89.002379
            ],
            [
                29.009407,
                -89.16121
            ],
            [
                29.042268,
                -89.336472
            ],
            [
                29.217531,
                -89.484349
            ],
            [
                29.310638,
                -89.851304
            ],
            [
                29.480424,
                -89.851304
            ],
            [
                29.425654,
                -90.032043
            ],
            [
                29.283254,
                -90.021089
            ],
            [
                29.151807,
                -90.103244
            ],
            [
                29.129899,
                -90.23469
            ],
            [
                29.277777,
                -90.333275
            ],
            [
                29.283254,
                -90.563307
            ],
            [
                29.129899,
                -90.645461
            ],
            [
                29.086084,
                -90.798815
            ],
            [
                29.179192,
                -90.963123
            ],
            [
                29.190146,
                -91.09457
            ],
            [
                29.436608,
                -91.220539
            ],
            [
                29.546147,
                -91.445094
            ],
            [
                29.529716,
                -91.532725
            ],
            [
                29.73784,
                -91.620356
            ],
            [
                29.710455,
                -91.883249
            ],
            [
                29.836425,
                -91.888726
            ],
            [
                29.715932,
                -92.146142
            ],
            [
                29.622824,
                -92.113281
            ],
            [
                29.535193,
                -92.31045
            ],
            [
                29.579009,
                -92.617159
            ],
            [
                29.715932,
                -92.97316
            ],
            [
                29.776178,
                -93.2251
            ],
            [
                29.726886,
                -93.767317
            ],
            [
                29.688547,
                -93.838517
            ],
            [
                29.787132,
                -93.926148
            ],
            [
                30.143133,
                -93.690639
            ],
            [
                30.334826,
                -93.767317
            ],
            [
                30.438888,
                -93.696116
            ],
            [
                30.575812,
                -93.728978
            ],
            [
                30.679874,
                -93.630393
            ],
            [
                30.93729,
                -93.526331
            ],
            [
                31.15089,
                -93.542762
            ],
            [
                31.556184,
                -93.816609
            ],
            [
                31.775262,
                -93.822086
            ],
            [
                31.994339,
                -94.041164
            ],
            [
                33.018527,
                -94.041164
            ],
            [
                33.018527,
                -93.608485
            ]
        ]
    ],
    "Maine": [
        [
            [
                43.057759,
                -70.703921
            ],
            [
                43.128959,
                -70.824413
            ],
            [
                43.227544,
                -70.807983
            ],
            [
                43.34256,
                -70.966814
            ],
            [
                44.657025,
                -71.032537
            ],
            [
                45.303304,
                -71.08183
            ],
            [
                45.440228,
                -70.649151
            ],
            [
                45.511428,
                -70.720352
            ],
            [
                45.664782,
                -70.556043
            ],
            [
                45.735983,
                -70.386258
            ],
            [
                45.796229,
                -70.41912
            ],
            [
                45.889337,
                -70.260289
            ],
            [
                46.064599,
                -70.309581
            ],
            [
                46.327492,
                -70.210996
            ],
            [
                46.415123,
                -70.057642
            ],
            [
                46.694447,
                -69.997395
            ],
            [
                47.461219,
                -69.225147
            ],
            [
                47.428357,
                -69.044408
            ],
            [
                47.242141,
                -69.033454
            ],
            [
                47.176418,
                -68.902007
            ],
            [
                47.285957,
                -68.578868
            ],
            [
                47.285957,
                -68.376221
            ],
            [
                47.357157,
                -68.233821
            ],
            [
                47.198326,
                -67.954497
            ],
            [
                47.066879,
                -67.790188
            ],
            [
                45.944106,
                -67.779235
            ],
            [
                45.675736,
                -67.801142
            ],
            [
                45.604536,
                -67.456095
            ],
            [
                45.48952,
                -67.505388
            ],
            [
                45.379982,
                -67.417757
            ],
            [
                45.281397,
                -67.488957
            ],
            [
                45.128042,
                -67.346556
            ],
            [
                45.160904,
                -67.16034
            ],
            [
                44.804903,
                -66.979601
            ],
            [
                44.646072,
                -67.187725
            ],
            [
                44.706318,
                -67.308218
            ],
            [
                44.596779,
                -67.406803
            ],
            [
                44.624164,
                -67.549203
            ],
            [
                44.531056,
                -67.565634
            ],
            [
                44.54201,
                -67.75185
            ],
            [
                44.328409,
                -68.047605
            ],
            [
                44.476286,
                -68.118805
            ],
            [
                44.48724,
                -68.222867
            ],
            [
                44.328409,
                -68.173574
            ],
            [
                44.251732,
                -68.403606
            ],
            [
                44.377701,
                -68.458375
            ],
            [
                44.311978,
                -68.567914
            ],
            [
                44.311978,
                -68.82533
            ],
            [
                44.459856,
                -68.830807
            ],
            [
                44.426994,
                -68.984161
            ],
            [
                44.322932,
                -68.956777
            ],
            [
                44.103854,
                -69.099177
            ],
            [
                44.043608,
                -69.071793
            ],
            [
                43.923115,
                -69.258008
            ],
            [
                43.966931,
                -69.444224
            ],
            [
                43.840961,
                -69.553763
            ],
            [
                43.82453,
                -69.707118
            ],
            [
                43.720469,
                -69.833087
            ],
            [
                43.742376,
                -69.986442
            ],
            [
                43.851915,
                -70.030257
            ],
            [
                43.676653,
                -70.254812
            ],
            [
                43.567114,
                -70.194565
            ],
            [
                43.528776,
                -70.358873
            ],
            [
                43.435668,
                -70.369827
            ],
            [
                43.320652,
                -70.556043
            ],
            [
                43.057759,
                -70.703921
            ]
        ]
    ],
    "Maryland": [
        [
            [
                [
                    37.95325,
                    -75.994645
                ],
                [
                    37.95325,
                    -76.016553
                ],
                [
                    37.95325,
                    -76.043938
                ],
                [
                    37.95325,
                    -75.994645
                ]
            ]
        ],
        [
            [
                [
                    39.722302,
                    -79.477979
                ],
                [
                    39.722302,
                    -75.786521
                ],
                [
                    38.462606,
                    -75.693413
                ],
                [
                    38.451652,
                    -75.047134
                ],
                [
                    38.029928,
                    -75.244304
                ],
                [
                    38.013497,
                    -75.397659
                ],
                [
                    37.95325,
                    -75.671506
                ],
                [
                    37.909435,
                    -75.885106
                ],
                [
                    38.073743,
                    -75.879629
                ],
                [
                    38.139466,
                    -75.961783
                ],
                [
                    38.210667,
                    -75.846768
                ],
                [
                    38.374975,
                    -76.000122
                ],
                [
                    38.303775,
                    -76.049415
                ],
                [
                    38.320205,
                    -76.257538
                ],
                [
                    38.500944,
                    -76.328738
                ],
                [
                    38.500944,
                    -76.263015
                ],
                [
                    38.736453,
                    -76.257538
                ],
                [
                    38.829561,
                    -76.191815
                ],
                [
                    39.147223,
                    -76.279446
                ],
                [
                    39.333439,
                    -76.169907
                ],
                [
                    39.366301,
                    -76.000122
                ],
                [
                    39.557994,
                    -75.972737
                ],
                [
                    39.536086,
                    -76.098707
                ],
                [
                    39.437501,
                    -76.104184
                ],
                [
                    39.311532,
                    -76.367077
                ],
                [
                    39.196516,
                    -76.443754
                ],
                [
                    38.906238,
                    -76.460185
                ],
                [
                    38.769315,
                    -76.55877
                ],
                [
                    38.539283,
                    -76.514954
                ],
                [
                    38.380452,
                    -76.383508
                ],
                [
                    38.259959,
                    -76.399939
                ],
                [
                    38.139466,
                    -76.317785
                ],
                [
                    38.057312,
                    -76.3616
                ],
                [
                    38.216144,
                    -76.591632
                ],
                [
                    38.292821,
                    -76.920248
                ],
                [
                    38.446175,
                    -77.018833
                ],
                [
                    38.358544,
                    -77.205049
                ],
                [
                    38.479037,
                    -77.276249
                ],
                [
                    38.632391,
                    -77.128372
                ],
                [
                    38.791222,
                    -77.040741
                ],
                [
                    38.895284,
                    -76.909294
                ],
                [
                    38.993869,
                    -77.035264
                ],
                [
                    38.933623,
                    -77.117418
                ],
                [
                    39.026731,
                    -77.248864
                ],
                [
                    39.076023,
                    -77.456988
                ],
                [
                    39.223901,
                    -77.456988
                ],
                [
                    39.306055,
                    -77.566527
                ],
                [
                    39.322485,
                    -77.719881
                ],
                [
                    39.601809,
                    -77.834897
                ],
                [
                    39.601809,
                    -78.004682
                ],
                [
                    39.694917,
                    -78.174467
                ],
                [
                    39.61824,
                    -78.267575
                ],
                [
                    39.623717,
                    -78.431884
                ],
                [
                    39.514178,
                    -78.470222
                ],
                [
                    39.585379,
                    -78.765977
                ],
                [
                    39.437501,
                    -78.963147
                ],
                [
                    39.470363,
                    -79.094593
                ],
                [
                    39.300578,
                    -79.291763
                ],
                [
                    39.20747,
                    -79.488933
                ],
                [
                    39.722302,
                    -79.477979
                ]
            ]
        ]
    ],
    "Massachusetts": [
        [
            [
                42.887974,
                -70.917521
            ],
            [
                42.871543,
                -70.818936
            ],
            [
                42.696281,
                -70.780598
            ],
            [
                42.55388,
                -70.824413
            ],
            [
                42.422434,
                -70.983245
            ],
            [
                42.269079,
                -70.988722
            ],
            [
                42.247172,
                -70.769644
            ],
            [
                42.08834,
                -70.638197
            ],
            [
                41.962371,
                -70.660105
            ],
            [
                41.929509,
                -70.550566
            ],
            [
                41.814493,
                -70.539613
            ],
            [
                41.715908,
                -70.260289
            ],
            [
                41.809016,
                -69.937149
            ],
            [
                41.672093,
                -70.008349
            ],
            [
                41.5516,
                -70.484843
            ],
            [
                41.546123,
                -70.660105
            ],
            [
                41.639231,
                -70.764167
            ],
            [
                41.611847,
                -70.928475
            ],
            [
                41.540646,
                -70.933952
            ],
            [
                41.496831,
                -71.120168
            ],
            [
                41.67757,
                -71.196845
            ],
            [
                41.710431,
                -71.22423
            ],
            [
                41.781632,
                -71.328292
            ],
            [
                42.01714,
                -71.383061
            ],
            [
                42.01714,
                -71.530939
            ],
            [
                42.006186,
                -71.799309
            ],
            [
                42.022617,
                -71.799309
            ],
            [
                42.039048,
                -73.053528
            ],
            [
                42.050002,
                -73.486206
            ],
            [
                42.08834,
                -73.508114
            ],
            [
                42.745573,
                -73.267129
            ],
            [
                42.729142,
                -72.456542
            ],
            [
                42.696281,
                -71.29543
            ],
            [
                42.789389,
                -71.185891
            ],
            [
                42.887974,
                -70.917521
            ]
        ]
    ],
    "Michigan": [
        [
            [
                [
                    41.732339,
                    -83.454238
                ],
                [
                    41.694001,
                    -84.807042
                ],
                [
                    41.759724,
                    -84.807042
                ],
                [
                    41.759724,
                    -85.990061
                ],
                [
                    41.759724,
                    -86.822556
                ],
                [
                    41.891171,
                    -86.619909
                ],
                [
                    42.115725,
                    -86.482986
                ],
                [
                    42.252649,
                    -86.357016
                ],
                [
                    42.444341,
                    -86.263908
                ],
                [
                    42.718189,
                    -86.209139
                ],
                [
                    43.013943,
                    -86.231047
                ],
                [
                    43.594499,
                    -86.526801
                ],
                [
                    43.813577,
                    -86.433693
                ],
                [
                    44.07647,
                    -86.499417
                ],
                [
                    44.34484,
                    -86.269385
                ],
                [
                    44.569394,
                    -86.220093
                ],
                [
                    44.689887,
                    -86.252954
                ],
                [
                    44.73918,
                    -86.088646
                ],
                [
                    44.903488,
                    -86.066738
                ],
                [
                    44.947303,
                    -85.809322
                ],
                [
                    45.128042,
                    -85.612152
                ],
                [
                    44.766564,
                    -85.628583
                ],
                [
                    44.750133,
                    -85.524521
                ],
                [
                    44.930872,
                    -85.393075
                ],
                [
                    45.237581,
                    -85.387598
                ],
                [
                    45.314258,
                    -85.305444
                ],
                [
                    45.363551,
                    -85.031597
                ],
                [
                    45.577151,
                    -85.119228
                ],
                [
                    45.75789,
                    -84.938489
                ],
                [
                    45.768844,
                    -84.713934
                ],
                [
                    45.653829,
                    -84.461995
                ],
                [
                    45.637398,
                    -84.215533
                ],
                [
                    45.494997,
                    -84.09504
                ],
                [
                    45.484043,
                    -83.908824
                ],
                [
                    45.352597,
                    -83.596638
                ],
                [
                    45.358074,
                    -83.4871
                ],
                [
                    45.144473,
                    -83.317314
                ],
                [
                    45.029457,
                    -83.454238
                ],
                [
                    44.88158,
                    -83.322791
                ],
                [
                    44.711795,
                    -83.273499
                ],
                [
                    44.339363,
                    -83.333745
                ],
                [
                    44.246255,
                    -83.536392
                ],
                [
                    44.054562,
                    -83.585684
                ],
                [
                    43.988839,
                    -83.82667
                ],
                [
                    43.758807,
                    -83.958116
                ],
                [
                    43.671176,
                    -83.908824
                ],
                [
                    43.589022,
                    -83.667839
                ],
                [
                    43.714992,
                    -83.481623
                ],
                [
                    43.972408,
                    -83.262545
                ],
                [
                    44.070993,
                    -82.917498
                ],
                [
                    43.994316,
                    -82.747713
                ],
                [
                    43.851915,
                    -82.643651
                ],
                [
                    43.435668,
                    -82.539589
                ],
                [
                    43.227544,
                    -82.523158
                ],
                [
                    42.975605,
                    -82.413619
                ],
                [
                    42.614127,
                    -82.517681
                ],
                [
                    42.559357,
                    -82.681989
                ],
                [
                    42.690804,
                    -82.687466
                ],
                [
                    42.652465,
                    -82.797005
                ],
                [
                    42.351234,
                    -82.922975
                ],
                [
                    42.236218,
                    -83.125621
                ],
                [
                    42.006186,
                    -83.185868
                ],
                [
                    41.814493,
                    -83.437807
                ],
                [
                    41.732339,
                    -83.454238
                ]
            ]
        ],
        [
            [
                [
                    45.730506,
                    -85.508091
                ],
                [
                    45.610013,
                    -85.49166
                ],
                [
                    45.588105,
                    -85.623106
                ],
                [
                    45.75789,
                    -85.568337
                ],
                [
                    45.730506,
                    -85.508091
                ]
            ]
        ],
        [
            [
                [
                    45.095181,
                    -87.589328
                ],
                [
                    45.199243,
                    -87.742682
                ],
                [
                    45.341643,
                    -87.649574
                ],
                [
                    45.363551,
                    -87.885083
                ],
                [
                    45.500474,
                    -87.791975
                ],
                [
                    45.675736,
                    -87.781021
                ],
                [
                    45.796229,
                    -87.989145
                ],
                [
                    45.922199,
                    -88.10416
                ],
                [
                    46.020784,
                    -88.531362
                ],
                [
                    45.987922,
                    -88.662808
                ],
                [
                    46.135799,
                    -89.09001
                ],
                [
                    46.338446,
                    -90.119674
                ],
                [
                    46.508231,
                    -90.229213
                ],
                [
                    46.568478,
                    -90.415429
                ],
                [
                    46.672539,
                    -90.026566
                ],
                [
                    46.793032,
                    -89.851304
                ],
                [
                    46.842325,
                    -89.413149
                ],
                [
                    46.990202,
                    -89.128348
                ],
                [
                    46.995679,
                    -88.996902
                ],
                [
                    47.099741,
                    -88.887363
                ],
                [
                    47.247618,
                    -88.575177
                ],
                [
                    47.373588,
                    -88.416346
                ],
                [
                    47.455742,
                    -88.180837
                ],
                [
                    47.384542,
                    -87.956283
                ],
                [
                    47.077833,
                    -88.350623
                ],
                [
                    46.973771,
                    -88.443731
                ],
                [
                    46.787555,
                    -88.438254
                ],
                [
                    46.929956,
                    -88.246561
                ],
                [
                    46.908048,
                    -87.901513
                ],
                [
                    46.809463,
                    -87.633143
                ],
                [
                    46.535616,
                    -87.392158
                ],
                [
                    46.486323,
                    -87.260711
                ],
                [
                    46.530139,
                    -87.008772
                ],
                [
                    46.469893,
                    -86.948526
                ],
                [
                    46.437031,
                    -86.696587
                ],
                [
                    46.667063,
                    -86.159846
                ],
                [
                    46.68897,
                    -85.880522
                ],
                [
                    46.678016,
                    -85.508091
                ],
                [
                    46.754694,
                    -85.256151
                ],
                [
                    46.760171,
                    -85.064458
                ],
                [
                    46.480847,
                    -85.02612
                ],
                [
                    46.442508,
                    -84.82895
                ],
                [
                    46.486323,
                    -84.63178
                ],
                [
                    46.4206,
                    -84.549626
                ],
                [
                    46.502754,
                    -84.418179
                ],
                [
                    46.530139,
                    -84.127902
                ],
                [
                    46.179615,
                    -84.122425
                ],
                [
                    46.031737,
                    -83.990978
                ],
                [
                    45.993399,
                    -83.793808
                ],
                [
                    46.091984,
                    -83.7719
                ],
                [
                    46.091984,
                    -83.580208
                ],
                [
                    45.987922,
                    -83.476146
                ],
                [
                    45.911245,
                    -83.563777
                ],
                [
                    45.976968,
                    -84.111471
                ],
                [
                    45.933153,
                    -84.374364
                ],
                [
                    46.053645,
                    -84.659165
                ],
                [
                    45.944106,
                    -84.741319
                ],
                [
                    45.850998,
                    -84.70298
                ],
                [
                    45.872906,
                    -84.82895
                ],
                [
                    46.00983,
                    -85.015166
                ],
                [
                    46.091984,
                    -85.338305
                ],
                [
                    46.097461,
                    -85.502614
                ],
                [
                    45.966014,
                    -85.661445
                ],
                [
                    45.933153,
                    -85.924338
                ],
                [
                    45.960537,
                    -86.209139
                ],
                [
                    45.905768,
                    -86.324155
                ],
                [
                    45.796229,
                    -86.351539
                ],
                [
                    45.703121,
                    -86.663725
                ],
                [
                    45.834568,
                    -86.647294
                ],
                [
                    45.861952,
                    -86.784218
                ],
                [
                    45.725029,
                    -86.838987
                ],
                [
                    45.719552,
                    -87.069019
                ],
                [
                    45.659305,
                    -87.17308
                ],
                [
                    45.423797,
                    -87.326435
                ],
                [
                    45.122565,
                    -87.611236
                ],
                [
                    45.095181,
                    -87.589328
                ]
            ]
        ],
        [
            [
                [
                    47.976051,
                    -88.805209
                ],
                [
                    47.850082,
                    -89.057148
                ],
                [
                    47.833651,
                    -89.188594
                ],
                [
                    47.937713,
                    -89.177641
                ],
                [
                    48.173221,
                    -88.547792
                ],
                [
                    48.008913,
                    -88.668285
                ],
                [
                    47.976051,
                    -88.805209
                ]
            ]
        ]
    ],
    "Minnesota": [
        [
            [
                46.705401,
                -92.014696
            ],
            [
                46.749217,
                -92.091373
            ],
            [
                46.667063,
                -92.29402
            ],
            [
                46.075553,
                -92.29402
            ],
            [
                46.015307,
                -92.354266
            ],
            [
                45.933153,
                -92.639067
            ],
            [
                45.719552,
                -92.869098
            ],
            [
                45.577151,
                -92.885529
            ],
            [
                45.566198,
                -92.770513
            ],
            [
                45.440228,
                -92.644544
            ],
            [
                45.286874,
                -92.75956
            ],
            [
                45.117088,
                -92.737652
            ],
            [
                44.750133,
                -92.808852
            ],
            [
                44.569394,
                -92.545959
            ],
            [
                44.552964,
                -92.337835
            ],
            [
                44.443425,
                -92.233773
            ],
            [
                44.333886,
                -91.927065
            ],
            [
                44.202439,
                -91.877772
            ],
            [
                44.032654,
                -91.592971
            ],
            [
                43.994316,
                -91.43414
            ],
            [
                43.775238,
                -91.242447
            ],
            [
                43.616407,
                -91.269832
            ],
            [
                43.501391,
                -91.215062
            ],
            [
                43.501391,
                -91.368417
            ],
            [
                43.501391,
                -96.451017
            ],
            [
                45.297827,
                -96.451017
            ],
            [
                45.412843,
                -96.681049
            ],
            [
                45.604536,
                -96.856311
            ],
            [
                45.818137,
                -96.582464
            ],
            [
                45.933153,
                -96.560556
            ],
            [
                46.332969,
                -96.598895
            ],
            [
                46.437031,
                -96.719387
            ],
            [
                46.656109,
                -96.801542
            ],
            [
                46.924479,
                -96.785111
            ],
            [
                46.968294,
                -96.823449
            ],
            [
                47.609096,
                -96.856311
            ],
            [
                47.948667,
                -97.053481
            ],
            [
                48.140359,
                -97.130158
            ],
            [
                48.545653,
                -97.16302
            ],
            [
                48.682577,
                -97.097296
            ],
            [
                49.000239,
                -97.228743
            ],
            [
                49.000239,
                -95.152983
            ],
            [
                49.383625,
                -95.152983
            ],
            [
                49.372671,
                -94.955813
            ],
            [
                49.295994,
                -94.824366
            ],
            [
                48.775685,
                -94.69292
            ],
            [
                48.715438,
                -94.588858
            ],
            [
                48.699007,
                -94.260241
            ],
            [
                48.649715,
                -94.221903
            ],
            [
                48.627807,
                -93.838517
            ],
            [
                48.518268,
                -93.794701
            ],
            [
                48.545653,
                -93.466085
            ],
            [
                48.589469,
                -93.466085
            ],
            [
                48.644238,
                -93.208669
            ],
            [
                48.62233,
                -92.984114
            ],
            [
                48.540176,
                -92.726698
            ],
            [
                48.436114,
                -92.655498
            ],
            [
                48.447068,
                -92.50762
            ],
            [
                48.222514,
                -92.370697
            ],
            [
                48.315622,
                -92.304974
            ],
            [
                48.359437,
                -92.053034
            ],
            [
                48.266329,
                -92.009219
            ],
            [
                48.200606,
                -91.713464
            ],
            [
                48.112975,
                -91.713464
            ],
            [
                48.041775,
                -91.565587
            ],
            [
                48.080113,
                -91.264355
            ],
            [
                48.178698,
                -91.083616
            ],
            [
                48.238944,
                -90.837154
            ],
            [
                48.091067,
                -90.749522
            ],
            [
                48.123929,
                -90.579737
            ],
            [
                48.091067,
                -90.377091
            ],
            [
                48.112975,
                -90.141582
            ],
            [
                47.987005,
                -89.873212
            ],
            [
                48.008913,
                -89.615796
            ],
            [
                47.954144,
                -89.637704
            ],
            [
                47.828174,
                -89.971797
            ],
            [
                47.729589,
                -90.437337
            ],
            [
                47.625527,
                -90.738569
            ],
            [
                47.368111,
                -91.171247
            ],
            [
                47.20928,
                -91.357463
            ],
            [
                47.028541,
                -91.642264
            ],
            [
                46.787555,
                -92.091373
            ],
            [
                46.705401,
                -92.014696
            ]
        ]
    ],
    "Mississippi": [
        [
            [
                34.995703,
                -88.471115
            ],
            [
                34.995703,
                -88.202745
            ],
            [
                34.891641,
                -88.098683
            ],
            [
                33.796253,
                -88.241084
            ],
            [
                31.895754,
                -88.471115
            ],
            [
                30.367688,
                -88.394438
            ],
            [
                30.323872,
                -88.503977
            ],
            [
                30.34578,
                -88.744962
            ],
            [
                30.411504,
                -88.843547
            ],
            [
                30.367688,
                -89.084533
            ],
            [
                30.252672,
                -89.418626
            ],
            [
                30.181472,
                -89.522688
            ],
            [
                30.285534,
                -89.643181
            ],
            [
                30.449842,
                -89.681519
            ],
            [
                30.66892,
                -89.845827
            ],
            [
                30.997536,
                -89.747242
            ],
            [
                30.997536,
                -91.636787
            ],
            [
                31.068736,
                -91.565587
            ],
            [
                31.265906,
                -91.636787
            ],
            [
                31.27686,
                -91.516294
            ],
            [
                31.643815,
                -91.499863
            ],
            [
                31.621907,
                -91.401278
            ],
            [
                31.846462,
                -91.341032
            ],
            [
                31.988862,
                -91.105524
            ],
            [
                32.218894,
                -90.985031
            ],
            [
                32.514649,
                -91.006939
            ],
            [
                32.640618,
                -91.154816
            ],
            [
                32.843265,
                -91.143862
            ],
            [
                32.887081,
                -91.072662
            ],
            [
                33.002096,
                -91.16577
            ],
            [
                33.13902,
                -91.089093
            ],
            [
                33.347144,
                -91.143862
            ],
            [
                33.429298,
                -91.056231
            ],
            [
                33.560744,
                -91.231493
            ],
            [
                33.867453,
                -91.072662
            ],
            [
                34.026284,
                -90.891923
            ],
            [
                34.135823,
                -90.952169
            ],
            [
                34.300131,
                -90.744046
            ],
            [
                34.365854,
                -90.749522
            ],
            [
                34.420624,
                -90.568783
            ],
            [
                34.617794,
                -90.585214
            ],
            [
                34.661609,
                -90.481152
            ],
            [
                34.831394,
                -90.409952
            ],
            [
                34.908072,
                -90.251121
            ],
            [
                34.995703,
                -90.311367
            ],
            [
                34.995703,
                -88.471115
            ]
        ]
    ],
    "Missouri": [
        [
            [
                40.609566,
                -91.833957
            ],
            [
                40.615043,
                -91.729895
            ],
            [
                40.412397,
                -91.527248
            ],
            [
                40.379535,
                -91.417709
            ],
            [
                40.237135,
                -91.50534
            ],
            [
                40.034488,
                -91.494386
            ],
            [
                39.727779,
                -91.368417
            ],
            [
                39.470363,
                -91.061708
            ],
            [
                39.256762,
                -90.727615
            ],
            [
                38.928146,
                -90.661891
            ],
            [
                38.867899,
                -90.585214
            ],
            [
                38.961007,
                -90.470199
            ],
            [
                38.917192,
                -90.251121
            ],
            [
                38.845992,
                -90.10872
            ],
            [
                38.725499,
                -90.207305
            ],
            [
                38.632391,
                -90.179921
            ],
            [
                38.374975,
                -90.349706
            ],
            [
                38.216144,
                -90.355183
            ],
            [
                38.013497,
                -90.059428
            ],
            [
                37.88205,
                -89.949889
            ],
            [
                37.903958,
                -89.84035
            ],
            [
                37.690357,
                -89.517211
            ],
            [
                37.537003,
                -89.517211
            ],
            [
                37.34531,
                -89.435057
            ],
            [
                37.279587,
                -89.517211
            ],
            [
                36.994786,
                -89.292656
            ],
            [
                36.983832,
                -89.133825
            ],
            [
                36.578538,
                -89.215979
            ],
            [
                36.622354,
                -89.363857
            ],
            [
                36.496384,
                -89.418626
            ],
            [
                36.496384,
                -89.484349
            ],
            [
                36.496384,
                -89.539119
            ],
            [
                36.249922,
                -89.533642
            ],
            [
                35.997983,
                -89.730812
            ],
            [
                35.997983,
                -90.377091
            ],
            [
                36.184199,
                -90.218259
            ],
            [
                36.304691,
                -90.064905
            ],
            [
                36.496384,
                -90.152536
            ],
            [
                36.501861,
                -94.473842
            ],
            [
                36.501861,
                -94.616242
            ],
            [
                37.000263,
                -94.616242
            ],
            [
                39.158177,
                -94.610765
            ],
            [
                39.20747,
                -94.824366
            ],
            [
                39.442978,
                -94.983197
            ],
            [
                39.541563,
                -95.109167
            ],
            [
                39.831841,
                -94.884612
            ],
            [
                39.908518,
                -95.207752
            ],
            [
                40.001626,
                -95.306337
            ],
            [
                40.264519,
                -95.552799
            ],
            [
                40.587659,
                -95.7664
            ],
            [
                40.571228,
                -94.632673
            ],
            [
                40.582182,
                -93.257961
            ],
            [
                40.609566,
                -91.833957
            ]
        ]
    ],
    "Montana": [
        [
            [
                49.000239,
                -104.047534
            ],
            [
                47.861036,
                -104.042057
            ],
            [
                45.944106,
                -104.047534
            ],
            [
                44.996596,
                -104.042057
            ],
            [
                44.996596,
                -104.058488
            ],
            [
                45.002073,
                -105.91517
            ],
            [
                45.002073,
                -109.080842
            ],
            [
                45.002073,
                -111.05254
            ],
            [
                44.476286,
                -111.047063
            ],
            [
                44.580348,
                -111.227803
            ],
            [
                44.75561,
                -111.386634
            ],
            [
                44.547487,
                -111.616665
            ],
            [
                44.509148,
                -111.819312
            ],
            [
                44.563917,
                -111.868605
            ],
            [
                44.520102,
                -112.104113
            ],
            [
                44.569394,
                -112.241036
            ],
            [
                44.481763,
                -112.471068
            ],
            [
                44.48724,
                -112.783254
            ],
            [
                44.394132,
                -112.887315
            ],
            [
                44.448902,
                -113.002331
            ],
            [
                44.772041,
                -113.133778
            ],
            [
                44.782995,
                -113.341901
            ],
            [
                44.865149,
                -113.456917
            ],
            [
                45.056842,
                -113.45144
            ],
            [
                45.128042,
                -113.571933
            ],
            [
                45.330689,
                -113.736241
            ],
            [
                45.522382,
                -113.834826
            ],
            [
                45.604536,
                -113.807441
            ],
            [
                45.703121,
                -113.98818
            ],
            [
                45.593582,
                -114.086765
            ],
            [
                45.456659,
                -114.333228
            ],
            [
                45.560721,
                -114.546828
            ],
            [
                45.670259,
                -114.497536
            ],
            [
                45.774321,
                -114.568736
            ],
            [
                45.88386,
                -114.387997
            ],
            [
                46.037214,
                -114.492059
            ],
            [
                46.272723,
                -114.464674
            ],
            [
                46.645155,
                -114.322274
            ],
            [
                46.639678,
                -114.612552
            ],
            [
                46.705401,
                -114.623506
            ],
            [
                46.809463,
                -114.886399
            ],
            [
                46.919002,
                -114.930214
            ],
            [
                47.187372,
                -115.302646
            ],
            [
                47.258572,
                -115.324554
            ],
            [
                47.302388,
                -115.527201
            ],
            [
                47.42288,
                -115.718894
            ],
            [
                47.696727,
                -115.724371
            ],
            [
                47.976051,
                -116.04751
            ],
            [
                49.000239,
                -116.04751
            ],
            [
                48.994762,
                -111.50165
            ],
            [
                49.000239,
                -109.453274
            ],
            [
                49.000239,
                -104.047534
            ]
        ]
    ],
    "Nebraska": [
        [
            [
                43.002989,
                -103.324578
            ],
            [
                42.997512,
                -101.626726
            ],
            [
                42.997512,
                -98.499393
            ],
            [
                42.94822,
                -98.466531
            ],
            [
                42.767481,
                -97.951699
            ],
            [
                42.866066,
                -97.831206
            ],
            [
                42.844158,
                -97.688806
            ],
            [
                42.844158,
                -97.217789
            ],
            [
                42.657942,
                -96.692003
            ],
            [
                42.515542,
                -96.626279
            ],
            [
                42.488157,
                -96.44554
            ],
            [
                42.039048,
                -96.264801
            ],
            [
                41.973325,
                -96.127878
            ],
            [
                41.798063,
                -96.062155
            ],
            [
                41.67757,
                -96.122401
            ],
            [
                41.540646,
                -96.095016
            ],
            [
                41.453015,
                -95.919754
            ],
            [
                41.201076,
                -95.925231
            ],
            [
                40.976521,
                -95.826646
            ],
            [
                40.719105,
                -95.881416
            ],
            [
                40.587659,
                -95.7664
            ],
            [
                40.264519,
                -95.552799
            ],
            [
                40.001626,
                -95.306337
            ],
            [
                40.001626,
                -101.90605
            ],
            [
                40.001626,
                -102.053927
            ],
            [
                41.003906,
                -102.053927
            ],
            [
                41.003906,
                -104.053011
            ],
            [
                43.002989,
                -104.053011
            ],
            [
                43.002989,
                -103.324578
            ]
        ]
    ],
    "Nevada": [
        [
            [
                42.000709,
                -117.027882
            ],
            [
                41.995232,
                -114.04295
            ],
            [
                37.000263,
                -114.048427
            ],
            [
                36.195153,
                -114.048427
            ],
            [
                36.025367,
                -114.152489
            ],
            [
                36.01989,
                -114.251074
            ],
            [
                36.140383,
                -114.371566
            ],
            [
                36.102045,
                -114.738521
            ],
            [
                35.516012,
                -114.678275
            ],
            [
                35.324319,
                -114.596121
            ],
            [
                35.138103,
                -114.574213
            ],
            [
                35.00118,
                -114.634459
            ],
            [
                35.970598,
                -115.85034
            ],
            [
                36.501861,
                -116.540435
            ],
            [
                37.21934,
                -117.498899
            ],
            [
                38.101128,
                -118.71478
            ],
            [
                38.999346,
                -120.001861
            ],
            [
                40.264519,
                -119.996384
            ],
            [
                41.995232,
                -120.001861
            ],
            [
                41.989755,
                -118.698349
            ],
            [
                42.000709,
                -117.027882
            ]
        ]
    ],
    "New Hampshire": [
        [
            [
                45.303304,
                -71.08183
            ],
            [
                44.657025,
                -71.032537
            ],
            [
                43.34256,
                -70.966814
            ],
            [
                43.227544,
                -70.807983
            ],
            [
                43.128959,
                -70.824413
            ],
            [
                43.057759,
                -70.703921
            ],
            [
                42.871543,
                -70.818936
            ],
            [
                42.887974,
                -70.917521
            ],
            [
                42.789389,
                -71.185891
            ],
            [
                42.696281,
                -71.29543
            ],
            [
                42.729142,
                -72.456542
            ],
            [
                42.80582,
                -72.544173
            ],
            [
                42.953697,
                -72.533219
            ],
            [
                43.008466,
                -72.445588
            ],
            [
                43.150867,
                -72.456542
            ],
            [
                43.572591,
                -72.379864
            ],
            [
                43.769761,
                -72.204602
            ],
            [
                43.994316,
                -72.116971
            ],
            [
                44.07647,
                -72.02934
            ],
            [
                44.322932,
                -72.034817
            ],
            [
                44.41604,
                -71.700724
            ],
            [
                44.585825,
                -71.536416
            ],
            [
                44.750133,
                -71.629524
            ],
            [
                44.914442,
                -71.4926
            ],
            [
                45.013027,
                -71.503554
            ],
            [
                45.270443,
                -71.361154
            ],
            [
                45.243058,
                -71.131122
            ],
            [
                45.303304,
                -71.08183
            ]
        ]
    ],
    "New Jersey": [
        [
            [
                41.14083,
                -74.236547
            ],
            [
                40.998429,
                -73.902454
            ],
            [
                40.708151,
                -74.022947
            ],
            [
                40.642428,
                -74.187255
            ],
            [
                40.489074,
                -74.274886
            ],
            [
                40.412397,
                -74.001039
            ],
            [
                40.297381,
                -73.979131
            ],
            [
                39.760641,
                -74.099624
            ],
            [
                39.360824,
                -74.411809
            ],
            [
                39.245808,
                -74.614456
            ],
            [
                38.993869,
                -74.795195
            ],
            [
                39.158177,
                -74.888303
            ],
            [
                39.240331,
                -75.178581
            ],
            [
                39.459409,
                -75.534582
            ],
            [
                39.607286,
                -75.55649
            ],
            [
                39.629194,
                -75.561967
            ],
            [
                39.683964,
                -75.507197
            ],
            [
                39.804456,
                -75.414089
            ],
            [
                39.88661,
                -75.145719
            ],
            [
                39.963288,
                -75.129289
            ],
            [
                40.127596,
                -74.82258
            ],
            [
                40.215227,
                -74.773287
            ],
            [
                40.417874,
                -75.058088
            ],
            [
                40.543843,
                -75.069042
            ],
            [
                40.576705,
                -75.195012
            ],
            [
                40.691721,
                -75.205966
            ],
            [
                40.866983,
                -75.052611
            ],
            [
                40.971045,
                -75.134765
            ],
            [
                41.179168,
                -74.882826
            ],
            [
                41.288707,
                -74.828057
            ],
            [
                41.359907,
                -74.69661
            ],
            [
                41.14083,
                -74.236547
            ]
        ]
    ],
    "New Mexico": [
        [
            [
                37.000263,
                -107.421329
            ],
            [
                36.994786,
                -106.868158
            ],
            [
                36.994786,
                -104.337812
            ],
            [
                37.000263,
                -103.001438
            ],
            [
                36.501861,
                -103.001438
            ],
            [
                36.501861,
                -103.039777
            ],
            [
                34.01533,
                -103.045254
            ],
            [
                33.002096,
                -103.067161
            ],
            [
                31.999816,
                -103.067161
            ],
            [
                31.999816,
                -106.616219
            ],
            [
                31.901231,
                -106.643603
            ],
            [
                31.786216,
                -106.528588
            ],
            [
                31.786216,
                -108.210008
            ],
            [
                31.331629,
                -108.210008
            ],
            [
                31.331629,
                -109.04798
            ],
            [
                37.000263,
                -109.042503
            ],
            [
                37.000263,
                -107.421329
            ]
        ]
    ],
    "New York": [
        [
            [
                45.013027,
                -73.343806
            ],
            [
                44.804903,
                -73.332852
            ],
            [
                44.618687,
                -73.387622
            ],
            [
                44.437948,
                -73.294514
            ],
            [
                44.246255,
                -73.321898
            ],
            [
                44.043608,
                -73.436914
            ],
            [
                43.769761,
                -73.349283
            ],
            [
                43.687607,
                -73.404052
            ],
            [
                43.523299,
                -73.245221
            ],
            [
                42.833204,
                -73.278083
            ],
            [
                42.745573,
                -73.267129
            ],
            [
                42.08834,
                -73.508114
            ],
            [
                42.050002,
                -73.486206
            ],
            [
                41.294184,
                -73.55193
            ],
            [
                41.21203,
                -73.48073
            ],
            [
                41.102491,
                -73.727192
            ],
            [
                40.987475,
                -73.655992
            ],
            [
                40.905321,
                -73.22879
            ],
            [
                40.965568,
                -73.141159
            ],
            [
                40.965568,
                -72.774204
            ],
            [
                40.998429,
                -72.587988
            ],
            [
                41.157261,
                -72.28128
            ],
            [
                41.042245,
                -72.259372
            ],
            [
                40.992952,
                -72.100541
            ],
            [
                40.845075,
                -72.467496
            ],
            [
                40.625997,
                -73.239744
            ],
            [
                40.582182,
                -73.562884
            ],
            [
                40.593136,
                -73.776484
            ],
            [
                40.543843,
                -73.935316
            ],
            [
                40.708151,
                -74.022947
            ],
            [
                40.998429,
                -73.902454
            ],
            [
                41.14083,
                -74.236547
            ],
            [
                41.359907,
                -74.69661
            ],
            [
                41.431108,
                -74.740426
            ],
            [
                41.436584,
                -74.89378
            ],
            [
                41.60637,
                -75.074519
            ],
            [
                41.754247,
                -75.052611
            ],
            [
                41.869263,
                -75.173104
            ],
            [
                41.863786,
                -75.249781
            ],
            [
                42.000709,
                -75.35932
            ],
            [
                42.000709,
                -79.76278
            ],
            [
                42.252649,
                -79.76278
            ],
            [
                42.269079,
                -79.76278
            ],
            [
                42.55388,
                -79.149363
            ],
            [
                42.690804,
                -79.050778
            ],
            [
                42.783912,
                -78.853608
            ],
            [
                42.953697,
                -78.930285
            ],
            [
                42.986559,
                -79.012439
            ],
            [
                43.260406,
                -79.072686
            ],
            [
                43.375421,
                -78.486653
            ],
            [
                43.369944,
                -77.966344
            ],
            [
                43.34256,
                -77.75822
            ],
            [
                43.233021,
                -77.533665
            ],
            [
                43.276836,
                -77.391265
            ],
            [
                43.271359,
                -76.958587
            ],
            [
                43.34256,
                -76.695693
            ],
            [
                43.523299,
                -76.41637
            ],
            [
                43.528776,
                -76.235631
            ],
            [
                43.802623,
                -76.230154
            ],
            [
                43.961454,
                -76.137046
            ],
            [
                44.070993,
                -76.3616
            ],
            [
                44.196962,
                -76.312308
            ],
            [
                44.366748,
                -75.912491
            ],
            [
                44.514625,
                -75.764614
            ],
            [
                44.848718,
                -75.282643
            ],
            [
                45.018503,
                -74.828057
            ],
            [
                44.991119,
                -74.148916
            ],
            [
                45.013027,
                -73.343806
            ]
        ]
    ],
    "North Carolina": [
        [
            [
                36.562108,
                -80.978661
            ],
            [
                36.545677,
                -80.294043
            ],
            [
                36.5402,
                -79.510841
            ],
            [
                36.551154,
                -75.868676
            ],
            [
                36.151337,
                -75.75366
            ],
            [
                36.189676,
                -76.032984
            ],
            [
                36.140383,
                -76.071322
            ],
            [
                36.080137,
                -76.410893
            ],
            [
                36.025367,
                -76.460185
            ],
            [
                36.008937,
                -76.68474
            ],
            [
                35.937736,
                -76.673786
            ],
            [
                35.987029,
                -76.399939
            ],
            [
                35.943213,
                -76.3616
            ],
            [
                35.992506,
                -76.060368
            ],
            [
                35.899398,
                -75.961783
            ],
            [
                35.937736,
                -75.781044
            ],
            [
                35.696751,
                -75.715321
            ],
            [
                35.581735,
                -75.775568
            ],
            [
                35.570781,
                -75.89606
            ],
            [
                35.324319,
                -76.147999
            ],
            [
                35.313365,
                -76.482093
            ],
            [
                35.14358,
                -76.536862
            ],
            [
                34.973795,
                -76.394462
            ],
            [
                34.940933,
                -76.279446
            ],
            [
                34.661609,
                -76.493047
            ],
            [
                34.694471,
                -76.673786
            ],
            [
                34.667086,
                -76.991448
            ],
            [
                34.60684,
                -77.210526
            ],
            [
                34.415147,
                -77.555573
            ],
            [
                34.163208,
                -77.82942
            ],
            [
                33.845545,
                -77.971821
            ],
            [
                33.916745,
                -78.179944
            ],
            [
                33.851022,
                -78.541422
            ],
            [
                34.80401,
                -79.675149
            ],
            [
                34.820441,
                -80.797922
            ],
            [
                34.935456,
                -80.781491
            ],
            [
                35.105241,
                -80.934845
            ],
            [
                35.044995,
                -81.038907
            ],
            [
                35.149057,
                -81.044384
            ],
            [
                35.198349,
                -82.276696
            ],
            [
                35.160011,
                -82.550543
            ],
            [
                35.066903,
                -82.764143
            ],
            [
                35.00118,
                -83.109191
            ],
            [
                34.984749,
                -83.618546
            ],
            [
                34.990226,
                -84.319594
            ],
            [
                35.225734,
                -84.29221
            ],
            [
                35.247642,
                -84.09504
            ],
            [
                35.41195,
                -84.018363
            ],
            [
                35.559827,
                -83.7719
            ],
            [
                35.565304,
                -83.498053
            ],
            [
                35.718659,
                -83.251591
            ],
            [
                35.773428,
                -82.994175
            ],
            [
                35.997983,
                -82.775097
            ],
            [
                36.063706,
                -82.638174
            ],
            [
                35.965121,
                -82.610789
            ],
            [
                36.156814,
                -82.216449
            ],
            [
                36.118475,
                -82.03571
            ],
            [
                36.304691,
                -81.909741
            ],
            [
                36.353984,
                -81.723525
            ],
            [
                36.589492,
                -81.679709
            ],
            [
                36.562108,
                -80.978661
            ]
        ]
    ],
    "North Dakota": [
        [
            [
                49.000239,
                -97.228743
            ],
            [
                48.682577,
                -97.097296
            ],
            [
                48.545653,
                -97.16302
            ],
            [
                48.140359,
                -97.130158
            ],
            [
                47.948667,
                -97.053481
            ],
            [
                47.609096,
                -96.856311
            ],
            [
                46.968294,
                -96.823449
            ],
            [
                46.924479,
                -96.785111
            ],
            [
                46.656109,
                -96.801542
            ],
            [
                46.437031,
                -96.719387
            ],
            [
                46.332969,
                -96.598895
            ],
            [
                45.933153,
                -96.560556
            ],
            [
                45.944106,
                -104.047534
            ],
            [
                47.861036,
                -104.042057
            ],
            [
                49.000239,
                -104.047534
            ],
            [
                49.000239,
                -97.228743
            ]
        ]
    ],
    "Ohio": [
        [
            [
                41.978802,
                -80.518598
            ],
            [
                40.636951,
                -80.518598
            ],
            [
                40.582182,
                -80.666475
            ],
            [
                40.472643,
                -80.595275
            ],
            [
                40.319289,
                -80.600752
            ],
            [
                40.078303,
                -80.737675
            ],
            [
                39.711348,
                -80.830783
            ],
            [
                39.388209,
                -81.219646
            ],
            [
                39.344393,
                -81.345616
            ],
            [
                39.410117,
                -81.455155
            ],
            [
                39.267716,
                -81.57017
            ],
            [
                39.273193,
                -81.685186
            ],
            [
                39.0815,
                -81.811156
            ],
            [
                38.966484,
                -81.783771
            ],
            [
                38.873376,
                -81.887833
            ],
            [
                39.026731,
                -82.03571
            ],
            [
                38.785745,
                -82.221926
            ],
            [
                38.632391,
                -82.172634
            ],
            [
                38.577622,
                -82.293127
            ],
            [
                38.446175,
                -82.331465
            ],
            [
                38.424267,
                -82.594358
            ],
            [
                38.561191,
                -82.731282
            ],
            [
                38.588575,
                -82.846298
            ],
            [
                38.758361,
                -82.890113
            ],
            [
                38.725499,
                -83.032514
            ],
            [
                38.626914,
                -83.142052
            ],
            [
                38.703591,
                -83.519961
            ],
            [
                38.632391,
                -83.678792
            ],
            [
                38.769315,
                -83.903347
            ],
            [
                38.807653,
                -84.215533
            ],
            [
                38.895284,
                -84.231963
            ],
            [
                39.103408,
                -84.43461
            ],
            [
                39.103408,
                -84.817996
            ],
            [
                40.500028,
                -84.801565
            ],
            [
                41.694001,
                -84.807042
            ],
            [
                41.732339,
                -83.454238
            ],
            [
                41.595416,
                -83.065375
            ],
            [
                41.513262,
                -82.933929
            ],
            [
                41.589939,
                -82.835344
            ],
            [
                41.431108,
                -82.616266
            ],
            [
                41.381815,
                -82.479343
            ],
            [
                41.513262,
                -82.013803
            ],
            [
                41.485877,
                -81.739956
            ],
            [
                41.672093,
                -81.444201
            ],
            [
                41.852832,
                -81.011523
            ],
            [
                41.978802,
                -80.518598
            ],
            [
                41.978802,
                -80.518598
            ]
        ]
    ],
    "Oklahoma": [
        [
            [
                37.000263,
                -100.087706
            ],
            [
                37.000263,
                -94.616242
            ],
            [
                36.501861,
                -94.616242
            ],
            [
                35.395519,
                -94.430026
            ],
            [
                33.637421,
                -94.484796
            ],
            [
                33.74696,
                -94.868182
            ],
            [
                33.861976,
                -94.966767
            ],
            [
                33.960561,
                -95.224183
            ],
            [
                33.87293,
                -95.289906
            ],
            [
                33.878407,
                -95.547322
            ],
            [
                33.933176,
                -95.602092
            ],
            [
                33.834591,
                -95.8376
            ],
            [
                33.889361,
                -95.936185
            ],
            [
                33.840068,
                -96.149786
            ],
            [
                33.686714,
                -96.346956
            ],
            [
                33.774345,
                -96.423633
            ],
            [
                33.845545,
                -96.631756
            ],
            [
                33.845545,
                -96.850834
            ],
            [
                33.960561,
                -96.922034
            ],
            [
                33.736006,
                -97.173974
            ],
            [
                33.861976,
                -97.256128
            ],
            [
                33.823637,
                -97.371143
            ],
            [
                33.905791,
                -97.458774
            ],
            [
                33.982469,
                -97.694283
            ],
            [
                33.851022,
                -97.869545
            ],
            [
                33.987946,
                -97.946222
            ],
            [
                34.004376,
                -98.088623
            ],
            [
                34.113915,
                -98.170777
            ],
            [
                34.157731,
                -98.36247
            ],
            [
                34.064623,
                -98.488439
            ],
            [
                34.146777,
                -98.570593
            ],
            [
                34.135823,
                -98.767763
            ],
            [
                34.223454,
                -98.986841
            ],
            [
                34.2125,
                -99.189488
            ],
            [
                34.404193,
                -99.260688
            ],
            [
                34.415147,
                -99.57835
            ],
            [
                34.382285,
                -99.698843
            ],
            [
                34.573978,
                -99.923398
            ],
            [
                34.563024,
                -100.000075
            ],
            [
                36.501861,
                -100.000075
            ],
            [
                36.501861,
                -101.812942
            ],
            [
                36.501861,
                -103.001438
            ],
            [
                37.000263,
                -103.001438
            ],
            [
                36.994786,
                -102.042974
            ],
            [
                37.000263,
                -100.087706
            ]
        ]
    ],
    "Oregon": [
        [
            [
                46.174138,
                -123.211348
            ],
            [
                46.185092,
                -123.11824
            ],
            [
                46.08103,
                -122.904639
            ],
            [
                45.960537,
                -122.811531
            ],
            [
                45.659305,
                -122.762239
            ],
            [
                45.549767,
                -122.247407
            ],
            [
                45.708598,
                -121.809251
            ],
            [
                45.725029,
                -121.535404
            ],
            [
                45.670259,
                -121.217742
            ],
            [
                45.604536,
                -121.18488
            ],
            [
                45.746937,
                -120.637186
            ],
            [
                45.697644,
                -120.505739
            ],
            [
                45.725029,
                -120.209985
            ],
            [
                45.823614,
                -119.963522
            ],
            [
                45.911245,
                -119.525367
            ],
            [
                45.933153,
                -119.125551
            ],
            [
                45.998876,
                -118.988627
            ],
            [
                45.993399,
                -116.918344
            ],
            [
                45.823614,
                -116.78142
            ],
            [
                45.752413,
                -116.545912
            ],
            [
                45.61549,
                -116.463758
            ],
            [
                45.319735,
                -116.671881
            ],
            [
                45.144473,
                -116.732128
            ],
            [
                45.02398,
                -116.847143
            ],
            [
                44.930872,
                -116.830713
            ],
            [
                44.782995,
                -116.934774
            ],
            [
                44.750133,
                -117.038836
            ],
            [
                44.394132,
                -117.241483
            ],
            [
                44.257209,
                -117.170283
            ],
            [
                44.240778,
                -116.97859
            ],
            [
                44.158624,
                -116.896436
            ],
            [
                43.830007,
                -117.027882
            ],
            [
                42.000709,
                -117.027882
            ],
            [
                41.989755,
                -118.698349
            ],
            [
                41.995232,
                -120.001861
            ],
            [
                41.995232,
                -121.037003
            ],
            [
                42.011663,
                -122.378853
            ],
            [
                42.006186,
                -123.233256
            ],
            [
                42.000709,
                -124.213628
            ],
            [
                42.115725,
                -124.356029
            ],
            [
                42.438865,
                -124.432706
            ],
            [
                42.663419,
                -124.416275
            ],
            [
                42.838681,
                -124.553198
            ],
            [
                43.002989,
                -124.454613
            ],
            [
                43.271359,
                -124.383413
            ],
            [
                43.55616,
                -124.235536
            ],
            [
                43.8081,
                -124.169813
            ],
            [
                44.657025,
                -124.060274
            ],
            [
                44.772041,
                -124.076705
            ],
            [
                45.144473,
                -123.97812
            ],
            [
                45.659305,
                -123.939781
            ],
            [
                45.944106,
                -123.994551
            ],
            [
                46.113892,
                -123.945258
            ],
            [
                46.261769,
                -123.545441
            ],
            [
                46.146753,
                -123.370179
            ],
            [
                46.174138,
                -123.211348
            ]
        ]
    ],
    "Pennsylvania": [
        [
            [
                42.252649,
                -79.76278
            ],
            [
                42.000709,
                -79.76278
            ],
            [
                42.000709,
                -75.35932
            ],
            [
                41.863786,
                -75.249781
            ],
            [
                41.869263,
                -75.173104
            ],
            [
                41.754247,
                -75.052611
            ],
            [
                41.60637,
                -75.074519
            ],
            [
                41.436584,
                -74.89378
            ],
            [
                41.431108,
                -74.740426
            ],
            [
                41.359907,
                -74.69661
            ],
            [
                41.288707,
                -74.828057
            ],
            [
                41.179168,
                -74.882826
            ],
            [
                40.971045,
                -75.134765
            ],
            [
                40.866983,
                -75.052611
            ],
            [
                40.691721,
                -75.205966
            ],
            [
                40.576705,
                -75.195012
            ],
            [
                40.543843,
                -75.069042
            ],
            [
                40.417874,
                -75.058088
            ],
            [
                40.215227,
                -74.773287
            ],
            [
                40.127596,
                -74.82258
            ],
            [
                39.963288,
                -75.129289
            ],
            [
                39.88661,
                -75.145719
            ],
            [
                39.804456,
                -75.414089
            ],
            [
                39.831841,
                -75.616736
            ],
            [
                39.722302,
                -75.786521
            ],
            [
                39.722302,
                -79.477979
            ],
            [
                39.722302,
                -80.518598
            ],
            [
                40.636951,
                -80.518598
            ],
            [
                41.978802,
                -80.518598
            ],
            [
                41.978802,
                -80.518598
            ],
            [
                42.033571,
                -80.332382
            ],
            [
                42.269079,
                -79.76278
            ],
            [
                42.252649,
                -79.76278
            ]
        ]
    ],
    "Rhode Island": [
        [
            [
                [
                    41.67757,
                    -71.196845
                ],
                [
                    41.496831,
                    -71.120168
                ],
                [
                    41.474923,
                    -71.317338
                ],
                [
                    41.67757,
                    -71.196845
                ]
            ]
        ],
        [
            [
                [
                    42.01714,
                    -71.530939
                ],
                [
                    42.01714,
                    -71.383061
                ],
                [
                    41.781632,
                    -71.328292
                ],
                [
                    41.710431,
                    -71.22423
                ],
                [
                    41.726862,
                    -71.344723
                ],
                [
                    41.578985,
                    -71.448785
                ],
                [
                    41.370861,
                    -71.481646
                ],
                [
                    41.321569,
                    -71.859555
                ],
                [
                    41.414677,
                    -71.799309
                ],
                [
                    42.006186,
                    -71.799309
                ],
                [
                    42.01714,
                    -71.530939
                ]
            ]
        ]
    ],
    "South Carolina": [
        [
            [
                35.066903,
                -82.764143
            ],
            [
                35.160011,
                -82.550543
            ],
            [
                35.198349,
                -82.276696
            ],
            [
                35.149057,
                -81.044384
            ],
            [
                35.044995,
                -81.038907
            ],
            [
                35.105241,
                -80.934845
            ],
            [
                34.935456,
                -80.781491
            ],
            [
                34.820441,
                -80.797922
            ],
            [
                34.80401,
                -79.675149
            ],
            [
                33.851022,
                -78.541422
            ],
            [
                33.80173,
                -78.716684
            ],
            [
                33.637421,
                -78.935762
            ],
            [
                33.380005,
                -79.149363
            ],
            [
                33.171881,
                -79.187701
            ],
            [
                33.007573,
                -79.357487
            ],
            [
                33.007573,
                -79.582041
            ],
            [
                32.887081,
                -79.631334
            ],
            [
                32.755634,
                -79.866842
            ],
            [
                32.613234,
                -79.998289
            ],
            [
                32.552987,
                -80.206412
            ],
            [
                32.399633,
                -80.430967
            ],
            [
                32.328433,
                -80.452875
            ],
            [
                32.246279,
                -80.660998
            ],
            [
                32.032678,
                -80.885553
            ],
            [
                32.120309,
                -81.115584
            ],
            [
                32.290094,
                -81.121061
            ],
            [
                32.558464,
                -81.279893
            ],
            [
                32.629664,
                -81.416816
            ],
            [
                32.843265,
                -81.42777
            ],
            [
                33.007573,
                -81.493493
            ],
            [
                33.160928,
                -81.761863
            ],
            [
                33.347144,
                -81.937125
            ],
            [
                33.462159,
                -81.926172
            ],
            [
                33.631944,
                -82.194542
            ],
            [
                33.81816,
                -82.325988
            ],
            [
                33.94413,
                -82.55602
            ],
            [
                34.152254,
                -82.714851
            ],
            [
                34.26727,
                -82.747713
            ],
            [
                34.486347,
                -82.901067
            ],
            [
                34.469916,
                -83.005129
            ],
            [
                34.683517,
                -83.339222
            ],
            [
                34.787579,
                -83.322791
            ],
            [
                35.00118,
                -83.109191
            ],
            [
                35.066903,
                -82.764143
            ]
        ]
    ],
    "South Dakota": [
        [
            [
                45.944106,
                -104.047534
            ],
            [
                45.933153,
                -96.560556
            ],
            [
                45.818137,
                -96.582464
            ],
            [
                45.604536,
                -96.856311
            ],
            [
                45.412843,
                -96.681049
            ],
            [
                45.297827,
                -96.451017
            ],
            [
                43.501391,
                -96.451017
            ],
            [
                43.479483,
                -96.582464
            ],
            [
                43.397329,
                -96.527695
            ],
            [
                43.222067,
                -96.560556
            ],
            [
                43.123482,
                -96.434587
            ],
            [
                43.052282,
                -96.511264
            ],
            [
                42.855112,
                -96.544125
            ],
            [
                42.707235,
                -96.631756
            ],
            [
                42.488157,
                -96.44554
            ],
            [
                42.515542,
                -96.626279
            ],
            [
                42.657942,
                -96.692003
            ],
            [
                42.844158,
                -97.217789
            ],
            [
                42.844158,
                -97.688806
            ],
            [
                42.866066,
                -97.831206
            ],
            [
                42.767481,
                -97.951699
            ],
            [
                42.94822,
                -98.466531
            ],
            [
                42.997512,
                -98.499393
            ],
            [
                42.997512,
                -101.626726
            ],
            [
                43.002989,
                -103.324578
            ],
            [
                43.002989,
                -104.053011
            ],
            [
                44.996596,
                -104.058488
            ],
            [
                44.996596,
                -104.042057
            ],
            [
                45.944106,
                -104.047534
            ]
        ]
    ],
    "Tennessee": [
        [
            [
                36.496384,
                -88.054868
            ],
            [
                36.677123,
                -88.071299
            ],
            [
                36.633308,
                -87.852221
            ],
            [
                36.655216,
                -86.592525
            ],
            [
                36.616877,
                -85.486183
            ],
            [
                36.627831,
                -85.289013
            ],
            [
                36.594969,
                -84.544149
            ],
            [
                36.584015,
                -83.689746
            ],
            [
                36.600446,
                -83.673316
            ],
            [
                36.589492,
                -81.679709
            ],
            [
                36.353984,
                -81.723525
            ],
            [
                36.304691,
                -81.909741
            ],
            [
                36.118475,
                -82.03571
            ],
            [
                36.156814,
                -82.216449
            ],
            [
                35.965121,
                -82.610789
            ],
            [
                36.063706,
                -82.638174
            ],
            [
                35.997983,
                -82.775097
            ],
            [
                35.773428,
                -82.994175
            ],
            [
                35.718659,
                -83.251591
            ],
            [
                35.565304,
                -83.498053
            ],
            [
                35.559827,
                -83.7719
            ],
            [
                35.41195,
                -84.018363
            ],
            [
                35.247642,
                -84.09504
            ],
            [
                35.225734,
                -84.29221
            ],
            [
                34.990226,
                -84.319594
            ],
            [
                34.984749,
                -85.606675
            ],
            [
                35.00118,
                -87.359296
            ],
            [
                34.995703,
                -88.202745
            ],
            [
                34.995703,
                -88.471115
            ],
            [
                34.995703,
                -90.311367
            ],
            [
                35.023087,
                -90.212782
            ],
            [
                35.198349,
                -90.114197
            ],
            [
                35.439335,
                -90.130628
            ],
            [
                35.603643,
                -89.944412
            ],
            [
                35.756997,
                -89.911551
            ],
            [
                35.811767,
                -89.763673
            ],
            [
                35.997983,
                -89.730812
            ],
            [
                36.249922,
                -89.533642
            ],
            [
                36.496384,
                -89.539119
            ],
            [
                36.496384,
                -89.484349
            ],
            [
                36.496384,
                -89.418626
            ],
            [
                36.507338,
                -89.298133
            ],
            [
                36.496384,
                -88.054868
            ]
        ]
    ],
    "Texas": [
        [
            [
                36.501861,
                -101.812942
            ],
            [
                36.501861,
                -100.000075
            ],
            [
                34.563024,
                -100.000075
            ],
            [
                34.573978,
                -99.923398
            ],
            [
                34.382285,
                -99.698843
            ],
            [
                34.415147,
                -99.57835
            ],
            [
                34.404193,
                -99.260688
            ],
            [
                34.2125,
                -99.189488
            ],
            [
                34.223454,
                -98.986841
            ],
            [
                34.135823,
                -98.767763
            ],
            [
                34.146777,
                -98.570593
            ],
            [
                34.064623,
                -98.488439
            ],
            [
                34.157731,
                -98.36247
            ],
            [
                34.113915,
                -98.170777
            ],
            [
                34.004376,
                -98.088623
            ],
            [
                33.987946,
                -97.946222
            ],
            [
                33.851022,
                -97.869545
            ],
            [
                33.982469,
                -97.694283
            ],
            [
                33.905791,
                -97.458774
            ],
            [
                33.823637,
                -97.371143
            ],
            [
                33.861976,
                -97.256128
            ],
            [
                33.736006,
                -97.173974
            ],
            [
                33.960561,
                -96.922034
            ],
            [
                33.845545,
                -96.850834
            ],
            [
                33.845545,
                -96.631756
            ],
            [
                33.774345,
                -96.423633
            ],
            [
                33.686714,
                -96.346956
            ],
            [
                33.840068,
                -96.149786
            ],
            [
                33.889361,
                -95.936185
            ],
            [
                33.834591,
                -95.8376
            ],
            [
                33.933176,
                -95.602092
            ],
            [
                33.878407,
                -95.547322
            ],
            [
                33.87293,
                -95.289906
            ],
            [
                33.960561,
                -95.224183
            ],
            [
                33.861976,
                -94.966767
            ],
            [
                33.74696,
                -94.868182
            ],
            [
                33.637421,
                -94.484796
            ],
            [
                33.544313,
                -94.380734
            ],
            [
                33.593606,
                -94.183564
            ],
            [
                33.54979,
                -94.041164
            ],
            [
                33.018527,
                -94.041164
            ],
            [
                31.994339,
                -94.041164
            ],
            [
                31.775262,
                -93.822086
            ],
            [
                31.556184,
                -93.816609
            ],
            [
                31.15089,
                -93.542762
            ],
            [
                30.93729,
                -93.526331
            ],
            [
                30.679874,
                -93.630393
            ],
            [
                30.575812,
                -93.728978
            ],
            [
                30.438888,
                -93.696116
            ],
            [
                30.334826,
                -93.767317
            ],
            [
                30.143133,
                -93.690639
            ],
            [
                29.787132,
                -93.926148
            ],
            [
                29.688547,
                -93.838517
            ],
            [
                29.68307,
                -94.002825
            ],
            [
                29.546147,
                -94.523134
            ],
            [
                29.622824,
                -94.70935
            ],
            [
                29.787132,
                -94.742212
            ],
            [
                29.672117,
                -94.873659
            ],
            [
                29.699501,
                -94.966767
            ],
            [
                29.557101,
                -95.016059
            ],
            [
                29.496854,
                -94.911997
            ],
            [
                29.310638,
                -94.895566
            ],
            [
                29.113469,
                -95.081782
            ],
            [
                28.867006,
                -95.383014
            ],
            [
                28.604113,
                -95.985477
            ],
            [
                28.647929,
                -96.045724
            ],
            [
                28.582205,
                -96.226463
            ],
            [
                28.642452,
                -96.23194
            ],
            [
                28.598636,
                -96.478402
            ],
            [
                28.724606,
                -96.593418
            ],
            [
                28.697221,
                -96.664618
            ],
            [
                28.439805,
                -96.401725
            ],
            [
                28.357651,
                -96.593418
            ],
            [
                28.406943,
                -96.774157
            ],
            [
                28.226204,
                -96.801542
            ],
            [
                28.039988,
                -97.026096
            ],
            [
                27.694941,
                -97.256128
            ],
            [
                27.333463,
                -97.404005
            ],
            [
                27.360848,
                -97.513544
            ],
            [
                27.229401,
                -97.540929
            ],
            [
                27.262263,
                -97.425913
            ],
            [
                26.99937,
                -97.480682
            ],
            [
                26.988416,
                -97.557359
            ],
            [
                26.840538,
                -97.562836
            ],
            [
                26.758384,
                -97.469728
            ],
            [
                26.457153,
                -97.442344
            ],
            [
                26.353091,
                -97.332805
            ],
            [
                26.161398,
                -97.30542
            ],
            [
                25.991613,
                -97.217789
            ],
            [
                25.887551,
                -97.524498
            ],
            [
                26.018997,
                -97.650467
            ],
            [
                26.06829,
                -97.885976
            ],
            [
                26.057336,
                -98.198161
            ],
            [
                26.221644,
                -98.466531
            ],
            [
                26.238075,
                -98.669178
            ],
            [
                26.369522,
                -98.822533
            ],
            [
                26.413337,
                -99.030656
            ],
            [
                26.539307,
                -99.173057
            ],
            [
                26.840538,
                -99.266165
            ],
            [
                27.021277,
                -99.446904
            ],
            [
                27.174632,
                -99.424996
            ],
            [
                27.33894,
                -99.50715
            ],
            [
                27.48134,
                -99.479765
            ],
            [
                27.640172,
                -99.605735
            ],
            [
                27.656603,
                -99.709797
            ],
            [
                27.799003,
                -99.879582
            ],
            [
                27.979742,
                -99.934351
            ],
            [
                28.14405,
                -100.082229
            ],
            [
                28.280974,
                -100.29583
            ],
            [
                28.582205,
                -100.399891
            ],
            [
                28.66436,
                -100.498476
            ],
            [
                28.905345,
                -100.629923
            ],
            [
                29.102515,
                -100.673738
            ],
            [
                29.244915,
                -100.799708
            ],
            [
                29.370885,
                -101.013309
            ],
            [
                29.458516,
                -101.062601
            ],
            [
                29.535193,
                -101.259771
            ],
            [
                29.754271,
                -101.413125
            ],
            [
                29.803563,
                -101.851281
            ],
            [
                29.792609,
                -102.114174
            ],
            [
                29.869286,
                -102.338728
            ],
            [
                29.765225,
                -102.388021
            ],
            [
                29.732363,
                -102.629006
            ],
            [
                29.524239,
                -102.809745
            ],
            [
                29.190146,
                -102.919284
            ],
            [
                29.184669,
                -102.97953
            ],
            [
                28.987499,
                -103.116454
            ],
            [
                28.982022,
                -103.280762
            ],
            [
                29.135376,
                -103.527224
            ],
            [
                29.381839,
                -104.146119
            ],
            [
                29.513285,
                -104.266611
            ],
            [
                29.639255,
                -104.507597
            ],
            [
                29.924056,
                -104.677382
            ],
            [
                30.181472,
                -104.688336
            ],
            [
                30.389596,
                -104.858121
            ],
            [
                30.570335,
                -104.896459
            ],
            [
                30.685351,
                -105.005998
            ],
            [
                30.855136,
                -105.394861
            ],
            [
                31.085167,
                -105.602985
            ],
            [
                31.167321,
                -105.77277
            ],
            [
                31.364491,
                -105.953509
            ],
            [
                31.468553,
                -106.205448
            ],
            [
                31.731446,
                -106.38071
            ],
            [
                31.786216,
                -106.528588
            ],
            [
                31.901231,
                -106.643603
            ],
            [
                31.999816,
                -106.616219
            ],
            [
                31.999816,
                -103.067161
            ],
            [
                33.002096,
                -103.067161
            ],
            [
                34.01533,
                -103.045254
            ],
            [
                36.501861,
                -103.039777
            ],
            [
                36.501861,
                -103.001438
            ],
            [
                36.501861,
                -101.812942
            ]
        ]
    ],
    "Utah": [
        [
            [
                41.995232,
                -112.164359
            ],
            [
                42.000709,
                -111.047063
            ],
            [
                40.998429,
                -111.047063
            ],
            [
                40.998429,
                -109.04798
            ],
            [
                39.125316,
                -109.053457
            ],
            [
                38.27639,
                -109.058934
            ],
            [
                38.166851,
                -109.042503
            ],
            [
                37.000263,
                -109.042503
            ],
            [
                37.00574,
                -110.499369
            ],
            [
                37.000263,
                -114.048427
            ],
            [
                41.995232,
                -114.04295
            ],
            [
                41.995232,
                -112.164359
            ]
        ]
    ],
    "Vermont": [
        [
            [
                45.013027,
                -71.503554
            ],
            [
                44.914442,
                -71.4926
            ],
            [
                44.750133,
                -71.629524
            ],
            [
                44.585825,
                -71.536416
            ],
            [
                44.41604,
                -71.700724
            ],
            [
                44.322932,
                -72.034817
            ],
            [
                44.07647,
                -72.02934
            ],
            [
                43.994316,
                -72.116971
            ],
            [
                43.769761,
                -72.204602
            ],
            [
                43.572591,
                -72.379864
            ],
            [
                43.150867,
                -72.456542
            ],
            [
                43.008466,
                -72.445588
            ],
            [
                42.953697,
                -72.533219
            ],
            [
                42.80582,
                -72.544173
            ],
            [
                42.729142,
                -72.456542
            ],
            [
                42.745573,
                -73.267129
            ],
            [
                42.833204,
                -73.278083
            ],
            [
                43.523299,
                -73.245221
            ],
            [
                43.687607,
                -73.404052
            ],
            [
                43.769761,
                -73.349283
            ],
            [
                44.043608,
                -73.436914
            ],
            [
                44.246255,
                -73.321898
            ],
            [
                44.437948,
                -73.294514
            ],
            [
                44.618687,
                -73.387622
            ],
            [
                44.804903,
                -73.332852
            ],
            [
                45.013027,
                -73.343806
            ],
            [
                45.002073,
                -72.308664
            ],
            [
                45.013027,
                -71.503554
            ]
        ]
    ],
    "Virginia": [
        [
            [
                [
                    38.013497,
                    -75.397659
                ],
                [
                    38.029928,
                    -75.244304
                ],
                [
                    37.860142,
                    -75.375751
                ],
                [
                    37.799896,
                    -75.512674
                ],
                [
                    37.569865,
                    -75.594828
                ],
                [
                    37.197433,
                    -75.802952
                ],
                [
                    37.120755,
                    -75.972737
                ],
                [
                    37.257679,
                    -76.027507
                ],
                [
                    37.564388,
                    -75.939876
                ],
                [
                    37.95325,
                    -75.671506
                ],
                [
                    38.013497,
                    -75.397659
                ]
            ]
        ],
        [
            [
                [
                    37.95325,
                    -76.016553
                ],
                [
                    37.95325,
                    -75.994645
                ],
                [
                    37.95325,
                    -76.043938
                ],
                [
                    37.95325,
                    -76.016553
                ]
            ]
        ],
        [
            [
                [
                    39.464886,
                    -78.349729
                ],
                [
                    39.130793,
                    -77.82942
                ],
                [
                    39.322485,
                    -77.719881
                ],
                [
                    39.306055,
                    -77.566527
                ],
                [
                    39.223901,
                    -77.456988
                ],
                [
                    39.076023,
                    -77.456988
                ],
                [
                    39.026731,
                    -77.248864
                ],
                [
                    38.933623,
                    -77.117418
                ],
                [
                    38.791222,
                    -77.040741
                ],
                [
                    38.632391,
                    -77.128372
                ],
                [
                    38.588575,
                    -77.248864
                ],
                [
                    38.446175,
                    -77.325542
                ],
                [
                    38.342113,
                    -77.281726
                ],
                [
                    38.374975,
                    -77.013356
                ],
                [
                    38.216144,
                    -76.964064
                ],
                [
                    38.15042,
                    -76.613539
                ],
                [
                    38.024451,
                    -76.514954
                ],
                [
                    37.887527,
                    -76.235631
                ],
                [
                    37.608203,
                    -76.3616
                ],
                [
                    37.389126,
                    -76.246584
                ],
                [
                    37.285064,
                    -76.383508
                ],
                [
                    37.159094,
                    -76.399939
                ],
                [
                    37.082417,
                    -76.273969
                ],
                [
                    36.961924,
                    -76.410893
                ],
                [
                    37.120755,
                    -76.619016
                ],
                [
                    37.065986,
                    -76.668309
                ],
                [
                    36.95097,
                    -76.48757
                ],
                [
                    36.923586,
                    -75.994645
                ],
                [
                    36.551154,
                    -75.868676
                ],
                [
                    36.5402,
                    -79.510841
                ],
                [
                    36.545677,
                    -80.294043
                ],
                [
                    36.562108,
                    -80.978661
                ],
                [
                    36.589492,
                    -81.679709
                ],
                [
                    36.600446,
                    -83.673316
                ],
                [
                    36.742847,
                    -83.136575
                ],
                [
                    36.852385,
                    -83.070852
                ],
                [
                    36.890724,
                    -82.879159
                ],
                [
                    36.978355,
                    -82.868205
                ],
                [
                    37.044078,
                    -82.720328
                ],
                [
                    37.120755,
                    -82.720328
                ],
                [
                    37.268633,
                    -82.353373
                ],
                [
                    37.537003,
                    -81.969987
                ],
                [
                    37.454849,
                    -81.986418
                ],
                [
                    37.285064,
                    -81.849494
                ],
                [
                    37.20291,
                    -81.679709
                ],
                [
                    37.208387,
                    -81.55374
                ],
                [
                    37.339833,
                    -81.362047
                ],
                [
                    37.235771,
                    -81.225123
                ],
                [
                    37.290541,
                    -80.967707
                ],
                [
                    37.482234,
                    -80.513121
                ],
                [
                    37.421987,
                    -80.474782
                ],
                [
                    37.509618,
                    -80.29952
                ],
                [
                    37.690357,
                    -80.294043
                ],
                [
                    37.849189,
                    -80.184505
                ],
                [
                    37.997066,
                    -79.998289
                ],
                [
                    38.177805,
                    -79.921611
                ],
                [
                    38.364021,
                    -79.724442
                ],
                [
                    38.594052,
                    -79.647764
                ],
                [
                    38.457129,
                    -79.477979
                ],
                [
                    38.413313,
                    -79.313671
                ],
                [
                    38.495467,
                    -79.209609
                ],
                [
                    38.851469,
                    -78.996008
                ],
                [
                    38.763838,
                    -78.870039
                ],
                [
                    39.169131,
                    -78.404499
                ],
                [
                    39.464886,
                    -78.349729
                ]
            ]
        ]
    ],
    "Washington": [
        [
            [
                [
                    49.000239,
                    -117.033359
                ],
                [
                    47.762451,
                    -117.044313
                ],
                [
                    46.426077,
                    -117.038836
                ],
                [
                    46.343923,
                    -117.055267
                ],
                [
                    46.168661,
                    -116.92382
                ],
                [
                    45.993399,
                    -116.918344
                ],
                [
                    45.998876,
                    -118.988627
                ],
                [
                    45.933153,
                    -119.125551
                ],
                [
                    45.911245,
                    -119.525367
                ],
                [
                    45.823614,
                    -119.963522
                ],
                [
                    45.725029,
                    -120.209985
                ],
                [
                    45.697644,
                    -120.505739
                ],
                [
                    45.746937,
                    -120.637186
                ],
                [
                    45.604536,
                    -121.18488
                ],
                [
                    45.670259,
                    -121.217742
                ],
                [
                    45.725029,
                    -121.535404
                ],
                [
                    45.708598,
                    -121.809251
                ],
                [
                    45.549767,
                    -122.247407
                ],
                [
                    45.659305,
                    -122.762239
                ],
                [
                    45.960537,
                    -122.811531
                ],
                [
                    46.08103,
                    -122.904639
                ],
                [
                    46.185092,
                    -123.11824
                ],
                [
                    46.174138,
                    -123.211348
                ],
                [
                    46.146753,
                    -123.370179
                ],
                [
                    46.261769,
                    -123.545441
                ],
                [
                    46.300108,
                    -123.72618
                ],
                [
                    46.239861,
                    -123.874058
                ],
                [
                    46.327492,
                    -124.065751
                ],
                [
                    46.464416,
                    -124.027412
                ],
                [
                    46.535616,
                    -123.895966
                ],
                [
                    46.74374,
                    -124.098612
                ],
                [
                    47.285957,
                    -124.235536
                ],
                [
                    47.357157,
                    -124.31769
                ],
                [
                    47.740543,
                    -124.427229
                ],
                [
                    47.88842,
                    -124.624399
                ],
                [
                    48.184175,
                    -124.706553
                ],
                [
                    48.381345,
                    -124.597014
                ],
                [
                    48.288237,
                    -124.394367
                ],
                [
                    48.162267,
                    -123.983597
                ],
                [
                    48.167744,
                    -123.704273
                ],
                [
                    48.118452,
                    -123.424949
                ],
                [
                    48.167744,
                    -123.162056
                ],
                [
                    48.080113,
                    -123.036086
                ],
                [
                    48.08559,
                    -122.800578
                ],
                [
                    47.866512,
                    -122.636269
                ],
                [
                    47.882943,
                    -122.515777
                ],
                [
                    47.587189,
                    -122.493869
                ],
                [
                    47.318818,
                    -122.422669
                ],
                [
                    47.346203,
                    -122.324084
                ],
                [
                    47.576235,
                    -122.422669
                ],
                [
                    47.800789,
                    -122.395284
                ],
                [
                    48.030821,
                    -122.230976
                ],
                [
                    48.123929,
                    -122.362422
                ],
                [
                    48.288237,
                    -122.373376
                ],
                [
                    48.468976,
                    -122.471961
                ],
                [
                    48.600422,
                    -122.422669
                ],
                [
                    48.753777,
                    -122.488392
                ],
                [
                    48.775685,
                    -122.647223
                ],
                [
                    48.8907,
                    -122.795101
                ],
                [
                    49.000239,
                    -122.756762
                ],
                [
                    49.000239,
                    -117.033359
                ]
            ]
        ],
        [
            [
                [
                    48.310145,
                    -122.718423
                ],
                [
                    48.35396,
                    -122.586977
                ],
                [
                    48.151313,
                    -122.608885
                ],
                [
                    48.227991,
                    -122.767716
                ],
                [
                    48.310145,
                    -122.718423
                ]
            ]
        ],
        [
            [
                [
                    48.583992,
                    -123.025132
                ],
                [
                    48.715438,
                    -122.915593
                ],
                [
                    48.556607,
                    -122.767716
                ],
                [
                    48.419683,
                    -122.811531
                ],
                [
                    48.458022,
                    -123.041563
                ],
                [
                    48.583992,
                    -123.025132
                ]
            ]
        ]
    ],
    "West Virginia": [
        [
            [
                40.636951,
                -80.518598
            ],
            [
                39.722302,
                -80.518598
            ],
            [
                39.722302,
                -79.477979
            ],
            [
                39.20747,
                -79.488933
            ],
            [
                39.300578,
                -79.291763
            ],
            [
                39.470363,
                -79.094593
            ],
            [
                39.437501,
                -78.963147
            ],
            [
                39.585379,
                -78.765977
            ],
            [
                39.514178,
                -78.470222
            ],
            [
                39.623717,
                -78.431884
            ],
            [
                39.61824,
                -78.267575
            ],
            [
                39.694917,
                -78.174467
            ],
            [
                39.601809,
                -78.004682
            ],
            [
                39.601809,
                -77.834897
            ],
            [
                39.322485,
                -77.719881
            ],
            [
                39.130793,
                -77.82942
            ],
            [
                39.464886,
                -78.349729
            ],
            [
                39.169131,
                -78.404499
            ],
            [
                38.763838,
                -78.870039
            ],
            [
                38.851469,
                -78.996008
            ],
            [
                38.495467,
                -79.209609
            ],
            [
                38.413313,
                -79.313671
            ],
            [
                38.457129,
                -79.477979
            ],
            [
                38.594052,
                -79.647764
            ],
            [
                38.364021,
                -79.724442
            ],
            [
                38.177805,
                -79.921611
            ],
            [
                37.997066,
                -79.998289
            ],
            [
                37.849189,
                -80.184505
            ],
            [
                37.690357,
                -80.294043
            ],
            [
                37.509618,
                -80.29952
            ],
            [
                37.421987,
                -80.474782
            ],
            [
                37.482234,
                -80.513121
            ],
            [
                37.290541,
                -80.967707
            ],
            [
                37.235771,
                -81.225123
            ],
            [
                37.339833,
                -81.362047
            ],
            [
                37.208387,
                -81.55374
            ],
            [
                37.20291,
                -81.679709
            ],
            [
                37.285064,
                -81.849494
            ],
            [
                37.454849,
                -81.986418
            ],
            [
                37.537003,
                -81.969987
            ],
            [
                37.553434,
                -82.101434
            ],
            [
                37.668449,
                -82.293127
            ],
            [
                37.783465,
                -82.342419
            ],
            [
                37.931343,
                -82.50125
            ],
            [
                38.123036,
                -82.621743
            ],
            [
                38.424267,
                -82.594358
            ],
            [
                38.446175,
                -82.331465
            ],
            [
                38.577622,
                -82.293127
            ],
            [
                38.632391,
                -82.172634
            ],
            [
                38.785745,
                -82.221926
            ],
            [
                39.026731,
                -82.03571
            ],
            [
                38.873376,
                -81.887833
            ],
            [
                38.966484,
                -81.783771
            ],
            [
                39.0815,
                -81.811156
            ],
            [
                39.273193,
                -81.685186
            ],
            [
                39.267716,
                -81.57017
            ],
            [
                39.410117,
                -81.455155
            ],
            [
                39.344393,
                -81.345616
            ],
            [
                39.388209,
                -81.219646
            ],
            [
                39.711348,
                -80.830783
            ],
            [
                40.078303,
                -80.737675
            ],
            [
                40.319289,
                -80.600752
            ],
            [
                40.472643,
                -80.595275
            ],
            [
                40.582182,
                -80.666475
            ],
            [
                40.636951,
                -80.518598
            ]
        ]
    ],
    "Wisconsin": [
        [
            [
                46.568478,
                -90.415429
            ],
            [
                46.508231,
                -90.229213
            ],
            [
                46.338446,
                -90.119674
            ],
            [
                46.135799,
                -89.09001
            ],
            [
                45.987922,
                -88.662808
            ],
            [
                46.020784,
                -88.531362
            ],
            [
                45.922199,
                -88.10416
            ],
            [
                45.796229,
                -87.989145
            ],
            [
                45.675736,
                -87.781021
            ],
            [
                45.500474,
                -87.791975
            ],
            [
                45.363551,
                -87.885083
            ],
            [
                45.341643,
                -87.649574
            ],
            [
                45.199243,
                -87.742682
            ],
            [
                45.095181,
                -87.589328
            ],
            [
                44.974688,
                -87.627666
            ],
            [
                44.95278,
                -87.819359
            ],
            [
                44.722749,
                -87.983668
            ],
            [
                44.563917,
                -88.043914
            ],
            [
                44.536533,
                -87.928898
            ],
            [
                44.640595,
                -87.775544
            ],
            [
                44.837764,
                -87.611236
            ],
            [
                44.914442,
                -87.403112
            ],
            [
                45.166381,
                -87.238804
            ],
            [
                45.22115,
                -87.03068
            ],
            [
                45.089704,
                -87.047111
            ],
            [
                44.969211,
                -87.189511
            ],
            [
                44.552964,
                -87.468835
            ],
            [
                44.322932,
                -87.545512
            ],
            [
                44.158624,
                -87.540035
            ],
            [
                44.103854,
                -87.644097
            ],
            [
                43.8793,
                -87.737205
            ],
            [
                43.687607,
                -87.704344
            ],
            [
                43.561637,
                -87.791975
            ],
            [
                43.249452,
                -87.912467
            ],
            [
                43.002989,
                -87.885083
            ],
            [
                42.783912,
                -87.76459
            ],
            [
                42.493634,
                -87.802929
            ],
            [
                42.493634,
                -88.788778
            ],
            [
                42.510065,
                -90.639984
            ],
            [
                42.636034,
                -90.711184
            ],
            [
                42.75105,
                -91.067185
            ],
            [
                42.909881,
                -91.143862
            ],
            [
                43.134436,
                -91.176724
            ],
            [
                43.254929,
                -91.056231
            ],
            [
                43.353514,
                -91.204109
            ],
            [
                43.501391,
                -91.215062
            ],
            [
                43.616407,
                -91.269832
            ],
            [
                43.775238,
                -91.242447
            ],
            [
                43.994316,
                -91.43414
            ],
            [
                44.032654,
                -91.592971
            ],
            [
                44.202439,
                -91.877772
            ],
            [
                44.333886,
                -91.927065
            ],
            [
                44.443425,
                -92.233773
            ],
            [
                44.552964,
                -92.337835
            ],
            [
                44.569394,
                -92.545959
            ],
            [
                44.750133,
                -92.808852
            ],
            [
                45.117088,
                -92.737652
            ],
            [
                45.286874,
                -92.75956
            ],
            [
                45.440228,
                -92.644544
            ],
            [
                45.566198,
                -92.770513
            ],
            [
                45.577151,
                -92.885529
            ],
            [
                45.719552,
                -92.869098
            ],
            [
                45.933153,
                -92.639067
            ],
            [
                46.015307,
                -92.354266
            ],
            [
                46.075553,
                -92.29402
            ],
            [
                46.667063,
                -92.29402
            ],
            [
                46.749217,
                -92.091373
            ],
            [
                46.705401,
                -92.014696
            ],
            [
                46.694447,
                -91.790141
            ],
            [
                46.864232,
                -91.09457
            ],
            [
                46.95734,
                -90.837154
            ],
            [
                46.88614,
                -90.749522
            ],
            [
                46.754694,
                -90.886446
            ],
            [
                46.584908,
                -90.55783
            ],
            [
                46.568478,
                -90.415429
            ]
        ]
    ],
    "Wyoming": [
        [
            [
                45.002073,
                -109.080842
            ],
            [
                45.002073,
                -105.91517
            ],
            [
                44.996596,
                -104.058488
            ],
            [
                43.002989,
                -104.053011
            ],
            [
                41.003906,
                -104.053011
            ],
            [
                40.998429,
                -105.728954
            ],
            [
                41.003906,
                -107.919731
            ],
            [
                40.998429,
                -109.04798
            ],
            [
                40.998429,
                -111.047063
            ],
            [
                42.000709,
                -111.047063
            ],
            [
                44.476286,
                -111.047063
            ],
            [
                45.002073,
                -111.05254
            ],
            [
                45.002073,
                -109.080842
            ]
        ]
    ],
    "Puerto Rico": [
        [
            [
                17.984326,
                -66.448338
            ],
            [
                18.006234,
                -66.771478
            ],
            [
                17.929556,
                -66.924832
            ],
            [
                17.973372,
                -66.985078
            ],
            [
                17.956941,
                -67.209633
            ],
            [
                18.19245,
                -67.154863
            ],
            [
                18.362235,
                -67.269879
            ],
            [
                18.515589,
                -67.094617
            ],
            [
                18.488204,
                -66.957694
            ],
            [
                18.488204,
                -66.409999
            ],
            [
                18.433435,
                -65.840398
            ],
            [
                18.367712,
                -65.632274
            ],
            [
                18.203403,
                -65.626797
            ],
            [
                18.186973,
                -65.730859
            ],
            [
                18.017187,
                -65.834921
            ],
            [
                17.929556,
                -66.234737
            ],
            [
                17.984326,
                -66.448338
            ]
        ]
    ]
};