import React, { useState } from "react";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import MainNavbar from "utils/Navbars/MainNavbar.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { Device, DeviceContext } from 'utils/Device';
import TooltipButton from "utils/TooltipButton/TooltipButton";

import NewQuotes from "components/MI/NewQuotes";
import NewApps from "components/all/NewApps";
import NewPolicies from "components/all/NewPolicies";
import PremiumsQuoted from "components/PremiumsQuoted";
import PremiumsBound from "components/PremiumsBound";
import CompCollQuotes from "components/CompCollQuotes";
import CompCollPolicies from "components/CompCollPolicies";
import CompCollVehiclesTypeQuotes from "components/CompCollVehiclesTypeQuotes";
import CompCollVehiclesTypePolicies from "components/CompCollVehiclesTypePolicies";
import EnvironmentServiceBanner from "components/EnvironmentServiceBanner";
import { Savings } from "components/Savings";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import { GWPPP_TransCode } from "components/all/GWPPP_TransCode";
import { CallAPI, all } from "services/all/API";
import PreviouslyInsured from "components/PreviouslyInsured";
import CreditScore from "components/CreditScore";
import TotalBanner from "components/TotalBanner";
import BookOfBusinessPercentage from "components/cities/BookOfBusinessPercentage";
import { LandingMessage } from "components/all/LandingMessage";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var nf = new Intl.NumberFormat();
var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        RefreshComments();
    };

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    //When user refresh page or submit new dates, get new data
    const RefreshQuote = async () => {
        const data = await CallAPI(all.GetQuoteData, {
            startDate: startDate, endDate: endDate, states: states, city: city, getQuoteType: true
        });

        if (data) {
            setQuoteData(data.result[0]);
            setQuoteDataByQuoteType(data.quoteTypeResult);
        }
    };
    const RefreshQuotePremium = async () => {
        const data = await CallAPI(all.GetPremiumsQuotedData, {
            startDate: startDate,
            endDate: endDate,
            states: states,
            city: city,
            getQuoteType: true
        });
        if (data?.length > 0) {
            setQuotePremData(data[0]);
        }
    };
    const RefreshPolicy = async () => {
        const data = await CallAPI(all.GetPolicyData, {
            startDate: startDate, endDate: endDate, states: states, city: city
        });
        if (data) {
            setPolicyData(data.result[0]);
            setPolicyPremData(data.result[0]);
            setPolicyDataByPolicyType(data.policyTypeResult);
        }
    };
    //When user refresh page or submit new dates, get new data for YTD
    const RefreshYTD = async () => {
        const data = await CallAPI(all.GetYTDData, {
            startDate: startDate, endDate: endDate, states: states, city: city, getQuoteType: true
        });
        if (data.length !== 0) {
            setytddata(data.result[0]);
        }
    };
    const RefreshBOB = async () => {
        const data = await CallAPI(all.GetBookOfBusinessData, {
            startDate: startDate, endDate: endDate, states: states
        });
        if (data) {
            setbobquotes(data.quotes.result[0]);
            setbobpolicies(data.policies.result[0]);
        }
    };
    const RefreshComments = async () => {
        const data = await CallAPI(all.GetComments, {
            startDate: startDate,
            endDate: endDate,
            states: states,
            city: city,
            page: 'Landing'
        });

        if (data) {
            setComments(data);
            setCall("true");
        }
    };

    //State Variables 
    var date = new Date();
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [states] = React.useState(["MI"]);
    const [city] = React.useState("Detroit");

    const [ytddata, setytddata] = React.useState([]);
    const [bobquotes, setbobquotes] = React.useState([]);
    const [bobpolicies, setbobpolicies] = React.useState([]);
    const [quotedata, setQuoteData] = React.useState([])
    const [quotepremdata, setQuotePremData] = React.useState([]);
    const [quoteDataByQuoteType, setQuoteDataByQuoteType] = React.useState([]);
    const [newAppsFetchCompleted, setNewAppsFetchCompleted] = useState(false)
    const [policydata, setPolicyData] = React.useState([]);
    const [policypremdata, setPolicyPremData] = React.useState([]);
    const [policyDataByPolicyType, setPolicyDataByPolicyType] = React.useState([]);
    const [comments, setComments] = React.useState([]);

    const [percentage] = React.useState("any");

    const [compCollQuotesFetchCompleted, setCompCollQuotesFetchCompleted] = useState(false)
    const [compCollPoliciesFetchCompleted, setCompCollPoliciesFetchCompleted] = useState(false);
    const [compCollVehiclesTypeQuotesFetchCompleted, setCompCollVehiclesTypeQuotesFetchCompleted] = useState(false)
    const [compCollVehiclesTypePoliciesFetchCompleted, setCompCollVehiclesTypePoliciesFetchCompleted] = useState(false)

    const [call, setCall] = React.useState("false");

    const device = Device();
    const isMobile = device.isMobile; //useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    const wrapper = React.createRef();
    const classes = useStyles();
    //When user refresh page or submit new dates, get all the data
    const didMount = React.useRef(false);
    React.useEffect(() => {
        async function func1() {
            console.log("func1")
            //disable scrolling when page is loading
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            if (call == "true" || call == null) {
                setNewAppsFetchCompleted(false)
                setCompCollQuotesFetchCompleted(false)
                setCompCollPoliciesFetchCompleted(false)
                setCompCollVehiclesTypeQuotesFetchCompleted(false)
                setCompCollVehiclesTypePoliciesFetchCompleted(false)

                setQuoteData([]);
                setQuotePremData([]);
                setQuoteDataByQuoteType([]);
                setPolicyData([]);
                setPolicyPremData([]);
                setPolicyDataByPolicyType([]);

                RefreshQuotePremium();
                RefreshQuote();
                RefreshPolicy();
                RefreshYTD();
                RefreshBOB();
            }

        }
        if (didMount.current && call === "true") {
            func1();
        } else {
            didMount.current = true;
        }

    }, [call]);
    React.useEffect(() => {
        async function func2() {
            setCall("false");
            //Enable scrolling when page is finished loading
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";

        }
        if (!isEmpty(quotedata) && !isEmpty(quotepremdata) && !isEmpty(quoteDataByQuoteType) && !isEmpty(ytddata) && !isEmpty(bobquotes) && !isEmpty(bobpolicies) && newAppsFetchCompleted && compCollPoliciesFetchCompleted && compCollQuotesFetchCompleted && compCollVehiclesTypeQuotesFetchCompleted && compCollVehiclesTypePoliciesFetchCompleted && !isEmpty(policydata) && !isEmpty(policypremdata) && !isEmpty(policyDataByPolicyType)) {
            func2()
        }
    }, [quotedata, quotepremdata, quoteDataByQuoteType, newAppsFetchCompleted, policydata, ytddata, bobquotes, bobpolicies, policypremdata, policyDataByPolicyType, compCollQuotesFetchCompleted, compCollPoliciesFetchCompleted, compCollVehiclesTypeQuotesFetchCompleted, compCollVehiclesTypePoliciesFetchCompleted])

    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    return (
        <DeviceContext.Provider value={device}>
            <div>
                <div className={classes.wrapper} ref={wrapper}>
                    <MainNavbar state={states[0]} city={city} onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} startDate={startDate} endDate={endDate} call={call} Text={"Detroit Dashboard"} {...rest} switchTo="pass" />
                    <div style={isMobile ? {} : { paddingRight: "17px" }}>
                        <NewQuotes
                            call={call}
                            startDate={startDate}
                            endDate={endDate}
                            states={states}
                            data={quotedata}
                            ytddata={ytddata}
                        />
                        <NewApps
                            call={call}
                            fetchCompleted={() => setNewAppsFetchCompleted(true)}
                            startDate={startDate}
                            endDate={endDate}
                            states={states}
                            city={city}
                            ytddata={ytddata}
                        />
                        <NewPolicies
                            Total={policydata.total ?? 0}
                            Online={policydata.online ?? 0}
                            Crc={policydata.crc ?? 0}
                            Combo={policydata.combo ?? 0}
                            states={["MI"]}
                            ytddata={ytddata}
                        />

                        <TotalBanner
                            title={<>ACTIVE <b>POLICIES</b> <TooltipButton
                                tooltipText="Active policies as of the End Date in the search."
                            /></>}
                            total={policydata.active}
                        />

                        <LandingMessage />

                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <PremiumsQuoted
                                    PremiumsQuotedTotal={quotepremdata.overallPrem ?? 0}
                                    PremiumsQuotedAvg={quotepremdata.overallAvgPrem ?? 0}
                                    PremiumsQuotedQuickAvg={quotepremdata.quickAvgPrem ?? 0}
                                    PremiumsQuotedCustomAvg={quotepremdata.customAvgPrem ?? 0}
                                    PremiumsQuotedCRCAvg={quotepremdata.crcAvgPrem ?? 0}
                                    PremiumsQuotedQuick={quotepremdata.quickPrem ?? 0}
                                    PremiumsQuotedCustom={quotepremdata.customPrem ?? 0}
                                    PremiumsQuotedCrc={quotepremdata.crcPrem ?? 0}
                                    comments={comments.filter(x => x.table == 'Premiums Quoted')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <PremiumsBound
                                    PremiumsBoundTotal={policydata.overallPrem ?? 0}
                                    PremiumsBoundAvg={policypremdata.overallAvgPrem ?? 0}
                                    PremiumsBoundComboAvg={policypremdata.comboAvgPrem ?? 0}
                                    PremiumsBoundOnlineAvg={policypremdata.onlineAvgPrem ?? 0}
                                    PremiumsBoundCRCAvg={policypremdata.crcAvgPrem ?? 0}
                                    PremiumsBoundCombo={policydata.comboPrem ?? 0}
                                    PremiumsBoundOnline={policydata.onlinePrem ?? 0}
                                    PremiumsBoundCrc={policydata.crcPrem ?? 0}
                                    comments={comments.filter(x => x.table == 'Premiums Bound')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <CreditScore
                                    type={"Quote"}
                                    data={quoteDataByQuoteType}
                                    comments={comments.filter(x => x.table == 'Quotes - Credit Score')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <CreditScore
                                    type={"Policy"}
                                    data={policyDataByPolicyType}
                                    comments={comments.filter(x => x.table == 'Policies - Credit Score')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <PreviouslyInsured
                                    type={"Quote"}
                                    data={quoteDataByQuoteType}
                                    comments={comments.filter(x => x.table == 'Quotes - Previously Insured')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <PreviouslyInsured
                                    type={"Policy"}
                                    data={policyDataByPolicyType}
                                    comments={comments.filter(x => x.table == 'Policies - Previously Insured')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <CompCollQuotes
                                    call={call}
                                    fetchCompleted={() => setCompCollQuotesFetchCompleted(true)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    states={states}
                                    city={city}
                                    comments={comments.filter(x => x.table == 'Quotes By Coverage Type')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <CompCollPolicies
                                    call={call}
                                    fetchCompleted={() => setCompCollPoliciesFetchCompleted(true)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    states={states}
                                    city={city}
                                    comments={comments.filter(x => x.table == 'Policies By Coverage Type')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <CompCollVehiclesTypeQuotes
                                    call={call}
                                    fetchCompleted={() => setCompCollVehiclesTypeQuotesFetchCompleted(true)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    states={states}
                                    city={city}
                                    comments={comments.filter(x => x.table == 'Vehicles By Coverage Type - Quotes')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <CompCollVehiclesTypePolicies
                                    call={call}
                                    fetchCompleted={() => setCompCollVehiclesTypePoliciesFetchCompleted(true)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    states={states}
                                    city={city}
                                    comments={comments.filter(x => x.table == 'Vehicles By Coverage Type - Policies')}
                                />
                            </Grid>
                        </Grid>

                        <BookOfBusinessPercentage
                            title={"% OF MICHIGAN QUOTES AND POLICIES IN DETROIT"}
                            quotes={quotedata.totalQuotes}
                            quotesBOB={bobquotes.totalQuotes}
                            policies={policydata && policydata.total || 0}
                            policiesBOB={bobpolicies && bobpolicies.total || 0}
                        />

                        <GWPPP_TransCode
                            startDate={startDate}
                            endDate={endDate}
                            call={call}
                            states={states}
                            city={city}
                            comments={comments.filter(x => x.table == 'Gross Written Premium' || x.table == 'Posted Premium')}
                        />
                        <Savings
                            startDate={startDate}
                            endDate={endDate}
                            states={states}
                            city={city}
                            Percentage={percentage}
                            call={call}
                        />
                        <EnvironmentServiceBanner call={call} />
                    </div>
                </div>
            </div>
        </DeviceContext.Provider>
    );
}