import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
// @material-ui/core components
import TablePagination from '@mui/material/TablePagination';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function PoliciesDMA(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [data, setData] = React.useState([]);
    const [premiumdata, setPremiumData] = React.useState([]);
    const [avgpremdata, setAvgPremData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const GetData = () => {
        fetch('../GetPolicyDMAData?' + new URLSearchParams({
            startDate: props.startDate,
            endDate: props.endDate,
            groupDateBy: props.groupDateBy
        }))
            .then(res => res.json())
            .then(data => obj = data)
            .then(() => {
                setData(obj.aggPolicyResult);
                setPremiumData(obj.aggPolicyResult);
                setAvgPremData(obj.aggAverageResult);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }
      
    }, [props.call]);

    return (
        <Grid container> {/* Quotes Table */}
            <Grid item xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        minWidth: "500px",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                    BOUND POLICIES
                                </span>
                            </span>
                            
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <PolicyTableView
                                data={data}
                                groupDateBy={props.groupDateBy}
                            />
                        </LoadingOverlay>

                        </Grid>
                    </Grid>
            </Grid>
            <Grid item xs={12}>

                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        padding: "1em",
                                        paddingRight: "1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        minWidth: "500px",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                    TOTAL BOUND PREMIUM
                                </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <PremiumTableView 
                                data={premiumdata}
                                groupDateBy={props.groupDateBy}
                            />
                        </LoadingOverlay>
                                
                        </Grid>
                    </Grid>
            </Grid>
            <Grid item xs={12}>

                <Grid container>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    padding: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.3em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    minWidth: "500px",
                                    width: "100%",
                                    margin: "1em",
                                    padding: "1em",
                                    fontSize: "2.5em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span

                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                AVERAGE BOUND PREMIUM
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <AvgTableView
                                data={avgpremdata}
                                groupDateBy={props.groupDateBy}
                            />
                        </LoadingOverlay>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}


const PolicyTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat();
    //related to the popup
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", width: "120px", minWidth: "120px"  }}>{qprops.groupDateBy}</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Detroit</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Flint</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Grand Rapids</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Lansing</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Traverse City</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Marquette UP</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px", paddingRight: "30px" }}>Other
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    style={{ color: "#25b7d3", maxHeight: "10px", position: "absolute" }}
                                    onClick={handleClick} component="span">
                                    <Help />
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography>"Other" includes the following 5 DMAs: Toledo, South Bend-Elkhart, Alpena, Dultuh-Superior, GreenBay-Appleton</Typography>
                                </Popover>

                            </TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.date}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={row.date == "Total" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countDetroit}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countFlint}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countGrandRapids}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countLansing}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countTraverseCity}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countMarquetteUP}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>{row.countOther}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ExportButton
                ExportName="Bound_Policies"
            />
            </Paper>
        );
}

const PremiumTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
const open = Boolean(anchorEl);
    return (
        <Paper>
        <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight:"400px" }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", width: "120px", minWidth: "120px" }}>{qprops.groupDateBy}</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Detroit</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Flint</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Grand Rapids</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Lansing</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Traverse City</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Marquette UP</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px", paddingRight: "30px" }}>Other
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    style={{ color: "#25b7d3", maxHeight: "10px", position: "absolute" }}
                                    onClick={handleClick} component="span">
                                    <Help />
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography>"Other" includes the following 5 DMAs: Toledo, South Bend-Elkhart, Alpena, Dultuh-Superior, GreenBay-Appleton</Typography>
                                </Popover>
                            </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.date}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={row.date == "Total" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premDetroit)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premFlint)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premGrandRapids)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premLansing)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premTraverseCity)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premMarquetteUP)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.premOther)}</TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
            </TableContainer>
        <TablePagination
            rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
            component="div"
            count={num.length}
            rowsPerPage={rowsPerPage}
            page={page > 0 && num.length < rowsPerPage ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ExportButton
                ExportName="Total_Bound_Premium"
            />
        </Paper>
    );
}

const AvgTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const id = open ? 'simple-popover' : undefined;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", width: "120px", minWidth: "120px"}}>{qprops.groupDateBy}</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Detroit</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Flint</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Grand Rapids</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Lansing</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Traverse City</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Marquette UP</TableCell>
                            <TableCell align="right" style={{ fontSize: "14px", Width: "120px", minWidth: "120px", paddingRight: "30px" }}>Other
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    style={{ color: "#25b7d3", maxHeight: "10px", position: "absolute" }}
                                    onClick={handleClick} component="span">
                                    <Help />
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography>"Other" includes the following 5 DMAs: Toledo, South Bend-Elkhart, Alpena, Dultuh-Superior, GreenBay-Appleton</Typography>
                                </Popover>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.date}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={row.date == "Total" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremDetroit)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremFlint)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremGrandRapids)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremLansing)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremTraverseCity)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremMarquetteUP)}</TableCell>
                                    <TableCell align="right" style={row.date == "Total" ? { fontWeight: "800" } : {}}>${nf.format(row.avgPremOther)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions
                ={["All", 32, 15, 10, 5]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ExportButton
                ExportName="Average_Bound_Premium"
            />
        </Paper>
    );
}