import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarNoSearchAllStates.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Button from "utils/CustomButtons/Button";

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";
import Projections from "components/Projections";
import ReleaseDrawer from "components/ReleaseDrawer";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    const [inforcedata, setInForceData] = React.useState([]);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    //Event Handlers
    const changeCall = () => {
        setCall("true");
    };

    //Events End

    //State Variables 
    var date = new Date();
    // var startdate = date.setDate(date.getDate() - 32);
    //startDate = Date.
    const [call, setCall] = React.useState(false);


    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {
        if (call == "true" || call == null) {

        }

        return () => {
            //End
            setCall("false");
        }
    }, [call]);

    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    //Utility functions End
    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <AuthNavbar onChangeCall={changeCall} {...rest} />
                <Projections
                /> 
            </div>
        </div>
    );
}