import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { DeviceContext } from 'utils/Device/DeviceContext';
import React, { useContext } from "react";

export default function BookOfBusinessPercentage(props) {
    const device = useContext(DeviceContext);
    const percent = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const cellStyle = { fontSize: "1.6em", lineHeight: "1.3em", color: "#ff8000" };
    const headerStyle = { fontSize: "1.3em", color: "#1d4c80", fontWeight: "400" };

    return (
        <div
            style={
                device.isMobile ? {
                    marginTop: "2em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    backgroundColor: "#f1f1f1"
                } : {
                    marginTop: "2em",
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    backgroundColor: "#f1f1f1"
                }
            }
        >
            <Grid container>
                <Grid item
                    xs={12} sm={12} md={12} lg={12}
                    style={{ textAlign: device.isMobile ? "center" : "left" }}
                >
                    <span
                        style={
                            device.isMobile ? {
                                fontSize: "1.8em",
                                color: "#1d4c80",
                                lineHeight: "30px",
                                paddingTop: "1.2em",
                                paddingBottom: "1em",
                                paddingLeft: ".4em",
                                display: "inline-block"
                            } : {
                                fontSize: "1.6em",
                                color: "#1d4c80",
                                lineHeight: "15px",
                                paddingTop: "1.2em",
                                paddingBottom: "1em",
                                paddingLeft: "1em",
                                display: "inline-block"
                            }
                        }
                    >
                        {props.title}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer style={{ position: "relative", marginTop: "1em", margin: "auto", maxWidth: "650px" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={headerStyle}>Quotes</TableCell>
                                    <TableCell style={headerStyle}>Policies</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                <TableRow key={"Percentage"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={cellStyle}>
                                        {percent.format(!props.quotes || props.quotes == 0 ? 0 : props.quotes / props.quotesBOB ?? 0)}
                                    </TableCell>
                                    <TableCell style={cellStyle}>
                                        {percent.format(!props.policies || props.policies == 0 ? 0 : props.policies / props.policiesBOB ?? 0)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};