import React from "react";

export function ViewSize(){
    const useMediaQuery = (query) => {
        const mediaMatch = window.matchMedia(query);
        const [matches, setMatches] = React.useState(mediaMatch.matches);

        React.useEffect(() => {
            const handler = e => setMatches(e.matches);
            mediaMatch.addEventListener("change", handler);
            return () => mediaMatch.removeEventListener("change", handler);
        });
        return matches;
    };

    const xs = useMediaQuery('(max-width:599px)');
    const sm = useMediaQuery('(min-width:600px) and (max-width:1279px)');
    const md = useMediaQuery('(min-width:1280px) and (max-width:1439px)');
    const lg = useMediaQuery('(min-width:1440px) and (max-width:1576px)');
    const xl = useMediaQuery('(min-width:1577px)');

    let size = "";
    if (xs) { size = "XS"}
    if (sm) { size = "SM"}
    if (md) { size = "MD"}
    if (lg) { size = "LG"}
    if (xl) { size = "XL"}

    return size;
}