import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from 'react-loading';
import LeafletMap from "./LeafletMap";
import { statesData } from "variables/statesData";
import { CallAPI } from "services/all/API";

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        height: "auto",
        width: "auto",
        margin: "1.5em",
        padding: (props) => props.isMobile ? "0" : "1em",
        backgroundColor: "#f1f1f1",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
    },
}));

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function MapAutoAppraisal(props) {
    const mobileWidth = useMediaQuery('(max-width: 500px)');
    const classes = useStyles({ isMobile: mobileWidth });
    const isMobile = useMediaQuery('(max-width: 1152px)');

    const [loading, setLoading] = useState(true);
    const [markers, setMarkers] = useState([]);
    const [heat, setHeat] = useState([]);

    useEffect(() => {
        if (props.submitted == true) {
            setLoading(true);
            fetchData();
        }
    }, [props.submitted]);

    const fetchData = async () => {
        const result = await CallAPI({
            method: "POST",
            url: `/GetPolicyData/GetAutoAppraisalData`,
        }, props.filters);

        if (result) {
            setMarkers(result.map((val, i) => {
                return {
                    info: <div>
                        ZipCode: {val.zipcode} <br />
                        Claim Count: {val.claimCount} <br />
                    </div>,
                    pos: [val.latitude, val.longitude]
                };
            }));
            setHeat(result.map((val, i) => {
                return [val.latitude, val.longitude, val.ClaimCount];
            }));

            props.onMapLoaded();
            setLoading(false);
        }
    };

  return (
    <div className={classes.mapContainer}>
        {loading ? (
            <div className={classes.loadingContainer}>
                <ReactLoading type={'spin'} color={'#1d4c80'} height={70} width={70} />
            </div>
        ) : (
            <LeafletMap
                position={{ lat: 42.3314, lng: -83.0458, zoom: 7 }}
                polygons={[
                    //{ pathOptions: { fillColor: 'blue' }, positions: statesData["New Jersey"] },
                    //{ pathOptions: { fillColor: 'blue' }, positions: statesData["Pennsylvania"] },
                    { pathOptions: { fillColor: 'blue' }, positions: statesData["Michigan"] },
                ]}
                markers={markers}
                heat={heat}
                isMobile={isMobile}
                state="MI"
                mapStyle={{ height: mobileWidth ? '60vh' : '70vh' }}
                goTopID="top"
                goTopShow={mobileWidth}
            />
        )}
    </div>
  );
}