import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarAdvance.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Hidden from "@material-ui/core/Hidden";
import { FormControl, Grid } from "@material-ui/core";

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import QuotesDMA from "components/QuotesDMA";
import PoliciesDMA from "components/PoliciesDMA";

import dmaState from "assets/img/Michigan_DMAImage.png";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    //Event Handlers
    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        setCall("true");
    };
    const changeGroupDateBy = (selected) => {
        setGroupDateBy(selected);
    };
    //Events End

    //State Variables 
    var date = new Date();
   // var startdate = date.setDate(date.getDate() - 32);
   //startDate = Date.
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [groupDateBy, setGroupDateBy] = React.useState("Day");
    const [call, setCall] = React.useState(false);


    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {
        if (call == "true" || call == null) {
            //setQuoteTotalPip(null);
            //Refresh();
        }
        

        return () => {
            //End
            setCall("false");

        }
    }, [call]);


    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    //Utility functions End
    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <AuthNavbar onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} onChangeGroupDateBy={changeGroupDateBy} startDate={startDate} endDate={endDate} groupDateBy={groupDateBy} state="MI"Text={"Michigan Dashboard"} {...rest} />
                <div>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Hidden smDown>
                                <h1 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)" }} >MICHIGAN DESIGNATED MARKETING AREAS</h1>
                            </Hidden>
                            <Hidden mdUp>
                                <h3 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)" }} >MICHIGAN DESIGNATED MARKETING AREAS</h3>
                            </Hidden>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={12} lg={3} ></Grid>
                        </Hidden>
                        {/*<Grid item xs={12} sm={4} md={5} lg={2}>*/}
                        {/*    <Hidden mdDown>*/}
                        {/*        <DMASetTableView mtop={"450px"} />*/}
                        {/*    </Hidden>*/}
                        {/*    <Hidden smDown lgUp>*/}
                        {/*        <DMASetTableView mtop={"320px"} />*/}
                        {/*    </Hidden>*/}
                        {/*    <Hidden xsDown mdUp>*/}
                        {/*        <DMASetTableView mtop={"220px"} />*/}
                        {/*    </Hidden>*/}
                        {/*    <Hidden smUp>*/}
                        {/*        <DMASetTableView mtop={"0px"} right={"auto"} />*/}
                        {/*    </Hidden>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={12} md={11} lg={5} style={{ textAlign:"center" }}>
                            <Hidden mdDown>
                               <img src={dmaState} style={{ width: "70%" }} alt="...." />
                            </Hidden>
                            <Hidden smDown lgUp>
                                <img src={dmaState} style={{ width: "40%" }} alt="...." />
                            </Hidden>
                            <Hidden xsDown mdUp>
                                <img src={dmaState} alt="...." />
                            </Hidden>
                            <Hidden smUp>
                                <img src={dmaState} style={{ width: "90%" }} alt="...." />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12}>
                            <QuotesDMA
                                startDate={startDate}
                                endDate={endDate}
                                call={call}
                                groupDateBy={groupDateBy}
                            />
                        </Grid>
                        <PoliciesDMA
                            startDate={startDate}
                            endDate={endDate}
                            call={call}
                            groupDateBy={groupDateBy}
                        />
                    </Grid>
                </div>
            </div>
        </div>
    );
}

const DMASetTableView = (prop) => {
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    const [top, setTop] = React.useState(prop.mtop);
    const [right, setRight] = React.useState(prop.right);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(dma, households) {
        return {dma,households };
    }

    const rows = [
        createData('Detroit', "1,862,620"),
        createData('Grand Rapids', "653,100"),
        createData('Flint', "385,280"),
        createData('Lansing', "241,940"),
        createData('Traverse City', "233,640"),
        createData('Marquette/UP', "89,600"),
    ];

    return (
        <TableContainer component={Paper} style={{ width: 200, marginTop: top, marginRight:right, marginLeft:"auto" }}>
            <Table style={{ width: 200, marginRight: "0px", marginLeft: "auto" }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left" style={{ padding: "5px" }}>DMA</StyledTableCell>
                        <StyledTableCell align="center" style={{ padding: "5px" }}>Households</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.dma}>
                            <StyledTableCell component="th" scope="row" style={{ padding: "5px" }}>
                                {row.dma}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ padding: "5px" }}>{row.households}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}