import React from "react";
import TableWithExportButtonAdvanced from "utils/Table/TableWithExportButtonAdvanced";
import { CallAPI, all } from "services/all/API";
import TooltipButton from "utils/TooltipButton/TooltipButton";

export default function VehiclesByCoverageTypeAdvanced(props) {
    const isQuote = props.type === "Quotes";

    // state variables
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    
    // format numbers, currencies, decimals, and dates
    const num = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const cur = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const date = new Intl.DateTimeFormat('en-US', {
        year: "numeric",
        month: props.groupDateBy == "Day" ? "2-digit" : props.groupDateBy == "Month" ? "long" : undefined,
        day: props.groupDateBy == "Day" ? "2-digit" : undefined
    });

    // functions
    const GetData = async () => {
        const response = await CallAPI(isQuote ? all.VehiclesByCoverageTypeQuotes : all.VehiclesByCoverageTypePolicies, {
            startDate: props.startDate,
            endDate: props.endDate,
            states: props.states,
            city: props.city,
            groupDateBy: props.groupDateBy
        });
        const result = response?.result ?? [];

        let rows = [];
        if (result.length > 0) {
            for (let i = 0, l = result.length / 3; i < l; i++) {
                let group = result.filter(x => x.groupBy === result[i * 3].groupBy);

                if (isQuote) {
                    rows.push([
                        group[0].groupBy == null ? "Total" : date.format(Date.parse(group[0].groupBy)),
                        num.format(group[0].vehicles), num.format(group[0].vehiclesPerc) + "%", cur.format(group[0].avgPrem), num.format(group[0].bindPerc) + "%",
                        num.format(group[1].vehicles), num.format(group[1].vehiclesPerc) + "%", cur.format(group[1].avgPrem), num.format(group[1].bindPerc) + "%",
                        num.format(group[2].vehicles), num.format(group[2].vehiclesPerc) + "%", cur.format(group[2].avgPrem), num.format(group[2].bindPerc) + "%"
                    ]);
                } else {
                    rows.push([
                        group[0].groupBy == null ? "Total" : date.format(Date.parse(group[0].groupBy)),
                        num.format(group[0].vehicles), num.format(group[0].vehiclesPerc) + "%", cur.format(group[0].avgPrem),
                        num.format(group[1].vehicles), num.format(group[1].vehiclesPerc) + "%", cur.format(group[1].avgPrem),
                        num.format(group[2].vehicles), num.format(group[2].vehiclesPerc) + "%", cur.format(group[2].avgPrem)
                    ]);
                }
            }
        }

        setData(rows);
        setLoading(false);
    };

    React.useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }
    }, [props.call]);

    return (
        <div>
            <TableWithExportButtonAdvanced
                title={"VEHICLES BY COVERAGE TYPE - " + props.type.toUpperCase()}
                fixedColumns={1}
                loading={loading}
                style={{ Table: { TableSX: { tableLayout: "auto" }, Header: { minWidth: isQuote ? "120px" : "160px", textAlign: "center" } } }}
                headers={[
                    [
                        { text: props.groupDateBy, rowspan: 2 },
                        {
                            text: (<h5 style={{ margin: 0 }}><b>Liability Only</b><TooltipButton tooltipText={"Quotes in which comp/coll not present on any vehicles"} /></h5>),
                            colspan: isQuote ? 4 : 3, style: { width: "auto" }
                        },
                        {
                            text: (<h5 style={{ margin: 0 }}><b>Coll/Comp</b><TooltipButton tooltipText={"Quotes where at least one vehicle has comp/coll"} /></h5>),
                            colspan: isQuote ? 4 : 3, style: { borderLeft: "solid thin", width: "auto" }
                        },
                        {
                            text: (<h5 style={{ margin: 0 }}><b>Comp Only</b><TooltipButton tooltipText={"Quotes where at least one vehicle has comp only, if one vehicle has comp/coll and the other has liability only, it'll be under comp/coll"} /></h5>),
                            colspan: isQuote ? 4 : 3, style: { borderLeft: "solid thin", width: "auto" }
                        },
                    ],
                    isQuote ? [
                        "Vehicles", "%", "Avg Prem", "Bind %",
                        "Vehicles", "%", "Avg Prem", "Bind %",
                        "Vehicles", "%", "Avg Prem", "Bind %"
                    ] : [
                        "Vehicles", "%", "Avg Prem",
                        "Vehicles", "%", "Avg Prem",
                        "Vehicles", "%", "Avg Prem"
                    ]
                ]}
                rows={data.filter(x => x[0] !== "Total")}
                footers={data.filter(x => x[0] === "Total")}
            />
        </div>
    );
};