import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import separator from "assets/img/Separator.png";
import { BannerBox } from "utils/DynamicComponents/LandingComponents";
import { Dynamic_Style } from "./Dynamic_Style";
import { BannerTitle } from "utils/DynamicComponents/LandingComponents";
const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function NewQuotes(props) {
    const didMount = React.useRef(false);

    const isMobile = useMediaQuery('(max-width: 1152px)');

    useEffect(() => {
        console.log(props.OnlineBP)
    },[props])

    return (
        <div>
            <Grid container spacing={0} style={{marginTop: "2em",marginLeft: "1em",marginRight: "1em"}}>
                <BannerTitle
                    Header={"QUOTES"}
                    SubHeader={"(BIND %)"}
                    SubHeaderPopover={"Percentage of policies bound to-date, based on the specific quotes from each applicable time period."}
                />
                <Grid container
                    lg={4}
                    xs={11}
                    style={{...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        flexWrap:"nowrap", overflow:"auto"}}
                >
                    <BannerBox Dotted={true} ColumnSize={3} Heading={"TOTAL"} Data={props.data.totalQuotes} Footer={props.data.bindPercentage}/>
                    <BannerBox ColumnSize={3} Heading={"Online"} Data={props.data.onlineQuotes} Footer={props.data.bindOnlinePercentage}/>
                    <BannerBox ColumnSize={3} Heading={"CRC"} Data={props.data.crcQuotes} Footer={props.data.bindCRCPercentage}/>
                    {props.HasAgency && <BannerBox ColumnSize={3} Heading={"Agency"} Data={props.data.agencyQuotes} Footer={props.data.bindAgencyPercentage}/>}
                </Grid>
                <Grid container
                    lg={4}
                    xs={11}
                    style={{...isMobile ? Dynamic_Style.BlueContainer_Mobile : Dynamic_Style.BlueContainer_Desktop,
                        flexWrap:"nowrap", overflow:"auto"}}
                >
                     <img src={separator} width="100%" style={isMobile ? Dynamic_Style.SeparatorWithSubHeader_Mobile : Dynamic_Style.SeparatorWithSubHeader_Desktop} alt="..." />
                     <span style={isMobile ? Dynamic_Style.YTDLabelWithSubHeader_Mobile : Dynamic_Style.YTDLabelWithSubHeader_Desktop}>
                         YTD
                     </span>
                    <BannerBox Dotted={true} ColumnSize={3} Heading={"Total"} Data={props.ytddata.quotetotalytd}/>
                    <BannerBox ColumnSize={3} Heading={"Online"} Data={props.ytddata.quoteonlineytd}/>
                    <BannerBox ColumnSize={3} Heading={"CRC"} Data={props.ytddata.quotecrcytd}/>
                    {props.HasAgency && <BannerBox ColumnSize={3} Heading={"Agency"} Data={props.ytddata.quoteagencyytd}/>}
                </Grid>                        
            </Grid>
        </div>
    );
}
