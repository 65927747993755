import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
// @material-ui/core components
import TablePagination from '@mui/material/TablePagination';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";
import { GetAppData } from "services/all/API";
import { formatDate } from "services/Options";


const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function Application_Advanced(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const GetData = async () => {
        const data = await GetAppData(props.startDate, props.endDate,props.states,props.groupDateBy)
        if (data.result.length !== 0) {
            setData(data.result);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }
      
    }, [props.call]);

    return (
        <Grid container>
            <Grid item lg={3} xs={false} />
            <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        minWidth: "500px",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                    APPLICATIONS
                                </span>
                            </span>
                            
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <AppTableView
                                data={data}
                                groupDateBy={props.groupDateBy}
                                showAgency={props.states.length > 1 || !props.states.includes("PA")}
                        />
                        </LoadingOverlay>                        
                        <ExportButton
                            ExportName="Applications"
                        />
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    );
}


const AppTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat();
    //related to the popup
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
            <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", width: "120px", minWidth: "120px" }}>{qprops.groupDateBy}</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", width: "120px", minWidth: "120px" }}>Total</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", width: "120px", minWidth: "120px" }}>Online</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", width: "120px", minWidth: "120px" }}>CRC</TableCell>
                            {qprops.showAgency && <TableCell align="left" style={{ fontSize: "14px", width: "120px", minWidth: "120px" }}>Agency</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                            <TableRow
                                key={row.groupBy}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" style={row.groupBy == "Total" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                    {row.groupBy !== "Total" ? formatDate(row.groupBy) : row.groupBy }
                                </TableCell>
                                <TableCell align="left" style={row.groupBy == "Total" ? { fontWeight: "800" } : {}}>{nf.format(row.total.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.groupBy == "Total" ? { fontWeight: "800" } : {}}>{nf.format(row.online.toFixed(2))}</TableCell>
                                <TableCell align="left" style={row.groupBy == "Total" ? { fontWeight: "800" } : {}}>{nf.format(row.crc.toFixed(2))}</TableCell>
                                {qprops.showAgency && <TableCell align="left" style={row.groupBy == "Total" ? { fontWeight: "800" } : {}}>{nf.format(row.agency.toFixed(2))}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Paper>
        );
}
