import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { InputBase, MenuItem, FormControl, ListItemText, Select, Checkbox, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// @material-ui/core components
import RegularButton from 'utils/CustomButtons/Button';
import { MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet';
import { statesData } from 'variables/statesData';
import L, { LeafletMouseEvent, Map, Point } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CustomDropdown from 'utils/CustomDropdown/CustomDropdown';
import CustomInput from 'utils/CustomInput/CustomInput';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3/lib';
import MarkerClusterGroup from 'react-leaflet-cluster';

function DisplayPosition({ map, center, zoom, setPos, gradient, heatMinMax, x }) {
    const [position, setPosition] = useState(map.getCenter());

    const addLegend = () => {
        var legend = L.control({ position: 'bottomright' });
        var heatPercent = Object.keys(gradient);
        heatPercent.push('0');
        heatPercent = heatPercent.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        });
        function getColor(d) {
            for (var i = heatPercent.length - 1; i > 0; i--) {
                if (d >= heatPercent[i] * heatMinMax.max) {
                    return gradient[heatPercent[i]];
                }
            }
        }
        legend.onAdd = function (map) {
            var prevLegend = document.getElementById('legend');
            if (prevLegend) {
                prevLegend.remove();
            }
            var div = L.DomUtil.create('div', 'info legend');
            div.id = 'legend';
            var grades = heatPercent.map((val) => Math.round(val * heatMinMax.max));

            // add style to legend
            div.setAttribute('style', 'line-height: 18px;color: #000;background-color: #ffffffdb;padding: 10px;border-radius: 10px;');
            // loop through our density intervals and generate a label with a colored square for each interval
            for (var i = 0; i < grades.length - 1; i++) {
                div.innerHTML +=
                    '<i style="width: 18px; height: 18px; float: left; margin-right: 8px; opacity: 0.7;background:' +
                    getColor(grades[i + 1] + 1) +
                    '"></i> ' +
                    grades[i] +
                    (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
            }
            return div;
        };
        legend.addTo(map);
    };

    const onClick = useCallback(
        (e) => {
            // map.on("click", (e) => console.log(e))
            map.setView(center, zoom);
        },
        [map]
    );

    const onMove = useCallback(
        (e) => {
            var center = map.getCenter();
            var zoom = map.getZoom();
            setPos({
                lat: center.lat.toFixed(4),
                lng: center.lng.toFixed(4),
                zoom,
            });
            setPosition(map.getCenter());
        },
        [map]
    );

    const onZoom = useCallback(
        (e) => {
            addLegend();
        },
        [map]
    );

    useEffect(() => {
        addLegend();
        // console.log("h", heatMinMax)
    }, [heatMinMax]);

    useEffect(() => {
        onClick();
        map.on('move', onMove);
        map.on('zoom', onZoom);
        addLegend();
    }, [map, onMove]);

    return (
        <p>
            <RegularButton onClick={onClick} style={{ color: 'white', backgroundColor: '#ff8000' }} color='primary' size='md'>
                RESET
            </RegularButton>
        </p>
    );
}

export default function LeafletMap(
    {
        position,
        polygons,
        mapStyle,
        markers,
        heat,
        isMobile,
        boolHeat = true,
        boolMark = true,
        boolPoly = true,
        state = "MI",
        gradient = {
            0.2: 'cyan',
            0.4: 'lime',
            0.6: 'yellow',
            0.8: 'orange',
            1.0: 'red',
        },
        ...props }) {
    const [map, setMap] = useState(null);
    const [pos, setPos] = useState(position);
    const [polyBool, setPolyBool] = useState(boolPoly);
    const [markBool, setMarkBool] = useState(boolMark);
    const [heatBool, setHeatBool] = useState(boolHeat);
    const [heatMinMax, setHeatMinMax] = useState({ min: 0, max: 10 });
    const heatRef = useRef(heat);

    useEffect(() => {
        heatRef.current = heat;
    }, [heat]);

    const center = [position.lat, position.lng];
    const zoom = position.zoom;

    const onStatsUpdate = (m) => {
        let max = m.max > heatRef.current.length ? heatRef.current.length : m.max > 0 ? m.max : 10;
        let min = m.min > max ? 0 : m.min;
        setHeatMinMax({ max, min });
    };

    const displayMap = useMemo(
        () => (
            <MapContainer
                style={{ height: '600px', width: '100%', alignSelf: 'center', ...mapStyle }}
                center={center}
                zoom={zoom}
                scrollWheelZoom={true}
                zoomDelta={.5}
                zoomSnap={.5}
                ref={setMap}
            >
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                {polyBool &&
                    polygons.map((val, i) => {
                        return <Polygon key={i} {...val}></Polygon>;
                    })}

                {heatBool && (
                    <HeatmapLayer
                        fitBoundsOnLoad
                        fitBoundsOnUpdate
                        minOpacity={.03}
                        points={heat}
                        radius={(m) => m[2]}
                        gradient={gradient}
                        onStatsUpdate={onStatsUpdate}
                        latitudeExtractor={(m) => m[0]}
                        longitudeExtractor={(m) => m[1]}
                        intensityExtractor={(m) => m[2]}
                    />
                )}

                {markBool && (
                    <MarkerClusterGroup showCoverageOnHover={true} spiderfyOnMaxZoom={true} chunkedLoading>
                        {markers.map((val, i) => {
                            return (
                                <Marker key={i} position={val.pos}>
                                    <Popup>{val.info}</Popup>
                                </Marker>
                            );
                        })}
                    </MarkerClusterGroup>
                )}
            </MapContainer>
        ),
        [polyBool, heatBool, markBool, polygons, heat, polygons, map]
    );

    return (
        <>
            {displayMap}
            <TableContainer component={Paper} style={{ position: 'relative' }}>
                <Table sx={{ minWidth: 550 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {map ? <DisplayPosition map={map} center={center} zoom={zoom} setPos={setPos} gradient={gradient} heatMinMax={heatMinMax} /> : null}
                            </TableCell>
                            <TableCell align='right'>Categories</TableCell>
                            <TableCell align='right'>Latitude</TableCell>
                            <TableCell align='right'>Longitude</TableCell>
                            <TableCell align='right'>Zoom</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component='th' scope='row'>
                                <strong>State:</strong> {state}
                            </TableCell>
                            <TableCell align='right'>
                                <FormControl sx={{ width: 120 }} hiddenLabel>
                                    <Select
                                        id="layers" multiple displayEmpty
                                        value={[]} onChange={(event, option) => {
                                            var checked = !option.props.children[0].props.checked;
                                            switch (option.props.value) {
                                                case "Outline": setPolyBool(checked); break;
                                                case "Heatmap": setHeatBool(checked); break;
                                                case "Markers": setMarkBool(checked); break;
                                            }
                                        }} renderValue={(selected) => "LAYERS"}
                                        input={<InputBase />}
                                        SelectDisplayProps={{ style: { background: '#ff8000', fontSize: '12px', color: 'white', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', boxShadow: '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)' } }}
                                        MenuProps={{
                                            anchorOrigin: { vertical: "top" },
                                            transformOrigin: { vertical: "bottom" },
                                            PaperProps: {
                                                style: { maxHeight: 450, width: 120 },
                                            },
                                            MenuListProps: {
                                                style: { padding: 0 },
                                                autoFocus: false,
                                                variant: 'menu',
                                            },
                                            disableAutoFocusItem: true,
                                        }}
                                    >
                                        <MenuItem key={0} value="Outline" style={{ textWrap: 'wrap' }} dense>
                                            <Checkbox checked={polyBool} sx={{ paddingLeft: "0px" }} />
                                            <ListItemText primary="Outline" />
                                        </MenuItem>
                                        <MenuItem key={1} value="Heatmap" style={{ textWrap: 'wrap' }} dense>
                                            <Checkbox checked={heatBool} sx={{ paddingLeft: "0px" }} />
                                            <ListItemText primary="Heatmap" />
                                        </MenuItem>
                                        <MenuItem key={2} value="Markers" style={{ textWrap: 'wrap' }} dense>
                                            <Checkbox checked={markBool} sx={{ paddingLeft: "0px" }} />
                                            <ListItemText primary="Markers" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell align='right'>{pos.lat}</TableCell>
                            <TableCell align='right'>{pos.lng}</TableCell>
                            <TableCell align='right'>{pos.zoom}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {props.goTopShow && <Button href={`#${props.goTopID || 'top'}`} variant="contained" color='primary' size='md' style={{ color: 'white', float: 'right' }}sx={{ my: 2, ml: 2 }}>
                GO TOP
            </Button>}
        </>
    );
}


