import {NJ} from '../APIConfig'
import axios from "axios";

const headers = {
    "Content-Type": "application/json",
    "Authorization" : ""
  };

export const GetBIQuotesData = async (startDate, endDate, state) => {
    const { method, url, ...props } = NJ.GetBIQuotesData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetBIQuotesData", apiResponse);
    } catch (error) {
      console.error("GetBIQuotesData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };
  export const GetBIPoliciesData = async (startDate, endDate, state) => {
    const { method, url, ...props } = NJ.GetBIPoliciesData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetBIPoliciesData", apiResponse);
    } catch (error) {
      console.error("GetBIPoliciesData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };