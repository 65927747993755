import { Grid, IconButton, Popover, Typography, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableFooter } from "@material-ui/core";
import React, { useEffect } from "react";
import { Help } from "@material-ui/icons";
import Button from "utils/CustomButtons/Button";
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import { CallAPI, all } from "services/all/API";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export const GWPPP_TransCode = (props) => {
    const showAgency = !props.city && (props.states.lenght > 0 || props.states[0] !== "PA");
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const isSmallerScreen = useMediaQuery('(max-width: 1900px)');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [agencyData, setAgencyData] = React.useState([]);
    const [isPP, setIsPP] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [agencyTotal, setAgencyTotal] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [comments, setComments] = React.useState(props.comments?.filter(x => x.table == 'Gross Written Premium') ?? []);

    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const cellStyle = { fontSize: "1.6em", lineHeight: "1.3em", color: "#ff8000", whiteSpace: "nowrap" };
    const headerStyle = { fontSize: "1.3em", color: "#1d4c80", fontWeight: "400" }

    const RefreshData = async (toggle) => {
        setComments(props.comments?.filter(x => x.table == (toggle ? 'Posted Premium' : 'Gross Written Premium')) ?? []);

        const data = await CallAPI(all.GetGWPData, {
            startDate: props.startDate,
            endDate: props.endDate,
            states: props.states,
            city: props.city,
            isPP: toggle
        });

        if (data) {
            setData(data.result);
            setAgencyData(data.agencyResult);
            setTotal(data.total);
            setAgencyTotal(data.agencyTotal);
            setLoading(false);
        }
    }

    const handleIsPP = () => {
        var toggle = !isPP;
        setIsPP(toggle);
        setLoading(true);
        RefreshData(toggle);
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            setData([]);
            setAgencyData([]);
            setTotal(0);
            setAgencyTotal(0);
            setLoading(false);
            RefreshData(isPP);
        }
        
       
    }, [props.call]);

    return (
        <div
            style={
                isMobile ?
                    {
                        marginTop: "2em",
                        marginLeft: "0em",
                        marginRight: "0em",
                        backgroundColor: "#f1f1f1"
                    }
                    :
                    {
                        marginTop: "2em",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                        backgroundColor: "#f1f1f1"
                    }
                
            }
        >
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "absolute!important"
                    })
                }}
                spinner={<ReactLoading type={'spin'} height={50} width={50} />}
            >
            <Grid container>
                <Grid item
                    xs={12} sm={12} md={12} lg={3}
                    style={{ textAlign: "center" }}
                >
                    <span
                        style={
                            isMobile ?
                                {
                                    fontSize: "1.8em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: ".4em",
                                    display: "inline-block"
                                }
                                :
                                {
                                    fontSize: "1.6em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: "1em",
                                    display: "inline-block"
                                }
                        }
                    >
                        {isPP?
                            "POSTED PREMIUM"
                            :
                            "GROSS WRITTEN PREMIUM"
                        }
                    </span>
                </Grid>
                <Grid item
                    xs={12} sm={12} md={12} lg={9}
                    style={ useMediaQuery('(max-width: 1025px)') ?{ textAlign: "center" } : { textAlign: "left" }}
                    >
                        <Button onClick={handleIsPP} style={isSmallerScreen ? { color: 'white', margin: '20px', backgroundColor: '#ff8000' } : { color: 'white', margin: '20px', marginLeft: '12em', backgroundColor: '#ff8000' }} color="primary"> {isPP ? "TOGGLE GWP" : "TOGGLE PP"}</Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer  style={{ position: "relative", marginTop: "1em", margin:"auto", maxWidth: showAgency && "850px" || "650px" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={headerStyle}>TRANSACTION CODE</TableCell>
                                    <TableCell style={headerStyle}>{showAgency && "TOTAL "}PREMIUM</TableCell>
                                    {showAgency && <TableCell style={headerStyle}>AGENCY PREMIUM (%)</TableCell>}
                                    {comments.length > 0 && <TableCell></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((row) => {
                                        const agencyRow = agencyData.find(a => a.transactionCD === row.transactionCD);
                                        const agencyPremium = agencyRow ? Number(agencyRow.premium) : 0;
                                        const totalPremium = Number(row.premium);
                                        const percentage = totalPremium !== 0 ? 
                                            Math.round((Math.abs(agencyPremium) / Math.abs(totalPremium)) * 100) : 0;
                                        
                                        return (
                                            <TableRow
                                                key={row.transactionCD}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" style={headerStyle}>
                                                    {row.transactionCD}
                                                </TableCell>
                                                <TableCell style={cellStyle}>
                                                    ${row.premium && !isNaN(row.premium)
                                                        ? nf.format(Math.round(Number(row.premium)))
                                                        : nf.format(0)
                                                    }
                                                </TableCell>
                                                {showAgency && <TableCell style={cellStyle}>
                                                    ${nf.format(Math.round(agencyPremium))} ({percentage}%)
                                                </TableCell>}
                                                {props.comments?.length > 0 && <TableCell style={{ whiteSpace: 'pre-line' }}>{comments.find(x => x.row == row.transactionCD)?.message}</TableCell>}
                                            </TableRow>
                                        );
                                    })
                                }
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row" style={headerStyle}>
                                        Total Premium
                                    </TableCell>
                                    <TableCell style={cellStyle}>
                                        ${nf.format(total.toFixed(0) ?? 0)}
                                    </TableCell>
                                    {showAgency && <TableCell style={cellStyle}>
                                        ${nf.format(agencyTotal.toFixed(0) ?? 0)} ({total !== 0 ?
                                            Math.round((Math.abs(agencyTotal) / Math.abs(total)) * 100) : 0}%)
                                    </TableCell>}
                                    {props.comments?.length > 0 && <TableCell style={{ whiteSpace: 'pre-line' }}>{comments.find(x => x.row == 'Total Premium')?.message}</TableCell>}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </LoadingOverlay>

        </div>
    );
}
const PopoverEl = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    if (props.Popover != null) {
        return (
            [
                <IconButton
                    aria-describedby={id}
                    variant="contained"
                    size="small"
                    style={{ color: "#25b7d3", marginBottom: ".5em" }}
                    onClick={handleClick} component="span">
                    <Help />
                </IconButton>,
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography>{props.Popover}</Typography>
                </Popover>

            ]
        );
    } else {
        return (<div></div>);
    }
}
