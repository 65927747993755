import React, { useState } from "react";
import { Styles } from "./MainNavbar_Styles";
import Button from "utils/CustomButtons/Button";
import TooltipButton from "utils/TooltipButton/TooltipButton";

// @material-ui/core components
import ReactLoading from 'react-loading';
import { Grid, TextField } from "@mui/material";
// react component plugin for creating a beautiful datetime dropdown picker
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import '../../assets/css/datepicker.css'
import { Datepicker } from '@mobiscroll/react';
import axios from 'axios';
import SearchResultsModal from 'components/SearchResultsModal';
import FilterProjectReleases from "components/FilterProjectReleases";

// assets
import state_NJ from "assets/img/NJ_State.png";
import state_MI from "assets/img/MI_State.png";
import city_Detroit from "assets/img/Detroit_City.png";

export function Header(props) {
    const location = props.city || props.state;

    var header = null;
    var logo = null;

    switch (location) {
        case "NJ":
            header = "NEW JERSEY DASHBOARD";
            logo = state_NJ;
            break;
        case "PA":
            header = "PENNSYLVANIA DASHBOARD";
            break;
        case "MI":
            header = "MICHIGAN DASHBOARD";
            logo = state_MI;
            break;
        case "Detroit":
            header = <>DETROIT DASHBOARD <TooltipButton
                style={{ position: "unset", marginTop: props.ViewSize() === "XS" ? "-0.4em" : "-15px" }}
                tooltipText="Data is when a quote/policy has Detroit as the city in the mailing address."
            /></>;
            logo = city_Detroit;
            break;
        default:
            header = <>DASHBOARD - ALL STATES <TooltipButton
                style={{ position: "unset", marginTop: props.ViewSize() === "XS" ? "-0.4em" : "-15px" }}
                tooltipText="NJ data added as of 9/1/22, PA data added as of 3/1/23."
            /></>;
            break;
    }

    return (
        <div>
            {logo && <img src={logo} style={Styles["StateLogo_" + props.ViewSize()]} alt="..." />}
            <h3 style={Styles['Header_' + props.ViewSize() + '_Heading']}>{header}</h3>
        </div>
    );
}

export function ChangeView(props){
    return (
        <Grid item container style={Styles['ChangeView_'+props.ViewSize()+'_p']}>
            <Grid item><span>CHANGE VIEW:</span></Grid>
            {(window.location.pathname !== "/pass") && <Grid item><a href={"/pass"} style={Styles['ChangeView_p_a']}>MI Only</a></Grid>}
            {(window.location.pathname !== "/NJ/pass") && <Grid item><a href={"/NJ/pass"} style={Styles['ChangeView_p_a']}>NJ Only</a></Grid>}
            {(window.location.pathname !== "/PA/pass") && <Grid item><a href={"/PA/pass"} style={Styles['ChangeView_p_a']}>PA Only</a></Grid>}
            {(window.location.pathname !== "/MI/Detroit/pass") && <Grid item><a href={"/MI/Detroit/pass"} style={Styles['ChangeView_p_a']}>Detroit Only</a></Grid>}
            {(window.location.pathname !== "/all/pass") && <Grid item><a href={"/all/pass"} style={Styles['ChangeView_p_a']}>All States</a></Grid>}
        </Grid>
    );
}

export function Wrapper(props) {
    return (
        <Grid item container lg={4} sm={12} style={Styles["DatePicker_" + props.ViewSize() + "_Wrapper"]}>
            {props.children}
        </Grid>
    );
}


export function DateSelection(props){
    const useMediaQuery = (query) => {
        const mediaMatch = window.matchMedia(query);
        const [matches, setMatches] = React.useState(mediaMatch.matches);

        React.useEffect(() => {
            const handler = e => setMatches(e.matches);
            mediaMatch.addEventListener("change", handler);
            return () => mediaMatch.removeEventListener("change", handler);
        });
        return matches;
    };
    const isMobile = useMediaQuery('(max-width: 1152px)');

    const inputProps = {
        className: 'md-mobile-picker-input',
        placeholder: 'Please Select...',
        style: {
            backgroundColor: "#ff8000", color: "white", borderRadius: "3px", border: "none", textAlign: "center"
        }
    };

    return(
        <Grid item lg={4} sm={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={7} lg={12}>
                    <Grid container spacing={2} alignItems="flex-end" style={{ marginTop: "5px" }}>
                        <Grid item xs={12} sm={2} sx={{ display: { xs: "none", sm: "block" } }}>
                            <div style={{ textAlign: "right" }}>
                                <label style={{ color: "white", fontSize: "18px" }}>DATE RANGE</label>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <p style={{ color: "white", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                            <Datepicker
                                controls={['date']}
                                display="center"
                                touchUi={true}
                                inputComponent="input"
                                inputProps={{ ...inputProps, label: "Start Date" }}
                                value={props.startDate}
                                onChange={function (event, inst) {
                                    props.handleStartDateChange(event.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <p style={{ color: "white", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                            <Datepicker
                                controls={['date']}
                                display="center"
                                touchUi={true}
                                inputComponent="input"
                                inputProps={inputProps}
                                value={props.endDate}
                                onChange={function (event, inst) {
                                    props.handleEndDateChange(event.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={3} style={{ fontSize: "1.4em", color: "white", fontWeight: "600" }}>
                            <Button onClick={props.handleCall} style={{ color: 'black', margin: '0px', height: '37px', fontSize: '12px', width: '100%' }} color="white" size="sm">Submit</Button>
                        </Grid>
                        <Grid item xs={1} sm={1} style={{ fontSize: "1.4em", color: "white", fontWeight: "600" }}>
                            <div>
                                <ReactLoading
                                    type={'spin'}
                                    style={props.call == "true" ? { fill: "white", height: "32px", width: "25px" } : { display: "none" }}
                                    height={isMobile ? 20 : 25} width={isMobile ? 20 : 25} color={"white"}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] === "pass" || window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] === "comparison") && (
                    <Grid item xs={12} sm={10} md={7} lg={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} sx={{ display: { xs: "none", sm: "block" } }} />
                            <Grid item xs={12} sm={6}>
                                <FilterProjectReleases
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    onFilterChange={props.onFilterChange}
                                    labelStyle={{ fontSize: "18px", whiteSpace: "nowrap", color: "white", fontWeight: "300" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export function LinkBar(props){
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };

    const isStaging = activeRoute('uat');

    return (
        <Grid item lg={12} style={activeRoute("pass") ? Styles["LinkBar_" + props.ViewSize() + "_FirstItem"] : { display: "none" }}>
            {!props.city && props.state === "all" && <a href="/all/options" style={Styles["LinkBar_" + props.ViewSize() + "_Item"]}>ADVANCED FILTER OPTIONS</a> }
            {!props.city && props.state === "MI" && <a href="/MI/options" color="primary" style={Styles["LinkBar_"+props.ViewSize()+"_Item"]} >ADVANCED FILTER OPTIONS</a>}
            {!props.city && props.state === "NJ" && <a href="/NJ/options" color="primary" style={Styles["LinkBar_"+props.ViewSize()+"_Item"]} >ADVANCED FILTER OPTIONS</a>}
            {!props.city && props.state === "PA" && <a href="/PA/options" color="primary" style={Styles["LinkBar_"+props.ViewSize()+"_Item"]} >ADVANCED FILTER OPTIONS</a>}
            {!props.city && props.state === "MI" && <a href="/dma" color="primary" style={Styles["LinkBar_"+props.ViewSize()+"_Item"]}>DMA STATS</a>}
            {(props.state === "all" || props.state === "MI" || props.state === "NJ" || props.state === "PA" || props.city === "Detroit") && 
                <a href={
                    props.state === "NJ" ? "/NJ/comparison" : 
                    props.state === "PA" ? "/PA/comparison" : 
                    props.state === "all" ? "/all/comparison" : 
                    props.city === "Detroit" ? "/Detroit/comparison" :
                    "/comparison"
                } color="primary" style={{
                    ...Styles["LinkBar_" + props.ViewSize() + "_Item"],
                    ...(props.city === 'Detroit' ? { borderRightStyle: 'none' } : {})
                }}>
                    COMPARISON SEARCH
                </a>
            }
            {!props.city && props.state === "MI" && <a href="/MI/cancelandrenewal" color="primary" style={Styles["LinkBar_" + props.ViewSize() + "_Item"]}>CANCELS & RENEWALS</a>}
            {!props.city && props.state === "NJ" && <a href="/NJ/cancelandrenewal" color="primary" style={Styles["LinkBar_" + props.ViewSize() + "_Item"]}>CANCELS & RENEWALS</a>}
            {props.state === "all" && <a href="/projections" color="primary" style={{ ...Styles["LinkBar_" + props.ViewSize() + "_Item"], borderRightStyle: undefined }}>PROJECTIONS</a>}
            {isStaging && !props.city && props.state === "MI" && <a href="/onlinebinds" color="primary" style={{ ...Styles["LinkBar_" + props.ViewSize() + "_Item"], borderRightStyle: undefined }}>ONLINE BINDS</a>}
        </Grid>
    );
}

export function SearchProjectReleases(props) {
    const { startDate, endDate } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [open, setOpen] = useState(false);

    const filterProjects = (projectsList, queryParameter) => {
        // Convert queryParameter to lowercase for case insensitive comparison
        const lowerQueryParameter = queryParameter.toLowerCase();

        // Check if the queryParameter is enclosed in quotes
        const quoteRegex = /^['"`](.*)['"`]$/;
        const match = lowerQueryParameter.match(quoteRegex);

        // Check if the queryParameter contains an asterisk (*)
        const hasWildcard = lowerQueryParameter.includes('*');

        if (match) {
            // If enclosed in quotes, treat the entire query as one word
            const searchString = match[1];
            return projectsList.filter(p => p.Projects.toLowerCase().includes(searchString));
        } else if (hasWildcard) {
            // If contains an asterisk, use it as a wildcard
            const wildcardRegex = new RegExp(lowerQueryParameter.replace(/\*/g, '.*'), 'i');
            return projectsList.filter(p => wildcardRegex.test(p.Projects));
        } else {
            // If multiple words separated by spaces, any word must be included
            const words = lowerQueryParameter.split(' ');
            return projectsList.filter(p => words.some(word => p.Projects.toLowerCase().includes(word)));
        }
    };

    const handleSearch = async () => {
        try {
            const trimmedQuery = searchQuery.trim() || '';
            const response = await axios.get(`/GetSharedDriveData/SearchReleases?query=${trimmedQuery}`);
            const releases = response.data.data;

            // Filter results by date and search query
            const dateFilteredReleases = releases.filter(release => {
                const releaseDate = new Date(release.ReleaseDate);
                const isWithinDateRange = releaseDate >= new Date(startDate) && releaseDate <= new Date(endDate);
                return isWithinDateRange;
            });

            const searchFilteredReleases = filterProjects(dateFilteredReleases, trimmedQuery);

            setSearchResults(searchFilteredReleases);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const inputStyle = {
        backgroundColor: "white",
        fontSize: "14px",
        lineHeight: "16px",
        margin: "0",
    };

    const buttonStyle = {
        fontSize: "14px",
        padding: "12px 30px",
        lineHeight: "16px",
        margin: "0",
        backgroundColor: "#ff8000",
        color: "white",
    };

    return (
        <Grid container alignItems="center" style={{ marginTop: "10px", width: "unset" }}>
            <Grid item>
                <TextField
                    placeholder="Search Releases..."
                    variant="outlined"
                    size="small"
                    style={inputStyle}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Grid>
            <Grid item style={{ marginLeft: "10px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    sx={buttonStyle}
                >
                    Search
                </Button>
            </Grid>
            <SearchResultsModal open={open} handleClose={handleClose} data={searchResults} />
        </Grid>
    );
}