import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarComparison.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Button from "utils/CustomButtons/Button";

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";
import Comparison from "components/Comparison";
import { Comparison_GWPPPTC } from "components/Comparison_GWPPPTC"
import EnvironmentServiceBanner from "components/EnvironmentServiceBanner";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    //Event Handlers
    const changeStartDateA = (date) => {
        setStartDateA((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDateA = (date) => {
        setEndDateA((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };
    const changeStartDateB = (date) => {
        setStartDateB((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDateB = (date) => {
        setEndDateB((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        setCall("true");
    };
    const changeGroupDateBy = (selected) => {
        setGroupDateBy(selected);
    };
    //Events End

    //State Variables 
    var today = new Date();
    var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
    var tomorrow = new Date(); tomorrow.setDate(tomorrow.getDate() + 1);
    var twodays = new Date(); twodays.setDate(twodays.getDate() - 2);
    var threedays = new Date(); threedays.setDate(threedays.getDate() - 3);


    // var startdate = date.setDate(date.getDate() - 32);
    //startDate = Date.
    const [startDateA, setStartDateA] = React.useState((yesterday.getMonth() + 1) + '/' + yesterday.getDate() + '/' + yesterday.getFullYear());
    const [endDateA, setEndDateA] = React.useState((today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear());

    const [startDateB, setStartDateB] = React.useState((threedays.getMonth() + 1) + '/' + threedays.getDate() + '/' + threedays.getFullYear());
    const [endDateB, setEndDateB] = React.useState((twodays.getMonth() + 1) + '/' + twodays.getDate() + '/' + twodays.getFullYear());
    const [groupDateBy, setGroupDateBy] = React.useState("");
    const [call, setCall] = React.useState(false);


    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {

        //disable scrolling when page is loading
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        //document.body.style.overflow = "hidden";
        //document.body.style.height = "100%";
        if (call == "true" || call == null) {
            //setQuoteTotalPip(null);
            //Refresh();
        }


        return () => {
            //End
            setCall("false");
            //Enable scrolling when page is finished loading
            //document.body.style.overflow = "auto";
            //document.body.style.height = "auto";
        }
    }, [call]);


    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    //Utility functions End
    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <AuthNavbar state="NJ" onChangeCall={changeCall} onChangeStartDateA={changeStartDateA} onChangeEndDateA={changeEndDateA} onChangeStartDateB={changeStartDateB} onChangeEndDateB={changeEndDateB} onChangeGroupDateBy={changeGroupDateBy} startDateA={startDateA} endDateA={endDateA} startDateB={startDateB} endDateB={endDateB} Text={"New Jersey Dashboard"} {...rest} />
                <Comparison
                    startDateA={startDateA}
                    endDateA={endDateA}
                    startDateB={startDateB}
                    endDateB={endDateB}
                    call={call}
                    groupDateBy={groupDateBy}
                    state="NJ"
                />
                <Comparison_GWPPPTC
                    startDateA={startDateA}
                    endDateA={endDateA}
                    startDateB={startDateB}
                    endDateB={endDateB}
                    call={call}
                    state="NJ"
                />
            </div>
            <EnvironmentServiceBanner call={call} />
        </div>
    );
}