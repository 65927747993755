import Styles from './TableWithExportButton.Styles.js';
import React from 'react'
import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const merge = (target, source, mutable = false) => {
    const newObj = typeof target == 'object' ? (mutable ? target : Object.assign({}, target)) : {};
    for (const prop in source) {
        if (target[prop] == null || typeof target[prop] === 'undefined') {
            newObj[prop] = source[prop];
        } else if (Array.isArray(target[prop])) {
            newObj[prop] = source[prop] || target[prop];
        } else if (target[prop] instanceof RegExp) {
            newObj[prop] = source[prop] || target[prop];
        } else {
            newObj[prop] = typeof source[prop] === 'object' ? merge(target[prop], source[prop]) : source[prop];
        }
    }
    return newObj;
};

export default function TableWithExportButton(props) {
    const style = (props.style === undefined) ? Styles() : merge(Styles(), props.style);
    const exportName = (props.exportName === undefined) ? props.title.toString().replace("-", "").replace(" ", "_") : props.exportName;
    const isMobile = window.matchMedia('(max-width: 1152px)').matches;

    return (
        <Grid container>
            <Grid item style={{
                ...style.Grid.Item,
                ...(isMobile ? {
                    margin: "1em",
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    width: "100%"
                } : {
                    margin: "2em",
                    paddingLeft: "1.9em",
                    paddingRight: "1em",
                    width: "100%"
                })
            }}>
                <span>
                    <span style={style.Title}>{props.title}</span>
                </span>
                <br />
                <br />
                {props.subtitle === undefined ? null : (
                    <span style={style.Subtitle}>{props.subtitle}</span>
                )}
                <TableContainer component={Paper} style={style.Table.Container}>
                    <Table stickyHeader style={style.Table.TableSX} aria-label="simple table">
                        <TableHead>
                            {props.headers.map((row) => {
                                if (row.length > 0)
                                    return (
                                        <TableRow key={row[0]}>
                                            {row.map((cell, index) => {
                                                if (index < props.fixedColumns)
                                                    return (
                                                        <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align="left" style={{ ...style.Table.FixedColumn, ...style.Table.Header, ...cell?.style ?? {} }}>
                                                            {cell?.text || cell}
                                                        </TableCell>
                                                    );
                                                else
                                                    return (
                                                        <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align={index == 0 ? "left" : "right"} style={{ ...style.Table.Header, ...cell?.style ?? {} }}>
                                                            {cell?.text || cell}
                                                        </TableCell>
                                                    );
                                            })}
                                        </TableRow>
                                    );
                            })}
                        </TableHead>
                        <TableBody>
                            {props.rows === undefined ? null : props.rows.map((row) => {
                                if (row.length > 0)
                                    return (
                                        <TableRow key={row[0]} sx={style.Table.BodyRowSX}>
                                            {row.map((cell, index) => {
                                                if (index < props.fixedColumns)
                                                    return (
                                                        <TableCell align="left" component="th" scope="row" style={{ ...style.Table.FixedColumn, ...style.Table.Body }}>
                                                            {cell}
                                                        </TableCell>
                                                    );
                                                else
                                                    return (
                                                        <TableCell align={index == 0 ? "left" : "right"} style={style.Table.Body}>{cell}</TableCell>
                                                    );
                                            })}
                                        </TableRow>
                                    );
                            })}
                            {props.footers === undefined ? null : props.footers.map((row) => {
                                if (row.length > 0)
                                    return (
                                        <TableRow key={row[0]} sx={style.Table.BodyRowSX}>
                                            {row.map((cell, index) => {
                                                if (index < props.fixedColumns)
                                                    return (
                                                        <TableCell align="left" component="th" scope="row" style={{ ...style.Table.FixedColumn, ...style.Table.Footer }}>
                                                            {cell}
                                                        </TableCell>
                                                    );
                                                else
                                                    return (
                                                        <TableCell align={index == 0 ? "left" : "right"} style={style.Table.Footer}>{cell}</TableCell>
                                                    );
                                            })}
                                        </TableRow>
                                    );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ExportButton
                    ExportName={exportName}
                />
            </Grid>
        </Grid>
    );
};