import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarAdvance.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Button from "utils/CustomButtons/Button";
import { Device, DeviceContext } from 'utils/Device';

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";
import QuotesAndPremium from "components/QuotesAndPremium";
import PoliciesAndPremium from "components/PoliciesAndPremium";
import Application from "components/Application_Advanced";
import PIPSelections from "components/PIPSelections";
import PostedPremium from "components/GWPPP_Detailed";
import QuotesByCoverageTypeAdvanced from "components/QuotesByCoverageTypeAdvanced";
import PoliciesByCoverageTypeAdvanced from "components/PoliciesByCoverageTypeAdvanced";
import VehiclesByCoverageTypeAdvanced from "components/VehiclesByCoverageTypeAdvanced";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    //Event Handlers
    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        setCall("true");
    };
    const changeGroupDateBy = (selected) => {
        setGroupDateBy(selected);
    };
    //Events End

    //State Variables 
    var date = new Date();
   // var startdate = date.setDate(date.getDate() - 32);
   //startDate = Date.
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [groupDateBy, setGroupDateBy] = React.useState("Day");
    const [call, setCall] = React.useState(false);

    const device = Device();
    const isMobile = device.isMobile; //useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {

        //disable scrolling when page is loading
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        //document.body.style.overflow = "hidden";
        //document.body.style.height = "100%";
        if (call == "true" || call == null) {
            //setQuoteTotalPip(null);
            //Refresh();
        }
        

        return () => {
            //End
            setCall("false");
            //Enable scrolling when page is finished loading
            //document.body.style.overflow = "auto";
            //document.body.style.height = "auto";
        }
    }, [call]);


    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    //Utility functions End
    return (
        <DeviceContext.Provider value={device}>
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <AuthNavbar onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} onChangeGroupDateBy={changeGroupDateBy} startDate={startDate} endDate={endDate} groupDateBy={groupDateBy} state = "all" Text={"Dashboard - All States"} {...rest} />
                    <div>
                        <QuotesAndPremium
                        startDate={startDate}
                        endDate={endDate}
                        call={call}
                        groupDateBy={groupDateBy}
                        states={['MI', 'NJ', 'PA']}
                        />
                        <PoliciesAndPremium
                            startDate={startDate}
                            endDate={endDate}
                            call={call}
                            groupDateBy={groupDateBy}
                            states={['MI', 'NJ', 'PA']}
                    />
                    <Application
                        startDate={startDate}
                        endDate={endDate}
                        call={call}
                        groupDateBy={groupDateBy}
                        states={['MI', 'NJ', 'PA']}
                    />
                    <PIPSelections
                        startDate={startDate}
                        endDate={endDate}
                        call={call}
                        groupDateBy={groupDateBy}
                        states={['MI', 'NJ', 'PA']}
                    />
                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <QuotesByCoverageTypeAdvanced
                                startDate={startDate}
                                endDate={endDate}
                                call={call}
                                groupDateBy={groupDateBy}
                                states={['MI', 'NJ', 'PA']}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <PoliciesByCoverageTypeAdvanced
                                startDate={startDate}
                                endDate={endDate}
                                call={call}
                                groupDateBy={groupDateBy}
                                states={['MI', 'NJ', 'PA']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <VehiclesByCoverageTypeAdvanced
                                startDate={startDate}
                                endDate={endDate}
                                call={call}
                                groupDateBy={groupDateBy}
                                states={['MI', 'NJ', 'PA']}
                                type="Quotes"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <VehiclesByCoverageTypeAdvanced
                                startDate={startDate}
                                endDate={endDate}
                                call={call}
                                groupDateBy={groupDateBy}
                                states={['MI', 'NJ', 'PA']}
                                type="Policies"
                            />
                        </Grid>
                    </Grid>
                    
                    <PostedPremium
                        startDate={startDate}
                        endDate={endDate}
                        call={call}
                        groupDateBy={groupDateBy}
                        states={['MI', 'NJ', 'PA']}
                    />
                    </div>
            </div>
            </div>
        </DeviceContext.Provider>
    );
}