import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
import AuthNavbar from "utils/Navbars/AuthNavbarComparison.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import Comparison from "components/Comparison";
import { Comparison_GWPPPTC } from "components/Comparison_GWPPPTC"
import EnvironmentServiceBanner from "components/EnvironmentServiceBanner";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    
    // Helper functions and event handlers (same as MI/NJ)
    function isNotNull(data) {
        return data != null && data !== "";
    }

    const changeStartDateA = (date) => {
        setStartDateA((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDateA = (date) => {
        setEndDateA((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeStartDateB = (date) => {
        setStartDateB((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDateB = (date) => {
        setEndDateB((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        setCall("true");
    };

    const changeGroupDateBy = (selected) => {
        setGroupDateBy(selected);
    };

    // State Variables 
    var today = new Date();
    var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
    var twodays = new Date(); twodays.setDate(twodays.getDate() - 2);
    var threedays = new Date(); threedays.setDate(threedays.getDate() - 3);

    const [startDateA, setStartDateA] = React.useState((yesterday.getMonth() + 1) + '/' + yesterday.getDate() + '/' + yesterday.getFullYear());
    const [endDateA, setEndDateA] = React.useState((today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear());
    const [startDateB, setStartDateB] = React.useState((threedays.getMonth() + 1) + '/' + threedays.getDate() + '/' + threedays.getFullYear());
    const [endDateB, setEndDateB] = React.useState((twodays.getMonth() + 1) + '/' + twodays.getDate() + '/' + twodays.getFullYear());
    const [groupDateBy, setGroupDateBy] = React.useState("");
    const [call, setCall] = React.useState(false);

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    const wrapper = React.createRef();
    const classes = useStyles();

    React.useEffect(() => {
        if (call === "true" || call == null) {
            // Add any specific logic for PA if needed
        }

        return () => {
            setCall("false");
        }
    }, [call]);

    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }

    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <AuthNavbar state="PA" onChangeCall={changeCall} onChangeStartDateA={changeStartDateA} onChangeEndDateA={changeEndDateA} onChangeStartDateB={changeStartDateB} onChangeEndDateB={changeEndDateB} onChangeGroupDateBy={changeGroupDateBy} startDateA={startDateA} endDateA={endDateA} startDateB={startDateB} endDateB={endDateB} Text={"Pennsylvania Dashboard"} {...rest} />
                <Comparison
                    startDateA={startDateA}
                    endDateA={endDateA}
                    startDateB={startDateB}
                    endDateB={endDateB}
                    call={call}
                    groupDateBy={groupDateBy}
                    state="PA"
                />
                <Comparison_GWPPPTC
                    startDateA={startDateA}
                    endDateA={endDateA}
                    startDateB={startDateB}
                    endDateB={endDateB}
                    call={call}
                    state="PA"
                />
            </div>
            <EnvironmentServiceBanner call={call} />
        </div>
    );
}