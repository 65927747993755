import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import lightBlue from "@material-ui/core/colors/lightBlue";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Datepicker, setOptions } from '@mobiscroll/react';
import { Box } from '@mui/material/';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import avatar from "assets/img/Logo.png";
import state from "assets/img/MI_State.png";
import state_NJ from "assets/img/NJ_State.png";
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Button from "utils/CustomButtons/Button";
import GridContainer from "utils/Grid/GridContainer";
import GridItem from "utils/Grid/GridItem";
import FilterProjectReleases from "components/FilterProjectReleases";
import ReleaseDrawer from 'components/ReleaseDrawer';

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });
    const inputProps = {
        className: 'md-mobile-picker-input',
        placeholder: 'Please Select...',
        style: {
            backgroundColor: "#ff8000", color: "white", borderRadius: "3px", border: "none", textAlign: "center", width: "100px"
        }
    };
    const useMediaQuery = (query) => {
        const mediaMatch = window.matchMedia(query);
        const [matches, setMatches] = React.useState(mediaMatch.matches);

        React.useEffect(() => {
            const handler = e => setMatches(e.matches);
            mediaMatch.addEventListener("change", handler);
            return () => mediaMatch.removeEventListener("change", handler);
        });
        return matches;
    };

    const [open, setOpen] = React.useState(false);
    const isSmallerScreen = useMediaQuery('(max-width: 1900px)');

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const [startDateA, setStartDateA] = React.useState(props.startDateA);
    const [endDateA, setEndDateA] = React.useState(props.endDateA);
    const [startDateB, setStartDateB] = React.useState(props.startDateB);
    const [endDateB, setEndDateB] = React.useState(props.endDateB);
    const [groupDateBy, setGroupDateBy] = React.useState("0");

    const [call, setCall] = React.useState(null);

    const [allReleases, setAllReleases] = React.useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);

    const handleStartDateAChange = (date) => {
        setStartDateA(date);
        setGroupDateBy("0");
        props.onChangeStartDateA(date);
    };

    const handleStartDateChange = (date) => {
        props.onChangeStartDate(date);
    };

    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };
    
    const handleEndDateChange = (date) => {
        props.onChangeEndDate(date);
    };

    const handleEndDateAChange = (date) => {
        setEndDateA(date);
        setGroupDateBy("0");
        props.onChangeEndDateA(date);
    };
    const handleStartDateBChange = (date) => {
        setStartDateB(date);
        setGroupDateBy("0");
        props.onChangeStartDateB(date);
    };

    const handleEndDateBChange = (date) => {
        setEndDateB(date);
        setGroupDateBy("0");
        props.onChangeEndDateB(date);
    };

    const handleGroupDateByChange = (selected) => {
        if (selected.target.value == "Month") {
            var today = new Date()
            var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
            setStartDateA(yesterday);
            setEndDateA(today);
            props.onChangeStartDateA(yesterday);
            props.onChangeEndDateA(today);
            var today = new Date(); today.setMonth(today.getMonth() - 1);
            var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1); yesterday.setMonth(yesterday.getMonth() - 1);
            setStartDateB(yesterday);
            setEndDateB(today);
            props.onChangeStartDateB(yesterday);
            props.onChangeEndDateB(today);
        } else if (selected.target.value == "Year") {
            var today = new Date();
            var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
            setStartDateA(yesterday);
            setEndDateA(today);
            props.onChangeStartDateA(yesterday);
            props.onChangeEndDateA(today);
            var today = new Date(); today.setYear(today.getFullYear() - 1);
            var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1); yesterday.setYear(yesterday.getFullYear() - 1);
            setStartDateB(yesterday);
            setEndDateB(today);
            props.onChangeStartDateB(yesterday);
            props.onChangeEndDateB(today);
        }
        setGroupDateBy(selected.target.value);
    };

    const handleCall = () => {
        setCall("true");
        props.onChangeCall();
    };

    const handleFilterChange = (releases, types) => {
        setAllReleases(releases);
        setSelectedTypes(types);
    };

    const getMinMaxDates = () => {
        const dates = [
            new Date(props.startDateA),
            new Date(props.endDateA),
            new Date(props.startDateB),
            new Date(props.endDateB)
        ];

        const minDate = new Date(Math.min(...dates));
        const maxDate = new Date(Math.max(...dates));

        return { minDate, maxDate };
    };

    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: "#1d4c80",
                },
            },
            MuiInputLabel: {
                root: {
                    color: "white",
                    fontSize: "1.7em"
                }
            },
            MuiInputBase: {
                input: {
                    color: "white",
                    backgroundColor: "#ff8000",
                    padding: "9px 0px",
                    marginTop: "10px",
                    textAlign: "center",
                    borderRadius: "3px",
                }
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    // backgroundColor: lightBlue.A200,
                    // color: "white",
                },
            },
            MuiPickersDay: {
                day: {
                    color: "#ff8000",
                },
                daySelected: {
                    backgroundColor: "#1d4c80",
                },
                dayDisabled: {
                    color: lightBlue["100"],
                },
                current: {
                    color: lightBlue["900"],
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: lightBlue["400"],
                },
            },
        },
    });

    // verifies if routeName is the one active (in browser input)
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    const classes = useStyles();
    let DateStart = Date.now();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });

    return (
        <>
            <AppBar position="static" className={classes.appBar + appBarClasses} style={{ backgroundColor: "#1d4c80", padding: 0, flexWrap: "wrap" }}>
                <Toolbar className={classes.container} style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
                    <GridContainer>
                        <Hidden mdDown>
                            <GridItem xs={3}>
                                <Button href="/"
                                    style={{ padding: "0px", margin: "0px" }}
                                    className={classes.title} color="transparent">
                                    <img src={avatar} height="auto" width="90%"
                                        style={{
                                            minWidth: "150px",
                                            maxHeight: "100px",
                                            marginTop: ".8em"
                                        }}
                                        alt="..." />
                                </Button>
                            </GridItem>
                        </Hidden>
                        <Hidden mdDown>
                            <GridItem lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                {props.state !== "all" && props.state !== "PA" && props.city !== "Detroit" && (
                                    <img src={props.state === "NJ" ? state_NJ : state}
                                        style={{
                                            marginTop: "1.3em",
                                            height: "auto",
                                            maxHeight: "75px",
                                            marginRight: ".8em",
                                        }} alt="..." />
                                )}
                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                    <h2 style={{
                                        fontSize: "2.2vw",
                                        fontFamily: 'GlacialIndifferenceRegular',
                                        whiteSpace: "nowrap",
                                        color: "#ffff",
                                        fontWeight: "normal",
                                        fontStyle: "normal",
                                        marginBottom: "0.5em"
                                    }}>
                                        {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : 
                                         props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : 
                                         props.state === "all" ? <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DASHBOARD - ALL STATES</div> : 
                                         props.city === "Detroit" ? <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DETROIT DASHBOARD</div> :
                                         <div>&nbsp;&nbsp;&nbsp;MICHIGAN DASHBOARD</div>}
                                    </h2>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {props.state === "all" ? (
                                            <>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = "/NJ/Comparison"}>Switch to New Jersey</a>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = "/Comparison"}>Switch to Michigan</a>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = "/PA/Comparison"}>Switch to Pennsylvania</a>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.href = "/Detroit/Comparison"}>Switch to Detroit</a>
                                            </>
                                        ) : (
                                            <>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = (props.state === "NJ" ? "/Comparison" : props.state === "PA" ? "/NJ/Comparison" : props.city === "Detroit" ? "/PA/Comparison" : "/NJ/Comparison")}>
                                                    Switch to {props.state === "NJ" ? "Michigan" : props.state === "PA" ? "New Jersey" : props.city === "Detroit" ? "Pennsylvania" : "New Jersey"}
                                                </a>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = ((props.state === "MI" & props.city !== "Detroit") ? "/PA/Comparison" : props.state === "NJ" ? "/PA/Comparison" : props.state === "PA" ? "/Comparison" : "/NJ/Comparison")}>
                                                    Switch to {(props.state === "MI" & props.city !== "Detroit") ? "Pennsylvania" : props.state === "NJ" ? "Pennsylvania" : props.state === "PA" ? "Michigan" : "New Jersey"}
                                                </a>
                                                <a style={{ color: "white", textDecoration: "underline", cursor: "pointer", marginRight: "10px" }} onClick={() => window.location.href = "/all/Comparison"}>
                                                    Switch to All States
                                                </a>
                                                {props.city !== "Detroit" && (
                                                    <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.href = "/Detroit/Comparison"}>
                                                        Switch to Detroit
                                                    </a>
                                                )}
                                                {props.city === "Detroit" && (
                                                    <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.href = "/Comparison"}>
                                                        Switch to Michigan
                                                    </a>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Box>
                            </GridItem>
                        </Hidden>
                        <Hidden mdDown>
                            <GridItem style={{ marginTop: "15px" }} lg={5}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid style={{ marginTop: "25px" }} container>
                                        <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item lg={2}>
                                            <div>
                                                <label style={{ "color": "white", fontSize: "18px" }}>Range A</label>
                                            </div>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Grid style={{ gap: "90px" }} container>
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }} item lg={4}>
                                                    <div style={{ position: "relative" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.startDateA}
                                                            onChange={function (event, inst) {
                                                                handleStartDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ position: "relative" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.endDateA}
                                                            onChange={function (event, inst) {
                                                                handleEndDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={2} sm={2}
                                                    style={{
                                                        marginLeft: "0.2em",
                                                        fontSize: "1.4em",
                                                        color: "white",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    <Button onClick={handleCall} style={{ color: 'black', margin: '0px', height: '36px', fontSize: '12px' }} color="white" size="sm">Submit</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item lg={2}>
                                            <div>
                                                <label style={{ "color": "white", fontSize: "18px" }}>Range B</label>
                                            </div>
                                        </Grid>
                                        <Grid item lg={9}>
                                            <Grid container>
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }} item lg={4}>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.startDateB}
                                                        onChange={function (event, inst) {
                                                            handleStartDateBChange(event.value)
                                                        }}
                                                    />
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.endDateB}
                                                        onChange={function (event, inst) {
                                                            handleEndDateBChange(event.value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg={2} sm={2}
                                                    style={{
                                                        marginTop: "41px",
                                                        marginLeft: "0.2em",
                                                        fontSize: "1.4em",
                                                        color: "white",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item lg={2}>
                                            <div>
                                                <label style={{ "color": "white", fontSize: "18px" }}>Presets</label>
                                            </div>
                                        </Grid>
                                        <Grid style={{ minWidth: "180px", width: "100%" }} item lg={9}>
                                            <Grid style={{ minWidth: "180px", width: "100%" }} item lg={4}>
                                                <Select
                                                    value={groupDateBy}
                                                    onChange={handleGroupDateByChange}
                                                    SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '7px 59px 7px 10px', 'margin': '0px 0px 10px 0px', 'width': '100%', 'overflow': 'visible', 'color': 'black' } }}
                                                    style={{ minWidth: "180px", width: "220px" }}
                                                >
                                                    <MenuItem value="0">Preset Dates</MenuItem>
                                                    <MenuItem value="Year">Last Year</MenuItem>
                                                    <MenuItem value="Month">Last Month</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item lg={2}>
                                            {/* Empty grid item for alignment */}
                                        </Grid>
                                        <Grid style={{ minWidth: "220px", width: "100%", maxWidth: "220px" }} item lg={9}>
                                            <FilterProjectReleases
                                                startDate={props.startDateA}
                                                endDate={props.endDateA}
                                                onFilterChange={handleFilterChange}
                                                labelStyle={{ fontSize: "18px", whiteSpace: "nowrap", color: "white", fontWeight: "300" }}
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </GridItem>

                        </Hidden>
                        <Hidden lgUp>
                            <GridItem xs={3} sm={4} style={{ paddingLeft: "0px" }}>
                                <Button
                                    href="/"
                                    className={classes.title}
                                    color="transparent"
                                    style={{
                                        marginTop: ".6em",
                                        padding: "0px"
                                    }}
                                >
                                    <img src={avatar} height="auto" width="100%"
                                        style={{
                                            minWidth: "120px",
                                            maxHeight: "100px"
                                        }}
                                        alt="..." />
                                </Button>

                            </GridItem>
                        </Hidden>
                        <Hidden lgUp xsDown>
                            <GridItem xs={5} sm={6} style={{ display: "inherit", marginLeft: "2.2em", padding: "0!important" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <h2
                                        style={{
                                            fontFamily: 'GlacialIndifferenceRegular',
                                            color: "#ffff",
                                            fontWeight: "normal",
                                            fontStyle: "normal",
                                            fontSize: "3vw",
                                            marginTop: "auto"
                                        }}>
                                        {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : (props.state === "all" ? "DASHBOARD - ALL STATES" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : props.city === "Detroit" ? "DETROIT DASHBOARD" : "MICHIGAN DASHBOARD")}
                                    </h2>
                                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                        {props.state !== "NJ" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/NJ/Comparison"}>
                                                    Switch to New Jersey
                                                </span>
                                            </a>
                                        )}
                                        {props.state !== "MI" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/Comparison"}>
                                                    Switch to Michigan
                                                </span>
                                            </a>
                                        )}
                                        {props.state !== "PA" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/PA/Comparison"}>
                                                    Switch to Pennsylvania
                                                </span>
                                            </a>
                                        )}
                                        {props.state !== "all" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/all/Comparison"}>
                                                    Switch to All States
                                                </span>
                                            </a>
                                        )}
                                        {props.city !== "Detroit" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/Detroit/Comparison"}>
                                                    Switch to Detroit
                                                </span>
                                            </a>
                                        )}
                                        {props.city === "Detroit" && (
                                            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                <span onClick={() => window.location.href = "/Comparison"}>
                                                    Switch to Michigan
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </Box>
                            </GridItem>

                            <GridItem xs={9}>
                                <Grid container>
                                    <GridItem xs={12} sm={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid style={{ gap: "50px", flexWrap: "nowrap", marginTop: "25px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item sm={2}>
                                                    <div>
                                                        <label style={{ 'color': 'white', 'font-size': '18px' }}>Range A</label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px", maxWidth: "unset", marginRight: "-50px" }} item sm={4}>
                                                    <div style={{ position: "relative" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.startDateA}
                                                            onChange={function (event, inst) {
                                                                handleStartDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ position: "relative" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.endDateA}
                                                            onChange={function (event, inst) {
                                                                handleEndDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item sm={1}
                                                    style={{
                                                        marginLeft: "0.5em",
                                                        fontSize: "1.4em",
                                                        color: "white",
                                                        fontWeight: "600",
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Button onClick={handleCall} style={{ color: 'black', margin: '0px', height: '37px', fontSize: '12px' }} color="white" size="sm">Submit</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ gap: "50px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item sm={2}>
                                                    <div>
                                                        <label style={{ 'color': 'white', 'font-size': '18px' }}>Range B</label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }} item sm={4}>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.startDateB}
                                                        onChange={function (event, inst) {
                                                            handleStartDateBChange(event.value)
                                                        }}
                                                    />
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.endDateB}
                                                        onChange={function (event, inst) {
                                                            handleEndDateBChange(event.value)
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid style={{ gap: "50px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item sm={2}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}>Presets</label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ width: "220px", maxWidth: "220px", minWidth: "220px" }} item sm={4}>
                                                    <Select
                                                        value={groupDateBy}
                                                        onChange={handleGroupDateByChange}
                                                        SelectDisplayProps={{ 'style': { textAlign: "left", 'background': 'white', 'padding': '7px 65px 7px 10px', 'margin': '0px 0px 10px 0px', 'width': '100%', 'overflow': 'visible' } }}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <MenuItem value="0">Preset Dates</MenuItem>
                                                        <MenuItem value="Year">Last Year</MenuItem>
                                                        <MenuItem value="Month">Last Month</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ gap: "50px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item sm={2}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}></label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ minWidth: "220px", width: "220px", maxWidth: "220px" }} item sm={4}>
                                                    <FilterProjectReleases
                                                        startDate={props.startDateA}
                                                        endDate={props.endDateA}
                                                        onFilterChange={handleFilterChange}
                                                        labelStyle={{ fontSize: "18px", whiteSpace: "nowrap", color: "white", fontWeight: "300" }}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Hidden>
                        <Hidden smUp>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
    <h2
        style={{
            fontFamily: 'GlacialIndifferenceRegular',
            color: "#ffff",
            fontWeight: "normal",
            fontStyle: "normal",
            fontSize: "14px",
            marginLeft: "0px",
            marginBottom: "5px",
            letterSpacing: "0px",
            marginTop: "auto",
            whiteSpace: "normal",
            paddingLeft: "15px",
            textAlign: "center"
        }}>
        {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : (props.state === "all" ? "DASHBOARD - ALL STATES" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : props.city === "Detroit" ? "DETROIT DASHBOARD" : "MICHIGAN DASHBOARD")}
    </h2>
    <div style={{ display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap", fontSize: "12px", paddingBottom: "5px" }}>
        {props.state !== "NJ" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/NJ/Comparison"}>
                Switch to New Jersey
                </span>
            </a>
        )}
        {props.state !== "MI" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/Comparison"}>
                Switch to Michigan
                </span>
            </a>
        )}
        {props.state !== "PA" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/PA/Comparison"}>
                Switch to Pennsylvania
                </span>
            </a>
        )}
        {props.state !== "all" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/all/Comparison"}>
                Switch to All States
                </span>
            </a>
        )}
        {props.city !== "Detroit" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/Detroit/Comparison"}>
                Switch to Detroit
                </span>
            </a>
        )}
        {props.city === "Detroit" && (
            <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>
                <span onClick={() => window.location.href = "/Comparison"}>
                Switch to Michigan
                </span>
            </a>
        )}
    </div>
</Box>
                            <GridItem xs={12} sm={12}>
                                <Grid container>
                                    <GridItem style={{ width: "100%" }} class="compareAuthDiv" xs={12} sm={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid style={{ columnGap: "20px", marginTop: "25px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item xs={1}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}>A</label>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px", maxWidth: "unset" }}>
                                                    <div style={{ position: "relative", height: "70%", paddingBottom: "10px" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.startDateA}
                                                            onChange={function (event, inst) {
                                                                handleStartDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ position: "relative", height: "70%", paddingBottom: "10px" }}>
                                                        <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                        <Datepicker
                                                            controls={['date']}
                                                            display="center"
                                                            touchUi={true}
                                                            inputComponent="input"
                                                            inputProps={inputProps}
                                                            value={props.endDateA}
                                                            onChange={function (event, inst) {
                                                                handleEndDateAChange(event.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <input onClick={handleCall} style={{ height: "37px", fontSize: "18px" }} type="button" value="🔄"></input>
                                                </Grid>
                                                <Grid item xs={2}
                                                    style={{
                                                        marginLeft: "0.2em",
                                                        fontSize: "1.4em",
                                                        color: "white",
                                                        fontWeight: "600",
                                                        justifyContent: "flex-end",
                                                        display: "flex"
                                                    }}
                                                >
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ gap: "20px" }} container justifyContent="space-around">
                                                <Grid style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item xs={1}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}>B</label>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.startDateB}
                                                        onChange={function (event, inst) {
                                                            handleStartDateBChange(event.value)
                                                        }}
                                                    />
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.endDateB}
                                                        onChange={function (event, inst) {
                                                            handleEndDateBChange(event.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ gap: "20px", marginTop: "5px" }} container justifyContent="space-around">
                                                <Grid item xs={1}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}></label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ minWidth: "220px", width: "220px", maxWidth: "220px" }} item xs={4}>
                                                    <Select
                                                        value={groupDateBy}
                                                        onChange={handleGroupDateByChange}
                                                        SelectDisplayProps={{ 'style': { textAlign: "left", 'background': 'white', 'padding': '7px 65px 7px 10px', 'margin': '0px 0px 10px 0px', 'width': '100%', 'overflow': 'visible' } }}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <MenuItem value="0">Preset Dates</MenuItem>
                                                        <MenuItem value="Year">Last Year</MenuItem>
                                                        <MenuItem value="Month">Last Month</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ gap: "20px", marginTop: "5px" }} container justifyContent="space-around">
                                                <Grid item xs={1}>
                                                    <div>
                                                        <label style={{ "color": "white", fontSize: "18px" }}></label>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ minWidth: "220px", width: "220px", maxWidth: "220px" }} item xs={4}>
                                                    <FilterProjectReleases
                                                        startDate={props.startDateA}
                                                        endDate={props.endDateA}
                                                        onFilterChange={handleFilterChange}
                                                        labelStyle={{ fontSize: "18px", whiteSpace: "nowrap", color: "white", fontWeight: "300" }}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Hidden>
                    </GridContainer>
                </Toolbar>
            </AppBar >
            <ReleaseDrawer
                allReleases={allReleases}
                selectedTypes={selectedTypes}
                startDate={formatDate(getMinMaxDates().minDate)}
                endDate={formatDate(getMinMaxDates().maxDate)}
                state={props.state}
            />
        </>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string
};
