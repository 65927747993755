import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox, Input } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// @material-ui/core components
import ReactLoading from 'react-loading';
import Button from "utils/CustomButtons/Button";
import { styled } from '@material-ui/styles';
import { statesData } from "variables/statesData";
import L, { LeafletMouseEvent, Map } from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import LeafletMap from "./LeafletMap";
import LoadingOverlay from "react-loading-overlay";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);

    });
    return matches;
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#33cf4d",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#1d4c80',
        opacity: 1
    },
}));

export default function MapQuotes({ startDate, endDate, ...props }) {
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [data, setData] = React.useState(null);
    const [files, setFiles] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const GetData = () => {
        fetch('../GetPolicyData/GetCoordinatesQuotesCSV?' + new URLSearchParams({ startDate, endDate }), {
            method: 'GET',
        })
            .then(res => res.json())
            .then(res => {
                setData(res);
                setLoading(false);
            });
    };
    const SetData = () => {
        var data = new FormData()
        if (files) {
            data.append('file', files[0])
        } fetch('../GetPolicyData/InsertCoordinatesQuotesCSV', {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then(res => {
                if(res?.result == 'Success'){
                    GetData();
                }else{
                    alert(res?.result)
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }

    }, [props.call]);

    var markers = []
    var heat = []

    if (data) {
        markers = data.map((val, i) => {
            heat.push([val.latitude, val.longitude, 10])
            return {
                info: <div>

                    {/* Policy Number:  {val.policynumber} <br />
                    Claim Number:  {val.claimnumber} <br />
                    Address:   {val.lossloc} <br /> */}
                </div>
                ,
                pos: [val.latitude, val.longitude]
            }
        })
    }
    useEffect(() => GetData(), [])

    try {
        return (
            <div>
                <Grid container>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "1em",
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.6em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "2em",
                                    paddingLeft: "1.9em",
                                    paddingRight: "1em",
                                    paddingTop: "2em",
                                    paddingBottom: `${props.state === "NJ" ? "1em" : "0"}`,
                                    fontSize: "1.8em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                {/* <ReactLoading type={'spin'} style={call ? { fill: "black", height: 70, width: 70, } : { display: "none" }} height={isMobile ? 70 : 70} width={isMobile ? 70 : 70} /> */}
                                Quotes Map
                            </span>
                        </span>
                        <br />
                        <br />
                        <LeafletMap
                            position={{ lat: 42.3667, lng: -83.0436, zoom: 11 }}
                            polygons={[{ pathOptions: { fillColor: 'blue' }, positions: statesData["Michigan"] }]}
                            markers={markers}
                            heat={heat}
                            boolMark={false}
                        />
                        {/* {props.state !== "NJ" && <IOSSwitch
                        checked={toggle}
                        onChange={handleToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} */}

                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ position: "relative", marginTop: "1em" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow
                            >
                                <TableCell align="right">
                                    <form >
                                        <Input type="file" onChange={(e) => { setFiles(e.target.files) }}></Input>
                                        <Button onClick={SetData} style={{ color: 'white', margin: '20px', backgroundColor: '#ff8000' }} color="primary" size="md">Upload</Button>
                                    </form>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <LoadingOverlay
                    active={loading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "absolute!important"
                        })
                    }}
                    spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                >
                    {/* <QuotesTableView
                                data={data}
                                groupDateBy={props.groupDateBy}
                            /> */}
                </LoadingOverlay>
            </div >
        );

    } catch (error) {
        console.log(error)
        return <>
        </>
    }
}
