import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const ReleaseDrawer = ({ allReleases, selectedTypes, startDate, endDate, state }) => {
    const [open, setOpen] = useState(false);
    const [filteredReleases, setFilteredReleases] = useState([]);

    useEffect(() => {
        console.log('All Releases:', allReleases);
        console.log('Selected Types:', selectedTypes);
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);
        console.log("State", state)
        console.log('Filtered Releases:', filteredReleases);
    }, [allReleases, selectedTypes, startDate, endDate, filteredReleases]);

    useEffect(() => {
        const filtered = allReleases.filter(release => {
            const releaseDate = new Date(release.ReleaseDate.split('-').join('/'));
            const startDateObj = new Date(startDate.split('-').join('/'));
            const endDateObj = new Date(endDate.split('-').join('/'));
            const stateMatch = state.toLowerCase() === 'all' || 
                               release.Territory.toLowerCase() === 'all' || 
                               release.Territory.toLowerCase().split(/[,\/]/).map(s => s.trim()).includes(state.toLowerCase());
            
            return (
                releaseDate >= startDateObj &&
                releaseDate <= endDateObj &&
                selectedTypes.includes(release.Category) &&
                stateMatch
            );
        });
        setFilteredReleases(filtered);
    }, [allReleases, selectedTypes, startDate, endDate, state]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '30px',
                    backgroundColor: 'white',
                    borderTop: '1px solid black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
                onClick={toggleDrawer}
            >
                <IconButton>
                    {open ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
            </div>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer}
                PaperProps={{
                    style: {
                        maxHeight: '50%',
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderBottom: 'none',
                    },
                }}
            >
                <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>Release Date</TableCell>
                                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>Territory</TableCell>
                                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>Category</TableCell>
                                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>Release Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredReleases.map((release, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ verticalAlign: 'top', whiteSpace: 'nowrap' }}>{release.ReleaseDate}</TableCell>
                                    <TableCell style={{ verticalAlign: 'top' }}>{release.Territory}</TableCell>
                                    <TableCell style={{ verticalAlign: 'top' }}>{release.Category}</TableCell>
                                    <TableCell style={{
                                        whiteSpace: 'pre',
                                        overflowX: 'auto',
                                        overflowY: 'hidden',
                                        maxWidth: '100%',
                                        boxSizing: 'border-box',
                                        verticalAlign: 'top',
                                        fontSize: '14px'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'flex-start',
                                        }}>
                                            {release.ReleaseDescription.split('\n').map((paragraph, index) => (
                                                <p key={index} style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    textAlign: 'left',
                                                    fontSize: '14px',
                                                    whiteSpace: 'nowrap',
                                                    WebkitTextSizeAdjust: '100%'
                                                }}>
                                                    {paragraph}
                                                </p>
                                            ))}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Drawer>
        </>
    );
};

export default ReleaseDrawer;