import React from "react";
import ReactDomServer from 'react-dom/server';
import { Dynamic_Style } from "components/all/Dynamic_Style";
import { Grid } from "@material-ui/core";
import { BannerBox, BannerTitle } from "utils/DynamicComponents/LandingComponents";
import { DeviceContext } from 'utils/Device/DeviceContext';
import separator from "assets/img/Separator.png";

export default function TotalBanner(props) {
    const device = React.useContext(DeviceContext);

    // data
    let total = props.total && (props.total.policies || props.total.quotes || props.total) || 0;
    let totalBindPercent = props.total && props.total.bindPercent;
    let data = props.data || [];

    if (props.data) {
        if (data.length == 0) {
            if (props.type === "policies")
                data = [{ policyType: "Online", policies: 0 }, { policyType: "CRC", policies: 0 }, { policyType: "Combo", policies: 0 }];
            else if (props.getQuoteType)
                data = [{ quoteType: "Quick", quotes: 0 }, { quoteType: "Custom", quotes: 0 }, { quoteType: "CRC", quotes: 0 }];
            else
                data = [{ quoteType: "Online", quotes: 0 }, { quoteType: "CRC", quotes: 0 }];
        }
        else if (!props.total) {
            data.forEach((x) => {
                total += (x.policies || x.quotes || 0);
                if (x.bindPercent)
                    totalBindPercent = (totalBindPercent || 0) + x.bindPercent;
            });
        }
    }

    // ytd
    let totalytd = props.totalytd || 0;
    let ytd = props.ytd || [];

    if (props.ytd) {
        if (ytd.length == 0) {
            data.forEach((x) => {
                ytd.push({ quoteType: (x.quoteType || x.policyType), quotes: 0 });
            });
        }
        else if (!props.totalytd) {
            ytd.forEach((x) => {
                totalytd += (x.policies || x.quotes || 0);
            });
        }
    }

    const colSize = 12 / (data.length + 1);
    const lgContainer = colSize == 12 ? 1 : 4;

    return (
        <div>
            <Grid container spacing={0} style={{ marginTop: "2em", marginLeft: "1em", marginRight: "1em" }}>
                <BannerTitle
                    CustomHeader={props.title}
                    SubHeader={totalBindPercent && "(BIND %)"}
                    SubHeaderPopover={totalBindPercent && "Percentage of policies bound to-date, based on the specific quotes from each applicable time period."}
                />
                <Grid container lg={lgContainer} xs={11} style={Dynamic_Style["BlueContainer_" + device.type]}>
                    <BannerBox
                        Dotted={data.length > 0} ColumnSize={colSize}
                        Heading={"TOTAL"}
                        Data={total}
                        Footer={totalBindPercent}
                    />
                    {data.map((row) =>
                        <BannerBox
                            ColumnSize={colSize}
                            Heading={row.policyType || row.quoteType}
                            Data={row.policies || row.quotes}
                            Footer={totalBindPercent && row.bindPercent}
                            Popover={row.policyType === "Combo" && "Policies quoted online initially then bound by CRC."}
                        />
                    )}
                </Grid>
                {(props.data || props.ytd) && (
                    <Grid container lg={8 - lgContainer} xs={11} style={Dynamic_Style["BlueContainer_" + device.type]}>
                        <img
                            src={separator} width="100%"
                            style={Dynamic_Style["SeparatorWith" + (totalBindPercent ? "" : "Out") + "SubHeader_" + device.type]}
                            alt="..."
                        />
                        <span style={Dynamic_Style["YTDLabelWith" + (totalBindPercent ? "" : "Out") + "SubHeader_" + device.type]}>
                            YTD
                        </span>
                        <BannerBox
                            Dotted={ytd.length > 0} ColumnSize={colSize}
                            Heading={"TOTAL"}
                            Data={totalytd.policies || totalytd.quotes || totalytd}
                        />
                        {ytd.map((row) =>
                            <BannerBox
                                ColumnSize={colSize}
                                Heading={row.policyType || row.quoteType}
                                Data={row.policies || row.quotes}
                                Popover={row.policyType === "Combo" && "Policies quoted online initially then bound by CRC."}
                            />
                        )}
                    </Grid>
                ) || (
                    <Grid container lg={8 - lgContainer} xs={11} style={{ ...Dynamic_Style["BlueContainer_" + device.type], ...{ backgroundColor: "unset" } }}></Grid>
                )}
            </Grid>
        </div>
    );
}
