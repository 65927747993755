import React from "react";
import Button from "utils/CustomButtons/Button";

const ExportToCVSButton = (props) =>
{
    const handleExport = (event) => {
        //Selecting the table above the export button
        var button;
        var tableDiv;
        if (event.target.tagName === 'BUTTON') {
            button = event.target;
        } else {
            button = event.target.closest('button');
        }
        var tableDiv = button;
        while (tableDiv !== null && (tableDiv.tagName !== 'DIV' || tableDiv.classList.contains('MuiTablePagination-root'))) {
            tableDiv = tableDiv.previousElementSibling;
        }
        var table = tableDiv.querySelector('table');

        //If table exists export it
        if (table) {
            var csv = [];
            var rows = table.getElementsByTagName('tr');
            // Iterate over the rows and cells, extracting the data
            for (var i = 0; i < rows.length; i++) {
                var row = [], cols = rows[i].querySelectorAll('td, th');

                for (var j = 0; j < cols.length; j++)
                    row.push('"' + cols[j].innerText + '"');

                csv.push(row.join(','));
            }
            // Create a data URL for the CSV content
            var csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.join('\n'));
            // Create a temporary link element and trigger the download
            var link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', props.ExportName + '_' + new Date().toLocaleDateString() + '.csv');
            link.click();
        }
    };
    return (
        <Button onClick={handleExport} style={{ float: 'right', backgroundColor: '#0275d8' }} size="sm"><i class="fa fa-download" aria-hidden="true"></i>&nbsp;Export</Button>
    );
};

export default ExportToCVSButton;