/// <reference path="../components/quotesdma.jsx" />
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
// core components
import MainNavbar from "utils/Navbars/MainNavbar.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Button from "utils/CustomButtons/Button";

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";
import Cancellations_PolicyCount from "components/Cancellations_PolicyCount";
import Cancellations_Premiums from "components/Cancellations_Premiums";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    const [inforcedata, setInForceData] = React.useState([]);
    const [canceldata, setCancelData] = React.useState([]);
    const [renewaldata, setRenewalData] = React.useState([]);

    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    //Event Handlers
    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        setCall("true");
    };
    //const changeGroupDateBy = (selected) => {
    //    setGroupDateBy(selected);
    //};
    //Events End

    //State Variables 
    var date = new Date();
   // var startdate = date.setDate(date.getDate() - 32);
   //startDate = Date.
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [call, setCall] = React.useState(false);


    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {
        async function func1() {
            //disable scrolling when page is loading
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            if (call == "true" || call == null) {
                setInForceData([]);
                setCancelData([]);
                setRenewalData([]);

                RefreshCancel();
            }       
        }
        func1();

    }, [call]);

    const RefreshCancel = () => {
        fetch('../GetCancellationData/PolicyCancel?' + new URLSearchParams({
            startDate: startDate,
            endDate: endDate,
            state: "NJ"
        }))
            .then(res => res.json())
            .then(data => obj = data)
            .then(() => {
                setInForceData(obj.inForceData[0]);
                setCancelData(obj.cancellationData[0]);
                //setRenewalData(obj.nonRenewalData[0]);
                setCall("false");
            });
    };

    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }

 

    //Utility functions End
    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <MainNavbar state="NJ" onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} startDate={startDate} endDate={endDate} Text={"Michigan Dashboard"} {...rest} />
                    <div>
                    <Cancellations_PolicyCount
                        InForceTotal={inforcedata.inForceTotal}
                        InForceTotalGWP={inforcedata.inForceTotalGWP}
                        TotalCancelPolicies={inforcedata.totalCancelPolicies}
                        TotalCancelPoliciesGWP={inforcedata.totalCancelPoliciesGWP}
                        TotalCancelPoliciesPer={inforcedata.totalCancelPoliciesPer}
                        TotalCancelPoliciesGWPPer={inforcedata.totalCancelPoliciesGWPPer}
                        TotalCancelReinstate={inforcedata.totalCancelReinstate}
                        TotalCancelReinstateGWP={inforcedata.totalCancelReinstateGWP}
                        TotalCancelPoliciesNetPer={inforcedata.totalCancelPoliciesNetPer}
                        TotalCancelPoliciesGWPNetPer={inforcedata.totalCancelPoliciesGWPNetPer}

                        RenewalsReady={inforcedata.renewalsReady}
                        RenewalsReadyGWP={inforcedata.renewalsReadyGWP}
                        RenewalsNonRenewed={canceldata.renewalsNonRenewed}
                        RenewalsNonRenewedGWP={canceldata.renewalsNonRenewedGWP}
                        RenewalsNonRenewedPer={canceldata.renewalsNonRenewedPer}
                        RenewalsNonRenewedGWPPer={canceldata.renewalsNonRenewedGWPPer}
                        RenewalsReinstated={canceldata.renewalsReinstated}
                        RenewalsReinstatedGWP={canceldata.renewalsReinstatedGWP}
                        RenewalsNonRenewedNetPer={canceldata.renewalsNonRenewedNetPer}
                        RenewalsNonRenewedGWPNetPer={canceldata.renewalsNonRenewedGWPNetPer}
                        

                        call={call}
                        />
                    <Cancellations_Premiums

                        InForceFTPolicies={inforcedata.inForceFTPolicies}
                        InForceFTPoliciesGWP={inforcedata.inForceFTPoliciesGWP}
                        CancelFTPolicies={canceldata.cancelFTPolicies}
                        CancelFTPoliciesGWP={canceldata.cancelFTPoliciesGWP}
                        CancelFTPoliciesPer={canceldata.cancelFTPoliciesPer}
                        CancelFTPoliciesGWPPer={canceldata.cancelFTPoliciesGWPPer}
                        CancelFTReinstate={canceldata.cancelFTReinstate}
                        CancelFTReinstateGWP={canceldata.cancelFTReinstateGWP}
                        CancelFTPoliciesNetPer={canceldata.cancelFTPoliciesNetPer}
                        CancelFTPoliciesGWPNetPer={canceldata.cancelFTPoliciesGWPNetPer}

                        InForceRenewals={inforcedata.inForceRenewals}
                        InForceRenewalsGWP={inforcedata.inForceRenewalsGWP}
                        CancelRenewals={canceldata.cancelRenewals}
                        CancelRenewalsGWP={canceldata.cancelRenewalsGWP}
                        CancelRenewalsPoliciesPer={canceldata.cancelRenewalsPoliciesPer}
                        CancelRenewalsGWPPer={canceldata.cancelRenewalsGWPPer}
                        CancelRenewalsReinstate={canceldata.cancelRenewalsReinstate}
                        CancelRenewalsReinstateGWP={canceldata.cancelRenewalsReinstateGWP}
                        CancelRenewalsPoliciesNetPer={canceldata.cancelRenewalsPoliciesNetPer}
                        CancelRenewalsGWPNetPer={canceldata.cancelRenewalsGWPNetPer}

                        

                        call={call}
                    />

                    </div>
            </div>
        </div>
    );
}