import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "utils/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { styled } from '@material-ui/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { statesData } from "variables/statesData";
import L, { LeafletMouseEvent, Map } from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CustomDropdown from "utils/CustomDropdown/CustomDropdown";
import CustomInput from "utils/CustomInput/CustomInput";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3/lib";
import MarkerClusterGroup from "react-leaflet-cluster";
import LeafletMap from "./LeafletMap";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#33cf4d",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#1d4c80',
        opacity: 1
    },
}));

export default function MapPolicyHolders({ state, ...props }) {
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const chartStyles = makeStyles(styles);
    const chartClasses = chartStyles();
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [toggle, setToggle] = React.useState(true);
    const GetData = () => {
        fetch('../GetPolicyData/GetCoordinatesPolicyData?' + new URLSearchParams({
            state,
        }))
            .then(res => res.json())
            .then(res => setData(res))
    };
    const [data, setData] = React.useState(null);

    useEffect(() => {
        // alert(props.call)
        // if (props.call == "true" || props.call == null) {
            GetData();
        // }

    }, [props.call]);
    const handleToggle = (val) => {
        setToggle(!toggle);
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                labels: {
                    font: {
                        size: "20"
                    }
                }
            },
            title: {
                display: "none",
                fullSize: false
            }
        }
    };
    var markers = []
    var heat = []

    if (data) {
        markers = data.result?.filter((val) => {
            return val.latitude != null && val.longitude != null
        }).map((val, i) => {
            heat.push([val.latitude, val.longitude, 10])
            return {
                info: <div>
                    Population:   {val.population} <br />
                    Zip Code:    {val.zipcode} <br />
                    Latitude:   {val.latitude} <br />
                    Longitude:  {val.longitude} <br />
                </div>
                ,
                pos: [val.latitude, val.longitude]
            }
        })
    }

    var statesKeys = {
        MI: "Michigan",
        NJ: "New Jersey"
    }
    var statesPos = {
        MI: { lat: 44.182205, lng: -84.506836, zoom: 7 },
        NJ: { lat: 40.2544, lng: -74.4437, zoom: 8 }
    }
    const gradient = {
        0.20: '#1890ff',
        0.40: 'lime',
        0.60: 'yellow',
        0.80: 'orange',
        1.0: 'red',
    };

    try {
        return (
            <div>
                <Grid container>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "1em",
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.6em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "2em",
                                    paddingLeft: "1.9em",
                                    paddingRight: "1em",
                                    paddingTop: "2em",
                                    paddingBottom: `${props.state === "NJ" ? "1em" : "0"}`,
                                    fontSize: "1.8em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                POLICY HOLDERS
                            </span>
                        </span>
                        <br />
                        <br />
                        <LeafletMap
                            position={statesPos[state]}
                            polygons={[{ pathOptions: { fillColor: 'white' }, positions: statesData[statesKeys[state]] }]}
                            markers={markers}
                            heat={heat}
                            boolMark={false}
                            state={state}
                            gradient={gradient}
                        />

                        {/* {props.state !== "NJ" && <IOSSwitch
                        checked={toggle}
                        onChange={handleToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} */}

                    </Grid>
                </Grid>
            </div>
        );

    } catch (error) {
        console.log(error)
        return <>
        </>
    }
}
