import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox, Box, Button } from '@mui/material';
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarCustom.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import MapAutoAppraisal from "components/MapAutoAppraisal";
import { CallAPI } from "services/all/API";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

function DropdownFilter(props) {
    const isMobile = useMediaQuery('(max-width: 500px)');
    const selected = props.options.filter(x => x.selected);
    const value = selected.length === props.options.length ? [...selected.map(x => x.value), props.selectAll] : selected.map(x => x.value);

    return (
        <FormControl variant="filled" color={"warning"} sx={{ m: 1, width: props.width || (isMobile ? 'calc(50% - 16px)' : 190) }} size="small">
            <InputLabel id={`${props.id}-label`} color={"warning"} style={{ fontSize: isMobile ? "1.3em" : "21px" }} shrink={true}>{props.label}</InputLabel>
            <Select
                labelId={`${props.id}-label`} id={props.id} multiple
                value={value} onChange={(event, option) => props.onChange(event, props.id, option)} onClose={props.onClose}
                renderValue={(selected) => `${selected.filter(x => x !== props.selectAll).length} selected`} displayEmpty={true}
                SelectDisplayProps={{ 'style': { 'background': 'white', 'fontSize': isMobile ? '0.7em' : '12px' } }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 450,
                            width: props.width || (isMobile ? 'calc(50% - 16px)' : 190),
                        },
                    },
                    MenuListProps: {
                        style: {
                            padding: 0,
                        },
                        autoFocus: true,
                        variant: 'menu',
                    },
                }}
            >
                {props.selectAll &&
                    <MenuItem key={-1} value={props.selectAll} style={{ textWrap: 'wrap' }} dense autoFocus>
                        <Checkbox
                            checked={selected.length === props.options.length} sx={{ paddingLeft: "0px" }}
                            indeterminate={selected.length > 0 && selected.length < props.options.length}
                        />
                        <ListItemText primary={props.selectAll} />
                    </MenuItem>
                }
                {props.options.map((option, index) => (
                    <MenuItem key={index} value={option.value} style={{ textWrap: 'wrap' }} dense>
                        <Checkbox checked={option.selected} sx={{ paddingLeft: "0px" }} />
                        <ListItemText primary={(props.formatOptionsText && props.formatOptions(option.value)) || option.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default function AutoAppraisal(props) {
    // constants
    const classes = useStyles();
    const newFilters = React.useRef(null);
    const selectAll = 'Select All';

    // state variables
    const [activeLoads, setActiveLoads] = React.useState(0);
    const [filters, setFilters] = React.useState(null);
    const [status, setStatus] = React.useState('Loading');
    const [submitted, setSubmitted] = React.useState(false);
    
    // functions
    const formatFilters = (object, formatFn) => {
        let result = { ...object };
        if (object) {
            for (var key in object)
                if (object.hasOwnProperty(key))
                    result[key] = formatFn(key, object[key]);
        }
        return result;
    };
    const loadFilters = async (request) => {
        setActiveLoads((prev) => prev + 1);

        let result = await CallAPI({
            method: "POST",
            url: `/GetPolicyData/GetAutoAppraisalFilters`,
        }, request);

        if (status === 'Loading') {
            setFilters(formatFilters(result, (k, v) => v.map(x => ({ value: x, selected: true })))); // set dropdown options
            setSubmitted(true); // submit form
            setStatus('Ready');
        } else {
            newFilters.current = result; // set temporary dropdown options
        }

        setActiveLoads((prev) => prev - 1);
    };
    const onChangeDropdown = (event, id, option) => {
        if (filters[id].length === 0)
            return;

        let newOptions = filters[id].slice();
        if (option.props.value === selectAll) {
            const selected = event.target.value.includes(selectAll);
            newOptions.map((item) => item.selected = selected);
        } else {
            newOptions.find((item) => item.value === option.props.value).selected = !newOptions.find(
                (item) => item.value === option.props.value
            ).selected;
        }

        const newValue = { ...filters, [id]: newOptions };
        setFilters(newValue);
        loadFilters(formatFilters(newValue, (k, v) => {
            const selected = v.filter(x => x.selected).map(x => x.value);

            if (selected.length === 0)
                return ['none'];
            else if (selected.length === v.length)
                return [...selected, selectAll];
            else
                return selected;
        }));
    };
    const onCloseDropdown = () => {
        setStatus('Updating');
    };

    // effects
    React.useEffect(() => {
        // page init
        loadFilters({
            fileType: [selectAll],
            moI_InspectionType: [selectAll],
            fileAppraiserName: [selectAll],
            estimateMonth: [selectAll],
        });
    }, []);
    React.useEffect(() => {
        if (status === 'Updating' && activeLoads === 0 && newFilters.current) {
            // update dropdown options
            setStatus('Ready');
            setFilters((prevState) => formatFilters(prevState, (k, v) => {
                const selected = v.filter(x => x.selected).map(x => x.value);
                return newFilters.current[k].map(x => ({ value: x, selected: selected.includes(x) || v.every(x => x.selected) }));
            }));
        }
    }, [activeLoads, status]);

    try {
        return filters && (
            <div>
                <div className={classes.wrapper}>
                    <AuthNavbar
                        color="primary"
                        brandText="Michigan Auto Appraisal"
                        title="CCC Auto Appraisal Heat Map"
                        styles={{
                            Header_XS_Heading: { whiteSpace: "normal", textAlign: "right" },
                            Header_SM_Heading: { whiteSpace: "normal", textAlign: "center" },
                            Header_MD_Heading: { whiteSpace: "normal", textAlign: "center" },
                            Header_LG_Heading: { whiteSpace: "normal", textAlign: "center" },
                            Header_XL_Heading: { whiteSpace: "normal", textAlign: "center" }
                        }} // overwrites the values in utils\Navbars\MainNavbar_Styles.js
                        {...props}
                    >
                        <Grid item xs={12}>
                            <Box display="flex" justifycontent="center" alignItems="center">
                                <div style={{ margin: "auto" }}>
                                    <DropdownFilter
                                        label="File Type"
                                        id="fileType"
                                        options={filters.fileType}
                                        selectAll={selectAll}
                                        onChange={onChangeDropdown}
                                        onClose={onCloseDropdown}
                                    />
                                    <DropdownFilter
                                        label="MOI Inspection Type"
                                        id="moI_InspectionType"
                                        options={filters.moI_InspectionType}
                                        selectAll={selectAll}
                                        onChange={onChangeDropdown}
                                        onClose={onCloseDropdown}
                                    />
                                    <DropdownFilter
                                        label="File Appraiser Name"
                                        id="fileAppraiserName"
                                        options={filters.fileAppraiserName}
                                        selectAll={selectAll}
                                        onChange={onChangeDropdown}
                                        onClose={onCloseDropdown}
                                    />
                                    <DropdownFilter
                                        label="Estimate Month"
                                        id="estimateMonth"
                                        options={filters.estimateMonth}
                                        selectAll={selectAll}
                                        onChange={onChangeDropdown}
                                        onClose={onCloseDropdown}
                                    />
                                    <Button onClick={() => setSubmitted(true)} variant="contained" sx={{ mx: 1, my: 1.6 }} color="warning">Submit</Button>
                                </div>
                            </Box>
                        </Grid>
                    </AuthNavbar>
                    <div>
                        <Grid container>
                            <Grid item lg={12} xs={12}>
                                <MapAutoAppraisal
                                    filters={formatFilters(filters, (k, v) => v.filter(x => x.selected).map(x => x.value))}
                                    submitted={submitted}
                                    onMapLoaded={() => setSubmitted(false)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        console.log(error);
        return <></>;
    }
}
