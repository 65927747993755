import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import AuthNavbar from "utils/Navbars/AuthNavbarNoSearchAllStates.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import PaginatedTable from "components/PaginatedTable";
import { Grid, Hidden, Container } from "@material-ui/core";
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function SharedDriveData(props) {
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [cookies, setCookie] = useCookies(['auth']);
    const [tableNames, setTableNames] = useState(["CustomerTrends", "SampleData", "MI_GWP", "NJ_GWP", "Quotes", "JulyBinds"]);
    const [tableData, setTableData] = useState({});
    const [queryResult, setQueryResult] = useState({});
    const [queryResultLoading, setQueryResultLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [call, setCall] = useState(false);
    const classes = useStyles();
    const wrapper = React.createRef();
    const history = useHistory();

    if (cookies.pass === undefined) {
        history.push('/');
    }

    const getPlaceholderMeta = (tableName) => {
        switch (tableName) {
            case "AgentData":
                return [
                    { "name": "AgentID", "type": "Int32" },
                    { "name": "CustomerAvailable", "type": "Int32" },
                    { "name": "InboundCallAvgHandleTime", "type": "String" },
                    { "name": "InboundCallHandled", "type": "Int32" },
                    { "name": "InboundCallProductivityRatio", "type": "Int32" },
                    { "name": "OutboundCallHandled", "type": "Int32" },
                    { "name": "TotalHandleTime", "type": "Int32" },
                    { "name": "TotalIdle", "type": "Int32" },
                    { "name": "TotalIdleTime", "type": "Int32" },
                    { "name": "TotalOnlineTime", "type": "Int32" }
                ];
            case "CustomerTrends":
                return [
                    { "name": "Age", "type": "Int16" },
                    { "name": "Category", "type": "String" },
                    { "name": "Color", "type": "String" },
                    { "name": "Gender", "type": "String" },
                    { "name": "Location", "type": "String" },
                    { "name": "Season", "type": "String" },
                    { "name": "Size", "type": "String" },
                    { "name": "DiscountApplied", "type": "String" }
                ];
            default:
                return [];
        }
    };

    const fetchTableData = () => {
        setLoading(true);
        fetch('../GetSharedDriveData/FetchMultiple', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(tableNames)
        })
            .then(res => res.json())
            .then(data => {
                let fetchedData = {};
                data.data.forEach(response => {
                    fetchedData[response.tableName] = {
                        data: response.data,
                        meta: response.meta
                    };
                });
                setTableData(fetchedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchQueryResults = () => {
        setQueryResultLoading(true);
        fetch('../GetSharedDriveData/ExecuteSavedQueries', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                let fetchedData = {};
                console.log(data)
                data.forEach(response => {
                    fetchedData[response.tableName] = {
                        data: response.data,
                        meta: response.meta,
                        error: response.error,
                        errorMessage: response.errorMessage
                    };
                });
                setQueryResult(fetchedData);
                setQueryResultLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setQueryResultLoading(false);
            });
    };

    useEffect(() => {
        fetchTableData();
        fetchQueryResults();
    }, []);

    return (
        <div className={classes.wrapper} ref={wrapper}>
            <AuthNavbar onChangeCall={() => setCall(true)} {...props} />
            <Grid item xs={12} sm={12}>
                <Hidden smDown>
                    <h1 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)", fontSize: "2.7em" }} >SHARED DRIVE DATA - QUERY GOOGLE SHEETS</h1>
                </Hidden>
                <Hidden mdUp>
                    <h3 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)" }} >SHARED DRIVE DATA - QUERY GOOGLE SHEETS</h3>
                </Hidden>
                <Container>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        padding: "1em",
                                        paddingRight: "1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        minWidth: "500px",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        paddingTop: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <LoadingOverlay
                                active={queryResultLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        position: "absolute!important"
                                    })
                                }}
                                spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                            >
                                {!queryResultLoading && queryResult && Object.keys(queryResult).length > 0 ? (
                                    Object.keys(queryResult).map(tableName => (
                                        <div style={{marginBottom: "30px"}} key={tableName}>
                                            <span style={{ marginBottom: "25px", display: "block" }}>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        color: "#1d4c80"
                                                    }}
                                                >
                                                    {tableName}
                                                </span>
                                            </span>
                                            {queryResult[tableName].error ? (
                                                <div style={{
                                                    backgroundColor: '#f8d7da',
                                                    color: '#721c24',
                                                    border: '1px solid #f5c6cb',
                                                    borderRadius: '4px',
                                                    padding: '1em',
                                                    marginBottom: '1em',
                                                    textAlign: 'left',
                                                    fontSize: '18px',
                                                    lineHeight: '22px'
                                                }}>
                                                    <strong>Error:</strong> {queryResult[tableName].errorMessage}
                                                </div>
                                            ) : (
                                                <>
                                                    <PaginatedTable
                                                        data={queryResult[tableName].data}
                                                        meta={queryResult[tableName].meta}
                                                    />
                                                    <ExportButton
                                                        ExportName={tableName}
                                                    />
                                                </>
     
                                                
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <PaginatedTable
                                        data={[]}
                                        meta={[]}
                                    />
                                )}
                            </LoadingOverlay>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    {tableNames.map(tableName => (
                        <div key={tableName}>
                            <Grid container>
                                <Grid item
                                    style={
                                        isMobile ?
                                            {
                                                padding: "1em",
                                                paddingRight: "1em",
                                                width: "100%",
                                                paddingTop: "1em",
                                                backgroundColor: "#f1f1f1",
                                                fontSize: "1.3em",
                                                position: "relative",
                                                textAlign: "center"
                                            }
                                            :
                                            {
                                                backgroundColor: "#f1f1f1",
                                                minWidth: "500px",
                                                width: "100%",
                                                margin: "1em",
                                                padding: "1em",
                                                paddingTop: "1em",
                                                fontSize: "2.5em",
                                                position: "relative",
                                                textAlign: "center"
                                            }
                                    }
                                >
                                    <span>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "#1d4c80"
                                            }}
                                        >
                                            {tableName}
                                        </span>
                                    </span>

                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                position: "absolute!important"
                                            })
                                        }}
                                        spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                                    >
                                        {!loading && tableData[tableName] ? (
                                            <PaginatedTable
                                                data={tableData[tableName].data}
                                                meta={tableData[tableName].meta}
                                            />
                                        ) : (
                                            <PaginatedTable
                                                data={[]}
                                                meta={getPlaceholderMeta(tableName)}
                                            />
                                        )}
                                    </LoadingOverlay>
                                    <ExportButton
                                        ExportName={tableName}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Container>
            </Grid>
        </div>
    );
}