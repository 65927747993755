import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
// core components
import AuthNavbar from "utils/Navbars/AuthNavbarComparison.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import { useCookies } from 'react-cookie';
/*import "../assets/css/loader.css";*/
import { useHistory } from "react-router-dom";
import MapStolenVehicles from "components/MapStolenVehicles";


const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);


    //Event Handlers

    const changeCall = () => {
        setCall("true");
    };

    //Events End

    //State Variables 
    var today = new Date();
    var yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
    var tomorrow = new Date(); tomorrow.setDate(tomorrow.getDate() + 1);
    var twodays = new Date(); twodays.setDate(twodays.getDate() - 2);
    var threedays = new Date(); threedays.setDate(threedays.getDate() - 3);


    //State Variables 
    var date = new Date();
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());

    const [call, setCall] = React.useState(false);

    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    //When user refresh page or submit new dates, get all the data
    React.useEffect(() => {

        //disable scrolling when page is loading
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        //document.body.style.overflow = "hidden";
        //document.body.style.height = "100%";
        if (call == "true" || call == null) {
            //setQuoteTotalPip(null);
            //Refresh();
        }


        return () => {
            //End
            setCall("false");
            //Enable scrolling when page is finished loading
            //document.body.style.overflow = "auto";
            //document.body.style.height = "auto";
        }
    }, [call]);


    //Utility functions
    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    //Utility functions End
    return (
        <div>
            <div className={classes.wrapper} ref={wrapper}>
            <AuthNavbar state="MI" onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} startDate={startDate} endDate={endDate} call={call} Text={"Michigan Dashboard"} {...rest} />
                <div style={isMobile ? {} : { paddingRight: "17px" }}>

                    <Grid container>
                        <Grid item lg={12} xs={12}>
                            <MapStolenVehicles />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}