import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox, Input } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from 'react-loading';
import Button from "utils/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { styled } from '@material-ui/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { statesData } from "variables/statesData";
import L, { LeafletMouseEvent, Map } from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CustomDropdown from "utils/CustomDropdown/CustomDropdown";
import CustomInput from "utils/CustomInput/CustomInput";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3/lib";
import MarkerClusterGroup from "react-leaflet-cluster";
import LeafletMap from "./LeafletMap";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);

    });
    return matches;
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#33cf4d",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#1d4c80',
        opacity: 1
    },
}));

export default function PolicyHolderMap(props) {
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [data, setData] = React.useState(null);
    const [files, setFiles] = React.useState(null);
    const [call, setCall] = React.useState(false);
    const GetData = () => {
        var data = new FormData()
        if (!call) {
            if (files) {
                data.append('file', files[0])
            }
            setCall(true)
            fetch('../GetPolicyData/GetCoordinatesStolenVehicles', {
                method: 'POST',
                body: data
            })
                .then(res => res.json())
                .then(res => { setCall(false); setData(res); })
        }
    };

    var markers = []
    var heat = []

    if (data) {
        console.log("data", data)
        markers = data.map((val, i) => {
            heat.push([val.latitude, val.longitude, 10])
            return {
                info: <div>

                    Policy Number:  {val.policynumber} <br />
                    Claim Number:  {val.claimnumber} <br />
                    Address:   {val.lossloc} <br />
                </div>
                ,
                pos: [val.latitude, val.longitude]
            }
        })
    }
    useEffect(() => GetData(), [])
    console.log("Markers", markers)

    try {
        return (
            <div>
                <Grid container>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "1em",
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.6em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "2em",
                                    paddingLeft: "1.9em",
                                    paddingRight: "1em",
                                    paddingTop: "2em",
                                    paddingBottom: `${props.state === "NJ" ? "1em" : "0"}`,
                                    fontSize: "1.8em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                <ReactLoading type={'spin'} style={call ? { fill: "black", height: 70, width: 70, } : { display: "none" }} height={isMobile ? 70 : 70} width={isMobile ? 70 : 70} />
                                STOLEN VEHICLES
                            </span>
                        </span>
                        <br />
                        <br />
                        <LeafletMap
                            position={{ lat: 42.3667, lng: -83.0436, zoom: 11 }}
                            polygons={[{ pathOptions: { fillColor: 'blue' }, positions: statesData["Michigan"] }]}
                            markers={markers}
                            heat={heat}
                        />
                        {/* {props.state !== "NJ" && <IOSSwitch
                        checked={toggle}
                        onChange={handleToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} */}

                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ position: "relative", marginTop: "1em" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow
                            >
                                <TableCell align="right">
                                    <form >
                                        <Input type="file" onChange={(e) => { setFiles(e.target.files) }}></Input>
                                        <Button onClick={GetData} style={{ color: 'white', margin: '20px', backgroundColor: '#ff8000' }} color="primary" size="md">Upload</Button>
                                    </form>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
        );

    } catch (error) {
        console.log(error)
        return <>
        </>
    }
}
