import React from "react";
import TableWithExportButton from "utils/Table/TableWithExportButton";

export default function PreviouslyInsured(props) {
    const typePlural = props.type == "Quote" ? "Quotes" : "Policies";
    const number = new Intl.NumberFormat('en-US');

    // Ensure data is always an array
    const data = Array.isArray(props.data) ? props.data : [];

    // Create table
    const title = typePlural.toUpperCase() + " - PREVIOUSLY INSURED";

    const headers = [[
        props.type + " Type",
        typePlural,
        "Yes (%)",
        "No (%)",
        "Not Answered (%)"
    ]];

    const rows = data.map((row) => [
        (row.policyType || row.quoteType) === "Total" ? "TOTAL" : row.policyType || row.quoteType,
        number.format(row.policies || row.quotes || 0),
        number.format(row.previouslyInsured_YesPercent || 0) + "%",
        number.format(row.previouslyInsured_NoPercent || 0) + "%",
        number.format(row.previouslyInsured_NotAnsweredPercent || 0) + "%"
    ]);
    
    if (props.comments?.length > 0) {
        headers.push('');
        rows.map(x => {
            let comment = props.comments.find(y => y.row === "Total" ? "TOTAL" : y.row == x[0]);
            x.push(comment ? comment.message : '');
        });
    }

    return (
        <div>
            <TableWithExportButton
                title={title}
                fixedColumns={1}
                style={{ Table: { Body: { whiteSpace: 'pre-line' } } }}
                headers={headers}
                rows={rows.filter(x => x[0] !== 'TOTAL')}
                footers={rows.filter(x => x[0] === 'TOTAL')}
            />
        </div>
    );
};