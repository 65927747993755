import { Grid, IconButton, Popover, Typography, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableFooter } from "@material-ui/core";
import React, { useEffect } from "react";
import { Help, ArrowUpward, ArrowDownward } from "@material-ui/icons";
import Button from "utils/CustomButtons/Button";
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export const Comparison_GWPPPTC = (props) => {
    //const [data, setData] = React.useState([]);

    //const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const isSmallerScreen = useMediaQuery('(max-width: 1900px)');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [totala, setTotalA] = React.useState(0);
    const [agencyTotalA, setAgencyTotalA] = React.useState(0);
    const [totalb, setTotalB] = React.useState(0);
    const [agencyTotalB, setAgencyTotalB] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const cellStyle = { fontSize: "1.6em", lineHeight: "1.3em", color: "#ff8000", textAlign: "left", textWrap: "nowrap" };
    const headerStyle = { fontSize: "1.3em", color: "#1d4c80", fontWeight: "400", textWrap: "nowrap" }

    const GetData = () => {
        fetch('../GetComparison_GWPPPTC/?' + new URLSearchParams({
            startDateA: props.startDateA,
            endDateA: props.endDateA,
            startDateB: props.startDateB,
            endDateB: props.endDateB,
            state: props.state === "NJ" ? "NJ" : props.state === "all" ? "all" : props.state === "PA" ? "PA" : "MI",
            city: props.city
        }))
            .then(res => res.json())
            .then(data => obj = data)
            .then(() => {                
                setData(obj.result);
                setTotalA(obj.totalA);
                setAgencyTotalA(obj.agencyTotalA);
                setTotalB(obj.totalB);
                setAgencyTotalB(obj.agencyTotalB);
                setLoading(false);
             });
    };

    const handleIsPP = () => {
        setLoading(true);
        GetData();
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }

    }, [props.call]);
    return (
        <div
            style={
                isMobile ?
                    {
                        marginTop: "2em",
                        marginLeft: "0em",
                        marginRight: "0em",
                        backgroundColor: "#f1f1f1"
                    }
                    :
                    {
                        marginTop: "2em",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                        backgroundColor: "#f1f1f1"
                    }
                
            }
        >
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "absolute!important"
                    })
                }}
                spinner={<ReactLoading type={'spin'} height={50} width={50} />}
            >
            <Grid container>
                <Grid item
                    xs={12} sm={12} md={12} lg={3}
                    style={{ textAlign: "center" }}
                >
                    <span
                        style={
                            isMobile ?
                                {
                                    fontSize: "1.8em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: ".4em",
                                    display: "inline-block"
                                }
                                :
                                {
                                    fontSize: "1.6em",
                                    color: "#1d4c80",
                                    paddingTop: "1.2em",
                                    paddingBottom: "1em",
                                    paddingLeft: "1em",
                                    display: "inline-block"
                                }
                        }
                    >
                        GROSS WRITTEN PREMIUM
                    </span>
                </Grid>
                <Grid item
                    xs={12} sm={12} md={12} lg={9}
                    style={ useMediaQuery('(max-width: 1025px)') ?{ textAlign: "center" } : { textAlign: "left" }}
                    >
                </Grid>
                <Grid item xs={12}>
                    <TableContainer  style={{ position: "relative", marginTop: "1em", margin:"auto", maxWidth:"1200px" }}>
                        <Table sx={{ width: "1200px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={headerStyle}>TRANSACTION CODE</TableCell>
                                        <TableCell style={headerStyle}>RANGE A</TableCell>
                                        {props.state != "PA" && !props.city && <TableCell style={headerStyle}>RANGE A AGENCY</TableCell>}
                                        <TableCell style={headerStyle}>RANGE B</TableCell>
                                        {props.state != "PA" && !props.city && <TableCell style={headerStyle}>RANGE B AGENCY</TableCell>}
                                        <TableCell style={headerStyle}>CHANGE</TableCell>
                                        {props.state != "PA" && !props.city && <TableCell style={headerStyle}>CHANGE AGENCY</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    data.map((row) => {
                                        var change = ~~((row.premiumB - row.premiumA) * 100 / (row.premiumA ?? 1));
                                        var agencyChange = ~~((row.agencyPremiumB - row.agencyPremiumA) * 100 / (row.agencyPremiumA ?? 1));

                                        return (
                                            <TableRow key={row.transactionCD} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" style={headerStyle}>{row.transactionCD}</TableCell>
                                                <TableCell style={cellStyle}>${nf.format(row.premiumA ?? 0)}</TableCell>
                                                {props.state != "PA" && !props.city && <TableCell style={cellStyle}>${nf.format(row.agencyPremiumA ?? 0)} ({nf.format(~~row.premiumA ?? 0 != 0 ? Math.abs((~~row.agencyPremiumA ?? 0) * 100 / row.premiumA) : 0)}%)</TableCell>}
                                                <TableCell style={cellStyle}>${nf.format(row.premiumB ?? 0)}</TableCell>
                                                {props.state != "PA" && !props.city && <TableCell style={cellStyle}>${nf.format(row.agencyPremiumB ?? 0)} ({nf.format(~~row.premiumB ?? 0 != 0 ? Math.abs((~~row.agencyPremiumB ?? 0) * 100 / row.premiumB) : 0)}%)</TableCell>}
                                                <TableCell style={change > 0 ? { fontSize: "1.6em", lineHeight: "1.3em", color: "green" } : { fontSize: "1.6em", lineHeight: "1.3em", color: "red" }}>
                                                    {change > 0 ? <ArrowUpward style={{ marginBottom: "-7px" }} /> : <ArrowDownward style={{ marginBottom: "-7px" }} />}
                                                    {nf.format(change) + "%"}
                                                </TableCell>
                                                {props.state != "PA" && !props.city && 
                                                    <TableCell style={agencyChange > 0 ? { fontSize: "1.6em", lineHeight: "1.3em", color: "green" } : { fontSize: "1.6em", lineHeight: "1.3em", color: "red" }}>
                                                        {agencyChange > 0 ? <ArrowUpward style={{ marginBottom: "-7px" }} /> : <ArrowDownward style={{ marginBottom: "-7px" }} />}
                                                        {nf.format(agencyChange) + "%"}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" style={headerStyle}>Total Premium</TableCell>
                                    <TableCell style={cellStyle}>${nf.format(totala ?? 0)}</TableCell>
                                    {props.state != "PA" && !props.city && <TableCell style={cellStyle}>${nf.format(agencyTotalA ?? 0)} ({nf.format(~~totala ?? 0 != 0 ? Math.abs((~~agencyTotalA ?? 0) * 100 / totala) : 0)}%)</TableCell>}
                                    <TableCell style={cellStyle}>${nf.format(totalb ?? 0)}</TableCell>
                                    {props.state != "PA" && !props.city && <TableCell style={cellStyle}>${nf.format(agencyTotalB ?? 0)} ({nf.format(~~totalb ?? 0 != 0 ? Math.abs((~~agencyTotalB ?? 0) * 100 / totalb) : 0)}%)</TableCell>}
                                    <TableCell style={((totalb-totala) * 100 / (totala ?? 1)) > 0 ? { fontSize: "1.6em", lineHeight: "1.3em", color: "green" } : { fontSize: "1.6em", lineHeight: "1.3em", color: "red" }}>
                                        {(totala != 0 && totalb != 0) && (((totalb - totala) * 100 /(totala ?? 1)) > 0 ? <ArrowUpward style={{ marginBottom: "-4px" }} /> : <ArrowDownward style={{ marginBottom: "-4px" }} />)}
                                        {nf.format(~~((totalb - totala) * 100 / (totala ?? 1))) + "%"}
                                    </TableCell>
                                    {props.state != "PA" && !props.city && 
                                        <TableCell style={((agencyTotalB - agencyTotalA) * 100 / (agencyTotalA ?? 1)) > 0 ? { fontSize: "1.6em", lineHeight: "1.3em", color: "green" } : { fontSize: "1.6em", lineHeight: "1.3em", color: "red" }}>
                                            {(agencyTotalA != 0 && agencyTotalB != 0) && (((agencyTotalB - agencyTotalA) * 100 / (agencyTotalA ?? 1)) > 0 ? <ArrowUpward style={{ marginBottom: "-4px" }} /> : <ArrowDownward style={{ marginBottom: "-4px" }} />)}
                                            {nf.format(~~((agencyTotalB - agencyTotalA) * 100 / (agencyTotalA ?? 1))) + "%"}
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </LoadingOverlay>

        </div>
    );
}
const PopoverEl = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    if (props.Popover != null) {
        return (
            [
                <IconButton
                    aria-describedby={id}
                    variant="contained"
                    size="small"
                    style={{ color: "#25b7d3", marginBottom: ".5em" }}
                    onClick={handleClick} component="span">
                    <Help />
                </IconButton>,
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography>{props.Popover}</Typography>
                </Popover>

            ]
        );
    } else {
        return (<div></div>);
    }
}
