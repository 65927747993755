import React, { useState  } from "react";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import MainNavbar from "utils/Navbars/MainNavbar.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import { Device, DeviceContext } from 'utils/Device';

import NewQuotes from "components/NJ/NewQuotes";
import NewApps from "components/all/NewApps";
import NewPolicies from "components/all/NewPolicies";
import PremiumsQuoted from "components/PremiumsQuoted";
import PremiumsBound from "components/PremiumsBound";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import { GWPPP_TransCode } from "components/all/GWPPP_TransCode";
import EnvironmentServiceBanner from "components/EnvironmentServiceBanner";
import { GetQuoteData,GetPolicyData,GetYTDData } from "services/all/API";
import BISelections from "components/NJ/BISelections";
import ReleaseDrawer from "components/ReleaseDrawer";
import PreviouslyInsured from "components/PreviouslyInsured";
import CreditScore from "components/CreditScore";
import { CallAPI, all } from "services/all/API";
import { LandingMessage } from "components/all/LandingMessage";

const useStyles = makeStyles(styles);

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var nf = new Intl.NumberFormat();
var obj;
export default function Pages(props) {
    const [cookies, setCookie] = useCookies(['auth']);
    //Helper functions
    function isNotNull(data) {
        if (data == null || data == "") {
            return false;
        } else {
            return true;
        }
    }

    const changeStartDate = (date) => {
        setStartDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeEndDate = (date) => {
        setEndDate((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    };

    const changeCall = () => {
        RefreshComments();
    };

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    //When user refresh page or submit new dates, get new data
    const RefreshQuote = async () => {
        const data = await GetQuoteData(startDate, endDate, states, true);

        if (data) {
            setQuoteData(data.result[0]);
            setQuoteDataByQuoteType(data.quoteTypeResult);
        }
    };
    const RefreshQuotePremium = async () => {
        const data = await CallAPI(all.GetPremiumsQuotedData, {
            startDate: startDate,
            endDate: endDate,
            states: states,
            getQuoteType: true
        });
        if (data?.length > 0) {
            setQuotePremData(data[0]);
        }
    };
    const RefreshPolicy = async () => {
        const data = await GetPolicyData(startDate, endDate,states)

        if (data) {
            setPolicyData(data.result[0]);
            setPolicyPremData(data.result[0]);
            setPolicyDataByPolicyType(data.policyTypeResult);
        }
    };
    //When user refresh page or submit new dates, get new data for YTD
    const RefreshYTD = async () => {
        const data = await GetYTDData(startDate, endDate,states,true)
        if (data.length !== 0) {
            setytddata(data.result[0]);
        }        
    };
    const RefreshComments = async () => {
        const data = await CallAPI(all.GetComments, {
            startDate: startDate,
            endDate: endDate,
            states: states,
            page: 'Landing'
        });

        if (data) {
            setComments(data);
            setCall("true");
        }
    };

    //State Variables 
    var date = new Date();
    const [startDate, setStartDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    const [endDate, setEndDate] = React.useState((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());

    const [states, setStates] = React.useState(["NJ"]);
    const [quotedata, setQuoteData] = React.useState([]);
    const [quotepremdata, setQuotePremData] = React.useState([]);
    const [quoteDataByQuoteType, setQuoteDataByQuoteType] = React.useState([]);
    const [newAppsFetchCompleted, setNewAppsFetchCompleted] = useState(false)
    const [newBISelectionsQuotesFetchCompleted, setnewBISelectionsQuotesFetchCompleted] = useState(false)
    const [newBISelectionsPoliciesFetchCompleted, setnewBISelectionsPoliciesFetchCompleted] = useState(false)
    const [policydata, setPolicyData] = React.useState([]);
    const [policypremdata, setPolicyPremData] = React.useState([]);
    const [policyDataByPolicyType, setPolicyDataByPolicyType] = React.useState([]);
    const [ytddata, setytddata] = React.useState([]);
    const [comments, setComments] = React.useState([]);

    const [percentage] = React.useState("any");
    const [call, setCall] = React.useState("false");

    const device = Device();
    const isMobile = device.isMobile; //useMediaQuery('(max-width: 1152px)');
    const { ...rest } = props;
    const wrapper = React.createRef();
    const classes = useStyles();
    //When user refresh page or submit new dates, get all the data
    const didMount = React.useRef(false);
    React.useEffect(() => {
        async function func1() {
            console.log("func1")
            //disable scrolling when page is loading
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            if (call == "true" || call == null) {
                setNewAppsFetchCompleted(false)
                setnewBISelectionsQuotesFetchCompleted(false)
                setnewBISelectionsPoliciesFetchCompleted(false)

                setQuoteData([]);
                setQuotePremData([]);
                setQuoteDataByQuoteType([]);
                setPolicyData([]);
                setPolicyPremData([]);
                setPolicyDataByPolicyType([]);
                setytddata([]);

                RefreshQuotePremium();
                RefreshQuote();
                RefreshPolicy();
                RefreshYTD();
            }

        }
        if (didMount.current && call === "true") {
            func1();
        } else {
            didMount.current = true;
        }

    }, [call]);
    React.useEffect(() => {
        async function func2() {
            setCall("false");
            //Enable scrolling when page is finished loading
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";

        }
        if (!isEmpty(quotedata) && !isEmpty(quotepremdata) && !isEmpty(quoteDataByQuoteType) && newBISelectionsQuotesFetchCompleted && newBISelectionsPoliciesFetchCompleted && newAppsFetchCompleted && !isEmpty(policydata) && !isEmpty(policypremdata) && !isEmpty(policyDataByPolicyType) && !isEmpty(ytddata)) {
            func2()
        }
    }, [quotedata, quotepremdata, quoteDataByQuoteType, policydata, policypremdata,policyDataByPolicyType,ytddata,newBISelectionsQuotesFetchCompleted,newBISelectionsPoliciesFetchCompleted,newAppsFetchCompleted])

    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }

    const handleStateUpdate = (event) => {
        console.log(event.target.value);
        setStates(event.target.value);
    };

    const history = useHistory();
    if (cookies.pass == undefined) {
        history.push('/');
    }
    return (
        <DeviceContext.Provider value={device}>
        <div>
            <div className={classes.wrapper} ref={wrapper}>
                <MainNavbar state="NJ" onChangeCall={changeCall} onChangeStartDate={changeStartDate} onChangeEndDate={changeEndDate} startDate={startDate} endDate={endDate} call={call} Text={"Michigan Dashboard"} {...rest} switchTo="pass" />
                <div style={isMobile ? {} : { paddingRight: "17px" }}>                    
                    <NewQuotes
                        call={call}
                        startDate={startDate}
                        endDate={endDate}
                        states={states}
                        data={quotedata}
                        ytddata={ytddata}
                        HasAgency={true}
                    />
                    <NewApps
                        call={call}
                        fetchCompleted={() => setNewAppsFetchCompleted(true)}
                        startDate={startDate}
                        endDate={endDate}
                        states={states}
                        ytddata={ytddata}
                        HasAgency={true}
                    />
                    <NewPolicies
                        Total={policydata.total ?? 0}
                        Online={policydata.online ?? 0}
                        Crc={policydata.crc ?? 0}
                        Combo={policydata.combo ?? 0}
                        Agency={policydata.agency ?? 0}
                        states={["NJ"]}
                        ytddata={ytddata}
                        HasAgency={true}
                    />

                    <LandingMessage />

                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <PremiumsQuoted
                                PremiumsQuotedTotal={quotepremdata.overallPrem ?? 0}
                                PremiumsQuotedAvg={quotepremdata.overallAvgPrem ?? 0}
                                PremiumsQuotedQuickAvg={quotepremdata.quickAvgPrem  ?? 0}
                                PremiumsQuotedCustomAvg={quotepremdata.customAvgPrem  ?? 0}
                                PremiumsQuotedCRCAvg={quotepremdata.crcAvgPrem ?? 0}
                                PremiumsQuotedQuick={(quotepremdata.quickPrem) ?? 0}
                                PremiumsQuotedCustom={(quotepremdata.customPrem) ?? 0}
                                PremiumsQuotedCrc={quotepremdata.crcPrem ?? 0}
                                PremiumsQuotedAgencyAvg={quotepremdata.agencyAvgPrem ?? 0}
                                PremiumsQuotedAgency={quotepremdata.agencyPrem ?? 0}
                                state="NJ"
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Premiums Quoted')}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <PremiumsBound
                                PremiumsBoundTotal={policydata.overallPrem ?? 0}
                                PremiumsBoundAvg={policypremdata.overallAvgPrem ?? 0}
                                PremiumsBoundComboAvg={policypremdata.comboAvgPrem ?? 0}
                                PremiumsBoundOnlineAvg={policypremdata.onlineAvgPrem ?? 0}
                                PremiumsBoundCRCAvg={policypremdata.crcAvgPrem ?? 0}
                                PremiumsBoundCombo={policydata.comboPrem ?? 0}
                                PremiumsBoundOnline={policydata.onlinePrem ?? 0}
                                PremiumsBoundCrc={policydata.crcPrem ?? 0}
                                PremiumsBoundAgencyAvg={policydata.agencyAvgPrem ?? 0}
                                PremiumsBoundAgency={policydata.agencyPrem ?? 0}
                                state="NJ"
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Premiums Bound')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <CreditScore
                                type={"Quote"}
                                data={quoteDataByQuoteType}
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Quotes - Credit Score')}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <CreditScore
                                type={"Policy"}
                                data={policyDataByPolicyType}
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Policies - Credit Score')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item lg={6} xs={12}>
                            <PreviouslyInsured
                                type={"Quote"}
                                data={quoteDataByQuoteType}
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Quotes - Previously Insured')}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <PreviouslyInsured
                                type={"Policy"}
                                data={policyDataByPolicyType}
                                HasAgency={true}
                                comments={comments.filter(x => x.table == 'Policies - Previously Insured')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <BISelections 
                            call={call}
                            Type="Quotes"
                            startDate={startDate}
                            endDate={endDate}
                            states={states}
                            fetchCompleted={() => setnewBISelectionsQuotesFetchCompleted(true)}
                            comments={comments.filter(x => x.table == 'Bodily Injury Selections - Quotes')}
                        />
                        <BISelections 
                            call={call}
                            Type="Policies"
                            startDate={startDate}
                            endDate={endDate}
                            states={states}
                            fetchCompleted={() => setnewBISelectionsPoliciesFetchCompleted(true)}
                            comments={comments.filter(x => x.table == 'Bodily Injury Selections - Policies')}
                        />
                    </Grid>
                    <GWPPP_TransCode
                        startDate={startDate}
                        endDate={endDate}
                        states={states}
                        call={call}
                        comments={comments.filter(x => x.table == 'Gross Written Premium' || x.table == 'Posted Premium')}
                    />
                    <EnvironmentServiceBanner call={call} />
                </div>
            </div>
        </div>
        </DeviceContext.Provider>
    );
}