import Styles from './TableWithExportButton.Styles.js';
import React from 'react'
import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import ExportButton from "utils/CustomButtons/ExportToCVSButton";
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import TablePagination from '@mui/material/TablePagination';

const merge = (target, source, mutable = false) => {
    const newObj = typeof target == 'object' ? (mutable ? target : Object.assign({}, target)) : {};
    for (const prop in source) {
        if (target[prop] == null || typeof target[prop] === 'undefined') {
            newObj[prop] = source[prop];
        } else if (Array.isArray(target[prop])) {
            newObj[prop] = source[prop] || target[prop];
        } else if (target[prop] instanceof RegExp) {
            newObj[prop] = source[prop] || target[prop];
        } else {
            newObj[prop] = typeof source[prop] === 'object' ? merge(target[prop], source[prop]) : source[prop];
        }
    }
    return newObj;
};

export default function TableWithExportButtonAdvanced(props) {
    const style = (props.style === undefined) ? Styles() : merge(Styles(), props.style);
    const exportName = (props.exportName === undefined) ? props.title.toString().replace("-", "").replace(" ", "_") : props.exportName;
    const isMobile = window.matchMedia('(max-width: 1152px)').matches;

    return (
        <Grid container>
            <Grid item style={{
                ...style.Grid.Item,
                ...(isMobile ? {
                    padding: "1em",
                    width: "100%",
                    margin: "0",
                    backgroundColor: "#f1f1f1",
                    fontSize: "1.3em",
                    position: "relative",
                    textAlign: "center"
                } : {
                    backgroundColor: "#f1f1f1",
                    minWidth: "500px",
                    width: "100%",
                    margin: "1em",
                    padding: "1em",
                    fontSize: "2.5em",
                    position: "relative",
                    textAlign: "center"
                })
            }}>
                <span>
                    <span style={style.Title}>{props.title}</span>
                </span>
                <br />
                <br />
                {props.subtitle === undefined ? null : (
                    <span style={style.Subtitle}>{props.subtitle}</span>
                )}
                <LoadingOverlay
                    active={props.loading}
                    styles={{overlay: (base) => ({ ...base, position: "absolute!important" })}}
                    spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                >
                    <PaginatedTable
                        style={props.style}
                        headers={props.headers}
                        fixedColumns={props.fixedColumns}
                        rows={props.rows}
                        footers={props.footers}
                    />
                </LoadingOverlay>
                <ExportButton
                    ExportName={exportName}
                />
            </Grid>
        </Grid>
    );
};

const PaginatedTable = (props) => {
    //related to the popup
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);
    const style = (props.style === undefined) ? Styles() : merge(Styles(), props.style);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper>
            <TableContainer style={style.Table.Container}>
                <Table stickyHeader style={style.Table.TableSX} aria-label="simple table">
                    <TableHead>
                        {props.headers.map((row, i) => {
                            if (row.length > 0)
                                return (
                                    <TableRow key={row[0]}>
                                        {row.map((cell, index) => {
                                            if (index < props.fixedColumns && (i == 0 || !props.headers[i - 1][index]?.rowspan))
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align="left" style={{ ...style.Table.FixedColumn, ...style.Table.Header, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                            else
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align={index == 0 ? "left" : "right"} style={{ ...style.Table.Header, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                        })}
                                    </TableRow>
                                );
                        })}
                    </TableHead>
                    <TableBody>
                        {props.rows === undefined ? null : props.rows.map((row) => {
                            if (row.length > 0)
                                return (
                                    <TableRow key={row[0]} sx={style.Table.BodyRowSX}>
                                        {row.map((cell, index) => {
                                            if (index < props.fixedColumns)
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align="left" component="th" scope="row" style={{ ...style.Table.FixedColumn, ...style.Table.Body, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                            else
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align={index == 0 ? "left" : "right"} style={{ ...style.Table.Body, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                        })}
                                    </TableRow>
                                );
                        })}
                        {props.footers === undefined ? null : props.footers.map((row) => {
                            if (row.length > 0)
                                return (
                                    <TableRow key={row[0]} sx={style.Table.BodyRowSX}>
                                        {row.map((cell, index) => {
                                            if (index < props.fixedColumns)
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align="left" component="th" scope="row" style={{ ...style.Table.FixedColumn, ...style.Table.Footer, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                            else
                                                return (
                                                    <TableCell colSpan={cell?.colspan} rowSpan={cell?.rowspan} align={index == 0 ? "left" : "right"} style={{ ...style.Table.Footer, ...cell?.style ?? {} }}>
                                                        {cell?.text || cell}
                                                    </TableCell>
                                                );
                                        })}
                                    </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={props.rows?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page > 0 && (props.rows?.length ?? 0) < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};