import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, Router } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from 'react-loading';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box } from '@mui/material/';
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "utils/Grid/GridContainer";
import GridItem from "utils/Grid/GridItem";
import { FormControl, Grid } from "@material-ui/core";
// react component plugin for creating a beautiful datetime dropdown picker
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
import lightBlue from "@material-ui/core/colors/lightBlue";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import '../../assets/css/datepicker.css'
import { Datepicker, setOptions } from '@mobiscroll/react';
import MenuItem from '@mui/material/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';

// core components
import Button from "utils/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

import avatar from "assets/img/Logo.png";
import state from "assets/img/MI_State.png";
import state_NJ from "assets/img/NJ_State.png";
import { Label } from "@material-ui/icons";
const useStyles = makeStyles(styles);





export default function AuthNavbar(props) {
    //const history = useHistory();
    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });
    const inputProps = {
        className: 'md-mobile-picker-input',
        placeholder: 'Please Select...',
        style: {
            backgroundColor: "#ff8000", color: "white", borderRadius: "3px", border: "none", textAlign: "center", width: "100px"
        }
    };
    const useMediaQuery = (query) => {
        const mediaMatch = window.matchMedia(query);
        const [matches, setMatches] = React.useState(mediaMatch.matches);

        React.useEffect(() => {
            const handler = e => setMatches(e.matches);
            mediaMatch.addEventListener("change", handler);
            return () => mediaMatch.removeEventListener("change", handler);
        });
        return matches;
    };

    const [open, setOpen] = React.useState(false);
    const isSmallerScreen = useMediaQuery('(max-width: 1900px)');
    const isMobile = useMediaQuery('(max-width: 1152px)');

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const [startDate, setStartDate] = React.useState(props.startDate);

    const [endDate, setEndDate] = React.useState(props.endDate);
    const [groupDateBy, setGroupDateBy] = React.useState("Day");//React.useState(props.groupDateBy);

    const [call, setCall] = React.useState("true");

    const handleStartDateChange = (date) => {
        setStartDate(date);
        props.onChangeStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        props.onChangeEndDate(date);
    };
    const handleGroupDateByChange = (selected) => {
        setGroupDateBy(selected.target.value);
        props.onChangeGroupDateBy(selected.target.value);
    };

    const handleCall = () => {
        setCall("true");
        props.onChangeCall();
    };


    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: "#1d4c80",
                },
            },
            MuiInputLabel: {
                root: {
                    color: "white",
                    fontSize: "1.7em"
                }
            },
            MuiInputBase: {
                input: {
                    color: "white",
                    backgroundColor: "#ff8000",
                    padding: "9px 0px",
                    marginTop: "10px",
                    textAlign: "center",
                    borderRadius: "3px",
                }
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    // backgroundColor: lightBlue.A200,
                    // color: "white",
                },
            },
            MuiPickersDay: {
                day: {
                    color: "#ff8000",
                },
                daySelected: {
                    backgroundColor: "#1d4c80",
                },
                dayDisabled: {
                    color: lightBlue["100"],
                },
                current: {
                    color: lightBlue["900"],
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: lightBlue["400"],
                },
            },
        },
    });

    // verifies if routeName is the one active (in browser input)
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    const classes = useStyles();
    let DateStart = Date.now();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });

    return (
        <AppBar position="static" className={classes.appBar + appBarClasses} style={{ backgroundColor: "#1d4c80", padding: 0, flexWrap: "wrap" }}>
            <Toolbar className={classes.container} style={{ marginLeft: 0, marginRight: 0, width: "100%" }}>
                <GridContainer>
                    <Hidden mdDown>
                        <GridItem xs={2}>
                            <Button href="/"
                                style={{ padding: "0px", margin: "0px" }}
                                className={classes.title} color="transparent">
                                <img src={avatar} height="auto" width="90%"
                                    style={{
                                        minWidth: "150px",
                                        maxHeight: "100px",
                                        marginTop: ".8em"
                                    }}
                                    alt="..." />
                            </Button>
                        </GridItem>
                    </Hidden>
                    <Hidden mdDown>
                        <GridItem lg={10} style={{ display: "inherit" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <Grid style={{ display: "flex", flexDirection: "row" }} item lg={12}>
                                        {props.state !== "all" && (
                                            <img src={props.state !== "NJ" ? state : state_NJ}
                                                style={{
                                                    marginTop: "1.2em",
                                                    height: "auto",
                                                    maxHeight: "75px",
                                                    marginRight: ".8em"
                                                }} alt="..." />
                                        )}
                                        {useMediaQuery('(max-width: 1576px)') ?
                                            <Box sx={{ display: "flex", flexDirection: "column", flexShrink: "1" }}>
                                                <h3 style={{ whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" }}>
                                                    {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : props.state === "MI" ? "MICHIGAN DASHBOARD" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : "ALL STATES DASHBOARD"}
                                                </h3>
                                                {props.state !== "all" && (
                                                    <a href={
                                                        props.state === "MI" ? "/NJ/pass" : 
                                                        props.state === "NJ" ? "/PA/pass" : 
                                                        props.state === "PA" ? "/pass/" : 
                                                        "/pass/"
                                                    } style={activeRoute("options") || activeRoute("dma") ? { display: "none" } : { textAlign: "right", color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                        Switch to {
                                                            props.state === "NJ" ? "Pennsylvania" : 
                                                            props.state === "MI" ? "New Jersey" : 
                                                            props.state === "PA" ? "Michigan" : 
                                                            "New Jersey"
                                                        }
                                                    </a>
                                                )}
                                            </Box> :
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <h2 style={{ whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" }}>
                                                    {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : props.state === "MI" ? "MICHIGAN DASHBOARD" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : "ALL STATES DASHBOARD"}
                                                </h2>
                                                {props.state !== "all" && (
                                                    <a href={
                                                        props.state === "MI" ? "/NJ/pass" : 
                                                        props.state === "NJ" ? "/PA/pass" : 
                                                        props.state === "PA" ? "/pass/" : 
                                                        "/pass/"
                                                    } style={activeRoute("options") || activeRoute("dma") ? { display: "none" } : { textAlign: "right", color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                                        Switch to {
                                                            props.state === "NJ" ? "Pennsylvania" : 
                                                            props.state === "MI" ? "New Jersey" : 
                                                            props.state === "PA" ? "Michigan" : 
                                                            "New Jersey"
                                                        }
                                                    </a>
                                                )}
                                            </Box>}
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item lg={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "25%", maxWidth: "unset", flexBasis: "unset" }}>
                                        <div>
                                            <label style={{ "color": "white", fontSize: "18px" }}>DATE RANGE</label>
                                        </div>
                                    </Grid>
                                    <Grid item lg={5} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "75%", maxWidth: "unset", flexBasis: "unset" }}>
                                        <Grid container style={{ alignItems: "center", justifyContent: "space-around", flexWrap: "nowrap" }}>
                                            <Grid item lg={4} style={{ maxWidth: "unset", flexBasis: "unset", display: "flex", justifyContent: "space-around", alignItems: "center", gap: "20px" }}>
                                                <div style={{ position: "relative" }}>
                                                    <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.startDate}
                                                        onChange={function (event, inst) {
                                                            handleStartDateChange(event.value)
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ position: "relative" }}>
                                                    <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.endDate}
                                                        onChange={function (event, inst) {
                                                            handleEndDateChange(event.value)
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item lg={2} sm={2}
                                                style={{
                                                    marginLeft: "0.2em",
                                                    fontSize: "1.4em",
                                                    color: "white",
                                                    fontWeight: "600",
                                                    maxWidth: "unset",
                                                    flexBasis: "unset"
                                                }}
                                            >
                                                <Button onClick={handleCall} style={{ color: 'black', margin: '0px', height: '37px', fontSize: '12px', width: '100%' }} color="white" size="sm">Submit</Button>
                                            </Grid>
                                            <Grid item lg={1} sm={1}
                                                style={{
                                                    marginTop: "46px",
                                                    marginLeft: "0.5em",
                                                    fontSize: "1.4em",
                                                    color: "white",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                <ReactLoading type={'spin'} style={props.call == "true" ? { fill: "white", height: "25px", width: "25px" } : { display: "none" }} height={isMobile ? 20 : 25} width={isMobile ? 20 : 25} color={"white"} />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={activeRoute("options") || activeRoute("dma") ? { flexWrap: "nowrap" } : { display: "none" }}>
                                    <Grid item md={4} lg={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{}}>
                                            <label style={{ "color": "white", fontSize: "18px" }}>SUM BY OPTIONS</label>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} lg={7} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{}}>
                                            <Select
                                                value={groupDateBy}
                                                onChange={handleGroupDateByChange}
                                                SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '0.45em 3em', 'marginBottom': '8px' } }}
                                            >
                                                <MenuItem value=""> </MenuItem>
                                                <MenuItem value="Day">Day</MenuItem>
                                                <MenuItem value="Month">Month</MenuItem>
                                                <MenuItem value="Year">Year</MenuItem>
                                            </Select>
                                        </div>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </GridItem>
                    </Hidden>
                    <Hidden lgUp>
                        <GridItem xs={3} sm={4} style={{ paddingLeft: "0px" }}>
                            <Button
                                href="/"
                                className={classes.title}
                                color="transparent"
                                style={{
                                    marginTop: ".6em",
                                    padding: "0px"
                                }}
                            >
                                <img src={avatar} height="auto" width="100%"
                                    style={{
                                        minWidth: "120px",
                                        maxHeight: "100px"
                                    }}
                                    alt="..." />
                            </Button>
                        </GridItem>
                    </Hidden>
                    <Hidden lgUp xsDown>
                        <GridItem xs={5} sm={6} style={{ display: "inherit", marginLeft: "2.2em", padding: "0!important", display: "flex", flexDirection: "column" }}>
                            <h2
                                style={{
                                    whiteSpace: "nowrap",
                                    fontFamily: 'GlacialIndifferenceRegular',
                                    color: "#ffff",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    fontSize: "4vw",
                                    marginTop: "auto",
                                    marginBottom: "0px",
                                    textAlign: "right",
                                }}>
                                {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : props.state === "MI" ? "MICHIGAN DASHBOARD" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : "ALL STATES DASHBOARD"}
                            </h2>
                            {props.state !== "all" && (
                                <a href={
                                    props.state === "MI" ? "/NJ/pass" : 
                                    props.state === "NJ" ? "/PA/pass" : 
                                    props.state === "PA" ? "/pass/" : 
                                    "/pass/"
                                } style={activeRoute("options") || activeRoute("dma") ? { display: "none" } : { textAlign: "right", color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                    Switch to {
                                        props.state === "NJ" ? "Pennsylvania" : 
                                        props.state === "MI" ? "New Jersey" : 
                                        props.state === "PA" ? "Michigan" : 
                                        "New Jersey"
                                    }
                                </a>
                            )}
                        </GridItem>

                        <GridItem lg={5}>
                            <Grid container>
                                <GridItem xs={12} sm={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid style={{ marginTop: "25px" }} container justifyContent="space-around">
                                            <Grid item sm={2} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                <div style={{}}>
                                                    <label style={{ 'color': 'white', 'font-size': '18px' }}>DATE RANGE</label>
                                                </div>
                                            </Grid>
                                            <Grid item lg={5} sm={3} style={{ maxWidth: "unset", flexBasis: "unset", display: "flex", justifyContent: "space-around", alignItems: "center", gap: "20px", marginRight: "20px" }}>
                                                <div style={{ position: "relative" }}>
                                                    <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.startDate}
                                                        onChange={function (event, inst) {
                                                            handleStartDateChange(event.value)
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ position: "relative" }}>
                                                    <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                    <Datepicker
                                                        controls={['date']}
                                                        display="center"
                                                        touchUi={true}
                                                        inputComponent="input"
                                                        inputProps={inputProps}
                                                        value={props.endDate}
                                                        onChange={function (event, inst) {
                                                            handleEndDateChange(event.value)
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item lg={1} sm={1}
                                                style={{
                                                    marginTop: "43px",
                                                    marginLeft: "1em",
                                                    fontSize: "1.4em",
                                                    color: "white",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                <ReactLoading type={'spin'} style={props.call == "true" ? { fill: "white", height: "30px", width: "30px" } : { display: "none" }} height={isMobile ? 30 : 30} width={isMobile ? 30 : 30} />
                                            </Grid>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <Grid container style={activeRoute("options") || activeRoute("dma") ? { flexWrap: "nowrap" } : { display: "none" }}>
                                        <Grid item sm={3}>
                                            <div style={{ 'marginTop': '15px' }}>
                                                <label style={{ 'color': 'white', 'font-size': '18px' }}>SUM BY OPTIONS</label>
                                            </div>
                                        </Grid>
                                        <Grid item sm={9} style={{ flexBasis: "unset", maxWidth: "unset" }}>
                                            <Select
                                                value={groupDateBy}
                                                onChange={handleGroupDateByChange}
                                                SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '0.45em 3em', 'margin': '10px 0px' } }}
                                            >
                                                <MenuItem value=""> </MenuItem>
                                                <MenuItem value="Day">Day</MenuItem>
                                                <MenuItem value="Month">Month</MenuItem>
                                                <MenuItem value="Year">Year</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item lg={2} sm={2}
                                            style={{
                                                marginLeft: "0.2em",
                                                fontSize: "1.4em",
                                                color: "white",
                                                fontWeight: "600",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Button onClick={handleCall} style={{ color: 'black', height: '37px', fontSize: '12px' }} color="white" size="sm">Submit</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} style={activeRoute("pass") ? { marginBottom: "14px", marginTop: "20px", textAlign: "center" } : { display: "none" }}>
                                        {props.state !== "NJ" && <a href="/options" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }} >ADVANCED FILTER OPTIONS</a>}
                                        {props.state !== "NJ" && <a href="/dma" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }} >DMA STATS</a>}
                                        <a href={props.state === "NJ" ? "/NJ/comparison" : "/comparison"} color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>COMPARISON SEARCH</a>
                                        {props.state !== "NJ" && <a href="/cancelandrenewal" color="primary" style={{ fontSize: "13px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>CANCELS & RENEWALS</a>}
                                        {props.state !== "NJ" && <a href="/projections" color="primary" style={{ fontSize: "13px", height: '37px', color: "#ff8000", padding: "0px 15px", textDecoration: "underline", fontWeight: "500" }}>PROJECTIONS</a>}
                                    </Grid>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Hidden>
                    <Hidden smUp>
                        <GridItem xs={8} sm={6} style={{ display: "inherit", marginLeft: "2.2em", padding: "0!important", display: "flex", flexDirection: "column" }}>
                            <h2
                                style={{
                                    fontFamily: 'GlacialIndifferenceRegular',
                                    color: "#ffff",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    marginLeft: "25px",
                                    letterSpacing: "-1px!important",
                                    marginTop: "auto",
                                    marginBottom: "0px",
                                    textAlign: "right",
                                    whiteSpace: "nowrap",
                                }}>
                                {props.state === "NJ" ? "NEW JERSEY DASHBOARD" : props.state === "MI" ? "MICHIGAN DASHBOARD" : props.state === "PA" ? "PENNSYLVANIA DASHBOARD" : "ALL STATES DASHBOARD"}
                            </h2>
                            {props.state !== "all" && (
                                <a href={
                                    props.state === "MI" ? "/NJ/pass" : 
                                    props.state === "NJ" ? "/PA/pass" : 
                                    props.state === "PA" ? "/pass/" : 
                                    "/pass/"
                                } style={activeRoute("options") || activeRoute("dma") ? { display: "none" } : { textAlign: "right", color: "white", textDecoration: "underline", cursor: "pointer" }}>
                                    Switch to {
                                        props.state === "NJ" ? "Pennsylvania" : 
                                        props.state === "MI" ? "New Jersey" : 
                                        props.state === "PA" ? "Michigan" : 
                                        "New Jersey"
                                    }
                                </a>
                            )}
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <Grid container>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around" style={{ marginBottom: "10px", paddingLeft: "15px", paddingTop: "10px", marginTop: "15px" }}>
                                        <Grid item lg={5} xs={4} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "unset", flexBasis: "unset", gap: "20px", marginRight: "20px" }}>
                                            <div style={{ position: "relative", height: "70%" }}>
                                                <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>Start Date</p>
                                                <Datepicker
                                                    controls={['date']}
                                                    display="center"
                                                    touchUi={true}
                                                    inputComponent="input"
                                                    inputProps={{ ...inputProps, style: { ...inputProps.style, height: "100%" } }}
                                                    value={props.startDate}
                                                    onChange={function (event, inst) {
                                                        handleStartDateChange(event.value)
                                                    }}
                                                />
                                            </div>
                                            <div style={{ position: "relative", height: "70%" }}>
                                                <p style={{ position: "absolute", color: "white", left: "0px", top: "-25px", fontSize: "18px", whiteSpace: "nowrap" }}>End Date</p>
                                                <Datepicker
                                                    controls={['date']}
                                                    display="center"
                                                    touchUi={true}
                                                    inputComponent="input"
                                                    inputProps={{ ...inputProps, style: { ...inputProps.style, height: "100%" } }}
                                                    value={props.endDate}
                                                    onChange={function (event, inst) {
                                                        handleEndDateChange(event.value)
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item lg={2} sm={2}
                                            style={{
                                                marginLeft: "0.2em",
                                                fontSize: "1.4em",
                                                color: "white",
                                                fontWeight: "600",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <input onClick={handleCall} style={{ height: "70%", width: "100%", fontSize: "12px" }} type="button" value="SUBMIT"></input>{/*<input onClick={handleCall} style={{height:"37px", width: "50px"}} type="button" value="🔄"></input>*/}
                                        </Grid>
                                        <Grid item lg={1} sm={1}
                                            style={{
                                                marginTop: "45px",
                                                marginLeft: "0.1em",
                                                fontSize: "1.4em",
                                                color: "white",
                                                fontWeight: "600",
                                            }}
                                        >
                                            <ReactLoading type={'spin'} style={props.call == "true" ? { fill: "white", height: "30px", width: "30px" } : { display: "none" }} height={isMobile ? 30 : 30} width={isMobile ? 30 : 30} />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <GridItem xs={12} sm={12}>
                                    <Grid container justifyContent="space-around" style={activeRoute("options") || activeRoute("dma") ? { 'margin': '5px 0px' } : { display: "none" }}>
                                        <Grid item xs={5}>
                                            <div style={{ 'margin': '5px' }}>
                                                <label style={{ 'color': 'white', 'font-size': '15px' }}>SUM BY OPTIONS</label>
                                            </div>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Select
                                                value={groupDateBy}
                                                onChange={handleGroupDateByChange}
                                                SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '0.45em 2em' } }}
                                            >
                                                <MenuItem value=""> </MenuItem>
                                                <MenuItem value="Day">Day</MenuItem>
                                                <MenuItem value="Month">Month</MenuItem>
                                                <MenuItem value="Year">Year</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container style={activeRoute("pass") ? {} : { display: "none" }}>
                                        {props.state !== "NJ" && <Grid item xs={12} style={activeRoute("pass") ? { 'marginTop': '10px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/options" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }} >ADVANCED FILTER OPTIONS</a>
                                        </Grid>}
                                        {props.state !== "NJ" && <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/dma" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }} >DMA STATS</a>
                                        </Grid>}
                                        <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href={props.state === "NJ" ? "/NJ/comparison" : "/comparison"} color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>COMPARISON SEARCH</a>
                                        </Grid>
                                        {props.state !== "NJ" && <Grid item xs={12} style={activeRoute("pass") ? { 'margin': '0px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/cancelandrenewal" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>CANCELS & RENEWALS</a>
                                        </Grid>}
                                        {props.state !== "NJ" && <Grid item xs={12} style={activeRoute("pass") ? { 'marginBottom': '10px', 'padding': '0px !important' } : { display: "none" }}>
                                            <a href="/projections" color="primary" style={{ fontSize: "15px", height: '37px', color: "#ff8000", padding: "0px 15px 0px 0px", textDecoration: "underline", fontWeight: "500" }}>PROJECTIONS</a>
                                        </Grid>}
                                    </Grid>

                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Hidden>
                </GridContainer>
            </Toolbar>
        </AppBar>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string,
    state: PropTypes.string,
};