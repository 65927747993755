import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { useCookies } from 'react-cookie';
import Alert from '@mui/material/Alert';
import avatar from "assets/img/LogoBlue.png";
import { browserName, browserVersion } from "react-device-detect";
import { Login } from 'services/all/API';

export default function ActionAreaCard() {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3, 2),
            height: '100vh',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
    }));
    const valueRefNewPass = React.useRef(null);
    const valueRefPass = React.useRef(null);
    const classes = useStyles();
    const history = useHistory();
    const [result, setResult] = React.useState(null);
    const [newpass, setNewPass] = React.useState(false);
    const [newpassval, setNewPassVal] = React.useState("");
    const [cookies, setCookie] = useCookies(['auth']);

    const handlePassChange = (obj) => {
        setNewPassVal(obj.target.value);
    };
    const handleAutoFillMode = (obj) => {
        setNewPass(true);
    };

    const handlePassSubmit = async () => {
        var passval = newpass ? valueRefNewPass.current.value : valueRefPass.current.value;
        const data = await Login(passval)
        if (data.isSuccess) {
            setResult("Success");
            setCookie('pass', true, { path: '/' });
            setCookie('UserGroup', data.userGroup, { path: '/' });
            setCookie('pwd', passval, { path: '/' });
            history.push('/all/pass');
        }
        else {
            setResult("Wrong Password");
        }
    };

    //const handlePassChange = (obj) => {
    //    if (valueRef.current.value == 'CURE2021!') {
    //        setPass("Success");
    //        //setCookie('pass', true, { path: '/' });
    //        history.push('/pass');
    //    }
    //    else {
    //        setPass("Wrong Password");
    //    }
    //};

    if (cookies.pass && cookies.UserGroup) {
        history.push('/all/pass');
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <form onSubmit={handlePassSubmit}>
            <Container maxWidth="sm" className={classes.root}>
                <img src={avatar} height="auto" width="100%"
                    style={{
                        minWidth: "150px"
                    }}
                    alt="..." />

                    <Stack spacing={2} style={{ marginTop: "25vh" }}>
                        <div style={{ "display": "none" }}>
                            {/*Not in use. Only here for Firefox autofill support*/}
                            <label for="username">Username</label> 
                            <input type="text" name="username" id="username" autocomplete="username" />
                        </div>
                        {browserName == "Firefox" ?
                            /*Added per MRD-53. Autofill support for Firefox,Chrome,and Safari mobile*/
                            <TextField
                                hintText="Password"
                                floatingLabelText="Password"
                                fullWidth
                                name="new-password"
                                label="Password"
                                id="new-password"
                                inputRef={valueRefPass}
                                autoComplete="new-password"
                                type="password">
                            </TextField>
                            :
                            <div>
                                <div id="newautofill" style={newpass ? {} : { "display": "none" }}>
                                    <TextField
                                        hintText="Save Password"
                                        floatingLabelText="Save Password"
                                        fullWidth
                                        name="new-password"
                                        label="Save Password"
                                        id="new-password"
                                        defaultValue={newpassval}
                                        inputRef={valueRefNewPass}
                                        autoComplete="new-password"
                                        type="password">
                                    </TextField>
                                </div>
                                <div id="currentautofill" style={newpass ? { "display": "none" } : {}}>
                                    <TextField
                                        hintText="Password"
                                        floatingLabelText="Password"
                                        fullWidth
                                        name="current-password"
                                        label="Password"
                                        id="current-password"
                                        autoComplete="current-password"
                                        inputRef={valueRefPass}
                                        onChange={handlePassChange}
                                        type="password">
                                    </TextField>
                                </div>
                             </div>
                        }
                        
                        
                    
                    
                </Stack>
                <Button fullWidth variant="contained" type="button" onClick={handlePassSubmit} style={{ marginTop: "1em", backgroundColor: "#1d4c80" }} >Submit</Button>

                <Alert severity="error"
                    style={result != undefined ? {display:""}: {display:"none"}}
                >
                    {
                        result
                    }
                </Alert>
                {newpass || browserName == "Firefox" ? null : <Button id="autofillBtn" fullWidth variant="text" style={{ marginTop: ".5em" }} onClick={handleAutoFillMode} >Enter Save Password Mode</Button>}
                
                </Container>
            </form>
        </React.Fragment>
    );
}