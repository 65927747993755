import { Options } from "./APIConfig";
import axios from "axios";

const headers = {
    "Content-Type": "application/json",
    "Authorization" : ""
  };

export const GetQuoteData = async (startDate, endDate, state, groupDateBy) => {
    const { method, url, ...props } = Options.GetQuoteData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        groupDateBy:groupDateBy,
        getQuoteType:true,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetQuoteData", apiResponse);
    } catch (error) {
      console.error("GetQuoteData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetPIPData = async (startDate, endDate, state, groupDateBy) => {
    const { method, url, ...props } = Options.GetPIPData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        groupDateBy:groupDateBy,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetPIPData", apiResponse);
    } catch (error) {
      console.error("GetPIPData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetPremiumData = async (startDate, endDate, state, groupDateBy,isPP) => {
    const { method, url, ...props } = Options.GetPremiumData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        groupDateBy:groupDateBy,
        isPP:isPP,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetPremiumData", apiResponse);
    } catch (error) {
      console.error("GetPremiumData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const formatDate = (date) => {
    if (date === undefined) { 
      return "";
    }
    const parts =  date.split('-');
    var formattedDate = "";
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    const year = parts[0];
    const month = parts.length > 1 ? parts[1] : '';
    const day = parts.length > 1 ? parts[2] : '';

    switch (parts.length) {
        case 1:
            formattedDate = year;
            break;
        case 2:
            formattedDate = monthNames[parseInt(month) - 1] + " " +year;
            break;  
        case 3:
            formattedDate = month + "/" + day + "/" + year;
            break
        default:
            break;
    }

    return formattedDate;
} 