import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, Router } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
// react component plugin for creating a beautiful datetime dropdown picker
import DateFnsUtils from '@date-io/date-fns';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import '../../assets/css/datepicker.css'
import { Datepicker, setOptions } from '@mobiscroll/react';
import {
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

// core components
import Button from "utils/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";
import { Styles } from "./MainNavbar_Styles";
import avatar from "assets/img/Logo.png";
import { ViewSize } from "./MainNavbar.Utils";
import { ChangeView, Header, DateSelection, LinkBar } from "./MainNavbar.Components";
const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
    //const history = useHistory();
    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });
    const classes = useStyles();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });

    // verifies if routeName is the one active (in browser input)
    const activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };

    // overwrites values in utils\Navbars\MainNavbar_Styles.js with values in props.styles
    let _style = Styles;
    if (props.styles) {
        for (let key of Object.keys(props.styles)) {
            _style[key] = Styles[key] ? { ...Styles[key], ...props.styles[key] } : props.styles[key];
        }
    }

    return (
        <AppBar position="static" className={classes.appBar + appBarClasses} style={_style.AppBar} id="top">
            <Toolbar className={classes.container} style={_style.ToolBar}>
                <Grid container>
                    <Grid item xs={12} style={{ display: "inherit" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                                <Grid item xs={3} sm={4} md={3} lg={3} >
                                    <Button href="/" style={_style.Logo_Button} className={classes.title} color="transparent">
                                        <img src={avatar} height="auto" width="90%" style={_style["Logo_" + ViewSize()]} alt="..." />
                                    </Button>
                                </Grid>
                                <Grid item container lg={6} md={6} sm={8} xs={9}>
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        {props.customTitle || <h3 style={_style['Header_' + ViewSize() + '_Heading']}>{props.title}</h3>}
                                    </div>
                                    {
                                        props.showChangeView &&
                                        <div style={_style['ChangeViewHeader_' + ViewSize() + '_Box']}>
                                            <ChangeView state={props.state} ViewSize={ViewSize} />
                                        </div>
                                    }
                                </Grid>
                                {props.showLinkBar && <LinkBar state={props.state} city={props.city} ViewSize={ViewSize} />}
                                {props.children}
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string
};
