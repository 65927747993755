import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ExportButton from "utils/CustomButtons/ExportToCVSButton";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { Pie } from 'react-chartjs-2';
import { styled } from '@material-ui/styles';
import marker from "assets/img/marker.png";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#33cf4d",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#1d4c80',
        opacity: 1
    },
}));

export default function PremiumsQuoted(props) {
    //const [data, setData] = React.useState([]);
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const chartStyles = makeStyles(styles);
    const chartClasses = chartStyles();
    //const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 1152px)');

    const options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                labels: {
                    font: {
                        size: "20"
                    }
                }
            },
            title: {
                display: "none",
                fullSize: false
            }
        }
    };
    const data = props.state === "all" ? {
        labels: ['Online', 'CRC',props.HasAgency && 'Agency'],
        datasets: [
            {
                label: '# of Votes',
                data: [props.PremiumsQuotedOnline, props.PremiumsQuotedCrc, props.HasAgency && props.PremiumsQuotedAgency],
                backgroundColor: ['#86eae9', '#4591b8', '#5dbdd3'],
                borderWidth: 1,
            },
        ],
    } : {
        labels: ['Quick', 'Custom', 'CRC',props.HasAgency &&  'Agency'],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    props.PremiumsQuotedQuick, 
                    props.PremiumsQuotedCustom, 
                    props.PremiumsQuotedCrc,
                    props.HasAgency && props.PremiumsQuotedAgency
                ],
                backgroundColor: ['#86eae9', '#4591b8', '#5dbdd3', '#3a7ca5'],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <Grid container>
                <Grid item
                    style={
                        isMobile ?
                            {
                                margin: "1em",
                                paddingLeft:"1em",
                                paddingRight: "1em",
                                width: "100%",
                                paddingTop: "1em",
                                backgroundColor: "#f1f1f1",
                                fontSize: "1.6em",
                                position: "relative",
                                textAlign: "center"
                            }
                            :
                            {
                                backgroundColor: "#f1f1f1",
                                width: "100%",
                                margin: "2em",
                                paddingLeft: "1.9em",
                                paddingRight: "1em",
                                paddingTop: "2em",
                                paddingBottom: "1em",
                                fontSize: "1.8em",
                                position: "relative",
                                textAlign: "center"
                            }
                    }
                >
                    <span

                    >
                        <span
                            style={{

                                color: "#ff8000"
                            }}
                        >${nf.format(props.PremiumsQuotedTotal)} </span>
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#1d4c80",
                                lineHeight: "1.2em"
                            }}
                        >
                            PREMIUMS QUOTED
                        </span>
                    </span>
                    <br />
                    <br />
                    <span
                        style={{fontSize:".7em",marginTop:"1em"}}
                    >
                        <span
                            style={{

                                color: "#ff8000"
                            }}
                        >${nf.format(isNaN(props.PremiumsQuotedAvg) ? 0 : props.PremiumsQuotedAvg)} </span>
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#1d4c80",
                                lineHeight: "1.2em"
                            }}
                        >
                            Average Premiums Quoted
                        </span>
                    </span>
                    <TableView
                        show={true}
                        data={data}
                        state={props.state}
                        HasAgency={props.HasAgency}
                        PremiumsQuotedQuickAvg={props.PremiumsQuotedQuickAvg}
                        PremiumsQuotedCustomAvg={props.PremiumsQuotedCustomAvg}
                        PremiumsQuotedOnlineAvg={props.PremiumsQuotedOnlineAvg}
                        PremiumsQuotedCRCAvg={props.PremiumsQuotedCRCAvg}
                        PremiumsQuotedAgencyAvg={props.HasAgency && props.PremiumsQuotedAgencyAvg}
                        PremiumsQuotedAgency={props.HasAgency && props.PremiumsQuotedAgency}
                        comments={props.comments ?? []}
                    />
                    <ExportButton ExportName="Premiums_Quoted" />
                </Grid>
            </Grid>
        </div>
    );
}
const GraphView = (props) => {
    if (props.show) {
        return (
            <Pie style={{ backgroundColor: "#f1f1f1",marginTop:".5em" }} options={props.options} data={props.data} />
        );
    } else {
        return null;
    }
}

const MarkerAvg = (props) => {
    const isMobile = useMediaQuery('(max-width: 1152px)');
    if (props.show) {
        return (
            [
                <img src={marker} width="100%"
                    style={
                        isMobile ?
                            {
                                position: "absolute",
                                width: "auto",
                                maxHeight: "130px",
                                right: ".8em",
                                bottom: "-20px",
                                transform: "rotate(90deg)"
                            }
                            :
                            {
                                position: "absolute",
                                width: "auto",
                                maxHeight: "200px",
                                right: ".8em",
                                bottom: "-20px",
                                transform: "rotate(90deg)"
                            }}
                    alt="..." />,
                <span
                    style={{
                        position: "absolute",
                        right: ".5em",
                        bottom: "3.7em",
                        fontSize: ".8em",
                        color: "white"
                    }}
                >
                    Average
                </span>,
                <span
                    style={{
                        position: "absolute",
                        right: ".3em",
                        bottom: "1.3em",
                        fontSize: "1.3em",
                        fontWeight: "500",
                        color: "#ff8000"
                    }}
                >
                    ${props.PremiumsQuotedAvg}
                </span>
            ]);
    } else {
        return (null);
    }
}

const TableView = (props) => {
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    if (props.show) {
        const rows = props.state === "all" ? [
            createData(props.data.labels[0], nf.format(props.PremiumsQuotedOnlineAvg ?? 0), nf.format(props.data.datasets[0].data[0] ?? 0)),
            createData(props.data.labels[1], nf.format(props.PremiumsQuotedCRCAvg ?? 0), nf.format(props.data.datasets[0].data[1] ?? 0))
        ] : [
            createData(props.data.labels[0], nf.format(props.PremiumsQuotedQuickAvg ?? 0), nf.format(props.data.datasets[0].data[0] ?? 0)),
            createData(props.data.labels[1], nf.format(props.PremiumsQuotedCustomAvg ?? 0), nf.format(props.data.datasets[0].data[1] ?? 0)),
            createData(props.data.labels[2], nf.format(props.PremiumsQuotedCRCAvg ?? 0), nf.format(props.data.datasets[0].data[2] ?? 0)),
        ];

        if(props.state === "all" && props.HasAgency === true){
            rows.push(createData(props.data.labels[2], nf.format(props.PremiumsQuotedAgencyAvg ?? 0), nf.format(props.data.datasets[0].data[2] ?? 0)));
        }

        if(props.state !== "all" && props.HasAgency === true){
            rows.push(createData(props.data.labels[3], nf.format(props.PremiumsQuotedAgencyAvg ?? 0), nf.format(props.data.datasets[0].data[3] ?? 0)));
        }

        function createData(name, avg, val) {
            return { name, avg, val };
        }

        return (
            <TableContainer component={Paper} style={{ position: "relative", marginTop: "1em" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Quote Type</TableCell>
                            <TableCell align="right">Average</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {props.comments.length > 0 && <TableCell></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">${row.avg}</TableCell>
                                <TableCell align="right">${row.val}</TableCell>
                                {props.comments.length > 0 && <TableCell style={{ whiteSpace: 'pre-line' }} align="right">{props.comments.find(x => x.row == row.name)?.message}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return null;
    }
}
