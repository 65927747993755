import { useContext } from 'react';
import { DeviceContext } from 'utils/Device/DeviceContext';

export default function Styles() {
    const device = useContext(DeviceContext);

    return {
        Grid: {
            Item: device.isMobile ? {
                margin: "1em",
                paddingLeft: "1em",
                paddingRight: "1em",
                width: "100%",
                paddingTop: "1em",
                backgroundColor: "#f1f1f1",
                fontSize: "1.3em",
                position: "relative",
                textAlign: "center"
            } : {
                backgroundColor: "#f1f1f1",
                width: "100%",
                margin: "2em",
                paddingLeft: "1.9em",
                paddingRight: "1em",
                paddingTop: "2em",
                paddingBottom: "1em",
                fontSize: "1.8em",
                position: "relative",
                textAlign: "center"
            }
        },
        Title: {
            fontWeight: "bold",
            color: "#1d4c80",
            lineHeight: "1.2em"
        },
        Subtitle: {
            fontSize: ".7em",
            marginTop: "1em"
        },
        Table: {
            Container: {
                position: "relative",
                marginTop: "1em",
                overflowX: "auto", // Ensures horizontal scrolling
                WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS devices
                msOverflowStyle: "scrollbar" // Ensures scrollbar visibility in Windows browsers
            },
            TableSX: {
                width: '100%', // Set the width of the table to 100%
                minWidth: "380px", // Ensures a minimum width for larger screens
                tableLayout: "fixed" // all columns same width
            },
            BodyRowSX: {
                "&:last-child td, &:last-child th": { border: 0 }
            },
            FixedColumn: {
                position: "sticky",
                top: "0",
                left: "0",
                zIndex: "20",
                backgroundColor: "#fafafa"
            },
            Header: {
                lineHeight: "15px"
            },
            Body: {

            },
            Footer: {
                fontWeight: "800"
            }
        }
    };
};