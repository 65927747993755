let Styles = {
Logo_Button: { padding: "0px", margin: "0px", maxWidth: "100%" },
Logo_XS:{minWidth: "95px", maxHeight: "100px", marginTop: ".8em"},
Logo_SM:{minWidth: "150px", maxHeight: "100px", marginTop: ".8em"},
Logo_MD:{minWidth: "150px", maxHeight: "100px", marginTop: ".8em"},
Logo_LG:{minWidth: "150px", maxHeight: "100px", marginTop: ".8em"},
Logo_XL:{minWidth: "150px", maxHeight: "100px", marginTop: ".8em"},
StateLogo_XS: { display: "none"},
StateLogo_SM:{display:"none"},
StateLogo_MD:{marginTop: "1.2em",height: "auto",maxHeight: "75px",marginRight: ".8em"},
StateLogo_LG:{marginTop: "1.2em",height: "auto",maxHeight: "75px",marginRight: ".8em"},
StateLogo_XL:{marginTop: "1.2em",height: "auto",maxHeight: "75px",marginRight: ".8em"},
AppBar:{ backgroundColor: "#1d4c80", padding: 0, flexWrap: "wrap" },
ToolBar:{ marginLeft: 0, marginRight: 0, width: "100%" },
TitleHeaderWrapper_XS: {display: "flex", paddingLeft: "25px", justifyContent: "flex-end"},
ChangeViewHeader_XS_Box:{ display: "flex",textAlign:"right", flexDirection: "column", flexShrink: "1", marginBottom:"1em", paddingRight: "20px" },
ChangeViewHeader_SM_Box:{ display: "flex",textAlign:"center", flexDirection: "column", flexShrink: "1", marginBottom:"2em", paddingRight: "20px" },
ChangeViewHeader_MD_Box:{ display: "flex",textAlign:"center", flexDirection: "column", flexShrink: "1", paddingRight: "20px" },
ChangeViewHeader_LG_Box:{ display: "flex",textAlign:"center", flexDirection: "column", flexShrink: "1", paddingRight: "20px" },
ChangeViewHeader_XL_Box:{ display: "flex",textAlign:"center", flexDirection: "column", flexShrink: "1", paddingRight: "20px" },
DatePicker_XS_Box:{ marginBottom:"1em", marginTop:"2em"},
DatePicker_SM_Box:{ marginBottom:"1em"},
DatePicker_MD_Box:{ },
DatePicker_LG_Box:{ },
DatePicker_XL_Box:{ maxWidth: "unset"},
DatePicker_XS_Wrapper:{ marginBottom:"1em", marginTop:"2em"},
DatePicker_SM_Wrapper:{ marginBottom:"1em"},
DatePicker_MD_Wrapper:{ },
DatePicker_LG_Wrapper:{ },
DatePicker_XL_Wrapper: {
    paddingTop: "30px",
    maxWidth: "unset",
    display: "flex",
},
DatePicker_XS_Label:{ display: "none", flexDirection: "column", justifyContent: "center", alignItems: "flexStart" },
DatePicker_SM_Label:{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flexStart" },
DatePicker_MD_Label:{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flexStart" },
DatePicker_LG_Label:{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flexStart" },
DatePicker_XL_Label:{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flexStart" },
Header_XS_Heading: { fontSize: "14px", lineHeight: "2em", whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", textAlign: "left", marginTop: ".7em", marginLeft: "1.2em" },
Header_SM_Heading:{ fontSize: "4vw",lineHeight:"1.4em", whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" },
Header_MD_Heading:{ fontSize: "1.825em",lineHeight:"1.4em", whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" },
Header_LG_Heading:{ fontSize: "1.825em",lineHeight:"1.4em", whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" },
Header_XL_Heading:{ fontSize: "2.6em", lineHeight:"1.5em",fontWeight: "bolder", whiteSpace: "nowrap", fontFamily: 'GlacialIndifferenceRegular', color: "#ffff", fontWeight: "normal", fontStyle: "normal", display: "inline-block", textAlign: "right" },
ChangeView_XS_p:{ whiteSpace: "nowrap", display: "box", justifyContent: "end", color: "white"},
ChangeView_SM_p:{ whiteSpace: "nowrap", display: "box", justifyContent: "center", color: "white"},
ChangeView_MD_p:{ whiteSpace: "nowrap", display: "box", justifyContent: "center", color: "white"},
ChangeView_LG_p:{ whiteSpace: "nowrap", display: "box", justifyContent: "center", color: "white"},
ChangeView_XL_p:{ whiteSpace: "nowrap", display: "box", justifyContent: "center", color: "white"},
ChangeView_p_a:{ textAlign: "right", color: "white", textDecoration: "underline", cursor: "pointer", marginLeft:"1em" },
LinkBar_XS_Item:{ fontSize: "15px", color: "#ff8000", textDecoration: "underline", fontWeight: "500",padding: "0px 15px 0px 15px",display:"flex" },
LinkBar_SM_Item:{ fontSize: "15px", color: "#ff8000", textDecoration: "underline", fontWeight: "500",padding: "0px 15px 0px 15px", display:"flex"},
LinkBar_MD_Item:{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px 0px 15px", textDecoration: "underline", fontWeight: "500" },
LinkBar_LG_Item:{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px 0px 15px", textDecoration: "underline", fontWeight: "500" },
LinkBar_XL_Item:{ fontSize: "15px", height: '37px', borderRightStyle: 'dotted', borderRightColor: '#fff', color: "#ff8000", padding: "0px 15px 0px 15px", textDecoration: "underline", fontWeight: "500" },
LinkBar_XS_FirstItem:{ marginBottom: "15px", textAlign: "right", flexBasis: "95.5%" },
LinkBar_SM_FirstItem:{ marginBottom: "15px", textAlign: "right", flexBasis: "95.5%" },
LinkBar_MD_FirstItem:{ marginBottom: "15px", marginTop: "20px", textAlign: "right", flexBasis: "95.5%" },
LinkBar_LG_FirstItem:{ marginBottom: "15px", marginTop: "20px", textAlign: "right", flexBasis: "95.5%" },
LinkBar_XL_FirstItem:{ marginBottom: "15px", marginTop: "20px", textAlign: "right", flexBasis: "95.5%" },
};

export {Styles};