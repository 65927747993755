let Dynamic_Style = {
  BlueContainer_Desktop :{
    fontSize: "1.5em",
    paddingTop: ".7em",
    paddingBottom: ".7em",
    paddingLeft: "1em",
    width: "100%",
    textAlign: "center",
    backgroundColor: "#1d4c80"
},
  BlueContainer_Mobile : {
    fontSize: "1.1em",
    paddingTop: ".7em",
    paddingBottom: ".7em",
    paddingLeft: "1em",
    width: "100%",
    textAlign: "center",
    backgroundColor: "#1d4c80"
}  ,
SeparatorWithSubHeader_Mobile :{
  display:'none'
},
SeparatorWithSubHeader_Desktop : {
  position: "absolute",
  width: "27px",
  height: "112px",
  marginLeft:"-30px",
  marginTop:"-14px"
},
SeparatorWithOutSubHeader_Mobile :{
  display:'none'
},
SeparatorWithOutSubHeader_Desktop : {
  position: "absolute",
  width: "27px",
  height: "79px",
  marginLeft:"-30px",
  marginTop:"-14px"
},
YTDLabelWithSubHeader_Mobile :{
  position: "absolute",
  textOrientation: "mixed",
  writingMode: "tb-rl",
  transform: "rotate(-180deg)",
  color: "#fff",
  paddingBottom:".5em",
  fontSize: ".8em",
  marginLeft:"-15px"
},
YTDLabelWithSubHeader_Desktop:{
  position: "absolute",
  textOrientation: "mixed",
  writingMode: "tb-rl",
  transform: "rotate(-180deg)",
  color: "#fff",
  paddingBottom:".5em",
  fontSize: ".8em",
  marginTop:"20px"
},
YTDLabelWithOutSubHeader_Mobile :{
  position: "absolute",
  textOrientation: "mixed",
  writingMode: "tb-rl",
  transform: "rotate(-180deg)",
  color: "#fff",
  paddingBottom:".5em",
  fontSize: ".8em",
  marginLeft:"-15px"
},
YTDLabelWithOutSubHeader_Desktop:{
  position: "absolute",
  textOrientation: "mixed",
  writingMode: "tb-rl",
  transform: "rotate(-180deg)",
  color: "#fff",
  paddingBottom:".5em",
  fontSize: ".8em",
},
BannerTitleContainer_Header_Mobile:{
  fontSize: "1.5em",
  color: "#1d4c80",
  paddingTop: "1em",
  paddingLeft: "1em",
  display: "inline-block"
},
BannerTitleContainer_Header_Desktop:{
  marginBottom: ".5em",
  fontSize: "1.5em",
  color: "#1d4c80",
  paddingTop: "1em",
  paddingLeft: "1em",
  display: "inline-block"
},
BannerTitleContainer_SubHeader_Mobile:{
  fontSize: "1.2em",
  color: "#1d4c80",
  paddingLeft: "1.3em",
  display: "inline-block"
},
BannerTitleContainer_SubHeader_Desktop:{
  fontSize: "1.5em",
  color: "#1d4c80",
  paddingTop: "1em",
  paddingLeft: "1em",
  display: "inline-block"
}
}

export {Dynamic_Style};