import React, { useState, useEffect } from "react";
import { GetEnvironmentService } from "services/all/API";

export default function EnvironmentServiceBanner(props) {
    const [environmentName, setEnvironmentName] = useState("")
    const [dataSource, setDataSource] = useState("")
    const [lastSyncDate, setLastSyncDate] = useState("")
    const fetchEnvironment = async () => {
        let env;
        if (window.location.href.includes("uat") || window.location.href.includes("localhost")) {
            env = "uat"
        }
        else if (window.location.href.includes("prep")) {
            env = "prep"
        }
        else {
            env = "prod"
        }
        const data = await GetEnvironmentService(env)
        console.log(data);
        if (data != null){
            setEnvironmentName(data.environmentName)
            setDataSource(data.isVK ? "Vazkor DB" : "Guidewire DB")
            setLastSyncDate(data.syncVKdbDate)
        }
    }

    useEffect(() => {
        setEnvironmentName("")
        setDataSource("")
        setLastSyncDate("")
        fetchEnvironment()
    }, [props.call]);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px", width: "100%", justifyContent: "start", alignItems: "center", background: "transparent", color: "grey", marginTop: "10px", marginLeft: "1em" }}>
                <p>Environment: {environmentName.toUpperCase()}</p>
                <p>Data Source: {dataSource}</p>
                {dataSource === "Vazkor DB" && <p>Last Vazkor DB Sync: {lastSyncDate.replace("T", " ")}</p>}
            </div>
        </>
    );
}
