import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function Cancellations_PolicyCount(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [loading, setLoading] = React.useState(false);



    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            //GetData();
            setLoading(true);
        } else {
            setLoading(false);
        }
      
    }, [props.call]);


    return (
        <Grid container> {/* Quotes Table */}
            <Grid item lg={12} xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        margin: "0em",
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            <span

                            >
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "#1d4c80"
                                    }}
                                >
                                TOTAL CANCELLATIONS AND NON-RENEWALS
                                </span>
                        </span>
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                Total In Force <IconPop message={"  Policies and GWP in force at 12:00am on the first day of the selected date range"} />
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <InForceTableView
                                data={props}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_In_Force"
                        />
                            
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                Total Up For Renewal <IconPop message={"  Total policies and GWP with a renewal date within the selected date range"} />
                            </span>
                        </span>
                        
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <CancellationsTableView
                                data={props}
                        />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_Up_For_Renewal"
                        />
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    );
}


const InForceTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];

    return (
            <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px"}}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px" , maxWidth: "70px" }}></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>In Force</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Canceled</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Cancel %<IconPop message={"  % of total in force policies and GWP that cancelled during the selected date range"} /></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Reinstated<IconPop message={"  Total policies and GWP cancelled during the selected date range that reinstated as of today's date"} /> </TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Net Cancel %<IconPop message={"  % of total in force policies and GWP that canceled within the selected date range minus reinstatements as of today's date"} /></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow
                            key={"policyInForce"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{fontWeight:"bold"}}>Policies</TableCell>
                            <TableCell align="left" >{formatWhole(num.InForceTotal)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.TotalCancelPolicies)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.TotalCancelPoliciesPer)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.TotalCancelReinstate)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.TotalCancelPoliciesNetPer)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={"GWPInForce"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>GWP</TableCell>
                            <TableCell align="left" >{formatDecimal(num.InForceTotalGWP)}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.TotalCancelPoliciesGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.TotalCancelPoliciesGWPPer))}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.TotalCancelReinstateGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.TotalCancelPoliciesGWPNetPer))}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        );
}

const CancellationsTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px" , maxWidth: "70px"  }}></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Up For Renewal</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Non-Renewed</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Non-Renew %<IconPop message={"  % policies and GWP up for renewal within the selected date range that canceled on the renewal date"} /></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Reinstated<IconPop message={"  % policies non-renewed within the selected date range minus reinstatements as of today's date"} /></TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Net Non-Renew %<IconPop message={"  % policies and GWP non-renewed within the selected date range minus reinstatements as of today's date"} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={"policyCancellations8"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>Policies</TableCell>
                            <TableCell align="left" >{formatWhole(num.RenewalsReady)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.RenewalsNonRenewed)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.RenewalsNonRenewedPer)}</TableCell>
                            <TableCell align="left" >{formatWhole(num.RenewalsReinstated)}</TableCell>
                            <TableCell align="left" >{formatPercentage(num.RenewalsNonRenewedNetPer)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={"policyCancellations7"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left" style={{ fontWeight: "bold" }}>GWP</TableCell>
                            <TableCell align="left" >{formatDecimal(num.RenewalsReadyGWP)}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.RenewalsNonRenewedGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.RenewalsNonRenewedGWPPer))}</TableCell>
                            <TableCell align="left" >{formatDecimal(num.RenewalsReinstatedGWP)}</TableCell>
                            <TableCell align="left" >{formatPercentage(Math.abs(num.RenewalsNonRenewedGWPNetPer))}</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export const formatPercentage = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(num)) + "%"
}
export const formatDecimal = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return "$" + (new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(num)))
}
export const formatWhole = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(num))
}

const IconPop = (qprops) => {
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <span>
            <IconButton              
                variant="contained"
                size="small"
                style={{ color: "#25b7d3", maxHeight: "20px", position: "absolute"}}
                onClick={handleClick} component="span">
                <Help style={{ width:".75em" }} />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: "0px 10px", fontSize: "14px" }}>{qprops.message}</Typography>
            </Popover>
        </span>
    );
}
