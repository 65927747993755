import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ExportButton from "utils/CustomButtons/ExportToCVSButton";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { CallAPI, all } from "services/all/API";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });

    return matches;
};

export default function CompCollQuotes(props) {
    const didMount = React.useRef(false);
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [compCollQuotes, setCompCollQuotes] = useState([]);
    const RefreshCompCollQuotes = async () => {
        const response = await CallAPI(all.QuotesByCoverageType, {
            startDate: props.startDate,
            endDate: props.endDate,
            states: props.states,
            city: props.city,
            groupDateBy: props.groupDateBy
        });

        setCompCollQuotes(response.result);
        props.fetchCompleted();
    };

    useEffect(() => {
        if (didMount.current) {
            if (props.call == "true" || props.call == null) {
                setCompCollQuotes([]);
                RefreshCompCollQuotes();
            }
        } else {
            didMount.current = true;
        }
    }, [props.call]);

    return (
        <div>
            <Grid container>
                <Grid item
                    style={
                        isMobile ?
                            {
                                margin: "1em",
                                paddingLeft: "1em",
                                paddingRight: "1em",
                                width: "100%",
                                paddingTop: "1em",
                                backgroundColor: "#f1f1f1",
                                fontSize: "1.3em",
                                position: "relative",
                                textAlign: "center"
                            }
                            :
                            {
                                backgroundColor: "#f1f1f1",
                                width: "100%",
                                margin: "2em",
                                paddingLeft: "1.9em",
                                paddingRight: "1em",
                                paddingTop: "2em",
                                paddingBottom: "1em",
                                fontSize: "1.8em",
                                position: "relative",
                                textAlign: "center"
                            }
                    }
                >
                    <span

                    >
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#1d4c80",
                                lineHeight: "1.2em"
                            }}
                        >
                            QUOTES BY COVERAGE TYPE
                        </span>
                    </span>
                    <br />
                    <br />
                    <TableView
                        show={true}
                        data={compCollQuotes}
                        comments={props.comments ?? []}
                    />
                    <ExportButton
                        ExportName="CompCollQuotes"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const TableView = ({ show, data, comments }) => {
    let number = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    let decimal = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });

    return (
        <TableContainer component={Paper} style={{
            position: "relative",
            marginTop: "1em",
            overflowX: "auto", // Ensures horizontal scrolling
            WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS devices
            msOverflowStyle: "scrollbar" // Ensures scrollbar visibility in Windows browsers
        }}>
            <Table
                stickyHeader
                sx={{
                    width: '100%', // Set the width of the table to 100%
                    minWidth: 650 // Ensures a minimum width for larger screens
                }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", width: "100px", minWidth: "100px", lineHeight: "15px" }} align="left">Coverage Type</TableCell>
                        <TableCell style={{ lineHeight: "15px" }} align="right">Quotes</TableCell>
                        <TableCell style={{ lineHeight: "15px" }} align="right">%</TableCell>
                        <TableCell style={{ lineHeight: "15px" }} align="right">Avg Prem</TableCell>
                        <TableCell style={{ lineHeight: "15px" }} align="right">Avg Vehicles</TableCell>
                        <TableCell style={{ lineHeight: "15px" }} align="right">Bind %</TableCell>
                        {comments.length > 0 && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        let coverageType = row.coverageType.toUpperCase();

                        return (
                            <TableRow
                                key={coverageType}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={coverageType === 'TOTAL' ? { fontWeight: 'bold !important' } : {}}
                            >
                                <TableCell component="th" scope="row" style={coverageType === 'TOTAL' ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }} >
                                    {coverageType}
                                </TableCell>
                                <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold' } : {}} align="right">{number.format(row.quotes)}</TableCell>
                                <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold' } : {}} align="right">{number.format(row.quotesPerc)}%</TableCell>
                                <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold' } : {}} align="right">{currency.format(row.avgPrem)}</TableCell>
                                <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold' } : {}} align="right">{decimal.format(row.avgVehicles)}</TableCell>
                                <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold' } : {}} align="right">{number.format(row.bindPerc)}%</TableCell>
                                {comments.length > 0 && <TableCell style={coverageType === 'TOTAL' ? { fontWeight: 'bold', whiteSpace: 'pre-line' } : { whiteSpace: 'pre-line' }} align="right">{comments.find(x => x.row == row.coverageType)?.message}</TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
