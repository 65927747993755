import React, { useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';

const PaginatedTable = ({ data, meta }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns = meta.map(column => ({
        id: column.name,
        label: column.name,
        minWidth: 120,
        align: 'left',
        format: value => (column.type === "Int32" || column.type === "Float32" ? value.toLocaleString() : value)
    }));

    return (

                <Paper>
                    <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ fontSize: "14px", minWidth: column.minWidth, position: "sticky", top: 0, backgroundColor: "#fafafa" }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {columns.map(column => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Typography noWrap={true}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[
                            { value: -1, label: 'Show All' },
                            { value: 32, label: '32' },
                            { value: 25, label: '25' },
                            { value: 10, label: '10' },
                            { value: 5, label: '5' },
                        ]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
    );
};

export default PaginatedTable;
