import React,{useEffect} from 'react';
import { BannerBoxStyle } from './LandingComponents_Style';
import GridContainer from 'utils/Grid/GridContainer';
import GridItem from "utils/Grid/GridItem";
import { Grid } from "@material-ui/core";
import { Dynamic_Style } from 'components/all/Dynamic_Style';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TooltipButton from "../TooltipButton/TooltipButton";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export function BannerBox(props) {
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const data = props.children ?? props.Data;

    return (
        <Grid container item spacing={1} xs
            style={{
                ...(props.Dotted ? BannerBoxStyle.ContainerDotted : BannerBoxStyle.ContainerNormal),
                ...props.style
            }}
        >
            <Grid item xs={12} style={props.Popover && {display:"flex",justifyContent: "center"}}>
                {props.Heading}
                {props.Popover &&
                    <TooltipButton
                        tooltipText={props.Popover.text || props.Popover}
                        icon={props.Popover.icon || props.PopoverIcon}
                        style={props.Popover.style}
                    />
                }
            </Grid>
            <Grid item xs={12} style={BannerBoxStyle.ItemData}>
                {typeof (data ?? 0) === 'number' ? nf.format(data ?? 0) : data}
            </Grid>
            {(props.Footer || props.Footer === 0) &&
                <Grid item xs={12} style={BannerBoxStyle.ItemFooter}>
                    ({nf.format(props.Footer ?? 0)}%)
                </Grid>
            }
        </Grid>
    );
}

export function BannerTitle(props){
    //const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 1152px)');
    return(
        <Grid item lg xs={11} style={{backgroundColor: "#f1f1f1"}}>
            <GridContainer  style={isMobile ? Dynamic_Style.BannerTitleContainer_Header_Mobile:Dynamic_Style.BannerTitleContainer_Header_Desktop}>
                <GridItem lg={5}>
                    {props.CustomHeader === undefined ? (<span>NEW <b>{props.Header}</b></span>) : props.CustomHeader}
                </GridItem>
            </GridContainer>
            {props.SubHeader && 
            <GridContainer  style={isMobile ? Dynamic_Style.BannerTitleContainer_SubHeader_Mobile : Dynamic_Style.BannerTitleContainer_SubHeader_Desktop}>
                <GridItem lg={8}                         >
                <b>{props.SubHeader}</b>
                {props.SubHeaderPopover && 
                    <TooltipButton tooltipText={props.SubHeaderPopover} />
                }
                </GridItem>
            </GridContainer>}
        </Grid>
    );
}

export function StateSelector(props){
    const isMobile = useMediaQuery('(max-width: 1152px)');
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 150,
            color:'primary'
          },
        },
      };

    return (
        <div style={{textAlign:'center',marginBottom:"-22px"}}> 
          <FormControl style={{minWidth: 120,maxWidth: 300,textAlign: 'center'}}>
            <Select
              labelId="multiState-label"
              id="mutlistateSelect"
              multiple primary
              value={props.StateSelections}
              onChange={props.UpdateStates}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {props.States.map((state) => (
                <MenuItem key={state} value={state}>
                  <Checkbox checked={props.StateSelections.indexOf(state) > -1} />
                  <ListItemText primary={state} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </div>
      );
}