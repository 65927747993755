import {all} from '../APIConfig'
import axios from "axios";

const getAuthHeader = (username, password) => {
  const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
  return `Basic ${token}`;
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

var headers = {
    "Content-Type": "application/json",
    "Authorization" : getAuthHeader(getCookie("UserGroup"),getCookie("pwd"))
  };



export const Login = async (passcode) => {
    const { method, url, ...props } = all.Login;
    const data = JSON.stringify({
      PassCode: passcode,
      ...props,
    })
    
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
    console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("Login", apiResponse);
    } catch (error) {
      console.error("Login", error);
      return { ResponseCode: error };
    }
    if (apiResponse.data.isSuccess){
      headers["Authorization"] = getAuthHeader(apiResponse.data.userGroup,"");
    }
 
    return apiResponse.data;
  };

  export const GetEnvironmentService = async (env) => {
    const { method, url, ...props } = all.EnvironmentService;
    const data = JSON.stringify({
      Env: env,
      ...props,
    })
    
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
    console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetEnvironmentService", apiResponse);
    } catch (error) {
      console.error("GetEnvironmentService", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

export const GetQuoteData = async (startDate, endDate, state, getQuoteType) => {
    const { method, url, ...props } = all.GetQuoteData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        getQuoteType:getQuoteType,
      ...props,
    })

    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetQuoteData", apiResponse);
    } catch (error) {
      console.error("GetQuoteData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetAppData = async (startDate, endDate, state,groupDateBy) => {
    const { method, url, ...props } = all.GetAppData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        groupDateBy:groupDateBy,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetAppData", apiResponse);
    } catch (error) {
      console.error("GetAppData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetPolicyData = async (startDate, endDate, state,groupDateBy) => {
    const { method, url, ...props } = all.GetPolicyData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        groupDateBy:groupDateBy,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetPolicyData", apiResponse);
    } catch (error) {
      console.error("GetPolicyData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetYTDData = async (startDate, endDate, state,getQuoteType) => {
    const { method, url, ...props } = all.GetYTDData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        getQuoteType:getQuoteType,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetYTDData", apiResponse);
    } catch (error) {
      console.error("GetYTDData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetGWPData = async (startDate, endDate, state,isPP) => {
    const { method, url, ...props } = all.GetGWPData;
    const data = JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        states:state,
        isPP:isPP,
      ...props,
    })
  
    const config = {
      method,
      url,
      headers,
      data,
    };
    var apiResponse;
  console.log(data);
    try {
      apiResponse = await axios(config);
      console.log("GetGWPData", apiResponse);
    } catch (error) {
      console.error("GetGWPData", error);
      return { ResponseCode: error };
    }
    return apiResponse.data;
  };

  export const GetEnv = () => {
    if (typeof window !== 'undefined') {
      switch (window.location.hostname) {
        case 'localhost':
          return 'dev' //http://localhost:57761
        case 'dashboarduat.cure.com':
          return 'uat'
        case 'dashboardprep.cure.com':
          return 'prod'
        case 'dashboard.cure.com':
          return 'prod'
        default:
          return 'dev'
      }
    }
}

export const CallAPI = async (config, request) => {
    const { method, url, ...props } = config;

    const data = JSON.stringify({
        ...request,
        ...props,
    });

    var apiResponse;

    try {
        apiResponse = await axios({
            method,
            url,
            headers,
            data,
        });
    } catch (error) {
        console.error(url, error);
        return { ResponseCode: error };
    }

    return apiResponse.data;
}

export { all } from '../APIConfig';