import React, { useState, useEffect, useCallback } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, FormLabel } from '@material-ui/core';
import axios from 'axios';

const FilterProjectReleases = ({ startDate, endDate, onFilterChange, labelStyle }) => {
    const [releaseTypes, setReleaseTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [allReleases, setAllReleases] = useState([]);

    useEffect(() => {
        fetchAllReleases();
    }, []);

    const fetchAllReleases = async () => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate());
        
        try {
            const response = await axios.get('/GetSharedDriveData/SearchReleases', {
                params: {
                    query: '',
                    startDate: '2016-01-01',
                    endDate: futureDate.toISOString().split('T')[0]
                }
            });
            setAllReleases(response.data.data);
            
            // Extract unique categories and sort them alphabetically
            const uniqueCategories = [...new Set(response.data.data.map(release => release.Category))].sort();
            setReleaseTypes(uniqueCategories);
            setSelectedTypes(uniqueCategories); // Select all categories by default
        } catch (error) {
            console.error('Error fetching releases:', error);
        }
    };

    useEffect(() => {
        onFilterChange(allReleases, selectedTypes);
    }, [allReleases, selectedTypes, onFilterChange]);

    const handleTypeChange = (event) => {
        const value = event.target.value;
        if (value.includes('all')) {
            setSelectedTypes(selectedTypes.length === releaseTypes.length ? [] : releaseTypes);
        } else {
            setSelectedTypes(value);
        }
    };

    const handleSelectOpen = () => {
        setTimeout(() => {
            const menuList = document.querySelector('.MuiMenu-list');
            if (menuList) {
                menuList.scrollTop = 0;
            }
        }, 0);
    };

    const menuItemStyle = {
        padding: '2px 16px',
        minHeight: '32px',
    };

    const checkboxStyle = {
        padding: '0 8px 0 0',
    };

    return (
        <FormControl style={{ width: "100%" }}>
            <FormLabel component="p" style={labelStyle}>Categories</FormLabel>
            <Select
                multiple
                value={selectedTypes}
                onChange={handleTypeChange}
                onOpen={handleSelectOpen}
                displayEmpty
                renderValue={(selected) => `${selected.length} selected`}
                SelectDisplayProps={{ 'style': { 'background': 'white', 'padding': '10px', 'paddingLeft': '15px' } }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: {
                            maxHeight: 'none',
                            width: 220,
                        },
                    },
                    MenuListProps: {
                        style: {
                            padding: 0,
                        },
                        autoFocus: true,
                        variant: 'menu',
                    },
                }}
            >
                <MenuItem
                    value="all"
                    style={{
                        ...menuItemStyle,
                        backgroundColor: selectedTypes.length === releaseTypes.length ? 'rgba(0, 0, 0, 0.08)' : 'inherit'
                    }}
                >
                    <Checkbox
                        checked={selectedTypes.length === releaseTypes.length}
                        style={checkboxStyle}
                    />
                    <ListItemText primary="Select All" />
                </MenuItem>
                {releaseTypes.map((type) => (
                    <MenuItem key={type} value={type} style={menuItemStyle}>
                        <Checkbox
                            checked={selectedTypes.indexOf(type) > -1}
                            style={checkboxStyle}
                        />
                        <ListItemText primary={type} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterProjectReleases;