import React, { useEffect } from "react";
import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import { Dynamic_Style } from "./Dynamic_Style";
import { GetBIQuotesData } from "services/NJ/API";
import { GetBIPoliciesData } from "services/NJ/API";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

export default function BISelections(props) {
    const didMount = React.useRef(false);
    const defaultData = [
        { bIlimit: "$0", total: 0, percentage: 0 },
        { bIlimit: "$10K", total: 0, percentage: 0 },
        { bIlimit: "$15k/$30k, $20k/$40k", total: 0, percentage: 0 },
        { bIlimit: "$25K/$50K", total: 0, percentage: 0 },
        { bIlimit: "$50K/$100K", total: 0, percentage: 0 },
        { bIlimit: "$100K+", total: 0, percentage: 0 }
    ];
    const [BIdata, setBIdata] = React.useState(defaultData);

    const RefreshData = async () => {
        let data = null;

        if (props.Type === "Quotes")
            data = await GetBIQuotesData(props.startDate, props.endDate, props.states);
        else
            data = await GetBIPoliciesData(props.startDate, props.endDate, props.states);

        if (data) {
            setBIdata(data.result);
        }
    }

    const isMobile = useMediaQuery('(max-width: 1152px)');

    useEffect(() => {
        async function fetchData() {
            if (props.call === "true" || props.call == null) {
                setBIdata(defaultData);

                await Promise.all([RefreshData()]).then(() => {
                    props.fetchCompleted();
                }).catch(error => {
                    console.error("Error fetching data: ", error);
                    // Handle any errors here
                });
            }
        }

        if (didMount.current) {
            fetchData();
        } else {
            didMount.current = true;
        }
    }, [props.call]);

    return (
            <Grid item lg={6} xs={12}>
                <div>
                    <Grid container>
                        <Grid item style={ isMobile ? Dynamic_Style.BISelection_Container_Mobile : Dynamic_Style.BISelection_Container_Desktop }>
                            <span>
                                <span style={{fontWeight: "bold",color: "#1d4c80"}}>
                                    BODILY INJURY SELECTIONS - {props.Type.toUpperCase()}
                                </span>
                            </span>
                            <br />
                            <br />
                            <TableContainer component={Paper} style={{ position: "relative", marginTop: "1em" }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row"></TableCell>
                                            {BIdata.map((item) => <TableCell><span dangerouslySetInnerHTML={{ __html: item.bIlimit.replace(", ", ",<br />") }}></span></TableCell>)}
                                            {props.comments?.length > 0 && <TableCell></TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">Total</TableCell>
                                            {BIdata.map((item) => <TableCell>{item.total == null ? 0 : item.total}</TableCell>)}
                                            {props.comments?.length > 0 && <TableCell style={{ whiteSpace: 'pre-line' }}>{props.comments?.find(x => x.row == 'Total')?.message}</TableCell>}
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">%</TableCell>
                                            {BIdata.map((item) => <TableCell>{(item.percentage == null ? 0 : item.percentage).toFixed(1)}%</TableCell>)}
                                            {props.comments?.length > 0 && <TableCell style={{ whiteSpace: 'pre-line' }}>{props.comments?.find(x => x.row == '%')?.message}</TableCell>}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </div>
        </Grid>
    );
}
