import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import TablePagination from '@mui/material/TablePagination';
import { Hidden } from "@material-ui/core";
import ExportButton from "utils/CustomButtons/ExportToCVSButton";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};

var obj;
export default function Projections(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [loading, setLoading] = React.useState(true);
    const [dataMI, setDataMI] = React.useState([]);
    const [dataNJ, setDataNJ] = React.useState([]);
    const [dataCombined, setDataCombined] = React.useState([]); //MI and NJ (2024 onward)

    const GetData = () => {
        fetch('../GetProjectionsData/Projections')
            .then(res => res.json())
            .then(data => obj = data)
            .then(() => {
                setDataMI(obj.dataMI.result);
                setDataNJ(obj.dataNJ.result);
                setDataCombined(obj.dataCombined.result);
                setLoading(false);
            });
    };

    useEffect(() => {
        GetData();
    }, []);


    return (
        <Grid container> {/* Quotes Table */}
            <Grid item lg={12} xs={12}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Hidden smDown>
                            <h1 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)", fontSize: "2.7em" }} >GROSS WRITTEN PREMIUM - PROJECTED VS ACTUAL</h1>
                        </Hidden>
                        <Hidden mdUp>
                            <h3 style={{ textAlign: "center", fontWeight: "bold", color: "rgb(29, 76, 128)" }} >GROSS WRITTEN PREMIUM - PROJECTED VS ACTUAL</h3>
                        </Hidden>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={12} lg={3} ></Grid>
                    </Hidden>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "0em",
                                    padding: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.3em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "1em",
                                    padding: "1em",
                                    fontSize: "2.5em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                TOTAL - MICHIGAN AND NEW JERSEY COMBINED
                            </span>
                        </span>

                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <TotalTableView
                                data={dataCombined}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_Combined"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                NEW BUSINESS - MICHIGAN AND NEW JERSEY COMBINED
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <NewBusinessTableView
                                data={dataCombined}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="New_Business_Combined"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                RENEWALS - MICHIGAN AND NEW JERSEY COMBINED
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <RenewalsTableView
                                data={dataCombined}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Renewals_Combined"
                        />
                    </Grid>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "0em",
                                    padding: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.3em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "1em",
                                    padding: "1em",
                                    fontSize: "2.5em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                TOTAL - MICHIGAN
                            </span>
                        </span>

                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <TotalTableView
                                data={dataMI}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_MI"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                NEW BUSINESS - MICHIGAN
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <NewBusinessTableView
                                data={dataMI}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="New_Business_MI"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                RENEWALS - MICHIGAN
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <RenewalsTableView
                                data={dataMI}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Renewals_MI"
                        />
                    </Grid>
                    <Grid item
                        style={
                            isMobile ?
                                {
                                    margin: "0em",
                                    padding: "1em",
                                    paddingRight: "1em",
                                    width: "100%",
                                    paddingTop: "1em",
                                    backgroundColor: "#f1f1f1",
                                    fontSize: "1.3em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                                :
                                {
                                    backgroundColor: "#f1f1f1",
                                    width: "100%",
                                    margin: "1em",
                                    padding: "1em",
                                    fontSize: "2.5em",
                                    position: "relative",
                                    textAlign: "center"
                                }
                        }
                    >
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                TOTAL - NEW JERSEY
                            </span>
                        </span>

                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <TotalTableView
                                data={dataNJ}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Total_NJ"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                NEW BUSINESS - NEW JERSEY
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <NewBusinessTableView
                                data={dataNJ}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="New_Business_NJ"
                        />
                        <br />
                        <br />
                        <span
                            style={{ fontSize: ".7em", marginTop: "1em" }}
                        >
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "#1d4c80"
                                }}
                            >
                                RENEWALS - NEW JERSEY
                            </span>
                        </span>
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                        >
                            <RenewalsTableView
                                data={dataNJ}
                            />
                        </LoadingOverlay>
                        <ExportButton
                            ExportName="Renewals_NJ"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}


const NewBusinessTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat();
    //related to the popup
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px", maxWidth: "70px" }}>Month</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Projected</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Actual</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Variance</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.datename}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={row.columntype == "BTOT" ? { backgroundColor: "lightgrey" } : {}}
                                >
                                    <TableCell component="th" scope="row" style={row.columntype == "BTOT" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "lightgrey" } : row.columntype == "TOT" || row.columntype == "CTOT" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.datename} {row.columntype == "CTOT" && <IconPop message={" MTD based on number of days passed within current month"} />} {row.columntype == "BTOT" && <IconPop message={" Full year projected total"} />}
                                    </TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.nb_projected)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.nb_actual)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatPercentage(row.nb_variance)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

const RenewalsTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat();
    //related to the popup
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px", maxWidth: "70px" }}>Month</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Projected</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Actual</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Variance</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.datename}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={row.columntype == "BTOT" ? { backgroundColor: "lightgrey" } : {}}
                                >
                                    <TableCell component="th" scope="row" style={row.columntype == "BTOT" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "lightgrey" } : row.columntype == "TOT" || row.columntype == "CTOT" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }: { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.datename}{row.columntype == "CTOT" && <IconPop message={" MTD based on number of days passed within current month"} />} {row.columntype == "BTOT" && <IconPop message={" Full year projected total"} />}
                                    </TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.rn_projected)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.rn_actual)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatPercentage(row.rn_variance)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

const TotalTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    var nf = new Intl.NumberFormat();
    //related to the popup
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Paper>
            <TableContainer style={{ position: "relative", marginTop: "1em", maxHeight: "400px" }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "70px", maxWidth: "70px" }}>Month</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Projected</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Actual</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Variance</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {num.slice((page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage, (page > 0 && num.length < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.datename}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={row.columntype == "BTOT" ? { backgroundColor: "lightgrey" } : {}}
                                >
                                    <TableCell component="th" scope="row" style={row.columntype == "BTOT" ? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "lightgrey" } : row.columntype == "TOT" || row.columntype == "CTOT"? { fontWeight: "800", position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" } : { position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>
                                        {row.datename}{row.columntype == "CTOT" && <IconPop message={" MTD based on number of days passed within current month"} />} {row.columntype == "BTOT" && <IconPop message={" Full year projected total"} />}
                                    </TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.tot_projected)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatDecimal(row.tot_actual)}</TableCell>
                                    <TableCell align="left" style={(row.columntype?.includes("TOT") || false) ? { fontWeight: "800" } : {}}>{formatPercentage(row.tot_variance)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}
                component="div"
                count={num.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && num.length < rowsPerPage ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export const formatPercentage = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return parseFloat(new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num)).toFixed(1) + "%"
}
export const formatDecimal = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return "$" + (new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num))
}
export const formatWhole = (num) => {
    if (isNaN(num) || num === undefined) { return "" }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(num))
}

const IconPop = (qprops) => {
    const id = open ? 'simple-popover' : undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <span>
            <IconButton
                variant="contained"
                size="small"
                style={{ color: "#25b7d3", maxHeight: "20px", position: "absolute" }}
                onClick={handleClick} component="span">
                <Help style={{ width: ".75em" }} />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: "0px 10px", fontSize: "14px" }}>{qprops.message}</Typography>
            </Popover>
        </span>
    );
}
