import { Grid, Switch, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import { Help, ArrowUpward, ArrowDownward } from "@material-ui/icons";
//core components
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
// @material-ui/core components
import TablePagination from '@mui/material/TablePagination';
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Card from "utils/Card/Card";
import CardHeader from "utils/Card/CardHeader";
import CardIcon from "utils/Card/CardIcon";
import CardBody from "utils/Card/CardBody";
import ChartistGraph from "react-chartist";
import CardFooter from "utils/Card/CardFooter";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { Pie } from 'react-chartjs-2';
import { styled } from '@material-ui/styles';
import marker from "assets/img/marker.png";
import { Rotate90DegreesCcw } from "@material-ui/icons";

const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });
    return matches;
};
var obj;
export default function Comparison(props) {

    const isMobile = useMediaQuery('(max-width: 1152px)');
    const [data, setData] = React.useState([]);
    const [loading,setLoading] = React.useState(false);
    const GetData = () => {
        fetch('../GetComparison?' + new URLSearchParams({
            startDateA: props.startDateA,
            endDateA: props.endDateA,
            startDateB: props.startDateB,
            endDateB: props.endDateB,
            state: props.state,
            city: props.city, // Add city parameter
        }))
            .then(res => res.json() )
            .then(data => obj = data )
            .then(() => {
                setData(obj.aggComparisonResult);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (props.call == "true" || props.call == null) {
            GetData();
            setLoading(true);
        }
      
    }, [props.call]);

    return (
        <Grid container > {/* Quotes Table */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item
                            style={
                                isMobile ?
                                    {
                                        padding:"1em",
                                        paddingRight:"1em",
                                        width: "100%",
                                        paddingTop: "1em",
                                        backgroundColor: "#f1f1f1",
                                        fontSize: "1.3em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                                    :
                                    {
                                        backgroundColor: "#f1f1f1",
                                        minWidth: "500px",
                                        width: "100%",
                                        margin: "1em",
                                        padding: "1em",
                                        fontSize: "2.5em",
                                        position: "relative",
                                        textAlign: "center"
                                    }
                            }
                        >
                            
                            <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    position: "absolute!important"
                                })
                            }}
                            spinner={<ReactLoading type={'spin'} height={50} width={50} />}
                            >
                            <ComparisonTableView
                                data={data}
                                groupDateBy={props.groupDateBy}
                                state={props.state}
                                city={props.city}
                                showAgency={props.state != "PA" && !props.city}
                            />
                            </LoadingOverlay>

                        </Grid>
                    </Grid>
            </Grid>

        </Grid>
    );
}


const ComparisonTableView = (qprops) => {
    const num = qprops.data ? qprops.data : [];
    
    var nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    var nfnz = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    //related to the popup
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(32);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function CompareRow(props) {
        const changeValue = ((props.ValueB - props.ValueA) * 100 / props.ValueA);
        const showPercentage = ["Total Quotes", "Quick", "Custom", "CRC", "Agency"].includes(props.Key);

        const formatBindValue = (value) => {
            if (showPercentage) {
                return (value != null && value !== "") ? value + "%" : "";
            }
            return value;
        };

        return <TableRow
            key={props.Key}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" style={{ position: "sticky", top: "0", left: "0", Zindex: "20", backgroundColor: "#fafafa" }}>{props.Title.indexOf("Total") != 0 && props.Title.indexOf("Average") != 0 ? "\u2002 \u2002" + props.Title : props.Title} </TableCell>
            <TableCell align="left">{(props.ValueA != null) && (props.IsPremium == true) ? "$" + nfnz.format(props.ValueA) : props.ValueA}</TableCell>
            <TableCell align="left">{formatBindValue(props.ValueBindA)}</TableCell>
            <TableCell align="left">{(props.ValueB != null) &&(props.IsPremium == true) ? "$" + nfnz.format(props.ValueB) : props.ValueB}</TableCell>
            <TableCell align="left">{formatBindValue(props.ValueBindB)}</TableCell>
            <TableCell align="left" style={changeValue > 0 ? { color: "green" } : { color: "red" }}>{props.ValueA != null && props.ValueB != null && (changeValue > 0 ? <ArrowUpward style={{ marginBottom: "-7px" }} /> : <ArrowDownward style={{ marginBottom: "-7px" }}/>)}{props.ValueA != null && props.ValueB != null && (nf.format(changeValue) + "%")}</TableCell>
        </TableRow>;
    }

    return (
        <Paper>
            <TableContainer style={{ position: "relative"}}>
                <Table stickyHeader sx={{  }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: "sticky", top: "0", left: "0", zIndex: "20", backgroundColor: "#fafafa", fontSize: "14px", Width: "120px", minWidth: "120px" }}>
                                {qprops.state === "NJ" ? "NEW JERSEY" : 
                                 qprops.state === "all" ? "ALL STATES" : 
                                 qprops.state === "PA" ? "PENNSYLVANIA" : 
                                 qprops.state === "MI" ? (qprops.city === "Detroit" ? "DETROIT" : "MICHIGAN") : 
                                 "MICHIGAN"}
                            </TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Range A</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", Width: "120px", minWidth: "120px", padding: "5px" }}>Bind %
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    style={{ color: "#25b7d3", maxHeight: "10px", position: "absolute" }}
                                    onClick={handleClick} component="span">
                                    <Help />
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography>Percentage of policies bound to-date, based on the specific quotes from each applicable time period.</Typography>
                                </Popover>
                            </TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Range B</TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", Width: "120px", minWidth: "120px", padding: "5px" }}>Bind %
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    style={{ color: "#25b7d3", maxHeight: "10px", position: "absolute" }}
                                    onClick={handleClick} component="span">
                                    <Help />
                                </IconButton>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography>Percentage of policies bound to-date, based on the specific quotes from each applicable time period.</Typography>
                                </Popover>
                            </TableCell>
                            <TableCell align="left" style={{ fontSize: "14px", Width: "120px", minWidth: "120px" }}>Change</TableCell>
                            
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <CompareRow Key="Total Quotes" Title="Total Quotes" ValueA={num.totalQuotesA} ValueBindA={num.totalQuotesBindA} ValueB={num.totalQuotesB} ValueBindB={num.totalQuotesBindB} IsPremium={false} />
                        <CompareRow Key="Quick" Title="Quick" ValueA={num.quickA} ValueBindA={num.quickBindA} ValueB={num.quickB} ValueBindB={num.quickBindB} IsPremium={false} />
                        <CompareRow Key="Custom" Title="Custom" ValueA={num.customA} ValueBindA={num.customBindA} ValueB={num.customB} ValueBindB={num.customBindB} IsPremium={false} />
                        <CompareRow Key="CRC" Title="CRC" ValueA={num.crca} ValueBindA={num.crcBindA} ValueB={num.crcb} ValueBindB={num.crcBindB} IsPremium={false} />
                        {qprops.showAgency && <CompareRow Key="Agency" Title="Agency" ValueA={num.agencyA} ValueBindA={num.agencyBindA} ValueB={num.agencyB} ValueBindB={num.agencyBindB} IsPremium={false} />}

                        <CompareRow Key={"TotalBoundPolicies"} Title={"Total Policies"} ValueA={num.totalPoliciesA} ValueBindA={""} ValueB={num.totalPoliciesB} ValueBindB={""} IsPremium={false} />
                        <CompareRow Key={"Online"} Title={"Online"} ValueA={num.policyOnlineA} ValueBindA={""} ValueB={num.policyOnlineB} ValueBindB={""} IsPremium={false} />
                        <CompareRow Key={"PolicyCRC"} Title={"CRC"} ValueA={num.policyCRCA} ValueBindA={""} ValueB={num.policyCRCB} ValueBindB={""} IsPremium={false} />
                        <CompareRow Key={"PolicyCombo"} Title={"Combo"} ValueA={num.policyComboA} ValueBindA={""} ValueB={num.policyComboB} ValueBindB={""} IsPremium={false} />
                        {qprops.showAgency && <CompareRow Key={"PolicyAgency"} Title={"Agency"} ValueA={num.policyAgencyA} ValueBindA={""} ValueB={num.policyAgencyB} ValueBindB={""} IsPremium={false} />}

                        <CompareRow Key={"TotalPremium"} Title={"Total Premium"} ValueA={num.totalPremA} ValueBindA={""} ValueB={num.totalPremB} ValueBindB={""} IsPremium={true}  />
                        <CompareRow Key={"OnlinePrem"} Title={"Online"} ValueA={num.onlinePremA} ValueBindA={""} ValueB={num.onlinePremB} ValueBindB={""} IsPremium={true}  />
                        <CompareRow Key={"CRCPrem"} Title={"CRC"} ValueA={num.crcPremA} ValueBindA={""} ValueB={num.crcPremB} ValueBindB={""} IsPremium={true}  />
                        <CompareRow Key={"ComboPrem"} Title={"Combo"} ValueA={num.comboPremA} ValueBindA={""} ValueB={num.comboPremB} ValueBindB={""} IsPremium={true} />
                        {qprops.showAgency && <CompareRow Key={"AgencyPrem"} Title={"Agency"} ValueA={num.agencyPremA} ValueBindA={""} ValueB={num.agencyPremB} ValueBindB={""} IsPremium={true} />}

                        <CompareRow Key={"AvgPremium"} Title={"Average Premium"} ValueA={num.avgTotalPremA} ValueBindA={""} ValueB={num.avgTotalPremB} ValueBindB={""} IsPremium={true} />
                        <CompareRow Key={"AvgOnlinePrem"} Title={"Online"} ValueA={num.avgOnlinePremA} ValueBindA={""} ValueB={num.avgOnlinePremB} ValueBindB={""} IsPremium={true} />
                        <CompareRow Key={"AvgCRCPrem"} Title={"CRC"} ValueA={num.avgCRCPremA} ValueBindA={""} ValueB={num.avgCRCPremB} ValueBindB={""} IsPremium={true} />
                        <CompareRow Key={"AvgComboPrem"} Title={"Combo"} ValueA={num.avgComboPremA} ValueBindA={""} ValueB={num.avgComboPremB} ValueBindB={""} IsPremium={true} />
                        {qprops.showAgency && <CompareRow Key={"AvgAgencyPrem"} Title={"Agency"} ValueA={num.avgAgencyPremA} ValueBindA={""} ValueB={num.avgAgencyPremB} ValueBindB={""} IsPremium={true} />}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<TablePagination*/}
            {/*    rowsPerPageOptions={[
                    { value: -1, label: 'Show All' },
                    { value: 32, label: '32' },
                    { value: 25, label: '25' },
                    { value: 10, label: '10' },
                    { value: 5, label: '5' },
                ]}*/}
            {/*    component="div"*/}
            {/*    count={num.length}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page > 0 && num.length < rowsPerPage ? 0 : page }*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
            </Paper>
        );
}